import React, { useEffect } from 'react';
import * as S from '../MobileHeader.style';
import { useNavigate } from 'react-router-dom'; // useNavigate import 추가
import { TbArrowBack } from 'react-icons/tb';
import { useRecoilState } from 'recoil';
import { mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { amlQueryParamsRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';

import { useEachChatRoomHook } from '@/components/hooks/customs/useEachChatRoomHook';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';

const MessageAddHeader = () => {
  const navigate = useNavigate();
  const eachHooks = useEachChatRoomHook();

  // 추가한 대화상대 리코일로 관리
  const [mobileBuddyListAddS, setMobileBuddyListAddS] = useRecoilState(mobileBuddyListAddRecoil);

  /* messageHistory api 요청 params */
  const [, setMhQueryParams] = useRecoilState(mhQueryParamsRecoil);

  //처음 마운트 될때 추가된 대화상대 리스트 지워버리기
  useEffect(() => {
    setMobileBuddyListAddS({});
  }, []);

  // ================================================================
  // 대화 상대를 선택 한 후 확인 버튼을 눌렀을 때 실행될 함수
  // - 기능: 선택한 상대의 번호로 대화방 페이지로 이동
  // ================================================================

  const onClickCreateChatRoom = async () => {
    // 선택된 대상자의 keyCommNo(전화번호) 가져오기
    const selectedBuddyKeyCommNo = mobileBuddyListAddS ? mobileBuddyListAddS.keyCommNo : null;
    const selectedBuddyBuddyNm = mobileBuddyListAddS ? mobileBuddyListAddS.buddyNm : null;

    if (selectedBuddyKeyCommNo && selectedBuddyBuddyNm) {
      navigate(`/mobile/message/messageChatRoom/${selectedBuddyKeyCommNo}`);
      setMhQueryParams((prevState: any) => {
        return {
          ...prevState,
          contactNumber: selectedBuddyKeyCommNo,
        };
      });
    }
  };

  return (
    <>
      <S.HeaderLeft className="addChatHeader">
        <S.HeaderButtonIcon
          onClick={() => {
            navigate(-1);
          }}
        >
          <IconArrowLongLeft />
        </S.HeaderButtonIcon>
        <S.HeaderTitle>대화 상대 추가</S.HeaderTitle>
      </S.HeaderLeft>

      <S.IconContainer>
        <S.HeaderButtonFont
          onClick={() => {
            onClickCreateChatRoom();
          }}
          className={Object.keys(mobileBuddyListAddS).length === 0 ? '' : 'active'}
        >
          확인
        </S.HeaderButtonFont>
      </S.IconContainer>
    </>
  );
};

export default MessageAddHeader;
