/* eslint-disable */
import { useRecoilState } from 'recoil';

import { useEffect } from 'react';

import { useSend015Chat } from '@/components/hooks/customs/messageSend/useSend015Chat';
import { IChatRoom015Foot } from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Foot/ChatRoom015Foot.types';
import { mhQueryParamsRecoil, rcvMsgPopupToggleRecoil } from '@/recoil/atoms/chatRoom';
import { acceptedFile015Types } from '@/shared/constants';

export const useChatRoom015Foot = (props: IChatRoom015Foot) => {
  // 메세지 내역 표출되는 채팅방 바디 useRef
  const chatRoomRef: HTMLDivElement = props.chatRoomRef.current as HTMLDivElement;
  // 채팅방 하단에 메세지 도착했다는 팝업 표출 toggle state
  const [rcvMsgPopupToggle] = useRecoilState(rcvMsgPopupToggleRecoil);
  /* 좌측 채팅방 리스트 API params - 해당 recoil 에 담겨있는 상대방 번호 사용 */
  const [mhQueryParamsS] = useRecoilState(mhQueryParamsRecoil);

  /* 015 채팅방 메세지 전송 hook */
  const {
    resetMsgState,
    sendMsgText,
    cancelImgFile,
    sendImgFileUrl,
    textAreaRef,
    onChangeSendMsgText,
    onChangeUploadImg,
    sendImgFileRef,
    sendMsgInfoL,
    sendMsgL,
    sendMsgHandler,
    selectNumberS,
    sendMsgByte,
    onCheckMsgType,
    sendImgFile,
    isProcessingToSend,
    adMessageS,
    setAdMessageS,
    msgTypeS,
    setMsgTypeS,
    byteS,
    setByteS,
    onClickInputFile,
  } = useSend015Chat();

  // accept 속성을 위한 문자열 생성
  const acceptFile015Types = acceptedFile015Types.join(', ');

  // ==========================================================
  // active 된 채팅방의 상대방 번호가 달라지면 메세지 전송에 관련된 정보 초기화
  // ==========================================================
  useEffect(() => {
    resetMsgState();
  }, [mhQueryParamsS.contactNumber]);

  /**
   * @title 새로운 메세지 팝업 클릭시 하단으로 이동 Hook
   * */
  const onClickRcvPopup = () => {
    const chatRoomRefCurrent = props.chatRoomRef.current;
    if (!chatRoomRefCurrent) return;
    chatRoomRefCurrent.scrollTop = chatRoomRefCurrent.scrollHeight; // 맨 아래
  };

  return {
    chatRoomRef,
    rcvMsgPopupToggle,
    onClickRcvPopup,
    sendImgFileUrl,
    cancelImgFile,
    sendMsgText,
    textAreaRef,
    onChangeSendMsgText,
    onChangeUploadImg,
    sendImgFileRef,
    sendMsgL,
    sendMsgInfoL,
    sendMsgHandler,
    selectNumberS,
    sendMsgByte,
    onCheckMsgType,
    sendImgFile,
    isProcessingToSend,
    adMessageS,
    setAdMessageS,
    msgTypeS,
    setMsgTypeS,
    byteS,
    setByteS,
    acceptFile015Types,
    onClickInputFile,
  };
};
