import styled from 'styled-components';

export const Notification = styled.div`
  z-index: 3;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  padding: 16px 24px;
  font-size: 1.6rem;
  border-radius: 14px;
  background: #474747;
  color: #fff;
  line-height: 1.6em;
`;

export const OffButton = styled.div`
  position: absolute;
  width: 100%;
  top: calc(100% - 7rem);
  > button {
    cursor: pointer;
    position: absolute;
    padding: 0;
    top: 6px;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    width: 24px;
    height: 24px;
    background: #474747;
    border-radius: 50px;
    border: 2px solid #fff;
    :hover {
      background: #333;
    }
  }

  @media screen and (max-width: 360px) {
    top: 14rem;
  }
`;

// 탭 i 스타일
export const IButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border: 2px solid ${(props) => props.theme.colors.gray500};
  border-radius: 30px;
  color: ${(props) => props.theme.colors.gray500};
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: 0;

  :hover {
    color: ${(props) => props.theme.colors.txt400};
    border-color: ${(props) => props.theme.colors.txt400};
  }
`;

// 팝업창  [ 소프트폰내 탭 i 클릭시 나오는 컴포넌트 ]
export const NoticeButton = styled.div`
  z-index: 1;
  .iButton {
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.8rem;
  }
  > div {
    position: absolute;
    top: 36px;
    right: 20px;
    width: 90%;
    max-width: 430px;
    background: #474747;
    color: #fff;
    padding: 20px;
    border-radius: 20px;
    font-size: 1.2rem;
    > button {
      width: 24px;
      height: 24px;
      right: -8px;
      top: -8px;
    }
    @media screen and (max-width: 1440px) {
      right: 5%;
    }
  }
`;

// ARS i 버튼 클릭시 나오는 컴포넌트
export const NoticeArs = styled.div`
  text-indent: 0;
  > p {
    margin-bottom: 6rem;
    font-size: 1.5rem;
    line-height: 1.6em;
    font-weight: 600;
    color: #dadada;
  }
  .group {
    position: relative;
    display: flex;
    align-items: center;
    > div {
      padding: 0 8px;
    }
    .arrow {
      position: absolute;
      top: calc(50% + 20px);
      transform: translateY(-50%);
      padding: 10px;
      cursor: pointer;
    }
    .arrowLeft {
      left: -18px;
    }
    .arrowRight {
      right: -18px;
    }
    h4 {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.6rem;
      line-height: 1.6em;
    }
  }

  /* 페이지 넘기는 버튼 스타일 */
  .pageCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 30px;
    span {
      width: 6px;
      height: 6px;
      background: #6a6a6a;
      border-radius: 50px;
      &.active {
        background: #d9d9d9;
      }
    }
  }
`;
