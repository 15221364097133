/* eslint-disable */
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { softPhone010CacheUpdate } from '@/components/cache/softPhone010CacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useGetBuddySeqNo } from '@/components/hooks/customs/commons/useGetBuddySeqNo';
import { useMainTableReset } from '@/components/hooks/customs/resetRecoil/useMainTableReset';
import { useMsgRecoilReset } from '@/components/hooks/customs/useMsgRecoilReset';
import { useMutationSendMsg } from '@/components/hooks/mutations/addressBook/useMutationSendMsg';
import { useSoftPhoneMutations } from '@/components/hooks/mutations/softPhone/useSoftPhoneMutations';
import { unRegisteredPhoneNum } from '@/recoil/atoms/addressList';
import {
  addressOpenToggleRecoil,
  adStateRecoil,
  fileResultRecoil,
  fileStateRecoil,
  messageSendPhoneNumberRecoil,
  messageSendViewArrayRecoil,
  reSendFileRecoil,
  reSendMsgRecoil,
  reSendMsgToggleRecoil,
  reSendSubjectRecoil,
  sndMsgRecoil,
  sndMsgSubjectRecoil,
} from '@/recoil/atoms/messageSend/messageSend';
import {
  leftZoneComponentRecoil,
  listToggleRecoil,
  softPhoneComponentRecoil,
  softPhoneRowTabRecoil,
  softPhoneTopTabRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import { activeGroupRecoil } from '@/recoil/atoms/softPhone';
import { acceptedFile010Types, acceptedFile015Types } from '@/shared/constants';
import { assertMsglen, calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { detectEnvUtils } from '@/shared/util/detectEnvUtils';
import { base64ToBlob, uploadFileHandler } from '@/shared/util/fileUtils';
import { removeHyphens, TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';

interface IUseMessageSend {
  selectNumber: any;
  fomatCallingNum: string;
}

/**
 * @title 소프트폰 문자 전송 Hooks
 *
 * @author 정휘학
 * @since 2024.04.16
 * */
export const useMessageSend = ({ selectNumber, fomatCallingNum }: IUseMessageSend) => {
  const queryClient = useQueryClient();

  const setSoftPhoneComponentS = useSetRecoilState(softPhoneComponentRecoil);
  const softPhoneRowTabS = useRecoilValue(softPhoneRowTabRecoil);
  const softPhoneTopTabS = useRecoilValue(softPhoneTopTabRecoil);
  /* 이미지 파일 URL STATE */
  const [fileResult, setFileResult] = useRecoilState(fileResultRecoil);
  /* 이미지 파일 STATE */
  const [fileState, setFileState] = useRecoilState(fileStateRecoil);
  /* 이미지 파일 확대 toggle STATE */
  const [fileModal, setFileModal] = useState<boolean>(false);
  /* 메세지 전송 제목 STATE */
  const [sndMsgSubjectState, setSndMsgSubjectState] = useRecoilState(sndMsgSubjectRecoil);
  /* 메세지 전송 내용 STATE */
  const [sndMsgState, setSndMsgState] = useRecoilState(sndMsgRecoil);
  /* 메세지 전송 날짜 STATE */
  const [sndDttm, setSndDttm] = useState<string>(dayjs(new Date()).format('YYYYMMDDHHmmss'));
  /* 이미지 첨부 input useRef */
  const fileInputRef = useRef<HTMLInputElement>(null);
  /* 이미지 cancel toggle */
  const [fileDeleteToggle, setFileDeleteToggle] = useState<boolean>(false);
  /* 직접 수신인 작성 Input value State */
  const [etcInputValueState, setEtcInputValueState] = useState<string>('');
  /* 주소록 열기 toggle state */
  const [addressOpenToggleState, setAddressOpenToggleState] = useRecoilState(addressOpenToggleRecoil);
  /* 재전송 toggle state */
  const reSendMsgToggleState = useRecoilValue(reSendMsgToggleRecoil);
  /* 메세지 내용 입력하는 태그 */
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  /* 재전송 초기 메세지 내용 */
  const reSendMsgState = useRecoilValue(reSendMsgRecoil);
  /* 재전송 초기 메세지 제목 */
  const reSendSubjectState = useRecoilValue(reSendSubjectRecoil);
  /* 재전송 초기 파일 */
  const [reSendFileState, setReSendFileState] = useRecoilState(reSendFileRecoil);
  /* 예약 전송인지 판단하는 날짜 */
  const [clockToggleS, setClockToggleS] = useState<boolean>(false);
  /* 광고 문자인지 아닌지 판단하는 state */
  const [adMessageS, setAdMessageS] = useRecoilState(adStateRecoil);
  /* 메세지 타입 */
  const [msgTypeS, setMsgTypeS] = useState<'SMS' | 'LMS' | 'MMS' | 'FILE'>('SMS');
  /* 메세지 바이트 */
  const [byteS, setByteS] = useState<number>(75);
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);

  // accept 속성을 위한 문자열 생성
  const acceptFile015Types = acceptedFile015Types.join(', ');
  // accept 속성을 위한 문자열 생성
  const acceptFile010Types = acceptedFile010Types.join(', ');

  const [messageSendPhoneNumberState, setMessageSendPhoneNumberState] = useRecoilState(messageSendPhoneNumberRecoil);
  const mainTableCheckDataFormatS = useSetRecoilState(mainTableCheckDataFormatRecoil);
  const messageSendViewArrayState = useRecoilValue(messageSendViewArrayRecoil);
  /* 주소록 체크박스 recoil reset hook */
  const { mainTableResetToGroupSeqNo, mainTableResetRecoil } = useMainTableReset();
  const { warningModal, successModal, confirmModal } = useModalHook();
  const { resetMsgInfo } = useMsgRecoilReset();
  const { handleMsgListCacheUpdate } = softPhone010CacheUpdate();

  /**
   * @title 메세지 전송 API
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const { mutate: sendMsgMutate, isLoading: sendMsgLoading } = useMutation(useMutationSendMsg);

  /**
   * @title 재전송에 관련된 데이터를 전송 STATE에 저장하는 useEffect
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  useEffect(() => {
    if (sndMsgState === '') setSndMsgState(reSendMsgState);
    if (!fileResult && !fileState && !fileDeleteToggle) setFileResult(reSendFileState);
    if (sndMsgSubjectState === '') setSndMsgSubjectState(reSendSubjectState);
  }, [reSendMsgState, reSendFileState, reSendSubjectState]);

  /**
   * @title 메세지 예약 전송 날짜 변경 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const handleDate = (date: any, dateString: any) => {
    if (dateString !== '') {
      setSndDttm(dayjs(dateString).format('YYYYMMDDHHmmss'));
    } else {
      setSndDttm(dayjs(new Date()).format('YYYYMMDDHHmmss'));
    }
  };
  /**
   * @title 메시지 전송 내용 변경 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const handleSendMsg = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (fomatCallingNum.startsWith('015')) {
      setSndMsgState(assertMsglen(e.currentTarget.value, adMessageS ? 1956 : 1985));
    } else {
      setSndMsgState(assertMsglen(e.currentTarget.value, adMessageS ? 1971 : 2000));
    }
  };
  /**
   * @title 메시지 전송 내용 변경시 컴포넌트 높이값 변경 useEffect
   *
   * @author 김기준
   * @since 2024.08.26
   * */
  useEffect(() => {
    handleResizeHeight();
  }, [sndMsgState]);

  /**
   * @title 이미지 첨부 버튼 클릭 이벤트 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const onClickInputFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      if (environmentS === 'app') {
        window.fromWebToAppReqFile();
      }
    }
  };
  /**
   * @title 메세지 전송 이미지 첨부 파일 업로드 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const onChangeUploadImg = async (file: any, file2?: any) => {
    const files = file ? file : file2;
    const fileState = file ? true : false;
    setReSendFileState(null);
    await uploadFileHandler({
      file: files,
      setSendImgFile: setFileState,
      setSendImgFileUrl: setFileResult,
      warningModal,
      fileState,
    });
  };

  // window 객체에 함수를 등록
  useEffect(() => {
    if (environmentS === 'app') {
      window.myReactFunction = (file2) => {
        onChangeUploadImg(null, file2);
      };
    }
  }, []);

  /**
   * @title 이미지 첨부 삭제 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const deleteInputFile = () => {
    if (fileInputRef?.current) {
      setFileDeleteToggle(true);
      fileInputRef.current.value = '';
      setFileState(null);
      setFileResult(null);
      setReSendFileState(null);
    }
  };
  /**
   * @title 수신인 직접 입력 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const etcInputHandler = (phoneNumber: string) => {
    const formatNumber = TelHyphen(phoneNumber);
    setEtcInputValueState(formatNumber);
  };
  /**
   * @title 메세지 전송전 전송 API params 가공 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const { getBuddySeqNosToMainTableCheckDataFormatRecoil } = useGetBuddySeqNo();
  const onClickSendValidation = async (rowTap: number) => {
    /* 주소록 체크 - 수신인 (buddySeqNos)*/
    const buddySeqList = await getBuddySeqNosToMainTableCheckDataFormatRecoil();
    /* 재전송, 직접 입력 - 수신인 (phoneNumber) */
    const etcPhoneNumberList = messageSendPhoneNumberState
      ? messageSendPhoneNumberState.map((item) => removeHyphens(item.phoneNumber))
      : [];

    let usrCd = '00000';
    let subject: string | null = null;
    let file;
    if (fileState) {
      file = fileState;
    } else if (reSendFileState) {
      // 재전송일 경우
      file = base64ToBlob(reSendFileState, 'image/jpeg');
    }

    // MMS, LMS 구분
    if (file) {
      usrCd = 'MMS01';
    } else if (
      (fomatCallingNum.startsWith('015') && calMsgByte(sndMsgState) > 75) ||
      (!fomatCallingNum.startsWith('015') && calMsgByte(sndMsgState) > 90)
    ) {
      usrCd = 'LMS01';
    }
    // 제목
    if (sndMsgSubjectState) {
      subject = sndMsgSubjectState;
    }
    // 기본값
    const dto: any = {
      callback: fomatCallingNum === '015' ? selectNumber['015'] : selectNumber['010'],
      sndMsg: sndMsgState,
      adMessage: adMessageS, // 광고 메세지 판단 여부
      usrCd,
      groupSeqList: [],
      buddySeqList,
      etcPhoneNumberList,
      reSendPhoneNumberList: [],
      sndDttm: '',
      subject: fomatCallingNum === '015' ? '제목없음' : subject ? subject : '제목없음',
    };
    if (clockToggleS) {
      dto.sndDttm = sndDttm;
    }
    return { dto, file, fomatCallingNum };
  };

  /**
   * @title 미등록 번호 전송을 저장할 건지에 대한 Hook
   * */
  const setUnRegisteredPhoneNum = useSetRecoilState(unRegisteredPhoneNum);
  // const setOpenLargeToggle = useSetRecoilState(openLargeToggleRecoil);
  /* 좌측 컴포넌트 상태 recoil */
  const setLeftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  const setListToggleS = useSetRecoilState(listToggleRecoil);
  const suggestAddNumber = (response: any) => {
    confirmModal(
      async () => {
        new Promise((resolve) => {
          // setOpenLargeToggle(true);
          setLeftZoneComponentS('addLargeTable');
          setListToggleS(true);
          resolve(true);
        }).then(() => {
          setUnRegisteredPhoneNum(response.unregisteredPhoneNumbers);
        });
      },
      '전송',
      '메세지 전송 성공</br>주소록에 미등록된 번호가 있습니다.</br>추가하시겠습니까?',
      true,
    );
  };

  const onClickSend = async (rowTap: number) => {
    ReactGA.event({
      category: 'Message',
      action: 'Send Message',
      label: 'Clicked SoftPhone Submit Send Button',
    });
    const sendData = await onClickSendValidation(rowTap);
    sendMsgMutate(sendData, {
      onSuccess: async (r: any) => {
        // 여기서 수신인 초기화 + 체크박스 초기화
        setMessageSendPhoneNumberState(null);
        mainTableResetRecoil();
        if (r.unregisteredPhoneNumbers.length > 0) {
          suggestAddNumber(r);
        } else {
          successModal('전송', '메세지 전송 성공', true);
        }
        if (fileInputRef.current) fileInputRef.current.value = '';
        setSndMsgState('');
        setSndMsgSubjectState('');
        setFileState(null);
        setFileResult(null);
        resetMsgInfo();
        // handleResizeHeight(true);
        await queryClient.refetchQueries(['/userPoint']); // 금액
        await queryClient.refetchQueries(['/say015/serviceStatus']); // 금액
        if (r.callback.startsWith('015')) {
          await queryClient.refetchQueries(['/smt/history/latest']);
        } else {
          handleMsgListCacheUpdate(r);
        }

        if (softPhoneTopTabS === '0') {
          setSoftPhoneComponentS('chatroom');
        } else {
          setSoftPhoneComponentS(() => {
            if (softPhoneRowTabS === '0') return 'send';
            return 'schedule';
          });
        }
      },
      onError: (error: any) => {
        warningModal('전송', error.response.data as string, true);
      },
    });
  };

  /**
   * @title 수신인 목록 리스트 삭제 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const deleteSendTopList = (seqNo: number) => {
    mainTableResetToGroupSeqNo([seqNo]);
  };
  /**
   * @title 수신인 목록 직접 수신인 추가 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const { contactsBuddyNameM } = useSoftPhoneMutations();
  const addEtcList = async (e: any) => {
    e.preventDefault();
    const saveEtcInputValueState = etcInputValueState.trim();
    if (!saveEtcInputValueState) {
      setEtcInputValueState('');
      return;
    }
    const { buddyNm } = await contactsBuddyNameM({ keyCommNo: removeHyphens(saveEtcInputValueState) });
    setEtcInputValueState('');
    setMessageSendPhoneNumberState((prevState: any) => {
      const newData = {
        buddyNm,
        phoneNumber: saveEtcInputValueState,
      };

      if (prevState) return prevState.concat(newData);
      return [newData];
    });
  };
  /**
   * @title 수신인 목록 직접 수신인 삭제 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const deleteSendBottomEtcList = (keyCommNo: string) => {
    setMessageSendPhoneNumberState((prevState: any) => {
      if (prevState) {
        const removeData = prevState.filter((f: any) => f.phoneNumber !== keyCommNo);
        if (removeData.length > 0) return removeData;
        return null;
      }
      return prevState;
    });
  };
  /**
   * @title 수신인 목록 직접 수신인 전체 삭제 Hook
   *
   * @author 정휘학
   * @since 2024.03.15
   * */
  const deleteSendTopEtcList = () => {
    setMessageSendPhoneNumberState(null);
  };

  /**
   * @title 이미지 모달창 toggle Hook
   *
   * @author 김남규
   * @since 2024.04.08
   */
  const onClickSendImgToggle = () => {
    setFileModal((prev) => !prev);
  };

  const onClickAddressToggle = () => {
    setAddressOpenToggleState((prevState: boolean) => !prevState);
  };
  const [activeGroupState, setActiveGroupState] = useRecoilState(activeGroupRecoil);
  const onClickGroupList = (groupSeqNo: number, groupNm: string) => {
    if (!groupSeqNo || !groupNm) return;
    setActiveGroupState({ groupSeqNo, groupNm, change: true });
  };

  const handleResizeHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `auto`;
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  }, []);

  /**
   * @title 광고 메세지 toggle handle hook
   * */
  const adMessageHandler = () => {
    setAdMessageS((prevState) => !prevState);
    setSndMsgState('');
    resetMsgInfo();
    deleteInputFile();
    // handleResizeHeight(true);
  };

  /**
   * ========================
   * @title 메세지 제목 handler
   * ========================
   * */
  const msgSubjectHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.currentTarget.value.replace(/[^a-zA-Z0-9가-힣ㄱ-ㅎㅏ-ㅣ\s]/g, '');
    if (calMsgByte(filteredValue) < 21) {
      setSndMsgSubjectState(filteredValue);
    }
  };

  /**
   * ========================
   * @title 저장된 메세지 리스트를 senderMessage로 보내기
   * ========================
   * */

  const createFileFromBlob = async (filePath: any) => {
    if (!filePath) return null;

    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      const fileName = filePath.split('/').pop(); // 파일 이름을 추출합니다.

      // Blob을 File 객체로 변환합니다.
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    } catch (error) {
      console.error('Blob을 파일로 변환하는 데 실패했습니다.', error);
      return null;
    }
  };

  const HandleClickMessagList = (item: any, messageTabState: string) => {
    confirmModal(
      () => {
        // 초기화
        setFileResult(null);
        setFileState(null);
        setSndMsgSubjectState('');
        setSndMsgState('');

        // 데이터가 있는 경우에만 설정
        if (item?.file) {
          let fileUrl = item.file;
          createFileFromBlob(fileUrl).then((file) => {
            file && setFileState(file);
          });
          setFileResult(item.file);
        }

        if (item?.title) {
          setSndMsgSubjectState(item.title);
        }

        // 메세지 바디 byte 체크 후 업데이트
        type MessageTabState = 'SMS' | 'MMS';

        // 메세지가 sms / lms / mms 인지 반환
        const newMessageState = (messageType: MessageTabState) => {
          if (messageType === 'SMS') {
            return messageType;
          } else {
            if (item.file) {
              return 'MMS';
            } else {
              return 'LMS';
            }
          }
        };

        const handleMessage = (
          itemBody: string, // 바디 글자
          maxByteLength: number, // 최대 글자 수(광고시 1971, 015시 1985)
          messageType: MessageTabState, // sms인지 mms/lms 인지
          warningPrefix: string, // 경고 창 문자
        ) => {
          const messageBodyBytes = calMsgByte(itemBody);
          if (messageBodyBytes > maxByteLength) {
            warningModal(
              `${maxByteLength}byte까지만 출력됩니다.`,
              `${warningPrefix}${newMessageState(messageType)}메세지 최대길이는 ${maxByteLength}byte 입니다.`,
              true,
            );
            const truncatedBody = assertMsglen(itemBody, maxByteLength);
            setSndMsgState(truncatedBody);
          } else {
            setSndMsgState(itemBody);
          }
        };

        // 바이트 제한값을 설정하는 함수
        const getByteLimits = (isAdMessage: boolean) => ({
          SMS: isAdMessage ? 60 : 75,
          MMS: isAdMessage ? 1971 : 1985,
        });

        // softPhoneTopTabS와 adMessageS에 따라 처리
        if (item?.body) {
          const isAdMessage = softPhoneTopTabS === '1' && adMessageS; // 010모드 이면서 광고일 때
          const byteLimits = getByteLimits(isAdMessage); // 광고모드인지에 따라 바이트 제한
          const warningPrefix = isAdMessage ? '광고 문자의 ' : '015mode의 '; // 광고 모드인지에 따라 경고 title 변경

          if (isAdMessage || softPhoneTopTabS === '0') {
            handleMessage(
              item.body,
              byteLimits[messageTabState as MessageTabState],
              messageTabState as MessageTabState,
              warningPrefix,
            );
          } else {
            // 그냥 010 모드일 때는 그대로 메세지 바디 출력
            setSndMsgState(item.body);
          }
        }
      },
      '선택하신 문자 템플릿을 사용 하시겠습니까?',
      undefined,
      true,
    );
  };

  return {
    setSndMsgState,
    fileInputRef,
    onChangeUploadImg,
    onClickInputFile,
    fileResult,
    onClickSend,
    deleteSendTopList,
    sndMsgState,
    deleteInputFile,
    handleDate,
    sndDttm,
    onClickSendValidation,
    sendMsgLoading,
    setSndMsgSubjectState,
    sndMsgSubjectState,
    handleSendMsg,
    addEtcList,
    setEtcInputValueState,
    deleteSendBottomEtcList,
    deleteSendTopEtcList,
    etcInputHandler,
    etcInputValueState,
    messageSendViewArrayState,
    messageSendPhoneNumberState,
    fileModal,
    setFileModal,
    onClickSendImgToggle,
    reSendFileState,
    fileDeleteToggle,
    textareaRef,
    handleResizeHeight,
    onClickGroupList,
    reSendMsgToggleState,
    onClickAddressToggle,
    addressOpenToggleState,
    clockToggleS,
    setClockToggleS,
    adMessageS,
    setAdMessageS,
    msgTypeS,
    setMsgTypeS,
    byteS,
    setByteS,
    adMessageHandler,
    msgSubjectHandler,
    activeGroupState,
    HandleClickMessagList,
    acceptFile015Types,
    acceptFile010Types,
    fileState,
  };
};
