import React from 'react';
import * as S from '../styles/My015Charge.styles';
import { parseInt } from 'lodash';
import {
  WORKS015_LMS,
  WORKS015_MMS,
  WORKS015_PUSH,
  WORKS015_SMS,
  WORKS015_STT,
  WORKS015_TTS,
} from '@/shared/constants';

/**
 * @title 015 이용요금 결제 - 015 서비스에 대한 금액 , 서비스 종류 안내 컴포넌트
 *
 * @author 정휘학
 * @since 2024.05.17
 * */

const My015ChargeBottom = () => {
  return (
    <S.Guide>
      <li>&#183; 음성/문자 받기 서비스는 무제한입니다.</li>
      <li>&#183; SMS는 단문(75byte), LMS는 장문(1,985Byte)입니다.</li>
      <li>
        &#183; 문자 알림 서비스는 웹 Push 알림 기본 제공이며, 문자로 알림 수신을 원하시는 경우 건당 {WORKS015_PUSH}원의
        요금이 발생합니다.
      </li>
      <li>
        &#183; STT 변환은 Speech to Text의 약자로, 음성을 텍스트로 변환시켜주는 서비스입니다. 음성녹음을 텍스트로 변환할
        때 사용합니다. 월 5분 제공. 추가 변환 시 분당 {parseInt(WORKS015_STT as string) * 60}
        원의 비용이 발생합니다. (1분까지 변환 가능합니다.)
      </li>
      <li>
        &#183; TTS 변환은 Text to Speech의 약자로, 텍스트를 음성으로 변환시켜주는 서비스입니다. 안내멘트 녹음시
        사용됩니다. 일일 작성 회수 5건 제공. 추가 수정 시 건당 {WORKS015_TTS}원의 비용이 발생합니다.
      </li>
      <li>
        &#183; 100건의 제공 문자는 &#39;015&#39;번호로만 발신 가능하며, 1건당 “일반문자:&nbsp;
        {WORKS015_SMS}원 / 장문문자:&nbsp;{WORKS015_LMS}원 / 포토문자:&nbsp;
        {WORKS015_MMS}원 / 문자알림:&nbsp;{WORKS015_PUSH}
        원”이 차감됩니다.
      </li>
      <li>
        &#183; 기본 제공되는 발신 문자 건수 소진 이후 문자 발신 서비스는 발송 시 요금이 과금되므로 선불 충전이
        필요합니다. 잔액이 부족할 경우 문자 발송이 제한됩니다.
      </li>
      <li>&#183; &#39;010&#39; 번호로 문자를 발송하고 싶은 경우에는 캐시 충전이 필요합니다.</li>
      <p>
        &#42; 월정액 통신료는 환불이 불가능하며, 연간 통신료 결제 및 결제금액의 환불은 환불신청이유를 심사한 후,
        제정경제부에서 고시한 &lt;인터넷이용관련소비자피해보상&gt; 규정에 의한 위약금(결제수수료 및 송금비용에 따라
        10,000원 이상 시 환불금액 10%, 이하 시 1,000원)을 공제한 후 회원의 요청금액을 환불합니다.
      </p>
    </S.Guide>
  );
};

export default My015ChargeBottom;
