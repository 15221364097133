import PrivateRoute from '@/components/common/PrivateRoute';
import AdNoticePopupContent from '@/components/windowPopup/adNoticePopup/AdNoticePopupContent';
import HelpPopupContent from '@/components/windowPopup/helpPopup/HelpPopupContent';
import CustomerPage from '@/pages/CustomerPage/CustomerPage';
import Error404 from '@/pages/Error404';
import AccountFindResult from '@/pages/FindAccountPage/AccountFindResult/AccountFindResult';
import FindAccount from '@/pages/FindAccountPage/FindAccount';
import FindAccountList from '@/pages/FindAccountPage/FindAccountList';
import LayoutWithHeader from '@/pages/LayoutWithHeader';
import LinkSignInPage from '@/pages/LinkSignInPage/LinkSignInPage';
import MyPage from '@/pages/MyPage/MyPage';
import SignUp from '@/pages/SignUpPage/signUp/SignUp';
import SignUpDelete from '@/pages/SignUpPage/signUpDelete/SignUpDelete';
import SignUpExistence from '@/pages/SignUpPage/signUpExistence/SignUpExistence';
import SignUpForm from '@/pages/SignUpPage/signUpForm/SignUpForm';
import SignUpIdCheck from '@/pages/SignUpPage/signUpIdCheck/SignUpIdCheck';
import SignUpNiceResult from '@/pages/SignUpPage/signUpNiceResult/SignUpNiceResult';
import SignUpRecovery from '@/pages/SignUpPage/signUpRecovery/SignUpRecovery';
import SignUpResult from '@/pages/SignUpPage/signUpResult/SignUpResult';
import ComEmailveto from '@/pages/Terms/ComEmailveto';
import ComProtect from '@/pages/Terms/ComProtect';
import ComProtectBack20151030 from '@/pages/Terms/ComProtectBack20151030';
import ComProtectBack20180424 from '@/pages/Terms/ComProtectBack20180424';
import ComProtectBack20240704 from '@/pages/Terms/ComProtectBack20240704';
import ComProtectOld from '@/pages/Terms/ComProtectOld';
import ComSpampolicy from '@/pages/Terms/ComSpampolicy';
import ComUseContent from '@/pages/Terms/ComUseContent';
import ComUseContent015 from '@/pages/Terms/ComUseContent015';
import ComUsecontentBack20160618 from '@/pages/Terms/ComUsecontentBack20160618';
import ComUsecontentBack20171201 from '@/pages/Terms/ComUsecontentBack20171201';
import ComYouthpolicy from '@/pages/Terms/ComYouthpolicy';
import MainPage from '@/pages/main';
import SignIn from '@/pages/signIn/SignIn';
import AuthComponent from '@/shared/components/niceCertification/ui/AuthComponent';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Thanos from '@/pages/Thanos';
import ComUseContentBack20241010 from '@/pages/Terms/ComUseContentBack20241010';
import EasyComponent from '@/shared/components/easyPay/ui/EasyComponent';
import MobileMessageComponents from '@/components/mobileApp/mobileMessage/mobileMessageList/MobileMessageList';
import MobileHeader from '@/components/mobileApp/mobileHeader/MobileHeader';
import MobileLayout from '@/components/mobileApp/mobileLayout/MobileLayout';
import MobilePrivateRoute from '@/components/mobileApp/mobileRouter/MobilePrivateRoute';
import MobileMessageAdd from '@/components/mobileApp/mobileMessage/mobileMessageAdd/MobileMessageAdd';
import MobileMessageList from '@/components/mobileApp/mobileMessage/mobileMessageList/MobileMessageList';
import MobileMessage from '@/components/mobileApp/mobileMessage/MobileMessage';
import { divide, escapeRegExp } from 'lodash';
import MobileMessageChatRoom from '@/components/mobileApp/mobileMessage/mobileMessageChatRoom/MobileMessageChatRoom';
import MobileEtc from '@/components/mobileApp/mobileEtc/MobileEtc';
import MobileEtcList from '@/components/mobileApp/mobileEtc/mobileEtcList/MobileEtcList';
import MobileEtcCharge from '@/components/mobileApp/mobileEtc/mobileEtcCharge/MobileEtcCharge';
import MobileEtcUserInfo from '@/components/mobileApp/mobileEtc/mobileEtcUserInfo/MobileEtcUserInfo';
import MobileEtcPwChange from '@/components/mobileApp/mobileEtc/mobileEtcPwChange/MobileEtcPwChange';
import MobileEtcPhoneNumberChange from '@/components/mobileApp/mobileEtc/mobileEtcPhoneNumberChange/MobileEtcPhoneNumberChange';
import MobileEtcCallbackNumber from '@/components/mobileApp/mobileEtc/mobileEtcCallbackNumber/MobileEtcCallbackNumber';
import MobileEtcUserNameChange from '@/components/mobileApp/mobileEtc/mobileEtcUserNameChange/MobileEtcUserNameChange';
import MobileEtcSubInfo from '@/components/mobileApp/mobileEtc/mobileEtcSubInfo/MobileEtcSubInfo';
import MobileEtcPayment from '@/components/mobileApp/mobileEtc/mobileEtcPayment/MobileEtcPayment';
import MobileEtcUsage from '@/components/mobileApp/mobileEtc/mobileEtcUsage/MobileEtcUsage';
import MobileEtcMessageResult from '@/components/mobileApp/mobileEtc/mobileEtcMessageResult/MobileEtcMessageResult';
import MobileEtcTaxInvoiceAuto from '@/components/mobileApp/mobileEtc/mobileEtcTaxInvoiceAuto/MobileEtcTaxInvoiceAuto';
import MobileEtcRegisterTaxInvoice from '@/components/mobileApp/mobileEtc/mobileEtcRegisterTaxInvoice/MobileEtcRegisterTaxInvoice';
import MobileEtcTaxCashReceiptsDetail from '@/components/mobileApp/mobileEtc/mobileEtcTaxCashReceiptsDetail/MobileEtcTaxCashReceiptsDetail';
import MobileEtcTaxInvoiceDetail from '@/components/mobileApp/mobileEtc/mobileEtcTaxInvoiceDetail/MobileEtcTaxInvoiceDetail';
import MobileEtcMemberLeave from '@/components/mobileApp/mobileEtc/mobileEtcMemberLeave/MobileEtcMemberLeave';
import MobileAddress from '@/components/mobileApp/mobileAddress/MobileAddress';
import MobileArs from '@/components/mobileApp/mobileArs/MobileArs';
import MobileAddressGroupList from '@/components/mobileApp/mobileAddress/mobileAddressGroupList/MobileAddressGroupList';
import MobileArsTree from '@/components/mobileApp/mobileArs/mobileArsTree/MobileArsTree';
import MobileSignin from '@/components/mobileApp/mobileSignin/MobileSignin';
import MobileAddressBuddyList from '@/components/mobileApp/mobileAddress/mobileAddressBuddyList/MobileAddressBuddyList';
import MobileArsScenario from '@/components/mobileApp/mobileArs/mobileArsScenario/MobileArsScenario';
import { useRecoilState, useRecoilValue } from 'recoil';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import MobileEtcNotice from '@/components/mobileApp/mobileEtc/mobileEtcCustomer/MobileEtcNotice';
import MobileEtcQA from '@/components/mobileApp/mobileEtc/mobileEtcCustomer/MobileEtcQA';
import MobileEtcVOC from '@/components/mobileApp/mobileEtc/mobileEtcCustomer/MobileEtcVOC';
import MobileEtcEvent from '@/components/mobileApp/mobileEtc/mobileEtcCustomer/MobileEtcEvent';
import ComUseContentBack20241031 from '@/pages/Terms/ComUsecontentBack20241031';
import AppDownloadComponent from '@/pages/AppDownloadComponent';
import AppRouter from './AppRouter';
import MobileFindAccount from '@/components/mobileApp/mobileFindAccount/MobileFindAccount';
import MobileSignUp from '@/components/mobileApp/mobileSignUp/MobileSignUp';
import MobileSignUpForm from '@/components/mobileApp/mobileSignUpForm/MobileSignUpForm';
import MobileSignUpIdCheck from '@/components/mobileApp/mobileSignUpIdCheck/MobileSignUpIdCheck';
import MobileSignUpRecovery from '@/components/mobileApp/mobileSignUpRecovery/MobileSignUpRecovery';
import MobileAddressGroupNewAdd from '@/components/mobileApp/mobileAddress/mobileAddressGroupNewAdd/MobileAddressGroupNewAdd';
import MobileAddressBuddyNewAdd from '@/components/mobileApp/mobileAddress/mobileAddressBuddyNewAdd/MobileAddressBuddyNewAdd';
import MobileFindAccountList from '@/components/mobileApp/mobileFindAccountList/MobileFindAccountList';
import MobileMessageNewAdd from '@/components/mobileApp/mobileMessage/mobileMessageNewAdd/MobileMessageNewAdd';

import MobileEtc015SubCharge from '@/components/mobileApp/mobileEtc/mobileEtc015SubCharge/MobileEtc015SubCharge';
import Mobile015Signup from '@/components/mobileApp/mobile015Signup/Mobile015Signup';
import Mobile015SubSignup from '@/components/mobileApp/mobile015Signup/Mobile015SubSignup';
import Mobile015SubCharge from '@/components/mobileApp/mobile015Signup/Mobile015SubCharge';

const AppRoutes: React.FC = () => {
  const [environmentS, setEnvironmentS] = useRecoilState(environmentRecoil);
  return (
    // 앱과 나머지 분기 처리 ****************************************************************************************************************
    <>
      {environmentS !== 'app' ? (
        <Routes>
          {/* 앱 다운로드 페이지 */}
          <Route path="/app" element={<AppDownloadComponent />} />
          {/* 고객센터 */}
          <Route path="/customer/:customerId" element={<CustomerPage />} />
          {/* 도움말 */}
          <Route path="/helpContent" element={<HelpPopupContent />} />
          {/* 광고 공지 */}
          <Route path="/adNoticeContent" element={<AdNoticePopupContent />} />
          {/* 구 아레오 링크 타고 오는 컴포넌트 */}
          <Route path={'/linkSignIn'} element={<LinkSignInPage />} />
          {/* 나머지 모든 페이지 에러 */}
          <Route path="/*" element={<Error404 />} />

          {/* 메인 페이지 ********************************************************************************************************************************************************************************************** */}
          {/* 헤더 필요 없고 로그인 해야하는 페이지 ********************************************************************************************************************************************************************************************** */}
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<MainPage />} />
            {/* <Route path="thanos" element={<Thanos />} /> */}
          </Route>

          {/* 헤더 필요 ******************************************************************************************************* */}
          <Route element={<LayoutWithHeader />}>
            {/*로그인 필요 ***************************************************************************************************************** */}
            <Route element={<PrivateRoute />}>
              {/* 회원정보 */}
              <Route path="/mypage" element={<MyPage link="mypage" />} />
              <Route path="/userinfochange" element={<MyPage link="userinfochange" />} />
              <Route path="/passwordchange" element={<MyPage link="passwordchange" />} />
              <Route path="/phonenumberchange" element={<MyPage link="phonenumberchange" />} />
              <Route path="/usernamechange" element={<MyPage link="usernamechange" />} />
              <Route path="/callingnumber" element={<MyPage link="callingnumber" />} />
              <Route path="/memberleave" element={<MyPage link="memberleave" />} />

              {/* 회원정보 - My015 서비스 */}
              <Route path="/my015subinfo" element={<MyPage link="my015subinfo" />} />
              <Route path="/my015charge" element={<MyPage link="my015charge" />} />
              <Route path="/say015subinfo" element={<MyPage link="say015subinfo" />} />

              {/* 회원정보 - 충전소 */}
              <Route path="/charge" element={<MyPage link="charge" />} />
              <Route path="/charging" element={<MyPage link="charging" />} />
              <Route path="/payment" element={<MyPage link="payment" />} />
              <Route path="/payment/detail" element={<MyPage link="detail" />} />
              <Route path="/usage" element={<MyPage link="usage" />} />
              <Route path="/messageResult" element={<MyPage link="messageResult" />} />
              <Route path="/taxCashReceiptsDetail" element={<MyPage link="taxCashReceiptsDetail" />} />
              <Route path="/TaxInvoiceDetail" element={<MyPage link="taxInvoiceDetail" />} />
              <Route path="/TaxInvoiceAuto" element={<MyPage link="TaxInvoiceAuto" />} />
              <Route path="/RegisterTaxInvoice" element={<MyPage link="RegisterTaxInvoice" />} />
            </Route>

            {/*로그인 필요 없음  ***************************************************************************************************************** */}

            {/* 로그인 */}
            <Route path="/signin" element={<SignIn />} />
            <Route path="/findaccount" element={<FindAccount />} />
            <Route path="/findaccountList" element={<FindAccountList />} />
            <Route path="/accountFindResult" element={<AccountFindResult />} />
            {/* 이용약관 */}
            <Route path="/ComUseContent" element={<ComUseContent />} />
            <Route path="/ComUseContent015" element={<ComUseContent015 />} />
            <Route path="/ComUseContentBack20241031" element={<ComUseContentBack20241031 />} />
            <Route path="/ComUseContentBack20241010" element={<ComUseContentBack20241010 />} />
            <Route path="/ComUsecontentBack20171201" element={<ComUsecontentBack20171201 />} />
            <Route path="/ComUsecontentBack20160618" element={<ComUsecontentBack20160618 />} />
            <Route path="/ComProtect" element={<ComProtect />} />
            <Route path="/ComProtectOld" element={<ComProtectOld />} />
            <Route path="/ComProtectBack20151030" element={<ComProtectBack20151030 />} />
            <Route path="/ComProtectBack20180424" element={<ComProtectBack20180424 />} />
            <Route path="/ComProtectBack20240704" element={<ComProtectBack20240704 />} />
            <Route path="/ComYouthpolicy" element={<ComYouthpolicy />} />
            <Route path="/ComSpampolicy" element={<ComSpampolicy />} />
            <Route path="/ComEmailveto" element={<ComEmailveto />} />
            {/* 회원가입 */}
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/SignUpForm" element={<SignUpForm />} />
            <Route path="/SignUpExistence" element={<SignUpExistence />} />
            <Route path="/SignUpNiceResult" element={<SignUpNiceResult />} />
            <Route path="/SignUpIdCheck" element={<SignUpIdCheck />} />
            <Route path="/SignUpRecovery" element={<SignUpRecovery />} />
            <Route path="/SignUpDelete" element={<SignUpDelete />} />
            <Route path="/SignUpResult" element={<SignUpResult />} />
            <Route path="/AuthComponent" element={<AuthComponent />} />
            <Route path="/EasyComponent" element={<EasyComponent />} />
          </Route>
          {/*여기까지가 pc ***************************************************************************************************************** */}
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<AppRouter />}>
            <Route path="/*" element={<Error404 />} />

            <Route index element={<Navigate to="/mobile" />} />

            <Route path="/mobile">
              {/* 이용약관 */}
              <Route path="ComUseContent" element={<ComUseContent />} />
              <Route path="ComUseContent015" element={<ComUseContent015 />} />
              {/* 개인정보 처리방침 */}
              <Route path="ComProtect" element={<ComProtect />} />

              <Route index element={<Navigate to="mobileSignin" />} />
              {/* <Route path="thanos" element={<Thanos />} /> */}

              {/* 개인정보 처리방침 */}
              <Route path="ComProtect" element={<ComProtect />} />
              {/* 이용약관 */}
              <Route path="ComUseContent" element={<ComUseContent />} />

              {/* 015 미가입시 015 회원가입 */}
              <Route path="015Signup" element={<Mobile015Signup />} />
              {/* 015 가입 했는데 구독 만료 됐을때 다시 가입 하기 */}
              <Route path="015SubSignup" element={<Mobile015SubSignup />} />
              {/* 015 .구독만료시 충전할 수 있는 페이지 */}
              <Route path="015SubCharge" element={<Mobile015SubCharge />} />

              {/* 모바일 로그인페이지 */}
              <Route path="mobileSignin" element={<MobileSignin />} />
              {/* 모바일 ID/PW 찾기페이지 */}
              <Route path="mobileFindAccount" element={<MobileFindAccount />} />
              <Route path="mobileFindAccountList" element={<MobileFindAccountList />} />
              {/* 모바일 회원가입페이지 */}
              <Route path="mobileSignUp" element={<MobileSignUp />} />
              <Route path="mobileSignUpForm" element={<MobileSignUpForm />} />
              <Route path="mobileSignUpIdCheck" element={<MobileSignUpIdCheck />} />
              <Route path="mobileSignUpRecovery" element={<MobileSignUpRecovery />} />

              {/* 모바일 화면 레이아웃  *************************************************************************************************/}
              <Route path="/mobile" element={<MobileLayout />}>
                {/* 모바일 로그인 확인 ************************************************************************************************ */}
                <Route element={<MobilePrivateRoute />}>
                  {/* 주소록 */}
                  <Route path="address" element={<MobileAddress />}>
                    <Route index element={<Navigate to="groupList" />} />
                    <Route path="groupList" element={<MobileAddressGroupList />} />
                    <Route path="buddyList/:buddyName" element={<MobileAddressBuddyList />} />
                    <Route path="groupAdd" element={<MobileAddressGroupNewAdd />} />
                    <Route path="buddyAdd" element={<MobileAddressBuddyNewAdd />} />
                  </Route>

                  {/* 메세지 */}
                  <Route path="message" element={<MobileMessage />}>
                    <Route index element={<Navigate to="messageList" />} />
                    <Route path="messageList" element={<MobileMessageList />} />
                    <Route path="messageAdd" element={<MobileMessageAdd />} />
                    <Route path="messageNewAdd" element={<MobileMessageNewAdd />} />
                    <Route path="messageChatRoom/:keyCommNo" element={<MobileMessageChatRoom />} />
                  </Route>

                  {/* ARS */}
                  <Route path="ars" element={<MobileArs />}>
                    <Route index element={<Navigate to="arsTree" />} />
                    <Route path="arsTree" element={<MobileArsTree />} />
                    <Route path="arsScenario" element={<MobileArsScenario />} />
                  </Route>

                  {/* 더보기(마이페이지) */}
                  <Route path="etc" element={<MobileEtc />}>
                    <Route index element={<Navigate to="etcList" />} />
                    <Route path="etcList" element={<MobileEtcList />} />
                    <Route path="etcCharge" element={<MobileEtcCharge />} />
                    <Route path="etc015SubCharge" element={<MobileEtc015SubCharge />} />
                    <Route path="etcUserInfo" element={<MobileEtcUserInfo />} />
                    <Route path="etcPwChange" element={<MobileEtcPwChange />} />
                    <Route path="etcPhoneNumberChange" element={<MobileEtcPhoneNumberChange />} />
                    <Route path="etcUserNameChange" element={<MobileEtcUserNameChange />} />
                    <Route path="etcCallbackNumber" element={<MobileEtcCallbackNumber />} />
                    <Route path="etcSubInfo" element={<MobileEtcSubInfo />} />
                    <Route path="etcPayment" element={<MobileEtcPayment />} />
                    <Route path="etcUsage" element={<MobileEtcUsage />} />
                    <Route path="etcMessageResult" element={<MobileEtcMessageResult />} />
                    <Route path="etcTaxInvoiceAuto" element={<MobileEtcTaxInvoiceAuto />} />
                    <Route path="etcRegisterTaxInvoice" element={<MobileEtcRegisterTaxInvoice />} />
                    <Route path="etcTaxCashReceiptsDetail" element={<MobileEtcTaxCashReceiptsDetail />} />
                    <Route path="etcTaxInvoiceDetail" element={<MobileEtcTaxInvoiceDetail />} />
                    <Route path="etcMemberLeave" element={<MobileEtcMemberLeave />} />
                    {/* 고객센터 */}
                    <Route path="etcNotice" element={<MobileEtcNotice />} />
                    <Route path="etcQA" element={<MobileEtcQA />} />
                    <Route path="etcVOC" element={<MobileEtcVOC />} />
                    <Route path="etcEvent" element={<MobileEtcEvent />} />
                  </Route>
                  {/* 여기 까지 Route privateRoute */}
                </Route>
              </Route>

              {/* 기타페이지 */}
              <Route path="EasyComponent" element={<EasyComponent />} />
              <Route path="AuthComponent" element={<AuthComponent />} />
            </Route>
          </Route>
        </Routes>
      )}
    </>
  );
};
export default AppRoutes;
