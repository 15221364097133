/**
 * @title 메세지 전송에 필요한 state 및 hook 공통
 *
 * @author 정휘학
 * @since 2024.07.03
 * */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectNumberState } from '@/recoil/atoms/addressList';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { assertMsglen, calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { uploadFileHandler } from '@/shared/util/fileUtils';
import { environmentRecoil, sendImgFileRecoil, sendImgFileUrlRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

/* 발신번호 앞자리가 015, 010 에 따라 byte 제한을 분기작업하기 위한 type */
interface IUseSendCommon {
  type: '015' | '010';
}

/* 메세지 전송 api params */
export type SendMsgParamsType = {
  callback: string; // 발신번호
  sndMsg: string; // 메세지 내용
  usrCd: 'MMS01' | '00000' | 'LMS01'; // 메세지 타입 - MMS, SMS, LMS
  etcPhoneNumberList: string[]; // 직접 수신인 입력 ( 하이픈 제거한 핸드폰번호 배열 )
  buddySeqList: string[]; // 버디 시퀀스 배열
  groupSeqList: string[]; // 그룹 시퀀스 배열
  reSendPhoneNumberList: string[]; // 사용안함
  subject: string; // 제목
};

export const useSendCommon = (props: IUseSendCommon) => {
  /* 메세지 제목 */
  // const [sendMsgSubject] = useState<string | null>('[제목없음]');
  /* 메세지 내용 */
  const [sendMsgText, setSendMsgText] = useState<string>('');
  /* 메세지 내용 Byte */
  const [sendMsgByte, setSendMsgByte] = useState<number>(0);

  /* 메세지 이미지 파일 */
  const [sendImgFile, setSendImgFile] = useRecoilState(sendImgFileRecoil);
  /* 메세지 이미지 파일 URL */
  const [sendImgFileUrl, setSendImgFileUrl] = useRecoilState(sendImgFileUrlRecoil);

  /* 메세지 이미지 파일 input ref */
  const sendImgFileRef = useRef<any>(null);
  /* 메시지 입력란 textarea ref */
  const textAreaRef = useRef<any>(null);
  /* 발신 번호 */
  const selectNumberS = useRecoilValue(selectNumberState);
  /* 메시지 전송 api 에 대한 중복 방지 */
  const [isProcessingToSend, setIsProcessingToSend] = useState<boolean>(false);
  /* 광고 문자인지 아닌지 판단하는 state */
  const [adMessageS, setAdMessageS] = useState<boolean>(false);
  /* 메세지 타입 */
  const [msgTypeS, setMsgTypeS] = useState<'SMS' | 'LMS' | 'MMS' | 'FILE'>('SMS');
  /* 메세지 바이트 */
  const [byteS, setByteS] = useState<number>(75);
  /* 알림 팝업창 */
  const { warningModal } = useModalHook();
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);

  /**
   * ==========================================
   * @title 메세지 전송에 관련된 state 초기화 Hook
   * ==========================================
   **/
  const resetMsgState = () => {
    setSendMsgText('');
    setSendMsgByte(0);
    setSendImgFile(null);
    setSendImgFileUrl(null);
    // setBlockS(false);
    // isProcessing.current = false;
    // sendImgFileRef가 null이 아닐 때만 초기화
    if (sendImgFileRef.current) {
      sendImgFileRef.current.value = ''; // 메세지 이미지 전송 input value 초기화
    }
    textAreaRef.current.style.height = 'auto'; // 메세지 입력란 height 초기화
  };

  /**
   * ==========================
   * @title 보낼 메세지 작성 Hook
   * ==========================
   **/
  const onChangeSendMsgText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text: string = e.currentTarget.value;
    setSendMsgText(assertMsglen(text, props.type === '015' ? 1985 : 2000));
    setSendMsgByte(calMsgByte(text));
    textAreaRef.current.style.height = '0px';
    const { scrollHeight } = textAreaRef.current;
    textAreaRef.current.style.height = `${scrollHeight}px`;
  };

  /**
   * =======================================
   * @title 메세지 전송 이미지 첨부 파일 업로드 Hook
   * =======================================
   **/

  const onClickInputFile = () => {
    if (sendImgFileRef.current) {
      sendImgFileRef.current.click();
      if (environmentS === 'app') {
        window.fromWebToAppReqFile();
      }
    }
  };

  const onChangeUploadImg = async (file: any, file2?: any) => {
    const files = file ? file : file2;
    const fileState = file ? true : false;
    await uploadFileHandler({ file: files, setSendImgFileUrl, setSendImgFile, warningModal, fileState });
  };

  // window 객체에 함수를 등록
  useEffect(() => {
    if (environmentS === 'app') {
      window.myReactFunction = (file2) => {
        onChangeUploadImg(null, file2);
      };
    }
  }, []);

  /**
   * ====================================
   * @title 메세지 이미지 업로드 파일 삭제 Hook
   * ====================================
   **/
  const cancelImgFile = () => {
    setSendImgFileUrl(null);
    setSendImgFile(null);
    if (sendImgFileRef.current) sendImgFileRef.current.value = '';
  };

  /**
   * ===================================
   * @title 보내려고 하는 메세지 타입 체크 hook
   * ===================================
   **/
  const onCheckMsgType = (): 'MMS01' | '00000' | 'LMS01' | false => {
    if (sendMsgByte < 1 && !sendImgFile) return false;
    let usrCd: 'MMS01' | '00000' | 'LMS01' = '00000';
    /* 메세지 타입 설정 */
    if (sendImgFile) {
      // 이미지 파일이 있을 경우 MMS
      usrCd = 'MMS01';
    } else if (sendMsgByte < (props.type === '015' ? 76 : 91)) {
      // 이미지 파일이 없고 메세지 내용이 75 바이트 내에 있으면 SMS
      usrCd = '00000';
    } else if (sendMsgByte > (props.type === '015' ? 75 : 90)) {
      // 이미지 파일이 없고 메세지 내용이 75 바이트 초과시 LMS
      usrCd = 'LMS01';
    }
    return usrCd;
  };

  return {
    sendMsgText,
    sendMsgByte,
    sendImgFile,
    selectNumberS,
    textAreaRef,
    sendImgFileUrl,
    sendImgFileRef,
    onCheckMsgType,
    cancelImgFile,
    onChangeUploadImg,
    onChangeSendMsgText,
    resetMsgState,
    isProcessingToSend,
    setIsProcessingToSend,
    adMessageS,
    setAdMessageS,
    msgTypeS,
    setMsgTypeS,
    byteS,
    setByteS,
    onClickInputFile,
  };
};
