import React, { useId } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // 추가된 부분
import styled from 'styled-components';
import { TbDots, TbHeadset, TbUserSquare, TbMessageCircleFilled } from 'react-icons/tb';
import IconHumanFill from '@/styles/icons/IconHumanFill';
import IconHeadSetFill from '@/styles/icons/IconHeadSetFill';
import IconCassetteTape from '@/styles/icons/IconCassetteTape';

const PageNavContainer = styled.div`
  z-index: 10;
  position: fixed;
  left: 0;
  bottom: 0;
  display: none;
  width: 100%;
  height: 6rem;
  min-height: 6rem;
  padding: 0.5rem 1.6rem;
  background-color: ${(props) => props.theme.colors.gray100};
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const NavButton = styled.button<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  color: ${(props) => (props.isActive ? props.theme.colors.txt500 : props.theme.colors.txt200)};
  font-size: 1.1rem;
  .iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
  }
`;

const MobilePageNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navItems = [
    { id: useId(), icon: <IconHumanFill />, label: '주소록', value: '/mobile/address' },
    {
      id: useId(),
      icon: <TbMessageCircleFilled style={{ fontSize: '2rem' }} />,
      label: '채팅',
      value: '/mobile/message',
    },
    { id: useId(), icon: <IconCassetteTape fontSize={'1.3rem'} />, label: '인사말설정', value: '/mobile/ars' },

    { id: useId(), icon: <TbDots style={{ fontSize: '2rem' }} />, label: '더보기', value: '/mobile/etc' },
  ];

  const handleClickNavBtn = (value: string) => {
    navigate(value);
  };
  return (
    <>
      {location.pathname === '/mobile/mobileSignin' ||
      location.pathname === '/mobile/message/messageAdd' ||
      location.pathname === '/mobile/message/messageNewAdd' ||
      location.pathname.startsWith('/mobile/message/messageChatRoom/') ||
      location.pathname.startsWith('/mobile/address/buddyList/') ? null : (
        <PageNavContainer className="pageNav">
          {navItems.map((item, index) => (
            <NavButton
              key={item.id}
              onClick={() => {
                handleClickNavBtn(item.value);
              }}
              isActive={`/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` === item.value}
            >
              <div className="iconWrap">{item.icon}</div>
              <p>{item.label}</p>
            </NavButton>
          ))}
        </PageNavContainer>
      )}
    </>
  );
};

export default MobilePageNavigation;
