import styled from 'styled-components';

// 헤더 컨테이너
export const HeaderContainer = styled.section`
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  min-height: 5rem;
  padding: 0 1.6rem;
  background-color: ${(props) => props.theme.colors.white};
  white-space: nowrap;
`;

//왼쪽 타이틀
export const HeaderTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  overflow: hidden;
  text-overflow: ellipsis;
`;

//왼쪽 컨텐츠
export const HeaderContents = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt200};
`;

// 왼쪽 아이콘 타이틀 컨테이너
export const HeaderLeft = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  overflow: hidden;
  line-height: normal;
`;

// 오른쪽 아이콘 컨테이너
export const IconContainer = styled.section`
  display: flex;
  gap: 2rem;
`;

//버튼일 경우 아이콘일 때
export const HeaderButtonIcon = styled.button`
  font-size: 2.4rem;
  border-radius: 100%;
  transition: background-color 0.4s ease; // 듀레이션 0.4초 추가
  color: ${(props) => props.theme.colors.txt500};
`;

//버튼일 경우 폰트일 때
export const HeaderButtonFont = styled.button`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt200};
  &.active {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const GroupListHeaderModalContainer = styled.div`
  width: 100%;
  margin-top: 5rem;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 0.7rem 1.3rem 0px rgba(0, 0, 0, 0.1);
`;

export const AddGroupComponent = styled.form`
  z-index: 1;
  gap: 1rem;
  border-top: 1px solid ${(props) => props.theme.colors.gray300};
  padding: 1rem 1.3rem;
  border-radius: 0 0 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  > svg {
    margin: 1rem 0 0;
    width: 1.7rem;
    height: 1.7rem;
  }
  .addGroup {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 3.8rem;
    background-color: ${(props) => props.theme.colors.white};
    outline: 1px solid ${(props) => props.theme.colors.gray300};
    padding: 0 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.gray600};

    :has(input:focus) {
      outline-color: ${(props) => props.theme.colors.gray400};
    }
  }
  input {
    border: 0;
    font-size: 1.6rem;
    font-weight: 600;
    width: 100%;
    :focus {
      border: 0;
      outline: 0;
    }
    ::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
      border: 0;
      font-size: 1.4rem;
    }
  }

  /* 저장, 취소 스타일 */
  .btnGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    button {
      cursor: pointer;
      width: 48%;
      border-radius: 0.5rem;
      padding: 0.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      background: ${(props) => props.theme.colors.gray600};
      color: ${(props) => props.theme.colors.txt100};
      &.addBtn {
        background: ${(props) => props.theme.colors.gray800};
      }
    }
  }
`;
