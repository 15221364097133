/**
 * @title 전역적 변수 사용으로 유지보수를 위한 파일
 */

// 010 서비스 이용 금액
export const ARREO_SMS = '25';
export const ARREO_LMS = '50';
export const ARREO_MMS = '250';

// 015 Works 서비스 이용 금액
export const WORKS015_SMS = '14';
export const WORKS015_LMS = '42';
export const WORKS015_MMS = '84';
export const WORKS015_STT = '1';
export const WORKS015_TTS = '100';
export const WORKS015_PUSH = '14';

// 015 Works 이용권 금액
export const WORKS015_MONTHLY = '1100';
export const WORKS015_ANNUAL = '11000';

// 문의전화
export const ENQUIRY_PHONE = '015-8504-0006';

// 서비스 명칭
export const SERVICE_NAME = '015';
