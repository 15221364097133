import TaxInvoiceAuto from '@/components/Organism/Charge/TaxInvoiceAuto';
import TaxInvoiceHistoryTable from '@/components/Organism/Charge/TaxInvoiceHistoryTable';
import React from 'react';

const MobileEtcTaxInvoiceAuto = () => {
  return (
    <div className="mobileEtcTaxInvoiceAuto">
      <TaxInvoiceAuto />
      <TaxInvoiceHistoryTable />
    </div>
  );
};
export default MobileEtcTaxInvoiceAuto;
