import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const MobileEtcContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 1.4rem;
  background: ${(props) => props.theme.colors.white};
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const MobileEtc = () => {
  return (
    <MobileEtcContainer className="mobileEtc">
      <Outlet />
    </MobileEtcContainer>
  );
};

export default MobileEtc;
