/* eslint-disable */
import DatePickerDateTime from '@/components/common/date/DatePickerDateTime';
import Loader from '@/components/common/Loader';
import { useMessageSend } from '@/components/hooks/customs/addressBook/useMessageSend';
import { useMessageSender } from '@/components/hooks/customs/addressBook/useMessageSender';
import { useWindowPopup } from '@/components/hooks/customs/windowPopup/useWindowPopup';
import SendMsgByte from '@/components/softPhone/commons/SendMsgByte';
import MessageSendImgModal from '@/components/softPhone/DisplayGroups/MessageSend/components/MessageSendImgModal';
import MessageSendInfo from '@/components/softPhone/DisplayGroups/MessageSend/components/MessageSendInfo';
import { useMessageSendPublishHook } from '@/components/softPhone/DisplayGroups/MessageSend/useMessageSendPublishHook';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import {
  mobileNavRecoil,
  softPhoneRowTabRecoil,
  softPhoneTopTabRecoil,
  windowWidthRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { useAddresseeNotify } from '../../../hooks/customs/messageSend/useAddresseeNotify';
import MessageAddNotify from './components/MessageAddNotify';
import SaveMessageBtn from './components/saveMessage/SaveMessageBtn';
import * as S from './MessageSend.styles';
import axios from 'axios';
import { FaPlus, FaRegAddressBook } from 'react-icons/fa';

/**ToggleSaveMessage
 * @title 소프트폰에 '문자 전송하기' 또는 '문자 예약하기' 버튼 클릭시 소프트폰 표출되는 컴포넌트
 * */
const MessageSend = () => {
  const mainTableCheckDataFormatS = useRecoilValue(mainTableCheckDataFormatRecoil);
  const softPhoneTopTab = useRecoilValue(softPhoneTopTabRecoil);

  const softPhoneRowTabState = useRecoilValue(softPhoneRowTabRecoil);
  // publish hook
  const publishHook = useMessageSendPublishHook();
  //  custom hook
  const { selectNumber, fomatCallingNum } = useMessageSender();
  const hooks = useMessageSend({ selectNumber, fomatCallingNum });
  const popupHooks = useWindowPopup();

  const notiHooks = useAddresseeNotify({
    messageSendPhoneNumberState: hooks.messageSendPhoneNumberState,
    mainTableCheckDataFormatS: mainTableCheckDataFormatS,
  });

  const windowWidth = useRecoilValue(windowWidthRecoil);
  const [mobileNavState, setMobileNavState] = useRecoilState(mobileNavRecoil);

  // TODO - 주소록 체크박스 여기 mainTableCheckDataFormatS값 확인

  return (
    <S.MessageSend className="messageSend">
      <S.MessageSendWrap className="MessageSendWrap">
        <S.MessageSendGroup>
          {hooks.sendMsgLoading && <Loader backgroundToggle />}
          {/* 중복 제거 알림 */}
          {notiHooks.addList && notiHooks.notifyToggle && windowWidth > 768 && <MessageAddNotify notiType={'add'} />}
          {/* 수신인 리스트 */}
          <S.RecipientsList className={`${publishHook.liVisibilities.buttonActive ? 'active' : ''}`}>
            {windowWidth > 768 ? (
              <>
                <h3>수신인 :</h3>
                <button className="addressBtn" type="button" onClick={() => publishHook.onClickFormToggleBtn()}>
                  {publishHook.formToggle ? (
                    <p className="btn delBtn">
                      <span></span>
                    </p>
                  ) : (
                    <p className="btn plusBtn">
                      <span></span>
                      <span></span>
                    </p>
                  )}
                </button>

                <S.AddressAdd className={`addressAdd ${publishHook.formToggle ? 'active' : ''}`}>
                  {publishHook.formToggle && (
                    <form onSubmit={hooks.addEtcList}>
                      <label htmlFor="recipients">
                        <input
                          type="text"
                          placeholder="수신번호 입력"
                          value={hooks.etcInputValueState}
                          onChange={(e) => hooks.etcInputHandler(e.currentTarget.value)}
                        />
                        <button type="submit">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                          >
                            <path
                              d="M13.5186 6.5C13.5186 6.70687 13.4364 6.90526 13.2901 7.05154C13.1438 7.19782 12.9454 7.28 12.7386 7.28H7.79855V12.22C7.79855 12.4269 7.71638 12.6253 7.5701 12.7715C7.42382 12.9178 7.22542 13 7.01855 13C6.81169 13 6.61329 12.9178 6.46701 12.7715C6.32073 12.6253 6.23855 12.4269 6.23855 12.22V7.28H1.29855C1.09169 7.28 0.89329 7.19782 0.747011 7.05154C0.600733 6.90526 0.518555 6.70687 0.518555 6.5C0.518555 6.29313 0.600733 6.09473 0.747011 5.94846C0.89329 5.80218 1.09169 5.72 1.29855 5.72H6.23855V0.78C6.23855 0.573131 6.32073 0.374735 6.46701 0.228457C6.61329 0.0821784 6.81169 0 7.01855 0C7.22542 0 7.42382 0.0821784 7.5701 0.228457C7.71638 0.374735 7.79855 0.573131 7.79855 0.78V5.72H12.7386C12.9454 5.72 13.1438 5.80218 13.2901 5.94846C13.4364 6.09473 13.5186 6.29313 13.5186 6.5Z"
                              fill="#98999A"
                            />
                          </svg>
                        </button>
                      </label>
                    </form>
                  )}

                  {hooks.reSendMsgToggleState && (
                    <S.Toggle onClick={hooks.onClickAddressToggle}>
                      <button className={hooks.addressOpenToggleState ? 'active' : ''}>
                        {hooks.addressOpenToggleState ? '주소록 닫기' : '주소록 열기'}
                      </button>
                    </S.Toggle>
                  )}
                  <S.RecipientsGroup className={`recipientsGroup ${publishHook.listBoxToggle ? 'active' : ''}`}>
                    <ol className={`recipients ${publishHook.liVisibilities.buttonActive ? 'active' : ''}`}>
                      {/* 직접 추가 수신인 목록 */}
                      {hooks.messageSendPhoneNumberState &&
                        hooks.messageSendPhoneNumberState.map((item) => (
                          // <li className={publishHook.activeTag === 'etcSend' ? 'active' : ''} key={item.phoneNumber}>
                          <li className={publishHook.activeTag === 'etcSend' ? 'active' : ''} key={uuidv4()}>
                            <div className="smallContents">
                              <div className="recipientTitle" role="button">
                                <p>{item.phoneNumber}</p>
                              </div>
                              <div className="state">
                                <p className="name">{item.buddyNm && `(${item.buddyNm})`}</p>
                                <S.BtnWhiteGray>
                                  <button
                                    className="eraseButton"
                                    onClick={() => hooks.deleteSendBottomEtcList(item.phoneNumber)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10"
                                      height="10"
                                      viewBox="0 0 8 9"
                                      fill="none"
                                    >
                                      <path
                                        d="M7.7494 8.03556C7.67126 8.1137 7.56528 8.1576 7.45477 8.1576C7.34427 8.1576 7.23829 8.1137 7.16015 8.03556L4.21387 5.08928L1.26759 8.03556C1.18945 8.1137 1.08347 8.1576 0.972961 8.1576C0.862454 8.1576 0.756473 8.1137 0.678333 8.03556C0.600193 7.95742 0.556294 7.85144 0.556295 7.74093C0.556295 7.63042 0.600193 7.52444 0.678333 7.4463L3.62461 4.50002L0.678333 1.55374C0.600193 1.4756 0.556294 1.36962 0.556294 1.25912C0.556294 1.14861 0.600193 1.04263 0.678333 0.964489C0.756474 0.886349 0.862455 0.84245 0.972961 0.84245C1.08347 0.84245 1.18945 0.886349 1.26759 0.964489L4.21387 3.91077L7.16015 0.964489C7.23829 0.886349 7.34427 0.84245 7.45477 0.84245C7.56528 0.84245 7.67126 0.886349 7.7494 0.964489C7.82754 1.04263 7.87144 1.14861 7.87144 1.25912C7.87144 1.36962 7.82754 1.4756 7.7494 1.55374L4.80312 4.50002L7.7494 7.4463C7.82754 7.52444 7.87144 7.63042 7.87144 7.74093C7.87144 7.85144 7.82754 7.95742 7.7494 8.03556Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </button>
                                </S.BtnWhiteGray>
                              </div>
                            </div>
                          </li>
                        ))}

                      {/* 그룹 수신인 */}
                      {mainTableCheckDataFormatS &&
                        mainTableCheckDataFormatS.map((item) => {
                          if (!(item.count > 0)) return null;
                          return (
                            <li
                              key={uuidv4()}
                              className={`${hooks.activeGroupState?.groupSeqNo === item.groupSeqNo ? 'active' : ''}`}
                            >
                              <div
                                className="smallContents"
                                onClick={() => hooks.onClickGroupList(item.groupSeqNo, item.groupNm)}
                              >
                                <div className={`recipientTitle`} role="button">
                                  <p>{item?.groupNm}</p>
                                </div>
                                <div className="state">
                                  <p className="countNum">&#40;{item.count}&#41;</p>
                                  <S.BtnWhiteGray>
                                    <button
                                      className="eraseButton"
                                      onClick={(e) => {
                                        hooks.deleteSendTopList(item.groupSeqNo);
                                        e.stopPropagation();
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 8 9"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.7494 8.03556C7.67126 8.1137 7.56528 8.1576 7.45477 8.1576C7.34427 8.1576 7.23829 8.1137 7.16015 8.03556L4.21387 5.08928L1.26759 8.03556C1.18945 8.1137 1.08347 8.1576 0.972961 8.1576C0.862454 8.1576 0.756473 8.1137 0.678333 8.03556C0.600193 7.95742 0.556294 7.85144 0.556295 7.74093C0.556295 7.63042 0.600193 7.52444 0.678333 7.4463L3.62461 4.50002L0.678333 1.55374C0.600193 1.4756 0.556294 1.36962 0.556294 1.25912C0.556294 1.14861 0.600193 1.04263 0.678333 0.964489C0.756474 0.886349 0.862455 0.84245 0.972961 0.84245C1.08347 0.84245 1.18945 0.886349 1.26759 0.964489L4.21387 3.91077L7.16015 0.964489C7.23829 0.886349 7.34427 0.84245 7.45477 0.84245C7.56528 0.84245 7.67126 0.886349 7.7494 0.964489C7.82754 1.04263 7.87144 1.14861 7.87144 1.25912C7.87144 1.36962 7.82754 1.4756 7.7494 1.55374L4.80312 4.50002L7.7494 7.4463C7.82754 7.52444 7.87144 7.63042 7.87144 7.74093C7.87144 7.85144 7.82754 7.95742 7.7494 8.03556Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </button>
                                  </S.BtnWhiteGray>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ol>
                    <button className="arrowBtn" type="button" onClick={() => publishHook.onClickListBoxToggleBtn()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="4" viewBox="0 0 10 4" fill="none">
                        <path
                          d="M5.22112 3.42915L8.45972 0.883344C8.51379 0.840866 8.54395 0.784431 8.54395 0.725744C8.54395 0.667058 8.51379 0.610623 8.45972 0.568145L8.45606 0.565404C8.42985 0.54474 8.3983 0.528286 8.36334 0.517042C8.32837 0.505798 8.29071 0.5 8.25266 0.5C8.2146 0.5 8.17695 0.505798 8.14198 0.517042C8.10701 0.528286 8.07546 0.54474 8.04925 0.565404L4.99972 2.96274L1.95141 0.565403C1.9252 0.54474 1.89365 0.528285 1.85868 0.517042C1.82371 0.505798 1.78606 0.499999 1.748 0.499999C1.70995 0.499999 1.67229 0.505798 1.63733 0.517042C1.60236 0.528285 1.57081 0.54474 1.5446 0.565403L1.54094 0.568144C1.48688 0.610623 1.45672 0.667057 1.45672 0.725744C1.45672 0.78443 1.48688 0.840865 1.54094 0.883343L4.77954 3.42915C4.80802 3.45154 4.84228 3.46936 4.88023 3.48154C4.91818 3.49372 4.95904 3.5 5.00033 3.5C5.04162 3.5 5.08248 3.49372 5.12043 3.48154C5.15838 3.46936 5.19264 3.45154 5.22112 3.42915Z"
                          fill="#FDFDFD"
                          stroke="#FDFDFD"
                        />
                      </svg>
                    </button>
                  </S.RecipientsGroup>
                </S.AddressAdd>
              </>
            ) : (
              <>
                <S.AddressAdd className={`addressAdd ${publishHook.formToggle ? 'active' : ''}`}>
                  <form onSubmit={hooks.addEtcList}>
                    <label htmlFor="recipients">
                      <input
                        type="text"
                        placeholder="수신번호를 입력해 주세요."
                        value={hooks.etcInputValueState}
                        onChange={(e) => hooks.etcInputHandler(e.currentTarget.value)}
                        onBlur={hooks.addEtcList}
                      />
                      <button type="submit" style={{ color: 'rgb(54, 110, 255)' }}>
                        <FaPlus />
                      </button>
                    </label>
                  </form>

                  <S.RecipientsGroup className={`recipientsGroup ${publishHook.listBoxToggle ? 'active' : ''}`}>
                    <ol className={`recipients ${publishHook.liVisibilities.buttonActive ? 'active' : ''}`}>
                      {/* 직접 추가 수신인 목록 */}
                      {hooks.messageSendPhoneNumberState &&
                        hooks.messageSendPhoneNumberState.map((item) => (
                          // <li className={publishHook.activeTag === 'etcSend' ? 'active' : ''} key={item.phoneNumber}>
                          <li className={publishHook.activeTag === 'etcSend' ? 'active' : ''} key={uuidv4()}>
                            <div className="smallContents">
                              <div className="recipientTitle" role="button">
                                <p>{item.phoneNumber}</p>
                              </div>
                              <div className="state">
                                <p className="name">{item.buddyNm && `(${item.buddyNm})`}</p>
                                <S.BtnWhiteGray>
                                  <button
                                    className="eraseButton"
                                    onClick={() => hooks.deleteSendBottomEtcList(item.phoneNumber)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10"
                                      height="10"
                                      viewBox="0 0 8 9"
                                      fill="none"
                                    >
                                      <path
                                        d="M7.7494 8.03556C7.67126 8.1137 7.56528 8.1576 7.45477 8.1576C7.34427 8.1576 7.23829 8.1137 7.16015 8.03556L4.21387 5.08928L1.26759 8.03556C1.18945 8.1137 1.08347 8.1576 0.972961 8.1576C0.862454 8.1576 0.756473 8.1137 0.678333 8.03556C0.600193 7.95742 0.556294 7.85144 0.556295 7.74093C0.556295 7.63042 0.600193 7.52444 0.678333 7.4463L3.62461 4.50002L0.678333 1.55374C0.600193 1.4756 0.556294 1.36962 0.556294 1.25912C0.556294 1.14861 0.600193 1.04263 0.678333 0.964489C0.756474 0.886349 0.862455 0.84245 0.972961 0.84245C1.08347 0.84245 1.18945 0.886349 1.26759 0.964489L4.21387 3.91077L7.16015 0.964489C7.23829 0.886349 7.34427 0.84245 7.45477 0.84245C7.56528 0.84245 7.67126 0.886349 7.7494 0.964489C7.82754 1.04263 7.87144 1.14861 7.87144 1.25912C7.87144 1.36962 7.82754 1.4756 7.7494 1.55374L4.80312 4.50002L7.7494 7.4463C7.82754 7.52444 7.87144 7.63042 7.87144 7.74093C7.87144 7.85144 7.82754 7.95742 7.7494 8.03556Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </button>
                                </S.BtnWhiteGray>
                              </div>
                            </div>
                          </li>
                        ))}

                      {/* 그룹 수신인 */}
                      {mainTableCheckDataFormatS &&
                        mainTableCheckDataFormatS.map((item) => {
                          if (!(item.count > 0)) return null;
                          return (
                            <li
                              key={uuidv4()}
                              className={`${hooks.activeGroupState?.groupSeqNo === item.groupSeqNo ? 'active' : ''}`}
                            >
                              <div
                                className="smallContents"
                                onClick={() => hooks.onClickGroupList(item.groupSeqNo, item.groupNm)}
                              >
                                <div className={`recipientTitle`} role="button">
                                  <p>{item?.groupNm}</p>
                                </div>
                                <div className="state">
                                  <p className="countNum">&#40;{item.count}&#41;</p>
                                  <S.BtnWhiteGray>
                                    <button
                                      className="eraseButton"
                                      onClick={(e) => {
                                        hooks.deleteSendTopList(item.groupSeqNo);
                                        e.stopPropagation();
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 8 9"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.7494 8.03556C7.67126 8.1137 7.56528 8.1576 7.45477 8.1576C7.34427 8.1576 7.23829 8.1137 7.16015 8.03556L4.21387 5.08928L1.26759 8.03556C1.18945 8.1137 1.08347 8.1576 0.972961 8.1576C0.862454 8.1576 0.756473 8.1137 0.678333 8.03556C0.600193 7.95742 0.556294 7.85144 0.556295 7.74093C0.556295 7.63042 0.600193 7.52444 0.678333 7.4463L3.62461 4.50002L0.678333 1.55374C0.600193 1.4756 0.556294 1.36962 0.556294 1.25912C0.556294 1.14861 0.600193 1.04263 0.678333 0.964489C0.756474 0.886349 0.862455 0.84245 0.972961 0.84245C1.08347 0.84245 1.18945 0.886349 1.26759 0.964489L4.21387 3.91077L7.16015 0.964489C7.23829 0.886349 7.34427 0.84245 7.45477 0.84245C7.56528 0.84245 7.67126 0.886349 7.7494 0.964489C7.82754 1.04263 7.87144 1.14861 7.87144 1.25912C7.87144 1.36962 7.82754 1.4756 7.7494 1.55374L4.80312 4.50002L7.7494 7.4463C7.82754 7.52444 7.87144 7.63042 7.87144 7.74093C7.87144 7.85144 7.82754 7.95742 7.7494 8.03556Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </button>
                                  </S.BtnWhiteGray>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ol>
                  </S.RecipientsGroup>
                </S.AddressAdd>
                <button
                  className="addressBtn"
                  type="button"
                  onClick={() => {
                    setMobileNavState('주소록');
                  }}
                >
                  <FaRegAddressBook />
                </button>
              </>
            )}
          </S.RecipientsList>
          {/* 전송내용 */}
          <S.Transmissiondetails
            subjectBoolean={
              (calMsgByte(hooks.sndMsgState) > (hooks.adMessageS ? 55 : 90) || hooks.fileResult) &&
              fomatCallingNum !== '015'
            }
          >
            {/* <div className="flexBox"> */}
            <div className="SendTextBox">
              {/* 010 일때 LMS 제목 */}
              {(calMsgByte(hooks.sndMsgState) > (hooks.adMessageS ? 55 : 90) || hooks.fileResult) &&
                fomatCallingNum !== '015' && (
                  <div className="titleGroup">
                    <p>제목 : </p>
                    <input
                      type="text"
                      value={hooks.sndMsgSubjectState}
                      placeholder={'제목을 입력해주세요.(최대 20byte)'}
                      onChange={hooks.msgSubjectHandler}
                    />
                  </div>
                )}
              {/* 광고 알림 */}
              {hooks.adMessageS && (
                <div className="adGroup">
                  <button
                    type="button"
                    className="adNotice"
                    onClick={() => popupHooks.openPopup({ url: '/adNoticeContent', target: '_blank', size: '2' })}
                  >
                    !
                  </button>
                  <p>&#40;광고&#41;</p>
                </div>
              )}
              {/* 내용 */}
              <div className="sendContentGroup">
                {hooks.fileResult ? (
                  <div className="sendImage">
                    <div className="imgBox" onClick={hooks.onClickSendImgToggle}>
                      {
                        /* 재전송 파일이 있으면서 input 파일 delete 버튼 안눌렀을 경우 */
                        hooks.reSendFileState && !hooks.fileDeleteToggle ? (
                          <>
                            {/* 재전송 - API 요청으로 받아온 바이너리 데이터 표출 */}
                            <img className={'onImage'} src={`data:image/jpeg;base64,${hooks.fileResult}`} alt="" />
                          </>
                        ) : (
                          <>
                            {/* INPUT 이미지 업로드로 이미지 표출 */}
                            {hooks.fileState &&
                              (() => {
                                const { type, name } = hooks.fileState;

                                // 파일 미리보기
                                if (type.startsWith('image/')) {
                                  return <img className="onImage" src={hooks.fileResult} alt={hooks.fileState.name} />;
                                }
                                if (type.startsWith('video/')) {
                                  return <video controls src={hooks.fileResult} />;
                                }
                                if (type.startsWith('audio/')) {
                                  return <audio controls src={hooks.fileResult} />;
                                }
                                return <div className="fileName">{name}</div>; // 파일 타입이 지원되지 않는 경우
                              })()}
                          </>
                        )
                      }
                    </div>
                    <button className="deleteButton" onClick={hooks.deleteInputFile}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <path
                          d="M6.82843 6.82854C6.76592 6.89106 6.68113 6.92617 6.59272 6.92617C6.50432 6.92617 6.41953 6.89106 6.35702 6.82854L4 4.47152L1.64298 6.82854C1.58047 6.89106 1.49568 6.92617 1.40728 6.92617C1.31887 6.92617 1.23408 6.89106 1.17157 6.82854C1.10906 6.76603 1.07394 6.68125 1.07394 6.59284C1.07394 6.50444 1.10906 6.41965 1.17157 6.35714L3.5286 4.00012L1.17157 1.64309C1.10906 1.58058 1.07394 1.4958 1.07394 1.40739C1.07394 1.31899 1.10906 1.2342 1.17157 1.17169C1.23408 1.10918 1.31887 1.07406 1.40728 1.07406C1.49568 1.07406 1.58047 1.10918 1.64298 1.17169L4 3.52871L6.35702 1.17169C6.41953 1.10918 6.50432 1.07406 6.59272 1.07406C6.68113 1.07406 6.76592 1.10918 6.82843 1.17169C6.89094 1.2342 6.92606 1.31899 6.92606 1.40739C6.92606 1.4958 6.89094 1.58058 6.82843 1.64309L4.4714 4.00012L6.82843 6.35714C6.89094 6.41965 6.92606 6.50444 6.92606 6.59284C6.92606 6.68125 6.89094 6.76603 6.82843 6.82854Z"
                          fill="#FDFDFD"
                          stroke="#FDFDFD"
                          strokeWidth="0.5"
                        />
                      </svg>
                    </button>
                  </div>
                ) : null}
                <textarea
                  name="comment"
                  value={hooks.sndMsgState}
                  // placeholder="전송할 메시지를 입력해주세요"
                  placeholder={
                    hooks.adMessageS
                      ? "(광고) 우측부터 내용이 입력되고, 업체명은 '(광고) 업체명' 과 같이 (광고) 바로 뒤에 작성해 주세요."
                      : '전송할 메시지를 입력해주세요'
                  }
                  ref={hooks.textareaRef}
                  onChange={(event) => {
                    hooks.handleSendMsg(event); // 이벤트 핸들러 호출
                    // hooks.handleResizeHeight();
                  }}
                  onInput={hooks.handleResizeHeight}
                />
              </div>
              {/* 무료 거부 */}
              {hooks.adMessageS && (
                <div className="freeRefusal">
                  <p>
                    무료거부 <span>080-156-4706</span>
                  </p>
                </div>
              )}
            </div>
            {/* </div> */}
          </S.Transmissiondetails>
          {/* 이미지 첨부, 예약, 전송 */}
          {/* <S.AddresContentGroup maxByte={calMsgByte(hooks.sndMsgState) < hooks.byteS}> */}
          <S.AddresContentGroup>
            <div className="tabBox">
              {/* 파일 추가 버튼 */}
              <div className="iconBtn imageButton" title="파일 첨부">
                <input
                  type="file"
                  id={'fileUpload'}
                  ref={hooks.fileInputRef}
                  onChange={hooks.onChangeUploadImg}
                  // TODO - hotfix : 010 모드와 동일하게 파일 받도록 수정합나다.
                  accept=".jpg, .jpeg"
                  // accept={fomatCallingNum !== '015' ? '.jpg, .jpeg' : hooks.acceptFile015Types}
                  style={{ display: 'none' }}
                />
                <button onClick={hooks.onClickInputFile}>
                  <svg
                    className="stroke"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.9403 11.5243L12.7503 20.7143C11.6244 21.8401 10.0975 22.4726 8.50529 22.4726C6.91311 22.4726 5.38613 21.8401 4.26029 20.7143C3.13445 19.5884 2.50195 18.0615 2.50195 16.4693C2.50195 14.8771 3.13445 13.3501 4.26029 12.2243L12.8303 3.65428C13.5809 2.90239 14.5994 2.47945 15.6618 2.47852C16.7241 2.47758 17.7434 2.89871 18.4953 3.64928C19.2472 4.39984 19.6701 5.41835 19.671 6.48074C19.672 7.54314 19.2509 8.56239 18.5003 9.31428L9.91029 17.8843C9.53501 18.2596 9.02602 18.4704 8.49529 18.4704C7.96456 18.4704 7.45557 18.2596 7.08029 17.8843C6.70501 17.509 6.49418 17 6.49418 16.4693C6.49418 15.9385 6.70501 15.4296 7.08029 15.0543L15.5703 6.57428"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {/* <p className="text">이미지 첨부</p> */}
                </button>
              </div>
              {/* 광고버튼 */}
              {softPhoneTopTab === '1' && (
                <div className={`iconBtn adButton ${hooks.adMessageS ? 'active' : ''}`} title="광고 문자">
                  <button type={'button'} onClick={hooks.adMessageHandler}>
                    <svg
                      className="stroke"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="20"
                      viewBox="0 0 25 20"
                      fill="none"
                    >
                      <path
                        d="M1 3.75C1 3.08696 1.26339 2.45107 1.73223 1.98223C2.20107 1.51339 2.83696 1.25 3.5 1.25H21C21.663 1.25 22.2989 1.51339 22.7678 1.98223C23.2366 2.45107 23.5 3.08696 23.5 3.75V16.25C23.5 16.913 23.2366 17.5489 22.7678 18.0178C22.2989 18.4866 21.663 18.75 21 18.75H3.5C2.83696 18.75 2.20107 18.4866 1.73223 18.0178C1.26339 17.5489 1 16.913 1 16.25V3.75Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 13.75V8.125C6 7.62772 6.19754 7.15081 6.54917 6.79917C6.90081 6.44754 7.37772 6.25 7.875 6.25C8.37228 6.25 8.84919 6.44754 9.20083 6.79917C9.55246 7.15081 9.75 7.62772 9.75 8.125V13.75M6 11.25H9.75M14.75 6.25V13.75H16C16.663 13.75 17.2989 13.4866 17.7678 13.0178C18.2366 12.5489 18.5 11.913 18.5 11.25V8.75C18.5 8.08696 18.2366 7.45107 17.7678 6.98223C17.2989 6.51339 16.663 6.25 16 6.25H14.75Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              )}

              {/* TODO */}
              {/* 메세지 저장 버튼 */}

              {/* {softPhoneTopTab === '1' && <SaveMessageBtn />} */}
              <SaveMessageBtn />

              {/* 예약날짜 */}
              {softPhoneRowTabState === '0' && softPhoneTopTab === '1' && (
                // dateTable - > class active로 조절
                <div className={`iconBtn dateTable ${hooks.clockToggleS && 'active'}`} title="예약 전송 설정">
                  <button className="clockIcon" onClick={() => hooks.setClockToggleS((prev) => !prev)}>
                    <svg
                      className="fill"
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M10.3389 0C15.8619 0 20.3389 4.477 20.3389 10C20.3389 15.523 15.8619 20 10.3389 20C4.81587 20 0.338867 15.523 0.338867 10C0.338867 4.477 4.81587 0 10.3389 0ZM10.3389 2C8.21714 2 6.1823 2.84285 4.68201 4.34315C3.18172 5.84344 2.33887 7.87827 2.33887 10C2.33887 12.1217 3.18172 14.1566 4.68201 15.6569C6.1823 17.1571 8.21714 18 10.3389 18C12.4606 18 14.4954 17.1571 15.9957 15.6569C17.496 14.1566 18.3389 12.1217 18.3389 10C18.3389 7.87827 17.496 5.84344 15.9957 4.34315C14.4954 2.84285 12.4606 2 10.3389 2ZM10.3389 4C10.5838 4.00003 10.8202 4.08996 11.0032 4.25272C11.1863 4.41547 11.3032 4.63975 11.3319 4.883L11.3389 5V9.586L14.0459 12.293C14.2252 12.473 14.3293 12.7144 14.3371 12.9684C14.3448 13.2223 14.2557 13.4697 14.0876 13.6603C13.9196 13.8508 13.6853 13.9703 13.4324 13.9944C13.1795 14.0185 12.9269 13.9454 12.7259 13.79L12.6319 13.707L9.63187 10.707C9.47645 10.5514 9.37663 10.349 9.34787 10.131L9.33887 10V5C9.33887 4.73478 9.44422 4.48043 9.63176 4.29289C9.8193 4.10536 10.0737 4 10.3389 4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                  {hooks.clockToggleS && <DatePickerDateTime onChange={hooks.handleDate} sndDttm={hooks.sndDttm} />}
                </div>
              )}
            </div>

            <div className="byteSend">
              {/* 바이트 표시 */}
              {fomatCallingNum === '015' && hooks.sndMsgState && (
                <SendMsgByte
                  sndMsg={hooks.sndMsgState}
                  file={hooks.fileResult}
                  phoneType={'015'}
                  adMessage={hooks.adMessageS}
                  byteS={hooks.byteS}
                  setByteS={hooks.setByteS}
                  msgTypeS={hooks.msgTypeS}
                  setMsgTypeS={hooks.setMsgTypeS}
                  styleType="a"
                />
              )}
              {fomatCallingNum === '010' && hooks.sndMsgState && (
                <SendMsgByte
                  sndMsg={hooks.sndMsgState}
                  file={hooks.fileResult}
                  phoneType={'010'}
                  adMessage={hooks.adMessageS}
                  byteS={hooks.byteS}
                  setByteS={hooks.setByteS}
                  msgTypeS={hooks.msgTypeS}
                  setMsgTypeS={hooks.setMsgTypeS}
                  styleType="a"
                />
              )}
              {/* 메세지 발송 버튼 */}
              {/* testCode수정 */}
              <S.SendButtonGroup title="전송">
                <MessageSendInfo
                  sndDttm={hooks.sndDttm}
                  handleDate={hooks.handleDate}
                  repeatCycle={publishHook.repeatCycle}
                  repeatDay={publishHook.repeatDay}
                  toggleHand1={publishHook.toggleHand1}
                  tab={publishHook.tab}
                  onClickSendValidation={hooks.onClickSendValidation}
                  setBottModalSwitch={publishHook.setBottModalSwitch}
                  setTab={publishHook.setTab}
                  onClickSend={hooks.onClickSend}
                  softPhoneRowTabState={softPhoneRowTabState}
                  fileResult={hooks.fileResult}
                  sndMsgState={hooks.sndMsgState}
                />
              </S.SendButtonGroup>
              {/* testCode수정 요거 삭제하면됌 */}
              {/* {fomatCallingNum !== '015' && (
                <S.SendButtonGroup title="전송">
                  <MessageSendInfo
                    sndDttm={hooks.sndDttm}
                    handleDate={hooks.handleDate}
                    repeatCycle={publishHook.repeatCycle}
                    repeatDay={publishHook.repeatDay}
                    toggleHand1={publishHook.toggleHand1}
                    tab={publishHook.tab}
                    onClickSendValidation={hooks.onClickSendValidation}
                    setBottModalSwitch={publishHook.setBottModalSwitch}
                    setTab={publishHook.setTab}
                    onClickSend={hooks.onClickSend}
                    softPhoneRowTabState={softPhoneRowTabState}
                    fileResult={hooks.fileResult}
                    sndMsgState={hooks.sndMsgState}
                  />
                </S.SendButtonGroup>
              )} */}
            </div>
          </S.AddresContentGroup>
        </S.MessageSendGroup>
      </S.MessageSendWrap>

      {/* 이미지 팝업 */}
      {hooks.fileResult && hooks?.fileModal && (
        <MessageSendImgModal
          fileResult={hooks.fileResult}
          onClickSendImgToggle={hooks.onClickSendImgToggle}
          setFileModal={hooks.setFileModal}
          reSendFileState={hooks.reSendFileState}
        />
      )}
    </S.MessageSend>
  );
};

export default MessageSend;
