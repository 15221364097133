import Loader from '@/components/common/Loader';
import { useChatRoom015Body } from '@/components/hooks/customs/chatRoom015/useChatRoom015Body';
import { useChatRoom015Image } from '@/components/hooks/customs/chatRoom015/useChatRoom015Image';
import { chatUserInfoRecoil, footerHeightRecoil } from '@/recoil/atoms/chatRoom';
import React, { Fragment, useEffect, useLayoutEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { formatDate } from '@/shared/util/format/dateFormatUtil';
import ChatRoom015MT from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015MT/chatRoom015MT';
import ChatRoom015MO from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015MO/ChatRoom015MO';
import ChatRoom015Foot from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Foot/ChatRoom015Foot';
import { environmentRecoil, mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useNavigate } from 'react-router-dom';
import * as D from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/ChatRoom015Body.styled';
import IconDownload from '@/styles/icons/IconDownload';
import IconX from '@/styles/icons/IconX';

export const ChatroomContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.chatBack};
  /* .chattingMyTextBox {
    background-color: ${(props) => props.theme.colors.mobileMain};
    p {
      color: ${(props) => props.theme.colors.txt500};
    }
  } */
`;

export const ChatroomChatting = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* .chattingSendBtn {
    color: ${(props) => props.theme.colors.txt500};
  } */
`;

const MobileMessageChatRoom = () => {
  const chatRoomRef = useRef<HTMLDivElement>(null);
  const chatFootRef = useRef<HTMLDivElement>(null);
  const hooks = useChatRoom015Body({ chatRoomRef });
  const imageHooks = useChatRoom015Image();
  const navigate = useNavigate();
  const [mobileBuddyListAddS, setMobileBuddyListAddS] = useRecoilState(mobileBuddyListAddRecoil);
  const environmentS = useRecoilValue(environmentRecoil);

  // 메시지 방에서 새로고침 했을 때 메인으로 이동
  useEffect(() => {
    if (mobileBuddyListAddS && Object.keys(mobileBuddyListAddS).length === 0) {
      navigate('/mobile');
    }
  }, [mobileBuddyListAddS]);

  // 처음 진입 시와 새로운 데이터가 쌓일 때 최하단으로 스크롤 이동
  useLayoutEffect(() => {
    if (chatRoomRef.current) {
      chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
    }
  }, [hooks.chatListData]);

  // 앱에서 이미지 다운로드
  const onClickImgDown = () => {
    if (environmentS === 'app') {
      // 앱 환경에서 처리
      const base64Data = imageHooks.closeUpImg; // Base64 이미지 데이터
      const fileName = 'downloaded-image.jpg'; // 다운로드할 파일 이름
      const message = JSON.stringify({ base64Data, fileName });

      // 앱에 다운로드 요청
      window.fromWebToAppReqDownload(message);
    } else {
      // 웹 환경에서 처리
      const link = document.createElement('a');
      link.href = `data:image/jpeg;base64,${imageHooks.closeUpImg}`; // Base64 URL
      link.download = 'downloaded-image.jpg'; // 다운로드할 파일 이름
      link.click(); // 다운로드 실행
    }
  };

  return (
    <>
      <ChatroomContainer className="mobileMessageChatRoom" ref={chatRoomRef}>
        <div style={{ padding: '0 1.6rem' }}>
          {hooks.mhLoading ? (
            <Loader backgroundToggle={false} />
          ) : (
            hooks.mhStatus === 'success' &&
            hooks.chatListData &&
            Object.entries(hooks.chatListData).map((content: any) => (
              <Fragment key={content[0]}>
                <D.EtcChattingDateText>
                  <span>{formatDate(content[0])}</span>
                </D.EtcChattingDateText>
                {content[1].map((list: any, index: number) =>
                  list.direction === 'mo'
                    ? ChatRoom015MO({
                        list,
                        content,
                        index,
                        reSend015Msg: hooks.reSend015Msg,
                        cancelSend015Msg: hooks.cancelSend015Msg,
                        closeUpImgHook: imageHooks.closeUpImgHook,
                        handleImgError: imageHooks.handleImgError,
                      })
                    : ChatRoom015MT({
                        list,
                        content,
                        index,
                        reSend015Msg: hooks.reSend015Msg,
                        cancelSend015Msg: hooks.cancelSend015Msg,
                        closeUpImgHook: imageHooks.closeUpImgHook,
                        handleImgError: imageHooks.handleImgError,
                      }),
                )}
              </Fragment>
            ))
          )}
          {/* 이미지 확대 */}
          {imageHooks.modalSwitch ? (
            <D.MobileChattingBigImgModal className="mobileBigImg" onClick={imageHooks.autoClosePopup}>
              {/* <D.MobileChattingBigImgWrap id={'closeUpView'} className="closeUpView" ref={imageHooks.closeUpViewRef}> */}
              <D.MobileChattingBigImgWrap className="closeUpView">
                <D.MobileChattingBigImg src={`data:image/jpeg;base64,${imageHooks.closeUpImg}`} alt="testimage" />
                <D.EtcChattingBigImageModalBtnWrap className="imgBtnWrap">
                  <D.MobileEtcChattingBtn className="imgDownload" type="button" onClick={() => onClickImgDown()}>
                    {/* 다운로드 버튼 */}
                    <p>다운로드</p>
                    {/* <IconDownload /> */}
                  </D.MobileEtcChattingBtn>
                  <D.MobileEtcChattingBtn className="imgClose" type="button" onClick={imageHooks.closeUpImgPopupClose}>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                      <path
                        opacity="0.9"
                        d="M4.54078 29.389C4.1518 29.778 3.62424 29.9966 3.07414 29.9966C2.52404 29.9966 1.99648 29.778 1.6075 29.389C1.21853 29 1 28.4724 1 27.9223C1 27.3722 1.21853 26.8446 1.6075 26.4556L12.5659 15.5L1.61095 4.54098C1.22198 4.15198 1.00345 3.62439 1.00345 3.07426C1.00345 2.52413 1.22198 1.99654 1.61095 1.60754C1.99993 1.21854 2.52749 0.999999 3.07759 0.999999C3.62769 0.999999 4.15525 1.21854 4.54423 1.60754L15.4991 12.5666L26.4575 1.61099C26.8465 1.22199 27.374 1.00345 27.9241 1.00345C28.4742 1.00345 29.0018 1.22199 29.3908 1.61099C29.7798 1.99999 29.9983 2.52758 29.9983 3.07771C29.9983 3.62784 29.7797 4.15544 29.3908 4.54444L18.4324 15.5L29.3925 26.459C29.7815 26.848 30 27.3756 30 27.9257C30 28.4759 29.7815 29.0035 29.3925 29.3925C29.0035 29.7815 28.476 30 27.9259 30C27.3758 30 26.8482 29.7815 26.4592 29.3925L15.4991 18.4335L4.54078 29.389Z"
                        fill="currentColor"
                      />
                    </svg> */}
                    <p>닫기</p>
                    {/* <IconX /> */}
                  </D.MobileEtcChattingBtn>
                </D.EtcChattingBigImageModalBtnWrap>
              </D.MobileChattingBigImgWrap>
            </D.MobileChattingBigImgModal>
          ) : null}
        </div>
      </ChatroomContainer>
      <ChatroomChatting>
        <ChatRoom015Foot chatRoomRef={chatRoomRef} chatFootRef={chatFootRef} />
      </ChatroomChatting>
    </>
  );
};

export default MobileMessageChatRoom;
