/* eslint-disable */
import { addAddressExcelItemsRecoil } from '@/recoil/atoms/atoms';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as XLSX from 'xlsx-js-style';
import { useDragDropFile } from '../useDragDropFile';
import { useXlsx } from '../useXlsx';
import { DefaultRowData } from '@/apis/api/addressApis';
import { useAddLargeReset } from './useAddLargeReset';
import { addressColumViewLabel, addressColumViewValue, excelKeyMap } from '@/shared/constants';
import { addressLargeColumViewLabelRecoil, addressLargeColumViewValueRecoil } from '@/recoil/atoms/addressColum';

interface IUseAddLargeExcel {
  handleAddRow: () => void;
  rows: DefaultRowData[];
  setRows: React.Dispatch<React.SetStateAction<DefaultRowData[]>>;
}

export const useAddLargeExcel = (props: IUseAddLargeExcel) => {
  /* 엑셀 추가 */
  const [addAddressExcelItemsS, setAddAddressExcelItemsS] = useRecoilState(addAddressExcelItemsRecoil);

  const resetHooks = useAddLargeReset({ setRows: props.setRows });

  const setAddressLargeColumViewValueR = useSetRecoilState(addressLargeColumViewValueRecoil);
  const setAddressLargeColumViewLabelR = useSetRecoilState(addressLargeColumViewLabelRecoil);

  const { readExcel, items } = useXlsx();

  const { dragRef, onChangeFiles, fileInputRef } = useDragDropFile({
    readExcel,
    handleLargeTableReset: resetHooks.handleLargeTableReset,
  });

  /**
   * @title  엑셀 파일 length 에 맞게 row 추가
   * */
  useEffect(() => {
    if (!addAddressExcelItemsS || addAddressExcelItemsS.length === 0) return;
    setAddressLargeColumViewValueR(new Set(addressColumViewValue));
    setAddressLargeColumViewLabelR(new Set(addressColumViewLabel));
    // Rows 길이를 맞추기 위해 필요한 만큼 추가 행을 생성
    if (props.rows.length < addAddressExcelItemsS.length) {
      const rowsToAdd = addAddressExcelItemsS.length - props.rows.length;
      for (let i = 0; i < rowsToAdd; i++) {
        props.handleAddRow();
      }
      return;
    }

    const updatedRows = props.rows.map((row, index) => {
      if (index < addAddressExcelItemsS.length) {
        const excelItem = addAddressExcelItemsS[index];
        const updateRow = { ...row };

        const fieldsToUpdate = Object.values(excelKeyMap);

        fieldsToUpdate.forEach((field) => {
          if (excelItem[field] !== undefined) {
            updateRow[field] = excelItem[field];
          }
        });
        return updateRow;
      }
      return row;
    });

    props.setRows(updatedRows);
    setAddAddressExcelItemsS(null);
  }, [addAddressExcelItemsS, props.rows.length]);

  const translateKeys = (items: any) => {
    return items.map((item: any) => {
      const newItem: any = {};
      Object.keys(item).forEach((key) => {
        if (key === '성별' && item['성별'] !== '남' && item['성별'] !== '여') {
          item['성별'] = ''; // 값 변경
        }
        if (key === '성별' && item['성별'] === '여') {
          item['성별'] = '1'; // 값 변경
        }
        if (key === '성별' && item['성별'] === '남') {
          item['성별'] = '2'; // 값 변경
        }

        const newKey: any = excelKeyMap[key] || key;
        newItem[newKey] = item[key];
      });
      return newItem;
    });
  };

  /* 엑셀파일 업로드시 엑셀파일에 있는 key 값을 꺼내오는 로직 */
  useEffect(() => {
    if (items) {
      const newItems = translateKeys(items);
      setAddAddressExcelItemsS(newItems);
    }
  }, [items]);

  // 엑셀 양식 다운로드
  const calculateMaxWidth = (data: any) => {
    const maxWidths: any = [];
    data.forEach((row: any) => {
      row.forEach((cell: any, index: any) => {
        const length = cell.v.toString().length;
        maxWidths[index] = Math.max(maxWidths[index] || 0, length);
      });
    });
    return maxWidths.map((width: any) => ({ wch: width + 10 }));
  };

  /* 엑셀 양식 파일 다운로드 */
  const handleClick = () => {
    const wb = XLSX.utils.book_new();

    const data = [
      [
        { v: '이름', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '휴대폰번호', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '이메일', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '메모', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '015번호', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '회사이름', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '부서', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '직함', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '회사전화', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '팩스', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '회사우편번호', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '회사주소', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '집전화', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '집우편번호', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '집주소', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '홈페이지', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '메신저', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '생일', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '결혼기념일', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '성별', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '주민번호', t: 's', s: { font: { sz: 18, bold: true } } },
        { v: '계좌번호', t: 's', s: { font: { sz: 18, bold: true } } },
      ],
      [
        { v: '홍길동', t: 's', s: { font: { sz: 12 } } },
        { v: '01012341234', t: 's', s: { font: { sz: 12 } } },
        { v: 'excel@excel.com', t: 's', s: { font: { sz: 12 } } },
        { v: '메모', t: 's', s: { font: { sz: 12 } } },
        { v: '01585040000', t: 's', s: { font: { sz: 12 } } },
        { v: '서울이동통신', t: 's', s: { font: { sz: 12 } } },
        { v: '부서', t: 's', s: { font: { sz: 12 } } },
        { v: '직함', t: 's', s: { font: { sz: 12 } } },
        { v: '0320000000', t: 's', s: { font: { sz: 12 } } },
        { v: '팩스', t: 's', s: { font: { sz: 12 } } },
        { v: '회사우편번호', t: 's', s: { font: { sz: 12 } } },
        { v: '회사주소', t: 's', s: { font: { sz: 12 } } },
        { v: '0320000000', t: 's', s: { font: { sz: 12 } } },
        { v: '집우편번호', t: 's', s: { font: { sz: 12 } } },
        { v: '집주소', t: 's', s: { font: { sz: 12 } } },
        { v: '홈페이지', t: 's', s: { font: { sz: 12 } } },
        { v: '메신저', t: 's', s: { font: { sz: 12 } } },
        { v: '생일', t: 's', s: { font: { sz: 12 } } },
        { v: '결혼기념일', t: 's', s: { font: { sz: 12 } } },
        { v: '남(여)', t: 's', s: { font: { sz: 12 } } },
        { v: '주민번호', t: 's', s: { font: { sz: 12 } } },
        { v: '계좌번호', t: 's', s: { font: { sz: 12 } } },
      ],
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    ws['!cols'] = calculateMaxWidth(data);
    XLSX.utils.book_append_sheet(wb, ws, 'readme demo');
    XLSX.writeFile(wb, 'seoul_mobile_excel.xlsx');
  };

  return { dragRef, fileInputRef, onChangeFiles, handleClick };
};
