/*eslint-disable*/

import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { activeNumberRecoil, selectedArsScenarioInfoRecoil, simpleViewArsTreeRecoil } from '@/recoil/atoms/say015Atom';
import { useQuery } from '@tanstack/react-query';
import { ArsJsonData } from '@/apis/api/pointApi';
import { useUpdateJsonFile } from '@/components/hooks/customs/say015/say105Handle/useUpdateJsonFile';
import { use015ArsFC } from '@/components/hooks/customs/say015/say015ARS/use015ArsFC';
import { useArsJsonFX } from '@/widgets/ars/components/arsTree/hooks/useArsJsonFX';

/**
 * @title 015 ARS 트리 컴포넌트 HOOKS - ArsTree.tsx
 *
 * */
export const useArsTree = () => {
  /* 인사말 ref */
  const cardRef = useRef<HTMLDivElement>(null);
  /* ars json data state */
  const [arsData, setArsData] = useState<any>(null);
  /* ars 위젯 추가여부 */
  const [isAddS, setIsAddS] = useState<boolean>(true);
  /* 휴대폰 클릭 number */
  const [activeNumberS] = useRecoilState(activeNumberRecoil);
  /* 왼쪽 그룹을 조절 - 반응형시 필요 */
  const [arsLeftNavS, setArsLeftNavS] = useState<boolean>(false);
  /* 간편보기 사용여부 */
  const [simpleViewArsTreeS, setSimpleViewArsTreeS] = useRecoilState(simpleViewArsTreeRecoil);
  /* 선택된 ARS 시나리오 */
  const [selectedArsScenarioInfoS] = useRecoilState(selectedArsScenarioInfoRecoil);

  // =======================
  // ars json 데이터 조작 HOOK
  // =======================
  const { findDataById } = useArsJsonFX({ arsData });

  // ====================
  // ARS 단순 이벤트 함수 모음
  // ====================
  const { isUpdatedIdS, handleUpdateIdState, handleAddIdState, isAddIdS, setSelectAddCardS, selectAddCardS } =
    use015ArsFC();

  // =============================
  // ARS Json 데이터 업데이트 Mutation
  // =============================
  const { mutateUpdatedJsonFile } = useUpdateJsonFile({ arsData, setArsData });

  // =======================================
  // ARS 화면이 작아질때 표출되는 우측 버튼 클릭 핸들러
  // =======================================
  const arsLeftNavClick = () => {
    setArsLeftNavS((prevState) => !prevState);
  };

  // =========================
  // ARS JSON 데이터 가져오는 API
  // =========================
  const { data, isLoading } = useQuery(
    ['arsJsonData', selectedArsScenarioInfoS?.fileName],
    () => ArsJsonData({ fileName: selectedArsScenarioInfoS?.fileName as string }),
    {
      enabled: selectedArsScenarioInfoS !== null,
    },
  );

  // ===============================
  // 가져온 ARS JSON 데이터 STATE 에 SET
  // ===============================
  useEffect(() => {
    if (data && !isLoading) {
      setArsData(data);
    }
  }, [data, isLoading]);

  // ==================
  // JsonFile 업데이트 Hook
  // ==================
  const updatedJsonFile = () => {
    mutateUpdatedJsonFile();
  };

  // =================
  // ARS 위젯 포커스
  // =================
  useEffect(() => {
    if (activeNumberS && cardRef.current) {
      if (activeNumberS === 'root') {
        cardRef.current.focus();
        cardRef.current.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
      }
    }
  }, [activeNumberS, cardRef]);

  // ========================
  // ARS Tree 간편보기 버튼 핸들러
  // ========================
  const handleSimpleView = () => {
    setSimpleViewArsTreeS((prevState) => !prevState);
  };

  return {
    arsLeftNavS,
    arsLeftNavClick,
    setIsAddS,
    activeNumberS,
    cardRef,
    findDataById,
    arsData,
    isUpdatedIdS,
    handleUpdateIdState,
    updatedJsonFile,
    isAddS,
    setArsData,
    selectAddCardS,
    setSelectAddCardS,
    handleAddIdState,
    isAddIdS,
    handleSimpleView,
    simpleViewArsTreeS,
    selectedArsScenarioInfoS,
  };
};
