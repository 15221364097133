import styled from 'styled-components';

export const MessageContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const NoDataMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.txt400};
  font-size: 1.4rem;
`;

export const MessageListContainer = styled.div`
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.4rem;
  gap: 1rem;
  transition: background-color 0.4s ease-in-out; // 듀레이션 0.4초 추가

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  cursor: pointer;

  > div {
    display: flex;
    gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    &:nth-of-type(2n) {
      background: ${(props) => props.theme.colors.gray200};
    }
  }
`;

export const MessageImage = styled.div`
  width: 5.2rem;
  height: 5.2rem;
  background-color: ${(props) => props.theme.colors.chatIconBack};
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.txt100}99;
  font-size: 1.8rem;
  font-weight: 900;
`;

export const MessageContents = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
`;

export const MessageContentsTop = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  > h3 {
    font-size: 1.6rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.txt500};
    display: inline-block;
    max-width: 12ch; /* 최대 7글자로 나오게 ... 포함 */
    white-space: nowrap;
    overflow: hidden; /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;
  }
  > div {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.txt300};
  }
  @media screen and (max-width: 380px) {
    align-items: flex-start;
    flex-wrap: wrap;
    > h3 {
      font-size: 1.4rem;
    }
    > div {
      font-size: 1.2rem;
    }
  }
`;

export const MessageContentsBottom = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.txt300};
  .textContent {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: normal;
      word-break: break-all;
      overflow-wrap: break-word;
      white-space: normal;
      word-wrap: break-word;
    }
  }
  @media screen and (max-width: 380px) {
    font-size: 1.2rem;
    .textContent {
      p {
        -webkit-line-clamp: 1;
      }
    }
  }
`;

export const MessageInfo = styled.div`
  white-space: nowrap;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 1rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt300};
  white-space: nowrap;

  > span {
    background-color: ${(props) => props.theme.colors.notify};
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    color: white;
  }
`;
