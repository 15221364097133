import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const TransmissionResultWrap = styled.div`
  .searchGroup {
    /* input{
			width: 16rem;
			background: #f4f4f7;
			border: 0;
			padding: 1rem 1.5rem 1rem 2rem;
			border-radius: 50px;
			box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);

			:focus{
			outline : none;
			}
		} */
    .searchDateBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.2rem;
      button {
        border: 0;
        width: 17px;
        height: 18px;
        padding: 0;
      }
    }
  }
`;
export const ManagementBtnGroup = styled.div`
  position: relative;
  /* margin-bottom: 1.2rem; */
  > .ManagementBtnWrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* height: 42px; */
    /* width: calc(100% - 126px); */
    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar {
      z-index: 2;
      height: 2px;
      border-radius: 50px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.colors.main};
    }
    :hover {
      ::-webkit-scrollbar-thumb {
        background-color: #98999a;
      }
      ::-webkit-scrollbar {
        background-color: #d6d6dc;
      }
    }
    .wrap {
      width: 100%;
      /* display: flex;
      justify-content: space-between;
      gap: 1rem; */
      .group {
        display: flex;
        gap: 1rem;
        > button {
          gap: 1.2rem;
          width: 50%;
        }
      }
    }
    .wrap.on {
      .buttonGroups {
        /* position: absolute; */
        position: relative;
        width: 100%;
      }
      .group {
        /* opacity: 0; */
        > button {
          font-size: 0;
          transition: 0.1s;
          gap: 0;
        }
      }
    }
    .buttonGroups {
      display: flex;
      justify-content: flex-end;
    }
    /* .group {
      > button {
        line-height: 40px;
        p {
          display: inherit;
        }
      }
    } */
  }
  @media screen and (max-width: 640px) {
    .ManagementBtnWrap {
      .wrap {
        gap: 1rem;
        .group {
          > button {
            gap: 1rem;
          }
        }
      }
      .wrap.on {
        .group {
          > button {
            /* font-size: 13px;
						gap: 1rem; */
          }
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    .ManagementBtnWrap {
      .wrap {
        flex-wrap: wrap;
      }
      .wrap .group > button {
        min-width: auto;
        max-width: none;
        white-space: normal;
        font-size: 1.8rem;
      }
      .wrap.on .group > button {
        font-size: 13px;
        gap: 1rem;
      }
    }
  }
  @media screen and (max-width: 375px) {
    .ManagementBtnWrap {
      .wrap {
        .group {
          width: 100%;
          /* flex-wrap: wrap; */
          button {
            justify-content: center;
            font-size: 1.4rem;
            p {
              display: none;
            }
            line-height: normal;
            width: 50%;
            padding: 8px;
            border-radius: 6px;
          }
        }
      }
    }
  }
`;

export const MenuButton = styled.button`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  padding: 1rem 1.2rem;
  white-space: nowrap;
  /* min-height: 52px; */
  border-radius: 5px;
  background: #fff;
  color: #98999a;
  font-weight: 400;
  font-size: 2.4rem;
  border: 1px solid #f7f7f8;
  background: #f7f7f8;
  transition: all 0.2s linear;

  svg {
    width: 17px;
    height: 16px;
  }
  :last-of-type {
    margin-right: 0;
  }

  &.active {
    border-color: ${(props) => props.theme.colors.main};
    color: #fff;
    background: ${(props) => props.theme.colors.main};
    svg {
      fill: #fff;
      path {
        fill: #fff;
      }
    }
    svg.stroke {
      path {
        stroke: #fff;
      }
    }
    svg.stroke2 {
      path {
        stroke: #fff;
        &:nth-child(2) {
          fill: transparent;
        }
      }
    }
    svg.stroke3 {
      path {
        fill: transparent;
        stroke: #fff;
      }
    }
  }

  @media screen and (max-width: 375px) {
    max-width: none;
    width: 100%;
  }
`;

export const MessageSendGroup = styled.div`
  margin-bottom: 1rem;
  button {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.white};
    font-size: 2rem;
    font-weight: 500;
    background: ${(props) => props.theme.colors.gray800};
    border-radius: 10px;
    /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.02) inset; */
    text-align: left;
    padding: 1rem 1.4rem;
    p {
      position: absolute;
      right: 12px;
      /* width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #98999a; */
    }
    svg {
      width: 100%;
      height: 100%;
      padding: 5px;
    }
  }
  @media screen and (max-width: 360px) {
    button {
      font-size: 1.6rem;
      height: 40px;
      p {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const TransmissionResult = styled.div``;

export const RangePickerStyled = styled(RangePicker)`
  width: 22rem;
  background: #fff;
  border: 0;
  border-radius: 50px;
  color: #98999a;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.03);
  .ant-picker-input {
    & > input {
      font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
      /* font-family: "Noto Sans KR", "Nanum Gothic", Roboto, "Open Sans", sans-serif; */
      font-size: 1.2rem;
      font-weight: 700;
      border: none;
      box-shadow: none;
    }
  }
  :where(.css-dev-only-do-not-override-dkbvqv).ant-picker-focused.ant-picker {
    border-color: transparent;
    box-shadow: none;
    outline: none;
  }

  /* active bar */
  .ant-picker-active-bar {
    background: ${(props) => props.theme.colors.main};
  }
`;

export const CancelTextButton = styled.div`
  .cancelBtn {
    width: 100%;
    padding: 2rem 1rem;
    color: #fff;
    background-color: #444444;
    border-radius: 1.5rem;
    font-size: 1.3rem;
    font-weight: 900;
  }
`;

// 소프트폰 토글버튼 개별/그룹 전체/미열람 스타일
export const ToggleButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  > div {
    position: relative;
    width: 49%;
    background: #f7f7f8;
    padding: 5px;
    border-radius: 5px;
    button {
      width: 50%;
      padding: 6px;
      border-radius: 5px;
      font-size: 1.4rem;
      color: #888;
      font-weight: 500;
      &.active {
        background: #fff;
        color: #333;
        font-weight: 600;
      }
    }
  }
`;
