import UserNameChange from '@/components/Organism/MyPage/UserNameChange';
import React from 'react';

const MobileEtcUserNameChange = () => {
  return (
    <>
      <UserNameChange />
    </>
  );
};
export default MobileEtcUserNameChange;
