import { mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import MessageAddHeader from './mobileHeaderList/MessageAddHeader';
import MessageChatRoomHeader from './mobileHeaderList/MessageChatRoomHeader';
import MessageListHeader from './mobileHeaderList/MessageListHeader';

import * as S from './MobileHeader.style';
import AddressBuddyListHeader from './mobileHeaderList/AddressBuddyListHeader';
import AddressGroupListHeader from './mobileHeaderList/AddressGroupListHeader';
import ArsTreeHeader from './mobileHeaderList/ArsTreeHeader';
import EtcCommonHeader from './mobileHeaderList/EtcCommonHeader';
import EtcListHeader from './mobileHeaderList/EtcListHeader';
import ArsScenarioHeader from './mobileHeaderList/ArsScenarioHeader';
import MessageNewAddHeader from './mobileHeaderList/MessageNewAddHeader';
import AddressGroupAddHeader from './mobileHeaderList/AddressGroupAddHeader';
import AddressBuddyAddHeader from './mobileHeaderList/AddressBuddyAddHeader';
import SubChargeHeader from './mobileHeaderList/SubChargeHeader';

const MobileHeader = () => {
  const location = useLocation();
  const mobileBuddyListAdd = useRecoilValue(mobileBuddyListAddRecoil);

  return (
    <S.HeaderContainer className="headerContainer">
      {/* 주소록 헤더 */}
      {location.pathname === '/mobile/address/groupList' && <AddressGroupListHeader />}
      {location.pathname.startsWith('/mobile/address/buddyList') && <AddressBuddyListHeader />}
      {location.pathname.startsWith('/mobile/address/groupAdd') && <AddressGroupAddHeader />}
      {location.pathname.startsWith('/mobile/address/buddyAdd') && <AddressBuddyAddHeader />}

      {/* 채팅 헤더 */}
      {location.pathname === '/mobile/message/messageList' && <MessageListHeader />}
      {location.pathname === '/mobile/message/messageAdd' && <MessageAddHeader />}
      {location.pathname === '/mobile/message/messageNewAdd' && <MessageNewAddHeader />}
      {mobileBuddyListAdd && location.pathname.startsWith('/mobile/message/messageChatRoom/') && (
        <MessageChatRoomHeader />
      )}

      {/* ARS 헤더 */}
      {location.pathname === '/mobile/ars/arsTree' && <ArsTreeHeader />}
      {location.pathname === '/mobile/ars/arsScenario' && <ArsScenarioHeader />}

      {/* 더보기 헤더 */}
      {location.pathname === '/mobile/etc/etcList' && <EtcListHeader />}
      {location.pathname.startsWith('/mobile/etc') && location.pathname !== '/mobile/etc/etcList' && (
        <EtcCommonHeader />
      )}

      {/* 015 구독만료 충전 페이지 헤더 */}
      {/* 더보기 헤더 */}
      {location.pathname === '/mobile/015SubCharge' && <SubChargeHeader />}
    </S.HeaderContainer>
  );
};

export default MobileHeader;
