import styled from 'styled-components';

export const PopupContent = styled.div``;
// 팝업 헤드
export const PopupHeadMenu = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: -2px -2px 8px #333;
  padding: 1rem 2%;
  .headMenus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 6rem;
    li {
      position: relative;
      margin: 0 1rem;
      button {
        position: relative;
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: 700;
        ::after {
          opacity: 0;
          content: '';
          position: absolute;
          left: 50%;
          bottom: -2px;
          transform: translateX(-50%);
          display: block;
          width: 100%;
          height: 2px;
          background: ${(props) => props.theme.colors.main};
        }
      }
      &.active {
        button {
          color: ${(props) => props.theme.colors.main};
          ::after {
            opacity: 1;
          }
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    min-height: 8rem;
    .headMenus {
      width: 100%;
      flex-wrap: wrap;
      gap: 1rem;
      li {
        text-align: center;
        width: 20%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .headMenus {
      li {
        width: 45%;
        margin: 0;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .headMenus {
      li {
        button {
          font-size: 1.4rem;
        }
      }
    }
  }
`;

// 팝업 내용
export const PopupBody = styled.div`
  background: #fff;
  .navHelp {
    position: fixed;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
  }
  .row {
    width: 96%;
    height: 2px;
    margin: 12rem auto;
    border: 2px dashed ${(props) => props.theme.colors.gray600};
  }
  .rowMain {
    background-color: transparent;
    border-color: transparent;
    margin: 8rem auto 10rem;
  }
  .hidePopup {
    position: sticky;
    width: 90%;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5%;
    button {
      cursor: pointer;
      border: 2px solid ${(props) => props.theme.colors.main};
      padding: 2rem;
      border-radius: 0.5rem;
      color: ${(props) => props.theme.colors.main};
      font-size: 1.6rem;
      font-weight: 600;
      transition: 0.2s linear;
      background: ${(props) => props.theme.colors.gray100};
      :hover {
        background: ${(props) => props.theme.colors.main};
        color: ${(props) => props.theme.colors.txt100};
      }
    }
  }
  @media screen and (max-width: 768px) {
    .rowMain {
      margin: 12rem auto 13rem;
    }
  }
  /* @media screen and (max-width: 540px) {
    .rowMain {
      margin: 26rem auto 26rem;
    }
  } */
`;
export const HelpPageBody = styled.div``;
export const Page = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  img {
    width: 96%;
    display: block;
    margin: 0 auto;
    max-width: 1920px;
    min-width: 600px;
  }
`;
