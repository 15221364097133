import React from 'react';

import * as S from '../MobileHeader.style';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import { useNavigate } from 'react-router-dom';

const SubChargeHeader = () => {
  const navigate = useNavigate();
  return (
    <>
      <S.HeaderLeft>
        <S.HeaderButtonIcon
          onClick={() => {
            navigate('/mobile/mobileSignin');
            localStorage.clear();
          }}
        >
          <IconArrowLongLeft />
        </S.HeaderButtonIcon>
        <S.HeaderTitle>구독만료 충전</S.HeaderTitle>
      </S.HeaderLeft>
    </>
  );
};
export default SubChargeHeader;
