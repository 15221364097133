import styled from 'styled-components';

export const ToolBarListPage = styled.div`
  height: 20rem;

  /* @media screen and (max-width: 640px){} */
  @media screen and (max-width: 768px) {
    height: 11rem;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  height: 100%;
  border-radius: 3.5rem 3.5rem 0 0;
  line-height: normal;

  .customerGroup {
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem;
  }

  .headerMenuGroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-bottom: 1rem;

    button {
      padding: 0;
      border: 0;
      font-size: 1.4rem;
      font-weight: 600;
      color: #98999a;
      background: transparent;
      cursor: pointer;

      a {
        color: #9e9ea1;
      }

      :hover {
        color: #4f4f4f;
      }
    }

    button.black {
      color: #4f4f4f;
    }
  }

  .flexBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* 핸드폰 그룹 */
  .customerNumberGroup {
    // CustomerNumber
    .customerNumberMain {
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0 2px;
      background: transparent;
      color: ${(props) => props.theme.colors.main};
      font-size: 1.3rem;
      font-weight: 500;
      cursor: pointer;

      span {
        display: block;
        margin-right: 0.8rem;
      }
    }

    // CustomerNumberOthers

    .customerNumberOthers {
      width: max-content;
      border: 1px solid ${(props) => props.theme.colors.main};
      border-radius: 5px;

      li {
        padding: 10px;
        font-size: 1.3rem;
        color: ${(props) => props.theme.colors.main};
        cursor: pointer;
      }

      li:hover {
        background-color: ${(props) => props.theme.colors.main};
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  /* 고객 그룹 */
  .nameGroup {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2.2rem;
    font-weight: 800;
    max-height: 4rem;

    b {
      cursor: pointer;
      color: ${(props) => props.theme.colors.txt500};
    }

    span {
      color: ${(props) => props.theme.colors.gray600};
    }

    > div {
      > div {
        > button {
          padding: 0;
        }
      }
      button {
        font-size: 1.6rem;
        background: transparent;
        box-shadow: none;
        color: ${(props) => props.theme.colors.main};
        path {
          fill: ${(props) => props.theme.colors.main};
        }
      }
      ul {
        button {
          color: ${(props) => props.theme.colors.txt500};
        }
      }

      &.active {
        button {
          color: ${(props) => props.theme.colors.txt500};
          border-color: #adc1f4;
          border-bottom-color: #d6d6dc;

          path {
            fill: ${(props) => props.theme.colors.txt500};
          }
        }
      }
    }
  }

  /* 잔여 포인트 그룹 */
  .pointGroups {
    .pointGroup {
      display: flex;
      color: ${(props) => props.theme.colors.txt500};
      span {
        margin-left: 2px;
      }
    }

    .chargeButton {
      font-size: 1.3rem;
      padding: 4px 10px;
      font-weight: 700;
      background-color: ${(props) => props.theme.colors.main};
      border: 0;
      color: #fff;
      border-radius: 100px;
      cursor: pointer;
    }
  }

  /* 메뉴 그룹 */
  .rowMenuGroup {
    /* z-index: 1; */
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 2rem 2rem 0 0;
    background: ${(props) => props.theme.colors.gray300};

    .tabButton015 {
      justify-content: space-between;
    }

    .tabButton {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: flex-start;
      color: ${(props) => props.theme.colors.txt200};
      font-size: 2.4rem;
      font-weight: 600;
      height: 7rem;
      white-space: nowrap;
      width: 50%;

      border-radius: 2rem 2rem 0 0;
      border-bottom: 0;
    }

    .mode {
      padding: 5px 1.2rem 5px 2rem;
      font-weight: 700;
    }
    .money {
      display: flex;
      gap: 0.3rem;
      font-size: 1.6rem;
      font-weight: 500;
      text-align: left;
      color: ${(props) => props.theme.colors.txt400};
      padding-top: 8px;
    }
    .userNumber {
      font-size: 1.2rem;
      font-weight: 500;
      text-align: left;
      color: ${(props) => props.theme.colors.txt400};
      padding-top: 0.2rem;
    }
    /* 벡터이미지 수정 */
    .mode015 {
      svg {
        width: 100%;
        max-height: 32px;
        stroke: ${(props) => props.theme.colors.gray600};
      }
    }

    .arsTab {
      cursor: pointer;
      width: 4rem;
      height: 4rem;
      background-color: ${(props) => props.theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.2));
      box-shadow: inset -4px -4px 5px rgba(0, 0, 0, 0.1);
      margin-top: 2rem;
      margin-right: 2.4rem;

      svg {
        path {
          fill: ${(props) => props.theme.colors.txt500};
          stroke: ${(props) => props.theme.colors.txt500};
        }
      }
    }

    .tabButton.active {
      color: ${(props) => props.theme.colors.txt500};
      background: ${(props) => props.theme.colors.gray200};

      border-radius: 2rem 2rem 0 0;
      border-bottom: 0;

      .my015 svg {
        stroke: ${(props) => props.theme.colors.txt500};
      }

      .arsTab:disabled {
        svg {
          path {
            fill: ${(props) => props.theme.colors.main};
            stroke: ${(props) => props.theme.colors.main};
          }
        }
      }
    }

    .alertDot {
      display: block;
      position: absolute;
      top: -4px;
      right: -8px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #ee5600;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    .rowMenuGroup {
      .tabButton {
        height: 5rem;
        align-items: center;
      }
      .mode {
        padding: 0 !important;
        padding-left: 1.6rem !important;
      }
      .money,
      .userNumber,
      .arsTab {
        display: none;
      }
    }
    .nameGroup {
      font-size: 2rem;
      gap: 0.6rem;
      > div {
        button {
          font-size: 1.8rem;
          color: ${(props) => props.theme.colors.txt500};
          line-height: 1;
        }
      }
    }
    .customerGroup {
      justify-content: space-between;
      flex-direction: unset;
      margin: 1.5rem 0 0;
    }
  }
`;

/* 탭 그룹 */
export const ToolBarTabGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  > * {
    padding: 1rem 0.6rem;
    border-radius: 0.8rem;
    :hover {
      background: ${(props) => props.theme.colors.gray200};
    }
  }

  .group {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      color: ${(props) => props.theme.colors.txt500};
      font-weight: 600;
      height: calc(100% - 2rem);
    }
    p {
      font-size: 1.3rem;
      color: ${(props) => props.theme.colors.txt400};
    }
    &.active {
      background: ${(props) => props.theme.colors.gray200};
    }
  }
  .freeGroup {
    position: relative;
  }
  .arsGroup {
    div {
      color: ${(props) => props.theme.colors.main};
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    grid-template-columns: unset;
    width: unset;
    gap: 1rem;
    > * {
      padding: 0.4rem 1rem;
      background: ${(props) => props.theme.colors.gray100};
      border-radius: 50rem;
    }
    .group {
      flex-direction: unset;

      p {
        order: -1;
      }
    }
    .freeGroup {
      order: -1;
    }
  }
  @media screen and (max-width: 510px) {
    flex-wrap: wrap;
    .group {
      &.freePoint {
        ::before {
          content: '무료';
          display: block;
          position: relative;
          font-size: 1.2rem;
        }
      }
      > div {
        font-size: 1.2rem;
        svg {
          max-width: 1.2rem;
        }
      }
      p {
        display: none;
      }
    }
  }
  @media screen and (max-width: 346px) {
    gap: 0.2rem;
    .group {
      > div {
        font-size: 1rem;
      }
    }
  }
`;

export const PopTextBox = styled.div`
  position: absolute;
  top: calc(100% + 1rem);
  right: 0;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 92vw;
  max-width: 36rem;
  color: ${(props) => props.theme.colors.txt100};
  padding: 1.4rem;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.gray800};
  font-size: 1.3rem;

  small {
    display: block;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.txt200};
    margin: 1rem 0 0;

    a {
      color: ${(props) => props.theme.colors.txt200};
      text-decoration: underline;
      font-weight: 700;
    }
  }
  button {
    color: ${(props) => props.theme.colors.txt200};
  }

  @media screen and (max-width: 540px) {
    z-index: 2;
    position: fixed;
    top: 4rem;
    right: 1rem;
    width: calc(100% - 2rem);
    max-width: unset;
  }
`;

// 알림설정
export const NotifyGroup = styled.div`
  z-index: 2;

  .gray {
    color: #9d9d9d;
  }
  > button {
    cursor: pointer;
  }
  /* notifyBox */
  > div {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 94%;
    background: #3c3c3c;
    color: #fff;
    border-radius: 1.8rem;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.6);
    &.active {
      display: block;
    }
    /* top */
    > div {
      display: flex;
      justify-content: space-between;
      box-shadow: 0px 7px 7px -4px rgba(0, 0, 0, 0.25);
      padding: 1.4rem;
      color: #d8d8d8;
      font-size: 1.4rem;
      button {
        cursor: pointer;
      }
    }
    > ul {
      padding: 2rem 1.4rem;
      li {
        margin-bottom: 1.6rem;
        :last-of-type {
          margin: 0;
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1.6rem;
        }
        p {
          margin-top: 0.4rem;
          font-size: 1.2rem;
        }
      }
    }
  }
`;

export const Mode010Wrap = styled.div<{ sub015User: boolean }>`
  /* border: 1px solid ${(props) => props.sub015User && props.theme.colors.main}; */
  /* transition: box-shadow 0.3s; */

  .mode010 {
    animation: notice2 2s linear infinite;
    color: ${(props) => props.sub015User && props.theme.colors.main};
  }

  @keyframes notice2 {
    0% {
      color: ${(props) => props.sub015User && props.theme.colors.main};
    }
    50% {
      color: ${(props) => props.sub015User && props.theme.colors.txt300};
    }
    100% {
      color: ${(props) => props.sub015User && props.theme.colors.main};
    }
  }
`;
