import styled from 'styled-components';

export const MobileMainLayout = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  padding: 5rem 0 6rem;
  background: ${(props) => props.theme.colors.white};

  &:has(.mobileMessageChatRoom) {
    padding: 5rem 0 5.2rem;
  }
  &:has(.messageAddContainer) {
    padding: 11rem 0 0;
  }
  &:has(.mobileAddressBuddyList) {
    padding: 5rem 0 0;
  }

  &:has(.015signup) {
    padding: none;
  }

  &:has(.015subsignup) {
    padding: none;
  }

  &:has(.mobileArsTree) {
    .full {
      ol {
        height: calc(var(--vh, 100vh) - 6rem - 5rem);
      }
    }
  }
`;
