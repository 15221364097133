import MemberLeave from '@/components/Organism/MyPage/MemberLeave';
import React from 'react';

const MobileEtcMemberLeave = () => {
  return (
    <>
      <MemberLeave />
    </>
  );
};
export default MobileEtcMemberLeave;
