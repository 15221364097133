import * as S from '@/widgets/ars/ui/ArsWidget.styles';
import React from 'react';
import { useArsWidget } from '@/widgets/ars/hooks/useArsWidget';
import ArsTreeComponent from '@/widgets/ars/components/arsTree/ui/ArsTree.index';
import { useRecoilValue } from 'recoil';
import { mobileNavRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import ArsMockFeatures from '../components/arsMock/ui/ArsMock.index';

/**
 * @title ARS 위젯 컴포넌트 - 회원정보에 따라 표출될 컴포넌트가 나뉨
 *
 * */

const ArsWidget = () => {
  const { pageState, userMockupViewS } = useArsWidget();
  const mobileNavState = useRecoilValue(mobileNavRecoil);

  return (
    <S.SayContent isActive={mobileNavState === '인사말설정'}>
      {/*   015 구독 상태에 따른 목업, 시나리오 페이지*/}
      <S.Say015Wrap>
        {/* MOCK페이지, ARS페이지 */}
        {pageState === '정기구독' && <>{userMockupViewS ? <ArsMockFeatures /> : <ArsTreeComponent />}</>}
        {pageState === '단기구독' && <>{userMockupViewS ? <ArsMockFeatures /> : <ArsTreeComponent />}</>}
        {pageState === '구독만료' && <ArsMockFeatures />}
        {pageState === '미가입' && <ArsMockFeatures />}
      </S.Say015Wrap>
    </S.SayContent>
  );
};

export default ArsWidget;
