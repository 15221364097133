import { useModalHook } from '@/components/commons/modals/useModalHook';
import useSignInValidation from '@/components/hooks/customs/signIn/useSignInValidation';
import { useMsgRecoilReset } from '@/components/hooks/customs/useMsgRecoilReset';
import { useMutationLogin } from '@/components/hooks/mutations/postApis';
import { useMutationAddAuth } from '@/components/hooks/mutations/useMutationAddAuth';
import { datasProps, useInfoProps } from '@/pages/signIn/SignIn.types';
import { environmentRecoil, syncLoadingRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import {
  leftZoneComponentRecoil,
  mobileNavRecoil,
  softPhoneComponentRecoil,
  softPhoneTopTabRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import { signInRecoil } from '@/recoil/atoms/signInAtom';
import { useNice } from '@/shared/components/niceCertification/hooks/useNice';
import { useNiceCertification } from '@/shared/components/niceCertification/hooks/useNiceCertification';
import { getCookie, removeCookie, setCookie } from '@/shared/util/cookie';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { decrypt, encrypt } from '@/shared/util/crypto';
import { callbackListRecoil } from '@/recoil/atoms/useCallbackList';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

/* 로그인 패스워드 입력시 한글 -> 영문으로 치환시키기 위한 상수화 */
const qwertyToKorean = {
  q: 'ㅂ',
  w: 'ㅈ',
  e: 'ㄷ',
  r: 'ㄱ',
  t: 'ㅅ',
  y: 'ㅛ',
  u: 'ㅕ',
  i: 'ㅑ',
  o: 'ㅐ',
  p: 'ㅔ',
  a: 'ㅁ',
  s: 'ㄴ',
  d: 'ㅇ',
  f: 'ㄹ',
  g: 'ㅎ',
  h: 'ㅗ',
  j: 'ㅓ',
  k: 'ㅏ',
  l: 'ㅣ',
  z: 'ㅋ',
  x: 'ㅌ',
  c: 'ㅊ',
  v: 'ㅍ',
  b: 'ㅠ',
  n: 'ㅜ',
  m: 'ㅡ',
};

const koreanToQwerty = Object.fromEntries(Object.entries(qwertyToKorean).map(([qwerty, korean]) => [korean, qwerty]));

const convertKoreanToQwerty = (input: string) => {
  return input
    .split('')
    .map((char) => koreanToQwerty[char] || char)
    .join('');
};

/**
 * @title 로그인 페이지
 *
 * @author 정휘학
 * @since 2024.03.18
 * */

export const useSignIn = () => {
  const { schema } = useSignInValidation();
  const { successModal, warningModal, confirmModal } = useModalHook();
  const setSignInState = useSetRecoilState(signInRecoil);
  const [loginDeviceIdS, setLoginDeviceIdS] = useState<string[]>([]);
  /* 디바이스 변경시 response 값에 따라 토글되며 엔드포인트 변경됩니다. */
  const [deviceState, setDeviceState] = useState<boolean>(false);
  /* 로그인 비밀번호 viewable 토글 STATE */
  const [viewable, setViewable] = useState<boolean>(false);
  /* 나이스 인증 성공시 바로 로그인시켜줄 트리거 */
  const [loginTriger, setLoginTriger] = useState<boolean>(false);
  const deviceTriger = localStorage.getItem('loginDeviceIds');

  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);

  /**
   * =================================================================
   * @title localStorage 에 저장되어있는 deviceId 값 state 에 세팅 useEffect
   * =================================================================
   **/
  useEffect(() => {
    const loginDeviceId = localStorage.getItem('loginDeviceIds');
    if (loginDeviceId) {
      setLoginDeviceIdS(JSON.parse(loginDeviceId));
    }
  }, [localStorage.getItem('loginDeviceIds')]);

  // 나이스인증 후 즉시 로그인
  useEffect(() => {
    // TODO - app : app일때는 자동로그인 안함
    if (environmentS !== 'app' && loginTriger && deviceTriger && loginDeviceIdS) {
      onClickLogin({ signInId: getValues('signInId'), signInPassword: getValues('signInPassword') });
    }
  }, [loginTriger, loginDeviceIdS]);

  /* 나이스 인증 팝업 띄우는 Hook */
  const { niceCertification } = useNiceCertification();
  /* 나이스 인증 초기 encodeData 받아오는 Hook */
  const { niceData } = useNice();
  // form 등록 기능
  const {
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      signInId: '',
      signInPassword: '',
    },
  });

  /* 로그인 API */
  const { mutate, isLoading } = useMutation(useMutationLogin);
  /* CI, DI 누락회원 업데이트 API */
  const { mutate: addAuthMutate } = useMutation(useMutationAddAuth);

  const [, setSoftPhoneTopTabS] = useRecoilState(softPhoneTopTabRecoil);
  const [, setSoftPhoneComponent] = useRecoilState(softPhoneComponentRecoil);
  const setLeftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  const setMobileNavState = useSetRecoilState(mobileNavRecoil);
  const callbackListS = useRecoilValue(callbackListRecoil);
  const setSyncLoadingS = useSetRecoilState(syncLoadingRecoil);

  const { resetRecoil } = useMsgRecoilReset();
  const navigation = useNavigate();

  const moveInitPage = () => {
    environmentS !== 'app' && navigation('/');
    resetRecoil();
    setSoftPhoneComponent('chatroom');
    setSoftPhoneTopTabS('0');
    setLeftZoneComponentS('address');
    setMobileNavState('메세지');
  };

  /**
   * ==============================
   * @title 로그인 버튼 클릭 이벤트 핸들러
   * ==============================
   **/

  const onClickLogin = async (data: useInfoProps) => {
    const encryptId = encrypt(data.signInId as string);
    const encryptPw = encrypt(convertKoreanToQwerty(data.signInPassword as string));
    const datas: datasProps = {};
    datas.loginId = encryptId;
    datas.loginPassword = encryptPw;
    datas.deviceId = loginDeviceIdS;
    if (environmentS === 'app') {
      datas.clientEnv = 'app';
    }
    mutate(JSON.stringify(datas), {
      onSuccess: (response) => {
        if (environmentS === 'app') {
          // TODO - app : 앱에서 쿠키에 5분간 암호화된 아이디 저장
          setCookie('appId', encryptId, { maxAge: 300, path: '/' });
        }
        if (response?.failMsg && !response?.needAuthInfo && !response?.needDeviceRegistration) {
          /* 단순히 ID 및 PW 틀렸을 경우 */
          warningModal('로그인 실패', response?.failMsg, true);
        } else if (response?.failMsg && response?.needAuthInfo) {
          /* CI, DI 값이 없을 경우 NICE 인증 요청 */
          confirmModal(niceCertification, '로그인 실패', response?.failMsg, true);
        } else if (response?.failMsg && !response?.needAuthInfo && response?.needDeviceRegistration) {
          /* 디바이스 변경될 경우 */
          // TODO - app : 앱에서 쿠키 값으로 endpoint 찍음
          setCookie('appDeviceS', true, { maxAge: 300, path: '/' });
          setDeviceState(true);
          confirmModal(
            niceCertification,
            '로그인 실패',
            `${response?.failMsg}</br>* 1개 기기당 최대 3개 계정인증 저장</br>* 초과시 맨 처음에 등록한 계정인증 삭제`,
            true,
          );
        } else {
          // TODO - app : 앱에서 쿠키에 암호화된 아이디 삭제
          removeCookie('appId');
          removeCookie('Refresh_Token');
          setSignInState(true);
          // 성공한 경우 로직 작성
          // localStorage.setItem('unreadMsg', JSON.stringify(response.unreadMsg));
          localStorage.setItem('authorizationToken', response.token);
          localStorage.setItem('user', response.usrNm);
          localStorage.setItem('say015User', String(response?.say015User));
          localStorage.setItem('availabilityStatus', String(response?.availabilityStatus));
          localStorage.setItem('isMobileContactsSynced', String(response?.isMobileContactsSynced));

          // 특정 이슈(핫픽스. 이벤트 등)가 있을때 사용하는 로컬스토리지
          // localStorage.setItem('hotfix', 'Y');
          // localStorage.setItem('event1', 'Y');
          setCookie('Refresh_Token', `${response.refreshToken}`, { path: '/' });
          // queryClient.refetchQueries(['/userPoint']);
          // queryClient.refetchQueries(['groupList2']);

          if (environmentS === 'app') {
            if (localStorage.getItem('say015User') === 'false') {
              //015 가입이 아예 안되어 있을 때 *************************************
              // 미가입 시
              confirmModal(
                () => {
                  navigation('/mobile/015Signup');
                },
                '015 서비스 가입자가 아닙니다.',
                '015 서비스를 가입하시겠습니까?',
                true,
                undefined,
                () => {
                  setSignInState(false);
                  navigation('/mobile');
                },
              );
            } else {
              //가입이 되어있을 때 *************************************
              if (localStorage.getItem('availabilityStatus') === 'false') {
                // 가입은 되어 있는데 구독이 만료됐을 때
                confirmModal(
                  () => {
                    navigation('/mobile/015SubSignup');
                  },
                  '015 구독이 만료 되었습니다.',
                  '015 서비스를 재가입 하시겠습니까?',
                  true,
                  undefined,
                  () => {
                    setSignInState(false);
                    navigation('/mobile');
                  },
                );
              } else {
                navigation('/mobile/message');

                if (
                  localStorage.getItem('say015User') === 'true' &&
                  (localStorage.getItem('isMobileContactsSynced') === 'false' ||
                    localStorage.getItem('isMobileContactsSynced') === null)
                ) {
                  confirmModal(
                    async () => {
                      setSyncLoadingS(true);
                      window.fromWebToAppReqAddressList(data.signInId);
                    },
                    '주소록 동기화',
                    '핸드폰의 주소록을 동기화하시겠습니까?',
                    true,
                    undefined,
                    () => {
                      warningModal('동기화 실패', '주소록에서 동기화를 다시 시도할 수 있습니다.', true);
                    },
                  );
                }
              }

              moveInitPage();
              //앱으로 Id값을 넘기고 + 주소록 달라고 요청
              window.fromWebToAppWithID(data.signInId);
            }
          }
        }
      },
    });
  };

  /**
   * @title addAuthMutate API onSuccess Hook
   *
   * @author 정휘학
   * @since 2024.03.18
   * */
  // const addAuthMutateSuccess = (res: IUseMutationAddAuthReturn) => {
  const addAuthMutateSuccess = (res: any) => {
    // TODO - app : 앱에서 쿠키 appDeviceS 제거
    removeCookie('appDeviceS');
    if (!res.updateResult) warningModal('인증', res.msg, true);

    if (res.updateResult) {
      /* 새로운 계정의 deviceId 인증 코드를 기존에 저장되어있던 localStorage 데이터와 병합 */
      const newLoginDeviceIds = [...loginDeviceIdS, res.deviceId];

      /* 하나의 기기에 3개의 계정에 대한 deviceId 코드를 가지고 있을 수 있음. 만약 3개 초과시 기존에 있던 가장 오래된 deviceId 코드 제거 */
      if (newLoginDeviceIds && newLoginDeviceIds.length > 3) {
        newLoginDeviceIds.shift();
      }
      localStorage.setItem('loginDeviceIds', JSON.stringify(newLoginDeviceIds));
      successModal('인증', res.msg, true);
    }
    setLoginTriger(true);
    // onClickLogin({ signInId: getValues('signInId'), signInPassword: getValues('signInPassword') });
  };

  /**
   * @title 나이스 인증 완료 후 로직 ( encodeData 로 ci, di 값 업데이트 )
   *
   * @author 정휘학
   * @since 2024.03.18
   * */
  useEffect(() => {
    if (niceData) {
      // TODO - app : 앱에서 id값 서버로 보냄
      const loginIdS = environmentS === 'app' ? (decrypt(getCookie('appId')) as string) : getValues('signInId');

      const deviceStateS = environmentS === 'app' && getCookie('appDeviceS');
      addAuthMutate(
        { loginId: loginIdS, encodeData: niceData, device: environmentS === 'app' ? deviceStateS : deviceState },
        {
          onSuccess: (res) => addAuthMutateSuccess(res),
        },
      );
      // 앱에서 들어올때 잠시 유저 아이디가 필요
    }
  }, [niceData]);

  // 패스워드 viewable button hook
  const onClickPwViewableToggle = () => {
    setViewable((prev) => !prev);
  };

  return {
    handleSubmit,
    onClickLogin,
    errors,
    isLoading,
    setValue,
    register,
    viewable,
    onClickPwViewableToggle,
  };
};
