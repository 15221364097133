import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconImage: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '1.7rem'}
      viewBox="0 0 17 17"
      fill="none"
    >
      <g clipPath="url(#clip0_19_40)">
        <path
          d="M6.49335 6.41516C6.49335 6.81299 6.33531 7.19452 6.05401 7.47582C5.7727 7.75713 5.39117 7.91516 4.99335 7.91516C4.59552 7.91516 4.21399 7.75713 3.93269 7.47582C3.65138 7.19452 3.49335 6.81299 3.49335 6.41516C3.49335 6.01734 3.65138 5.63581 3.93269 5.3545C4.21399 5.0732 4.59552 4.91516 4.99335 4.91516C5.39117 4.91516 5.7727 5.0732 6.05401 5.3545C6.33531 5.63581 6.49335 6.01734 6.49335 6.41516Z"
          fill={fill ?? 'currentColor'}
        />
        <path
          d="M2.49335 1.91516C1.96291 1.91516 1.45421 2.12587 1.07913 2.50095C0.704061 2.87602 0.493347 3.38473 0.493347 3.91516V13.9152C0.493347 14.4456 0.704061 14.9543 1.07913 15.3294C1.45421 15.7044 1.96291 15.9152 2.49335 15.9152H14.4933C15.0238 15.9152 15.5325 15.7044 15.9076 15.3294C16.2826 14.9543 16.4933 14.4456 16.4933 13.9152V3.91516C16.4933 3.38473 16.2826 2.87602 15.9076 2.50095C15.5325 2.12587 15.0238 1.91516 14.4933 1.91516H2.49335ZM14.4933 2.91516C14.7586 2.91516 15.0129 3.02052 15.2005 3.20805C15.388 3.39559 15.4933 3.64994 15.4933 3.91516V10.4152L11.7163 8.46816C11.6226 8.42118 11.5164 8.40489 11.4128 8.42158C11.3093 8.43827 11.2136 8.4871 11.1393 8.56116L7.42935 12.2712L4.76935 10.4992C4.67331 10.4352 4.55811 10.4065 4.44329 10.4178C4.32846 10.4291 4.22108 10.4797 4.13935 10.5612L1.49335 12.9152V3.91516C1.49335 3.64994 1.5987 3.39559 1.78624 3.20805C1.97378 3.02052 2.22813 2.91516 2.49335 2.91516H14.4933Z"
          fill={fill ?? 'currentColor'}
        />
      </g>
    </Svg>
  );
};

export default IconImage;
