import React from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import BaseButton from '../../Atom/BaseButton';
import BaseSelect from '../../Atom/BaseSelect';
import { ChargeDatesSearch } from '../../hooks/customs/charge/useChargeDatesSearch';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { formatDateBase } from '@/shared/util/format/dateFormatUtil';
import IconSearch from '@/styles/icons/IconSearch';

const { RangePicker } = DatePicker;

const SearchWrap = styled.div`
  display: flex;

  select,
  .ant-picker-borderless {
    cursor: pointer;
    color: ${(props) => props.theme.colors.txt400};
    border-radius: 0.6rem;
    border: 1px solid ${(props) => props.theme.colors.txt200};
  }

  select {
    background: transparent;
    font-size: 1.4rem;
  }
  .ant-picker-borderless {
    padding: 0.3rem 0.5rem;
  }

  @media screen and (max-width: 768px) {
    select,
    .ant-picker-borderless {
      font-size: 1.6rem;
      padding: 0.6rem 1rem;
    }
  }
  @media screen and (max-width: 540px) {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    > div {
      width: 100%;
    }
    .ant-picker-borderless {
      width: 100%;
    }
  }
`;

const RangePickerWrap = styled.div`
  margin-left: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  button {
    width: auto;
    svg {
      color: ${(props) => props.theme.colors.txt200};
    }
  }
  @media screen and (max-width: 540px) {
    margin-left: 0;
    > div {
      padding: 0;
    }
  }
  .custom-range-picker input::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
    font-size: 1.4rem;
  }
`;

// date 클릭 시 현재 달 출력
const MonthEnd = dayjs().subtract(1, 'month').startOf('month');

const ChargeDateSearch = (props: any) => {
  const { chargeOptionsProp, name1, disabledDate, setSelectMode } = props;

  const { onChangeRangePickerDate, handleChargeSelect, handleChargeSearch, selectedValue, initialDates } =
    ChargeDatesSearch({
      setStartPage: props.setStartPage,
      setActivePage: props.setActivePage,
      setSearchSelectValue: props.setSearchSelectValue,
      setSearchDates: props.setSearchDates,
      setCurrentPage: props.setCurrentPage,
    });

  const onChangeMode = (e: string) => {
    setSelectMode(e);
  };

  return (
    <SearchWrap>
      {window.location.pathname === '/messageResult' && (
        <>
          <select
            onChange={(e) => {
              onChangeMode(e.target.value);
            }}
            id="selectBox"
            name="options"
          >
            <option value="010">010</option>
            {/* Todo 전송결과조회 -> 015추가 되면 살리기  */}
            {/* <option value="015">015</option> */}
          </select>
        </>
      )}
      {chargeOptionsProp && (
        <BaseSelect name={name1} options={chargeOptionsProp} value={selectedValue} onChange={handleChargeSelect} />
      )}
      <RangePickerWrap>
        <RangePicker
          id="searchDate"
          variant="borderless"
          className="custom-range-picker"
          onChange={onChangeRangePickerDate}
          disabledDate={disabledDate}
          defaultValue={
            initialDates[0] && initialDates[1]
              ? [
                  dayjs(formatDateBase(initialDates?.[0]), 'YYYY-MM-DD'),
                  dayjs(formatDateBase(initialDates?.[1]) ?? new Date(), 'YYYY-MM-DD'),
                ]
              : undefined
          }
          defaultPickerValue={[MonthEnd, MonthEnd]}
          locale={locale}
        />
        <BaseButton type={'submit'} backgroundColor="transparent" onClick={handleChargeSearch}>
          <IconSearch />
        </BaseButton>
      </RangePickerWrap>
    </SearchWrap>
  );
};

export default ChargeDateSearch;
