// Component CSS 스타일링
// import styled from '@emotion/styled';
//
import styled from 'styled-components';

export const AddTalbeComponent = styled.div`
  z-index: 4;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  &.active {
    .selectPop {
      button {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  form {
    width: 100%;
    .wrap {
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
        border: 1px solid ${(props) => props.theme.colors.gray100};
      }
      /* scroll thumb */
      ::-webkit-scrollbar-thumb {
        background-color: #98999a;
        border-radius: 4rem;
        border: 2px solid transparent;
        background-clip: padding-box;
      }
    }

    &:has(.selBox.active) .wrap {
      overflow: unset;
    }
    .delTd {
      position: sticky;
      background: ${(props) => props.theme.colors.white};
      right: 0;
      min-width: 2.8rem;
      width: 2.8rem;
    }
    .addTableInputWarp {
      input {
        width: 100%;
        height: 30px;
        font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
        text-align: center;
        outline: none;
        border: 1px solid #d6d6dc;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        &::placeholder {
          font-size: 1.3rem;
          font-weight: 500;
          font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
          color: ${(props) => props.theme.colors.placeholder};
        }
      }
    }
  }
  .selBox {
    button {
      padding: 0.8rem 1rem;
    }
  }
  @media screen and (max-width: 1024px) {
    form {
      display: block;
    }
  }
  @media screen and (max-width: 640px) {
    .selectPop {
      ul {
        left: 0;
        transform: unset;
      }
    }
  }
`;

export const AddTalbeGroup = styled.div`
  display: flex;
  gap: 1.2rem;
  border-top: 3px solid ${(props) => props.theme.colors.main};
  padding: 1.4rem 1.2rem;
  box-shadow: 0px 0.7rem 2rem 0px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 0.5rem 0.5rem;
  .inputwrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: calc(100% - 10.6rem);
    > p {
      svg {
        width: 1.7rem;
        path {
          stroke: ${(props) => props.theme.colors.main};
          fill: ${(props) => props.theme.colors.main};
        }
      }
    }
    > input,
    > div {
      width: calc(100% / 5);
      border: 0;
      border-radius: 0.5rem;
      background: ${(props) => props.theme.colors.gray100};
      outline: 1px solid ${(props) => props.theme.colors.gray300};
      text-overflow: ellipsis;
    }
    .txtMax {
      p {
        max-width: calc(100% - 2.4rem);
      }
      svg {
        width: 1.4rem;
      }
    }
    input {
      padding: 0.8rem 1rem;
      color: ${(props) => props.theme.colors.txt500};
      ::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }
    }
  }
  /* 저장, 취소 스타일 */
  .btnGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    button {
      cursor: pointer;
      width: 5rem;
      min-height: 3.2rem;
      height: 100%;
      border-radius: 0.5rem;
      background: ${(props) => props.theme.colors.gray600};
      color: ${(props) => props.theme.colors.white};
      &.addBtn {
        background: ${(props) => props.theme.colors.gray800};
      }
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    .selBox {
      button {
        min-height: 3.2rem;
      }
    }
    .inputwrap {
      width: 100%;
      flex-wrap: wrap;
      > div {
        width: calc(100% - 3rem);
      }
      > input {
        width: 100%;
      }
    }
    .btnGroup {
      width: 100%;
      button {
        width: 50%;
      }
    }
  }
`;
