import styled from 'styled-components';

export const SignUpContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 4rem;
  }
`;

export const SignUpFormWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 4.3vw 0;
  position: relative;

  padding: 0 5%;
  box-sizing: border-box;

  &::before {
    content: '서울이동통신 회원가입';
    display: block;
    color: ${(props) => props.theme.colors.txt500};
    text-align: center;
    font-family: 'Inter';
    font-size: 3rem;
    font-style: normal;
    font-weight: 582;
    line-height: normal;
  }
`;

export const Say015ApplyTitle = styled.h2`
  text-align: center;
  margin-top: 11.5rem;
  color: ${(props) => props.theme.colors.txt500};
  font-size: 2rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.1vw;
  @media screen and (max-width: 640px) {
    font-size: 1.6rem;
  }
`;

// 인증 번호
export const CertifyTitle = styled.h2`
  font-size: 1.5rem;
  display: inline-block;
`;

export const CertifyWrap = styled.div`
  margin-bottom: 5rem;
`;

export const CertifyInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const CertifyTimer = styled.time`
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.notify};
`;

// 인증 번호

export const SignUpLine = styled.div`
  margin: 2.5rem 0;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.gray600};
`;

export const RecoveryPwWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const RecoveryPwInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  button {
    font-size: 1.4rem;
  }
  input {
    padding: 0.5rem 1rem;
    min-height: 4rem;
    width: 100%;
  }
`;

export const AgreementButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
  box-sizing: border-box;
  margin: 2vw auto;
`;

export const ValidationError = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.notify};
`;

export const ValidationMessage = styled.p`
  font-size: 1.4rem;
  color: gray;
  margin-bottom: 1rem;
`;

export const ValidationSuccess = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.green};
`;
