import React from 'react';
import * as S from '../MobileHeader.style';
import { TbArrowBack } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';

const MessageChatRoomHeader = () => {
  const navigate = useNavigate();
  const [mobileBuddyListAdd, setMobileBuddyListAdd] = useRecoilState(mobileBuddyListAddRecoil);

  return (
    <>
      <S.HeaderLeft className="chatRoomHeader">
        <S.HeaderButtonIcon
          onClick={() => {
            navigate(-1);
          }}
        >
          {/* <TbArrowBack /> */}
          <IconArrowLongLeft />
        </S.HeaderButtonIcon>
        {mobileBuddyListAdd.buddyNm && <S.HeaderTitle>{mobileBuddyListAdd.buddyNm}</S.HeaderTitle>}

        {/* 버디 네임이 없으면 전화번호가 타이틀 */}
        {mobileBuddyListAdd.buddyNm ? (
          <S.HeaderContents>{mobileBuddyListAdd.keyCommNo && TelHyphen(mobileBuddyListAdd.keyCommNo)}</S.HeaderContents>
        ) : (
          <S.HeaderTitle>{mobileBuddyListAdd.keyCommNo && TelHyphen(mobileBuddyListAdd.keyCommNo)}</S.HeaderTitle>
        )}
      </S.HeaderLeft>
    </>
  );
};

export default MessageChatRoomHeader;
