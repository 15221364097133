import styled from 'styled-components';

export const SignUpContainer = styled.div`
  /* background: #f5f5f5; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 4rem;
  }
`;

export const SignUpFormWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 4.3vw 0;
  position: relative;
  flex-shrink: 0;
  padding: 0 5%;
  box-sizing: border-box;

  &::before {
    content: '서울이동통신 회원가입';
    display: block;
    color: ${(props) => props.theme.colors.txt500};
    text-align: center;
    font-family: 'Inter';
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 582;
    line-height: normal;
  }
`;

export const Say015ApplyTitle = styled.h2`
  text-align: center;
  margin-top: 10rem;
  color: ${(props) => props.theme.colors.txt500};
  font-size: 2rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.1vw;
`;

export const SignUpLine = styled.div`
  margin: 2.5rem 0;
  width: 100%;
  border: 1px solid #8c8c8c;
`;

export const SignUpSubTitle = styled.p`
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 25px;
`;

export const AgreementButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  margin: 4vw auto;
`;

export const Say015ApplyServiceGuide = styled.div`
  width: 100%;
  margin-top: 7.2rem;
  /* margin-left: 5px; */
  margin-bottom: 10rem;
  font-size: 1.2rem;
  line-height: 2.5rem;
`;

export const SignUpSubTitleWrap = styled.div`
  display: flex;
  line-height: 3.5rem;
`;

export const IdCheckBigWrap = styled.div`
  width: 42%;
`;

export const IdCheckSmallWrap = styled.div`
  width: 15%;
  margin-left: 3.5rem;
`;
