import { theme } from 'antd';
import styled from 'styled-components';

/* Start EtcChattingHead */
export const EtcChattingHead = styled.div`
  padding: 1.5rem;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  /* @media screen and (max-width: 768px) {
    padding: 2.6rem 0 1rem 0;
  } */
`;

export const EtcChattingHeadReg = styled.div`
  // 등록
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  /* @media screen and (max-width: 640px) {
    padding: 0 1.6rem;
  } */
`;

export const EtcChattingHeadRegLeftWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const EtcChattingHideBtn = styled.button`
  // 채팅방 닫기 버튼
  cursor: pointer;
  padding: 0;
  color: ${(props) => props.theme.colors.txt500};
  /* svg {
    width: 1.7rem;
    height: 1.6rem;
  } */
`;

export const ProfileInquiryBtn = styled.button`
  // 프로필 조회?
  padding: 0;
  cursor: pointer;
`;

export const RegContactInfoWrap = styled.div`
  // 연락처 이름 + 연락처 휴대폰번호 + 주소록 추가 버튼
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const RegContactName = styled.h5`
  // 연락처 이름
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  .iconCustomer {
    color: ${(props) => props.theme.colors.main};
    svg {
      width: 1.8rem;
      height: 2rem;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: 480px) {
    margin: 0;
    font-size: 1.6rem;
  }
`;
export const RegContacPhoneNumber = styled.span`
  // 연락처 휴대폰번호
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.txt200};
  font-weight: 700;
  @media screen and (max-width: 768px) {
    /* font-size: 1.2rem; */
  }
`;

export const RegAddressBookAddBtn = styled.button`
  // 주소록 추가 버튼
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.txt300};
  border-radius: 0.4rem;
  :hover {
    border-color: ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.main};
  }
`;

export const EtcChattingHeadRegRightWrap = styled.div``;
export const SearchBtn = styled.button`
  //***** 검색 버튼 등록 & 미등록 공용 *****//
  padding: 0;
  cursor: pointer;
`;

export const EtcChattingHeadNotReg = styled.div`
  // 미등록
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  /* @media screen and (max-width: 480px) {
    padding: 0 1.6rem;
  } */
`;

export const EtcChattingHeadNotRegLeftWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const NotRegContactInfoWrap = styled.div`
  // 미등록 휴대폰번호 + 주소등록하기버튼
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const NotRegContacPhoneNumber = styled.h5`
  // 미등록 휴대폰번호
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};

  .iconCustomer {
    color: ${(props) => props.theme.colors.main};
    svg {
      width: 1.8rem;
      height: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 480px) {
    margin: 0;
  }
`;

export const AddressAddBtn = styled.button`
  // 주소록 등록하기
  display: flex;
  align-items: center;
  gap: 17px;
  padding: 0 9px;
  height: 28px;
  border-radius: 10px;
  background: #f1f2f8;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    gap: 8px;
  }

  span {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.main};
    @media screen and (max-width: 480px) {
      font-size: 1.1rem;
    }
  }
`;

export const EtcChattingHeadNotRegRightWrap = styled.div``;
/* End EtcChattingHead */
