import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { GetPointApiType } from '@/apis/api/pointApi';
import { chargeOnlienYupSchema } from '@/components/Molecules/Charge/charge/chargeOnlienPopup/ChargeOnlienPopup.validation';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { IUseMutationPurchasePass } from '@/components/hooks/mutations/apiTypes';
import { useMutationPurchasePass } from '@/components/hooks/mutations/postApis';
import { useEasy } from '@/shared/components/easyPay/hooks/useEasy';
import { ENQUIRY_PHONE, SERVICE_NAME } from '@/shared/constants';
import { detectEnvUtils } from '@/shared/util/detectEnvUtils';
import { amountFormat } from '@/shared/util/format/amountFormatUtil';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { IUseMutationChargeKicc, useMutationChargeKicc } from '../../../mutations/useMutationChargeKicc';
import { useMutationChargeProcessKicc } from '../../../mutations/useMutationChargeProcessKicc';
import { useRecoilValue } from 'recoil';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { Navigate, useNavigate } from 'react-router-dom';

interface IUsePageCharge {
  // 리다이렉션될 url
  returnUrl: string;
}

export const usePageCharge = (props: IUsePageCharge) => {
  const queryClient = useQueryClient();
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);
  const userPoint: GetPointApiType | undefined = queryClient.getQueryData(['/userPoint']);
  const { warningModal, successModal, confirmModal, customSuccessModal } = useModalHook();
  const navigate = useNavigate();

  // 무통장 입금에 연관되어 있는 데이터 useForm
  const { register, handleSubmit, formState, getValues } = useForm({
    resolver: yupResolver(chargeOnlienYupSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      phoneNumber: '',
    },
  });
  const { errors, isValidating } = formState;

  // 이지페이 결제 승인 ENV URL
  const EASYPAY_APPROVE_URL = process.env.REACT_APP_EASYPAY_APPROVE_URL;
  // 이지페이 승인 취소 ENV URL
  const EASYPAY_REVISE_URL = process.env.REACT_APP_EASYPAY_REVISE_URL;
  // 무통장 입금 팝업창 표출 state
  const [bankTransferOpenS, setBankTransferOpenS] = useState<boolean>(false);
  // 무통장 입금 알림받을 휴대폰번호 state
  const [bankTransferPhoneNumberS, setBankTransferPhoneNumberS] = useState<string | undefined>('');
  // 이지페이 API ENV Url
  const easyPayUrl = process.env.REACT_APP_EASYPAY_UI_URL;

  const { easyData } = useEasy();

  // ===================================================================================================================
  // ( end_point : /purchase/pass )
  // 015 이용권 캐시로 결제 MUTATION POST API
  // ===================================================================================================================
  const { mutate: purchasePassM } = useMutation(useMutationPurchasePass);

  // ===================================================================================================================
  // ( end_point : /charge/kicc )
  // 이지페이 팝업 표출에 필요한 데이터 요청 MUTATION POST API
  // ===================================================================================================================
  const {
    mutate: chargeKiccMutation,
    data: chargeKiccData,
    isLoading: isChargeKiccLoading,
  } = useMutation(useMutationChargeKicc);

  // ===================================================================================================================
  // ( end_point : /charge/process-kicc )
  // 이지페이 결제 팝업에서 결제 완료 후 받은 데이터를 서버에 저장 MUTATION POST API
  // ===================================================================================================================
  const {
    mutate: chargeProcessKiccMutation,
    data: chargeProcessKiccData,
    isLoading: isChargeProcessKiccLoading,
  } = useMutation(useMutationChargeProcessKicc);

  // ===================================================================================================================
  // 015 이용권 캐시 결제 Handle hook
  // ===================================================================================================================
  const handlePurchasePass = ({ amount, productType }: IUseMutationPurchasePass) => {
    if (!userPoint) return;
    const pointEnough = userPoint.use_pg - amount >= 0;

    const title = `${SERVICE_NAME} 이용권 캐시 결제`;
    const successContent = `${productType === 'monthly_pass' ? '월간 이용권' : '연간 이용권'} 구매하시겠습니까? </br> 금액은 ${amountFormat(amount)}입니다.`;
    const failContent = `${productType === 'monthly_pass' ? '월간 이용권' : '연간 이용권'} 구매에 실패했습니다.</br> 이용권금액 : ${amountFormat(amount)}원 / 보유캐시 : ${userPoint.use_pg}원`;

    if (pointEnough) {
      confirmModal(
        async () => {
          purchasePassM(
            { amount, productType },
            {
              onSuccess: () => {
                customSuccessModal(
                  () => {
                    environmentS === 'app' ? (navigate('/'), window.location.reload()) : undefined;
                  },
                  `${SERVICE_NAME} 통신료 결제`,
                  `${SERVICE_NAME} 통신료 결제 성공했습니다.`,
                  true,
                );

                queryClient.refetchQueries(['/say015/serviceStatus']);
                queryClient.refetchQueries(['/userPoint']);
              },
              onError: () => {
                warningModal(
                  `${SERVICE_NAME} 통신료 결제`,
                  `${SERVICE_NAME} 통신료 결제 실패했습니다.</br>문의 전화번호 : ${ENQUIRY_PHONE}`,
                  true,
                );
              },
            },
          );
        },
        title,
        successContent,
        true,
      );
    } else {
      warningModal(title, failContent, true);
    }
  };

  // ===================================================================================================================
  // 무통장 입금 '입금요청서 작성하기' 버튼 클릭 이벤트 트리거 hook
  // ===================================================================================================================
  const onClickDepositRequest = (amount: number) => {
    if (amount && amount >= 1000) {
      setBankTransferOpenS(true);
    } else {
      warningModal('결제 실패', '결제 금액은 1,000원 이상만 가능합니다.', true);
    }
  };

  // ===================================================================================================================
  // 결제 버튼 클릭 이벤트 트리거 Hook
  // ===================================================================================================================
  const onChargeApi = async ({ payMethodTypeCode, amount, returnUrl, productType }: IUseMutationChargeKicc) => {
    const returnUrlS =
      environmentS === 'app'
        ? `${window.location.origin}/mobile/${returnUrl}`
        : `${window.location.origin}/${returnUrl}`;
    if (!payMethodTypeCode || amount < 1000) {
      // 결제수단 미선택 및 결제금액이 1,000원 미만에 대한 분기처리
      warningModal('결제 실패', '결제 금액은 1,000원 이상만 가능합니다.', true);
      return;
    }

    if (payMethodTypeCode === '1') {
      // 015 이용권 결제 수단이 캐시일 경우
      handlePurchasePass({ productType, amount });
    } else {
      // 그 외 결제 수단
      const data: IUseMutationChargeKicc = {
        // ( /charge/kicc ) api 요청에 필요한 data
        payMethodTypeCode, // 결제 방식 11: 신용카드, 21: 계좌이체, 31: 휴대폰
        amount, // 상품 가격
        returnUrl: returnUrlS, // 리다이렉션 될 URL
        productType, // cash : 기본 캐시 충전 , monthly_pass : 월 이용권, annual_pass: 년 이용권
      };
      chargeKiccMutation(data, {
        onSuccess: (data) => {
          fetchPaymentData(data.data);
        },
        onError: () => {},
      }); // ( /charge/kicc ) api 요청
    }
  };

  const fetchPaymentData = async (data: any) => {
    if (!easyPayUrl) return;
    const response = await axios.post(easyPayUrl, data);
    if (response.data?.resCd !== '0000') {
      warningModal(
        '결제 실패',
        `결제를 실패했습니다.</br>다시 시도 부탁드립니다.</br>계속 실패가 될 경우 문의 부탁드립니다.</br>문의 전화번호: ${ENQUIRY_PHONE}</br>에러코드:${response?.data?.resCd}`,
        true,
      );
    } else {
      const url = response?.data?.authPageUrl;
      if (url) {
        if (environmentS === 'app') {
          localStorage.setItem('prevPage', window.location.pathname);
          window.location.href = url;
        } else {
          const left = window.screen.width / 2 - 500 / 2;
          const top = window.screen.height / 2 - 800 / 2;
          const option = `status=no, menubar=no, toolbar=no, scrollbars=yes, resizable=yes, width=700, height=600, left=${left}, top=${top}`;

          window.open(url, 'EasypayWindow', option);
        }
      }
    }
  };

  // ===================================================================================================================
  // 이지페이 팝업을 표출하기 위해 필요한 데이터를 서버로 부터 받아와
  // state 에 저장 및 이지페이 팝업 컴포넌트 표출 state 를 true 로 변경하는 useEffect
  // ===================================================================================================================
  // useEffect(() => {
  //   if (!isChargeKiccLoading && chargeKiccData) {
  //     setEasyPayPopupDataS(chargeKiccData.data);
  //     openPopup();
  //   }
  // }, [chargeKiccData, isChargeKiccLoading]);

  // ? /charge/process-kicc api 반환값에 type 이 없을 경우 승인 취소 api ( type 이 없다는건 DB 오류 )
  useEffect(() => {
    if (!isChargeProcessKiccLoading && chargeProcessKiccData) {
      if (chargeProcessKiccData?.type === undefined && EASYPAY_REVISE_URL) {
        // DB 에 데이터 적재 실패시
        axios.post(EASYPAY_REVISE_URL, chargeProcessKiccData).then(() => {
          warningModal(
            '결제 실패',
            `결제를 실패했습니다.</br>다시 시도 부탁드립니다.</br>계속 실패가 될 경우 문의 부탁드립니다.</br></br>문의 전화번호: ${ENQUIRY_PHONE}</br>에러코드: 0001`,
          );
        });
      } else {
        customSuccessModal(
          () => {
            environmentS === 'app' ? (navigate('/'), window.location.reload()) : undefined;
          },
          '결제 성공',
          `결제가 성공적으로 처리되었습니다.</br>결제한 금액: ${parseInt(
            chargeProcessKiccData?.amount,
            10,
          )?.toLocaleString('ko-KR')}원`,
          true,
        );

        queryClient.refetchQueries(['/userPoint']);
        queryClient.refetchQueries(['/message/getCallbackList']);
      }
    }
  }, [chargeProcessKiccData, isChargeProcessKiccLoading]);

  // ===================================================================================================================
  // 무통장입금 알람받을 전화번호 하이픈 추가 포멧 useEffect
  // ===================================================================================================================
  useEffect(() => {
    if (typeof getValues('phoneNumber') === 'string') {
      setBankTransferPhoneNumberS(TelHyphen(getValues('phoneNumber') as string));
    }
  }, [isValidating]);

  useEffect(() => {
    if (easyData && EASYPAY_APPROVE_URL) {
      const { mallId, shopTransactionId, authorizationId, shopOrderNo, approvalReqDate, type, productType } = easyData;
      const obj = {
        mallId,
        shopTransactionId,
        authorizationId,
        shopOrderNo,
        approvalReqDate,
        type,
      };

      handlePaymentData(obj, productType);
    }
  }, [easyData]);

  const handlePaymentData = async (obj: any, productType: string) => {
    if (!EASYPAY_APPROVE_URL) return;
    try {
      const response = await axios.post(EASYPAY_APPROVE_URL, obj);
      if (response?.data?.resCd === '0000') {
        const data = {
          ...response.data,
          productType,
        };
        chargeProcessKiccMutation(data);
      } else if (response?.data?.resCd !== '0000' && response?.data?.resCd !== 'R108') {
        warningModal(
          '결제 실패',
          `결제를 실패했습니다.</br>다시 시도 부탁드립니다.</br>계속 실패가 될 경우 문의 부탁드립니다.</br></br>문의 전화번호: ${ENQUIRY_PHONE}</br>에러코드: ${response?.data?.resCd}`,
          true,
        );
      }
    } catch (error) {
      warningModal(
        '결제 실패',
        `결제를 실패했습니다.</br>다시 시도 부탁드립니다.</br>계속 실패가 될 경우 문의 부탁드립니다.</br></br>문의 전화번호: ${ENQUIRY_PHONE}</br>에러코드: 0002`,
        true,
      );
    }
  };

  return {
    isChargeProcessKiccLoading,
    onChargeApi,
    isChargeKiccLoading,
    register,
    handleSubmit,
    formState,
    getValues,
    onClickDepositRequest,
    bankTransferOpenS,
    setBankTransferOpenS,
    errors,
    bankTransferPhoneNumberS,
  };
};
