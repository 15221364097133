import React, { useState, useRef, useEffect } from 'react';
import { TbBell, TbDeviceMobilePlus, TbMessageCirclePlus, TbSettings } from 'react-icons/tb';
import * as S from '../MobileHeader.style';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import IconAddChatting from '@/styles/icons/IconAddChatting';
import MobilePopup from '../mobilePopup/MobilePopup';

const MessageListHeader = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [, setMhQueryParams] = useRecoilState(mhQueryParamsRecoil);
  const [mobileBuddyListAddS, setMobileBuddyListAddS] = useRecoilState(mobileBuddyListAddRecoil);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
      inputRef.current?.focus();
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  const onClickCreateChatroom = () => {
    if (inputValue) {
      navigate(`/mobile/message/messageChatRoom/${inputValue}`);
      setMobileBuddyListAddS({
        buddySeqNo: '',
        buddyNm: '',
        keyCommNo: inputValue,
      });
      setMhQueryParams((prevState: any) => {
        return {
          ...prevState,
          contactNumber: inputValue,
        };
      });
    }
  };

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = value.replace(/-/g, ''); // 하이픈을 제거한 값
    if (/^\d*$/.test(numericValue)) {
      setInputValue(numericValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // 기본 동작 방지 (예: 폼 제출)
      onClickCreateChatroom(); // 엔터 키가 눌리면 채팅방 생성 함수 호출
    }
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible((prev) => !prev);
  };

  return (
    <>
      <S.HeaderTitle>채팅</S.HeaderTitle>
      <S.IconContainer>
        <S.HeaderButtonIcon onClick={togglePopup}>
          <IconAddChatting />
        </S.HeaderButtonIcon>

        {/* <S.HeaderButtonIcon>
          <TbDeviceMobilePlus style={{ strokeWidth: 1.5 }} onClick={() => setOpenModal(true)} />
        </S.HeaderButtonIcon> */}

        {/* <S.HeaderButtonIcon>
          <TbSettings style={{ strokeWidth: 1.5 }} />
        </S.HeaderButtonIcon> */}
      </S.IconContainer>

      {/* 모바일 채팅 */}
      {isPopupVisible && <MobilePopup onClose={togglePopup} />}
    </>
  );
};

export default MessageListHeader;
