import styled from 'styled-components';

export const CustomerServiceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  .serviceGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 14px;
      height: 14px;
      background-color: #d6d6dc;
      border: 4px solid transparent;
      background-clip: padding-box;
    }
    ::-webkit-scrollbar-thumb {
      background: #98999a;
      border-radius: 40px;
      border: 4px solid transparent;
      background-clip: padding-box;
    }
  }
  .backButton {
    display: block;
    margin: 20px auto 0;
    background: ${(props) => props.theme.colors.main};
    color: #fff;
    font-size: 1.3rem;
    padding: 1rem 1.5rem;
    border-radius: 50rem;
    font-weight: 800;
    border: 0;
    min-width: 16rem;
    min-height: 4rem;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const CustomerService = styled.div`
  margin-bottom: 12%;
  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3.7rem;
  }
  .helpGroup {
    display: flex;
    flex-wrap: wrap;
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 2rem;
      height: 10vh;
      min-height: 5rem;
      max-height: 70px;
      background: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.gray500};
      border-radius: 1rem;
      font-size: 1.5rem;
      font-weight: 700;
      gap: 1rem;
      margin-bottom: 1rem;
      box-sizing: border-box;
      cursor: pointer;
      svg {
        display: none;
      }
      :hover {
        z-index: 2;
        color: ${(props) => props.theme.colors.main};
        background: ${(props) => props.theme.colors.white};
        border-color: ${(props) => props.theme.colors.main};
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
        svg {
          display: block;
        }
      }
    }
    button.active {
      color: ${(props) => props.theme.colors.main};
      background: ${(props) => props.theme.colors.gray100};
      border-color: ${(props) => props.theme.colors.main};
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
      svg {
        display: block;
      }
    }
  }
`;

export const VocHelp = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.colors.gray200};
  width: 100%;
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.colors.gray500};
  :hover {
    border-color: ${(props) => props.theme.colors.main};
    .text {
      border-color: ${(props) => props.theme.colors.main};
    }
  }
  .text {
    border-radius: 1rem 1rem 0 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray500};
    font-size: 1.5rem;
    font-weight: 700;
    padding: 2rem;
  }
  .chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 2rem);
    margin: 0 auto;
    border-radius: 0.8rem;
    padding: 1.2rem;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    background: ${(props) => props.theme.colors.white};
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.txt200};
    margin-bottom: 1rem;

    svg {
      color: ${(props) => props.theme.colors.main};
    }
  }
`;

export const ArreoService = styled.div`
  padding: 6rem 2rem 2rem;
  h2 {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.main};
    text-align: left;
  }
  .addressBook {
    max-width: 23rem;
    background: ${(props) => props.theme.colors.white};
    border-radius: 1rem;
    padding: 1.6rem;

    /* 공통 사항 */
    .smallGroup {
      margin-bottom: 2rem;
      h4 {
        color: rgba(25, 25, 25, 0.5);
        font-size: 1.4rem;
        font-weight: 700;
        margin-bottom: 0.8rem;
      }
      h3 {
        font-size: 1.6rem;
        font-weight: 600;
        color: ${(props) => props.theme.colors.txt500};
      }
      p {
        color: ${(props) => props.theme.colors.txt400};
        line-height: 2rem;
        font-size: 1.3rem;
      }
      :last-child {
        margin-bottom: 0;
      }
    }
    .workGroup {
      margin-bottom: 4rem;
    }
  }
`;
