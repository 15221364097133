import UsageHistoryTable from '@/components/Organism/Charge/UsageHistoryTable';
import React from 'react';

const MobileEtcUsage = () => {
  return (
    <>
      <UsageHistoryTable />
    </>
  );
};
export default MobileEtcUsage;
