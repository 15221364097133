import styled from 'styled-components';

// selectBox 셀렉트박스 공통 포멧
// button > svg, p | ul > li > button > svg, p
export const SelectPop = styled.div`
  position: relative;
  height: 100%;

  &.active {
    ul {
      display: block;
    }
  }
  &.w100 {
    > button {
      width: 100%;
    }
  }
  button {
    position: relative;
    cursor: pointer;
  }
  > button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 10.6rem; */
    height: 100%;
    padding: 0.8rem 1.6rem;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.gray600};
    color: ${(props) => props.theme.colors.txt100};
    svg {
      margin-right: 3rem;
    }
  }
  ul {
    display: none;
    z-index: 6;
    position: absolute;
    top: calc(100% + 1rem);
    left: 50%;
    transform: translateX(-50%);
    width: 24rem;
    background: ${(props) => props.theme.colors.gray100};
    border-radius: 1rem;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 0px 0.4rem 1.5rem 0px rgba(0, 0, 0, 0.25);

    li {
      :first-of-type {
        margin-top: 0.6rem;
      }
      :last-of-type {
        margin-bottom: 0.6rem;
      }
      button {
        margin: 0 auto;
        padding: 1rem 1.2rem;
        width: calc(100% - 1.2rem);
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        :hover {
          border-radius: 0.5rem;
          background: ${(props) => props.theme.colors.gray300};
        }
        > svg {
          width: 2rem;
          &.fill {
            path {
              fill: ${(props) => props.theme.colors.txt400};
            }
          }
          &.stroke {
            path {
              stroke: ${(props) => props.theme.colors.txt400};
            }
          }
          &.mix {
            path {
              :nth-of-type(n):nth-of-type(even),
              :nth-of-type(1) {
                stroke: ${(props) => props.theme.colors.txt400};
              }
              :nth-of-type(3) {
                fill: ${(props) => props.theme.colors.txt400};
              }
            }
          }
        }
      }
      p {
        width: calc(100% - 3rem);
        color: ${(props) => props.theme.colors.txt500};
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          path {
            fill: ${(props) => props.theme.colors.txt200};
          }
        }
      }
    }
  }
  .addSelect {
    background: ${(props) => props.theme.colors.grayButton};
  }
  .groupList {
    > div {
      max-height: 226px;
      margin: 10px 6px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        background: transparent;
      }
      li {
        :first-of-type {
          margin-top: 0;
        }
        :last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @media screen and (max-width: 340px) {
    > button {
      padding: 0.8rem 1rem;
      font-size: 1.2rem;
    }
  }
`;

// div > ( button, ul > li > button)
export const SelectBox = styled.div`
  z-index: 2;
  position: relative;

  button {
    cursor: pointer;
    gap: 1rem;
    color: ${(props) => props.theme.colors.txt500};
  }
  /* svg {
    fill: ${(props) => props.theme.colors.txt500};
    path {
      fill: ${(props) => props.theme.colors.txt500};
    }
  } */
  &.active {
    border-radius: 1rem 1rem 0 0;

    ul {
      display: block;
    }

    > button {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &.borBtn {
    > button {
      border: 1px solid ${(props) => props.theme.colors.gray500};
      min-width: 12rem;
    }
  }

  > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 1.4rem;
    border-radius: 0.5rem;
    white-space: nowrap;
    svg {
      max-width: 1.7rem;
      max-height: 0.6rem;
    }

    @media screen and (max-width: 768px) {
      font-weight: 700;
      font-size: 2rem !important;
    }
  }

  ul {
    display: none;
    z-index: 6;
    position: absolute;
    top: calc(100% + 0.1rem);
    left: 50%;
    min-width: 100%;
    white-space: nowrap;
    transform: translateX(-50%);
    background: ${(props) => props.theme.colors.gray100};
    border-radius: 10px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    overflow-y: auto;

    // overflow 스크롤
    ::-webkit-scrollbar {
      width: 1.4rem;
      background: ${(props) => props.theme.colors.gray100};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 40px;
      border: 0.5rem solid transparent;
      background-clip: padding-box;
    }

    li {
      position: relative;
      margin: 0 0.6rem;

      :first-of-type {
        margin-top: 0.6rem;
      }
      :last-of-type {
        margin-bottom: 0.6rem;
      }
      button {
        margin: 0 auto;
        width: 100%;
        min-height: 4rem;
        padding: 1rem 1.2rem;
        font-size: 1.4rem;
        font-weight: 500;
        :hover {
          border-radius: 5px;
          background: ${(props) => props.theme.colors.hover};
        }
        > svg {
          width: 2rem;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    &.borBtn {
      > button {
        min-width: auto;
      }
    }
  }
`;

// 메인 테이블 셀렉트박스
export const SelectBoxMax = styled.div`
  position: relative;
  height: 100%;

  &.arrow {
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
    }
  }

  &.active {
    width: 100%;
    min-height: 100%;
    ul {
      display: block;
      border-top: 0;
    }

    > button {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  button {
    cursor: pointer;
    color: ${(props) => props.theme.colors.txt500};
    gap: 1rem;
    white-space: normal;
    &.txtMax:has(> p) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  > button {
    width: 100%;
    min-height: 100%;
    padding: 0.2rem 3rem;
    font-size: 1.4rem;

    span {
      margin-left: 10px;
    }
  }

  ul {
    z-index: 1;
    display: none;
    position: absolute;
    top: 100%;
    left: -1px;
    width: calc(100% + 1px);
    background: ${(props) => props.theme.colors.gray100};
    border-radius: 1rem;

    > div {
      margin: 1rem 0.6rem;
      overflow: auto;
      max-height: 20rem;
    }
    // overflow 스크롤
    ::-webkit-scrollbar {
      width: 1.4rem;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 40px;
      border: 0.5rem solid transparent;
      background-clip: padding-box;
    }

    li {
      position: relative;

      :last-child {
        margin-bottom: 0;
      }

      button {
        width: 100%;
        padding: 1rem 1.2rem;
        border-radius: 5px;
        color: ${(props) => props.theme.colors.txt500};
        background: transparent;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: left;
        white-space: normal;
        text-overflow: ellipsis;
        box-sizing: border-box;
        :hover {
          background: ${(props) => props.theme.colors.hover};
        }
      }
    }
  }
`;

// 검색이 있는 셀렉트박스
// div > ( button, div > div > input , ul > li > button)
export const SelectInput = styled.div`
  button {
    cursor: pointer;
  }

  &.active {
    > div {
      display: block;
    }
    svg.arrow {
      transform: rotate(180deg);
    }
  }
  &.right {
    position: unset;
    > div {
      left: auto;
      transform: unset;
      right: 1rem;
    }
  }
  &.arrow {
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
    }
  }
  > button {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt500};
    white-space: normal;
    padding: 0.2rem 3rem;
  }
  > div {
    display: none;
    z-index: 6;
    position: absolute;
    top: calc(100% + 0.1rem);
    left: 50%;
    transform: translateX(-50%);
    min-width: 12rem;
    white-space: nowrap;
    background: ${(props) => props.theme.colors.gray100};
    border-radius: 0.8rem;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);

    ul {
      overflow-y: auto;
      max-height: 19rem;
      min-width: 19rem;

      // overflow 스크롤
      ::-webkit-scrollbar {
        width: 1.3rem;
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 40px;
        border: 0.5rem solid transparent;
        background-clip: padding-box;
      }
    }
    li {
      position: relative;
      margin: 0 0.6rem;

      :first-of-type {
        margin-top: 0.6rem;
      }
      :last-of-type {
        margin-bottom: 0.6rem;
      }
      button {
        margin: 0 auto;
        width: 100%;
        min-height: 4rem;
        padding: 1rem 1.2rem;
        font-size: 1.4rem;
        font-weight: 500;
        white-space: normal;
        :hover {
          border-radius: 5px;
          background: ${(props) => props.theme.colors.hover};
        }
        p {
          text-align: left;
          color: ${(props) => props.theme.colors.txt500};
        }
        > svg {
          width: 2rem;
        }
      }
    }
  }
  .search {
    z-index: 2;
    background: ${(props) => props.theme.colors.gray100};
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.2rem 1rem;
    border-radius: 0.8rem 0.8rem 0 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
    color: ${(props) => props.theme.colors.gray600};
    input[type='text'] {
      background: ${(props) => props.theme.colors.gray100};
      padding: 0.8rem 0;

      ::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }
      text-align: left;
      :focus {
        outline: none;
      }
    }
  }
`;
