import BaseInput from '@/components/Atom/BaseInput';
import SkeletonBox from '@/components/common/SkeletonBox';
import { useDeleteGroup } from '@/components/hooks/customs/addressBook/useDeleteGroup';
import { useEditGroup } from '@/components/hooks/customs/addressBook/useEditGroup';
import { useSortGroup } from '@/components/hooks/customs/addressBook/useSortGroup';
import { IGroupListMid } from '@/pages/main/components/addressLeftZone/groupList/mid/GroupListMid.types';
import { groupSearchTextRecoil } from '@/recoil/atoms/addressBookGroup';
import { amountFormat } from '@/shared/util/format/amountFormatUtil';
import * as CBS from '@/styles/checkBox.styles';
import MoveDots from '@/styles/icons/moveDots';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import * as S from '../GroupList.styles';
import { groupSeachTextToggleRecoil } from '@/recoil/atoms/addressList';
import {
  editModeRecoil,
  isLongPressRecoil,
  mobileBuddyListRecoil,
  windowWidthRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';

import { FaRegTrashCan } from 'react-icons/fa6';
import { FaEdit } from 'react-icons/fa';
import { useLongPress } from 'use-long-press';

/**
 * @title GroupList.tsx 컴포넌트의 mid 컴포넌트
 *
 * @author 정휘학
 * @since 2024.03.15
 * */

const GroupListMidIndex = (props: IGroupListMid) => {
  const editGroupHooks = useEditGroup();
  const deleteGroupHook = useDeleteGroup();
  const sortHooks = useSortGroup();
  const windowWidth = useRecoilValue(windowWidthRecoil);
  // 그룹 검색어
  const groupSearchTextS = useRecoilValue(groupSearchTextRecoil);
  // 검색 후 검색 텍스트를 변경해서 제출하거나, 초기화 했을 때 체크박스 최신화를 위한 트리거
  const groupSeachTextToggleS = useRecoilValue(groupSeachTextToggleRecoil);
  const setMobileBuddyListState = useSetRecoilState(mobileBuddyListRecoil);
  const groupListRefs = useRef<Map<number, HTMLDivElement>>(new Map());
  const editMode = useRecoilValue(editModeRecoil);
  const [isLongPress, setIsLongPress] = useRecoilState(isLongPressRecoil);
  const [clickedGroupSeqNo, setClickedGroupSeqNo] = useState<number | null>(null);

  const handleMoveList = (e: React.MouseEvent, el: any) => {
    // 다른 그룹을 클릭했을 때 수정/삭제 버튼을 닫기 위해 setClickedGroupSeqNo(null) 추가
    if (clickedGroupSeqNo === null && !editMode) {
      props.onClickGroupNm(el);
      setMobileBuddyListState(true);
    } else if (clickedGroupSeqNo !== el.groupSeqNo) {
      setClickedGroupSeqNo(null); // 클릭된 그룹이 현재 선택된 그룹이 아니라면 닫기
      editGroupHooks.setEditOpen({});
      setIsLongPress(false);
    }
  };

  // 수정 외부 클릭 시 수정 종료
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target instanceof HTMLElement)) return;
      // 수정 모드가 활성화된 상태일 때
      if (editGroupHooks.editOpen) {
        // 현재 수정 중인 인풋을 클릭하지 않은 경우 수정 모드 종료
        const isClickInside = Array.from(groupListRefs.current.values()).some(
          (ref) => ref && ref.contains(event.target as Node),
        );

        if (!isClickInside) {
          editGroupHooks.setEditOpen({});
          setIsLongPress(false);
          editGroupHooks.setGroupEditValue({});
        }
      }
      // 불필요한 return 제거
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

    // 조건을 만족하지 않으면 undefined 반환 필요 없음
  }, [editGroupHooks.editOpen]);

  const bind = useLongPress(
    (e, el: any) => {
      e.stopPropagation();
      setClickedGroupSeqNo(el.context.groupSeqNo); // 새 그룹의 번호 설정
      setIsLongPress(true);
      editGroupHooks.setEditOpen({});
      editGroupHooks.setGroupEditValue({});
    },
    { threshold: 500 },
  );

  return (
    <S.Middle className="openAddGroup">
      {windowWidth > 768 && (
        <S.Sort className={sortHooks.sortOrder}>
          <p>정렬</p>
          <button onClick={() => sortHooks.sortByName()}>
            <p className="custom">전체</p>
            <p className="desc">내림차순</p>
            <p className="asc">오름차순</p>
          </button>
        </S.Sort>
      )}
      <S.GroupListDrag ref={sortHooks.scrollRef}>
        {props.groupListIsLoading
          ? [...Array(20)].map((_) => (
              <SkeletonBox
                key={uuidv4()}
                type="1"
                title={false}
                active
                paragraph={{
                  className: 'groupListLoading',
                  rows: 2,
                  width: ['16px', '50%'],
                }}
              />
            ))
          : sortHooks.sortListS?.map((el: any, index: number) => {
              return el?.groupNm.toLowerCase().includes(groupSearchTextS) ? (
                <S.MiddleList className={`list`} key={el.groupSeqNo} {...sortHooks.getDraggableProps(index)}>
                  {/* 더블 클릭 했을 때 */}
                  {editGroupHooks.editOpen[el.groupSeqNo] ? (
                    <S.MiddleListAfterDiv
                      className={`middleListAfterDiv  ${props.clickGroupName?.groupSeqNo === el.groupSeqNo ? 'active' : ''}`}
                      ref={(elRef) => elRef && groupListRefs.current.set(el.groupSeqNo, elRef)}
                    >
                      <FaEdit />
                      <BaseInput
                        type="text"
                        key={el.groupSeqNo}
                        placeholder={el.groupNm}
                        onKeyDown={(e) => editGroupHooks.groupEditOnkeyDownHandle(e, el)}
                        onChange={(e) => editGroupHooks.groupEditValueHandle(e.target.value, el.groupSeqNo)}
                        value={editGroupHooks.groupEditValue[el.groupSeqNo] || ''}
                        autoFocus={editGroupHooks.editOpen[el.groupSeqNo]}
                      />
                      <button
                        className="checkButton storage"
                        onClick={() => {
                          setIsLongPress(false);
                          editGroupHooks.groupEditSendHandle(el);
                        }}
                      >
                        저장
                      </button>
                    </S.MiddleListAfterDiv>
                  ) : (
                    /* 기본 */
                    <>
                      {windowWidth > 768 ? (
                        <S.MiddleListBasicDiv
                          className={`middleListBasicDiv  ${props.clickGroupName?.groupSeqNo === el.groupSeqNo ? 'active' : ''}`}
                        >
                          <CBS.CheckBoxInput
                            type="checkBox"
                            onChange={(e) => {
                              e.stopPropagation();
                              props.updateDeleteGroupList(el.groupSeqNo);
                            }}
                            checked={props.checkedGroupListS.includes(el.groupSeqNo)} // 체크 여부를 deleteGroupList에 따라 설정
                          />
                          <MoveDots />
                          <S.GroupNameBox
                            className="groupNameBox"
                            title={el.groupNm}
                            style={props.clickGroupStyles[el.groupSeqNo] ? props.childrenClickStyle : undefined}
                            onClick={() => {
                              props.onClickGroupNm(el);
                              setMobileBuddyListState(true);
                            }}
                            onDoubleClick={() =>
                              windowWidth > 768 && editGroupHooks.groupDoubleClickHandle(el.groupSeqNo)
                            }
                          >
                            <span className={'groupNm'}>{el?.groupNm}</span>
                            <div className="btnWrap">
                              <span className="buddyCount">{amountFormat(el?.buddyCount)}</span>
                              {/* 그룹 삭제 버튼 */}
                              <button
                                className="delBtn"
                                disabled={deleteGroupHook.delGroupState}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteGroupHook.onClickOneGroupDelete(el.groupSeqNo);
                                }}
                              >
                                <FaRegTrashCan />
                              </button>
                            </div>
                          </S.GroupNameBox>
                        </S.MiddleListBasicDiv>
                      ) : (
                        <>
                          <S.MobileGroupList
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            ref={(elRef) => elRef && groupListRefs.current.set(el.groupSeqNo, elRef)}
                          >
                            <section>
                              <S.StyledCheckbox
                                onChange={(e) => {
                                  e.stopPropagation();
                                  props.updateDeleteGroupList(el.groupSeqNo);
                                }}
                                checked={props.checkedGroupListS.includes(el.groupSeqNo)}
                              />

                              <div
                                onClick={(e) => {
                                  handleMoveList(e, el); // long press 상태가 아닐 때만 실행
                                }}
                                {...bind(el)}
                                className={`${props.clickGroupName?.groupSeqNo === el.groupSeqNo ? 'active' : ''}`}
                              >
                                <span
                                  className="groupNm"
                                  title={el.groupNm}
                                  style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                                >
                                  {el?.groupNm}
                                </span>
                                {!isLongPress || clickedGroupSeqNo !== el.groupSeqNo ? (
                                  <span className="buddyCount">{amountFormat(el?.buddyCount)}명</span>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        height: '100%',
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                      }}
                                    >
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          editGroupHooks.groupDoubleClickHandle(el?.groupSeqNo);
                                        }}
                                        style={{
                                          height: '100%',
                                          backgroundColor: 'darkgrey',
                                          padding: '0 2rem',
                                          color: 'white',
                                          display: 'flex',
                                          gap: '2px',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <FaEdit />
                                        수정
                                      </button>
                                      <button
                                        style={{
                                          height: '100%',
                                          backgroundColor: 'red',
                                          padding: '0 2rem',
                                          color: 'white',
                                          display: 'flex',
                                          gap: '2px',
                                          alignItems: 'center',
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          deleteGroupHook.deleteGroup(el?.groupSeqNo);
                                        }}
                                      >
                                        <FaRegTrashCan />
                                        삭제
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </section>
                          </S.MobileGroupList>
                        </>
                      )}
                    </>
                  )}
                </S.MiddleList>
              ) : null;
            })}
      </S.GroupListDrag>
    </S.Middle>
  );
};

export default GroupListMidIndex;
