import { TOpenModal } from '@/components/hooks/customs/useHotfixModal';
import React from 'react';
import styled from 'styled-components';

const EventModalWrap = styled.div`
  z-index: 98;
  position: fixed;
  top: 2rem;
  left: 5%;
  width: 90%;
  max-width: 60rem;
  background: ${(props) => props.theme.colors.gray300};
  color: ${(props) => props.theme.colors.txt400};
  border: 1px solid ${(props) => props.theme.colors.gray400};

  .btnGroup {
    display: flex;
    button {
      position: relative;
      width: 50%;
      padding: 1.4rem 1rem;
      background: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.txt500};
      font-size: 1.6rem;
      :nth-of-type(1) {
        border-right: 1px solid ${(props) => props.theme.colors.gray400};
      }
    }
  }
  .imgGroup {
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 478px) {
    button {
      font-size: 1.2rem;
    }
  }
`;

interface IHotfixModal {
  onClickHotfixClose: (type: keyof TOpenModal, button?: '7days') => void;
}

const Event1Modal = (props: IHotfixModal) => {
  return (
    <EventModalWrap>
      <div className="imgGroup">
        <a
          href="https://play.google.com/store/apps/details?id=com.app015.talk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="img/eventBanner/event015app.png" alt="event015" />
        </a>
      </div>
      <div className="btnGroup">
        <button onClick={() => props.onClickHotfixClose('event1', '7days')}>일주일 간 열지 않기</button>
        <button onClick={() => props.onClickHotfixClose('event1')}>닫기</button>
      </div>
    </EventModalWrap>
  );
};
export default Event1Modal;
