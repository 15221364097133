import axios from 'axios';
import { removeCookie, setCookie } from '@/shared/util/cookie';

const getAxiosInstance = (type: string) => {
  /* 일반적인 API 요청 Instace config */
  const config = {
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true,
    headers: {
      'Content-Type': type,
    },
  };

  /* refreshToken API 요청 Instace config */
  const refreshConfig = {
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true,
    headers: {
      'Content-Type': type,
    },
  };

  const instance = axios.create(config);
  const refreshInstance = axios.create(refreshConfig);

  /* API 요청 인터셉터 */
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('authorizationToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  refreshInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      localStorage.removeItem('authorizationToken');
      setTimeout(() => {
        window.location.replace('/signin');
      }, 1500);
      return Promise.reject(error);
    },
  );

  /* API 응답 인터셉터 */
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config, response } = error;

      // CORS에러 등 401이 아닌 응답이 없는 에러가 나타났을 경우 로그인 페이지로 이동
      if (!response) {
        window.location.replace('/signin');
        return Promise.reject(error);
      }

      /* 토큰이 만료가 됐을 경우 */
      if (response.status === 401) {
        const originRequest = config;

        try {
          const refreshResponse = await refreshInstance.get('/signin/regenerate');
          removeCookie('Refresh_Token');
          const newAccessToken = refreshResponse.data.token;
          localStorage.setItem('authorizationToken', newAccessToken);
          setCookie('Refresh_Token', `${refreshResponse.data.refreshToken}`, { path: '/' });

          axios.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
          originRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          return axios(originRequest);
        } catch (refreshError) {
          console.error('Token refresh failed', refreshError);
          window.location.replace('/signin');
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    },
  );
  return instance;
};

export const instance = getAxiosInstance('application/json');
export const instanceAudio = getAxiosInstance('audio/x-wav');
export const instanceFile = getAxiosInstance('multipart/form-data');
// export const client = getAxiosInstance('application/json');
export const client = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

/* 기존 코드 */
// import axios from 'axios';
// import { removeCookie, setCookie } from '@/shared/util/cookie';

// const getAxiosInstance = (type: string) => {
//   /* 일반적인 API 요청 Instace config */
//   const config = {
//     baseURL: process.env.REACT_APP_SERVER_URL,
//     withCredentials: true,
//     headers: {
//       'Content-Type': type,
//     },
//   };
//   /* refreshToken API 요청 Instace config */
//   const refreshConfig = {
//     baseURL: process.env.REACT_APP_SERVER_URL,
//     withCredentials: true,
//     headers: {
//       'Content-Type': type,
//     },
//   };

//   const instance = axios.create(config);
//   const refreshInstance = axios.create(refreshConfig);

//   /* API 요청 인터셉터 */
//   instance.interceptors.request.use(
//     (config) => {
//       const token = localStorage.getItem('authorizationToken');
//       config.headers.Authorization = `Bearer ${token}`;
//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     },
//   );

//   refreshInstance.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//       localStorage.removeItem('authorizationToken');
//       setTimeout(() => {
//         window.location.replace('/signin');
//       }, 1500);
//     },
//   );

//   /* API 응답 인터셉터 */
//   instance.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//       const {
//         config,
//         response: { status },
//       } = error;
//       try {
//         /* 토큰이 만료가 됐을 경우 */

//         if (status === 401) {
//           const originRequest = config;
//           await refreshInstance.get('/signin/regenerate').then((response) => {
//             removeCookie('Refresh_Token');
//             const newAccessToken = response.data.token;
//             localStorage.setItem('authorizationToken', newAccessToken);
//             setCookie('Refresh_Token', `${response.data.refreshToken}`, { path: '/' });

//             axios.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
//             originRequest.headers.Authorization = `Bearer ${newAccessToken}`;
//             // return axios(originRequest);
//           });
//         }
//         return Promise.reject(error);
//       } catch (error) {
//         await Promise.reject(error);
//       }
//       return Promise.reject(error);
//     },
//   );
//   return instance;
// };

// export const instance = getAxiosInstance('application/json');
// export const instanceAudio = getAxiosInstance('audio/x-wav');
// export const instanceFile = getAxiosInstance('multipart/form-data');
// // export const client = getAxiosInstance('application/json');
// export const client = axios.create({
//   baseURL: process.env.REACT_APP_SERVER_URL,
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });
