import { detectEnvUtils } from '@/shared/util/detectEnvUtils';
import { atom } from 'recoil';

interface NavigationItem {
  buddySeqNo?: string;
  buddyNm?: string;
  keyCommNo?: string;
}

interface GroupItem {
  groupSeqNo?: number;
  usrKey?: string;
  buddyCount?: number;
  groupNm?: string;
}

//모바일 대화상대 추가 리코일
// mobileMessageAdd 에서는 하나씩만 추가할 수 있지만
// 그룹 채팅방 만들 때는 여러개 추가할 수 있게 리스트로 관리

export const mobileBuddyListAddRecoil = atom<NavigationItem>({
  key: 'mobileBuddyListAddRecoil',
  default: {},
});

// 모바일 주소록 그룹에서 선택된 그룹 값
export const selectedMobileGroupListRecoil = atom<GroupItem>({
  key: 'selectedMobileGroupListRecoil',
  default: {},
});

type mobileEnv = 'app' | 'pc' | 'mobile';

// 모바일 환경 구축 리코일
export const environmentRecoil = atom<mobileEnv>({
  key: 'environmentRecoil',
  default: localStorage.getItem('userAgent') ? 'app' : detectEnvUtils(),
});

//모바일 로딩 - true : 로딩 , false: 완료
export const syncLoadingRecoil = atom<boolean>({
  key: 'syncLoadingRecoil',
  default: false,
});

//모바일에서 신규 추가하는 인풋 밸류 - 번호
export const addNumberValueRecoil = atom<string>({
  key: 'addNumberValueRecoil',
  default: '',
});
//모바일에서 신규 추가하는 인풋 밸류 - 이름
export const addStringValueRecoil = atom<string>({
  key: 'addStringValueRecoil',
  default: '',
});

// testCode수정
// app 로그인창 이후 진입 막는 RECOIL - true : 접속불가, false : 접속가능
export const mobileAdRecoil = atom<boolean>({
  key: 'mobileAdRecoil',
  default: false,
});

export const sendImgFileRecoil = atom<any>({
  key: 'sendImgFileRecoil',
  default: null,
});
export const sendImgFileUrlRecoil = atom<string | null>({
  key: 'sendImgFileUrlRecoil',
  default: null,
});
