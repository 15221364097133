import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from '../MobileHeader.style';

const EtcCommonHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <S.HeaderLeft>
        <S.HeaderButtonIcon
          onClick={() => {
            navigate('/mobile/etc');
          }}
        >
          {/* <TbArrowBack /> */}
          <IconArrowLongLeft />
        </S.HeaderButtonIcon>
        <S.HeaderTitle>더보기</S.HeaderTitle>
      </S.HeaderLeft>
    </>
  );
};
export default EtcCommonHeader;
