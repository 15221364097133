import React from 'react';
import styled from 'styled-components';

import { SignUpProcesseEllipseProps, SignUpProcesseNameProps } from '../../../apis/utils/typeGuard/signUpGuard';

const SignUpProcessWrap = styled.div`
  width: 100%;
  /* height: 42px; */
  margin: 4rem auto 6rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const SignUpProcessTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% / 3);
`;

const SignUpProcesseEllipse = styled.span<SignUpProcesseEllipseProps>`
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: ${(props) => props.BackGroundColor};
`;

const SignUpProcesseName = styled.p<SignUpProcesseNameProps>`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.main};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${(props) => props.Color};
  @media screen and (max-width: 360px) {
    font-size: 1rem;
  }
`;
interface processProps {
  process: string;
}
const SignUpProcess = (props: processProps) => {
  return (
    <SignUpProcessWrap>
      {props?.process === '1' ? (
        <SignUpProcessTag>
          <SignUpProcesseEllipse BackGroundColor="#366EFF" />
          <SignUpProcesseName Color="#366EFF">약관동의 및 본인인증</SignUpProcesseName>
        </SignUpProcessTag>
      ) : (
        <SignUpProcessTag>
          <SignUpProcesseEllipse BackGroundColor="#C8C8C8" />
          <SignUpProcesseName Color="#C8C8C8">약관동의 및 본인인증</SignUpProcesseName>
        </SignUpProcessTag>
      )}
      {props?.process === '2' ? (
        <SignUpProcessTag>
          <SignUpProcesseEllipse BackGroundColor="#366EFF" />
          <SignUpProcesseName Color="#366EFF">회원 정보 입력</SignUpProcesseName>
        </SignUpProcessTag>
      ) : (
        <SignUpProcessTag>
          <SignUpProcesseEllipse BackGroundColor="#C8C8C8" />
          <SignUpProcesseName Color="#C8C8C8">회원 정보 입력</SignUpProcesseName>
        </SignUpProcessTag>
      )}
      {props?.process === '4' ? (
        <SignUpProcessTag>
          <SignUpProcesseEllipse BackGroundColor="#366EFF" />
          <SignUpProcesseName Color="#366EFF">가입 완료</SignUpProcesseName>
        </SignUpProcessTag>
      ) : (
        <SignUpProcessTag>
          <SignUpProcesseEllipse BackGroundColor="#C8C8C8" />
          <SignUpProcesseName Color="#C8C8C8">가입 완료</SignUpProcesseName>
        </SignUpProcessTag>
      )}
    </SignUpProcessWrap>
  );
};

export default SignUpProcess;
