import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  /* 왼쪽 시나리오 높이 조절 */
  height: calc(var(--vh, 10vh));

  h1 {
    margin-bottom: 2.4rem;
    padding: 0 1rem;
    text-align: center;
  }
  .gray {
    color: ${(props) => props.theme.colors.txt200};
  }

  .scrollWrap {
    overflow-y: auto;
    height: 100%;
    padding: 0 1rem 2rem;
  }
  @media screen and (max-width: 768px) {
    padding-top: 6rem;
    background-color: ${(props) => props.theme.colors.white};
    .scrollWrap {
      /* height: calc(100% - 11.4rem); */
      height: calc(100% - 6rem);
    }
    .logo {
      display: none;
    }
  }
`;

export const Subtitle = styled.h2`
  color: ${(props) => props.theme.colors.txt400};
  margin: 0 0 1.2rem;
  font-size: 1.7rem;
  font-weight: 600;
  text-indent: 1.5rem;
`;

export const ArsScenarioWrap = styled.div`
  margin: 0 0 4rem;
  textarea,
  input {
    resize: none;
    ::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
    }
  }
  .scenarioSetWrap {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  /* 선택 */
  .scenarioSelect {
    position: relative;
    border-radius: 1rem;
    background-color: ${(props) => props.theme.colors.gray700};
    &.active {
      border-radius: 1rem 1rem 0 0;
      ul {
        z-index: 2;
        display: block;
      }
    }

    .pick {
      cursor: pointer;
      padding: 1.5rem;
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      align-items: center;
      color: ${(props) => props.theme.colors.txt100};

      button {
        padding: 0;
        display: flex;
        width: calc(100% - 1.3rem);
        p {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      svg {
        width: 1.2rem;
        height: 0.6rem;
      }
    }
    button {
      max-width: 100%;
      font-size: 1.4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      padding: 1rem;
      color: ${(props) => props.theme.colors.txt100};
    }

    ul {
      position: absolute;
      top: 100%;
      border-top: 1px solid ${(props) => props.theme.colors.gray300};
      left: 0;
      display: none;
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray800};
      border-radius: 0 0 1rem 1rem;
      padding: 0 0 1rem;

      li {
        button {
          display: flex;
          justify-content: space-between;
          padding: 1.5rem;
          /* 삭제버튼 */
          .iconTrash {
            color: ${(props) => props.theme.colors.txt200};
            :hover {
              color: ${(props) => props.theme.colors.txt100};
            }
          }
          :hover {
            background: ${(props) => props.theme.colors.gray700};
          }
          &.selected {
            background-color: ${(props) => props.theme.colors.main};
            :hover {
              background: ${(props) => props.theme.colors.main};
            }
          }
        }
      }
      /* 시나리오 추가하기 */
      button {
        width: 100%;
      }
      .addScenario {
        margin-top: 1rem;
        border-radius: 0.6rem;
        padding: 0 1rem;
        button {
          width: 100%;
          border-radius: 0.6rem;
          background: ${(props) => props.theme.colors.main};
          text-align: center;
        }
      }
    }
  }

  /* 이름설정 */
  .scenarioName {
    input {
      width: 100%;
      padding: 1.5rem;
      border: 1px solid ${(props) => props.theme.colors.gray300};
      border-radius: 1rem;
      font-size: 1.4rem;
      background: ${(props) => props.theme.colors.gray100};
      color: ${(props) => props.theme.colors.txt400};
    }
  }

  /* 메모 */
  .scenarioMemo {
    textarea {
      width: 100%;
      height: 10rem;
      padding: 1.5rem;
      border: 1px solid ${(props) => props.theme.colors.gray300};
      border-radius: 1rem;
      font-size: 1.4rem;
      background: ${(props) => props.theme.colors.gray100};
      color: ${(props) => props.theme.colors.txt400};
    }
  }
`;
// ARS 시나리오 설정
export const ScenarioSetting = styled.div`
  font-size: 1.4rem;
  padding: 1.2rem 1rem;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors.gray200};
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 0 0 1.5rem;
    h3 {
      font-size: 1.4rem;
      font-weight: 700;
      color: ${(props) => props.theme.colors.txt500};
    }
  }
  p {
    font-size: 1.2rem;
    line-height: normal;
    color: ${(props) => props.theme.colors.txt400};
  }
`;
export const ArsWidgetWrap = styled.div`
  .widgetCardWrap {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
`;

export const WidgetCard = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1.2rem 1rem;
  background: ${(props) => props.theme.colors.gray200};
  border-radius: 1rem;
  svg {
    max-width: 2.6rem;
    max-height: 2.6rem;
  }
  .textBox {
    h3 {
      margin: 0 0 1.5rem;
      font-size: 1.7rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.txt500};
    }
    p {
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.txt200};
      line-height: normal;
    }
  }
`;
