import My015SubInfo from '@/pages/My015Charge/My015SubInfo';
import React from 'react';

const MobileEtcSubInfo = () => {
  return (
    <>
      <My015SubInfo />
    </>
  );
};
export default MobileEtcSubInfo;
