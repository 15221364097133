import PwChange from '@/components/Organism/MyPage/PwChange';
import React from 'react';

const MobileEtcPwChange = () => {
  return (
    <>
      <PwChange />
    </>
  );
};
export default MobileEtcPwChange;
