import React from 'react';
import styled from 'styled-components';

/**
 * @title 호출할 데이터가 존재하지 않을 때 보여줄 컴포넌트
 *
 * @author 김남규
 * @since 2024-04-28
 */

const NodataBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.2rem;
  color: ${(props) => props.theme.colors.txt200};
`;

const NoData = () => {
  return <NodataBox className="NodataBox">No Data</NodataBox>;
};
export default NoData;
