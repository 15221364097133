import React, { useEffect, useState } from 'react';
import * as SS from '@/widgets/ars/components/arsTree/components/arsCardModal/ui/ArsCardModal.styles';
import { ArsCardModalProps } from './ArsCardModal';
import ArsCardSpeak3 from './ArsCardSpeak3';
import ArsCardSpeak2 from './ArsCardSpeak2';
import { useArsGuidePlayAudio } from '@/components/hooks/customs/say015/say105Handle/useArsGuidePlayAudio';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useArsCardTitle } from '@/components/hooks/customs/say015/say105Handle/useArsCardTitle';

const ArsCardSpeakSet = (props: ArsCardModalProps) => {
  const { deleteArs, findDataById } = use015ArsJSON({
    arsData: props.arsData,
    data: props.data,
    updatedJsonFile: props.updatedJsonFile,
  });
  const audioPlayHooks = useArsGuidePlayAudio({ data: findDataById(props.arsData, props.data?.id) });

  type TSelect = '음성' | '텍스트';
  const initSelectType: TSelect = props.data.s015Data.ttsText === '' ? '음성' : '텍스트';
  const [selectType, setSelectType] = useState<TSelect>(props.type === 'add' ? '텍스트' : initSelectType);
  const onClickEditType = (data: TSelect) => {
    setSelectType(data);
  };

  /* title 변경 Hooks */
  const { cardTitle, onChangeCardTitle } = useArsCardTitle({ data: props.data });

  useEffect(() => {
    if (props.type === 'view') setSelectType(initSelectType);
  }, [props.type, initSelectType]);

  return (
    <div>
      {/* 드래그앤드롭 모양 UI 버튼 */}
      <div className="txtTop">
        <div className="group">
          {props.type === 'view' && (
            <button className="grab">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.33333 1.66667C3.33333 2.58712 2.58712 3.33333 1.66667 3.33333C0.746208 3.33333 0 2.58712 0 1.66667C0 0.746192 0.746208 0 1.66667 0C2.58712 0 3.33333 0.746192 3.33333 1.66667ZM1.66667 9.16667C2.58712 9.16667 3.33333 8.42046 3.33333 7.5C3.33333 6.57954 2.58712 5.83333 1.66667 5.83333C0.746208 5.83333 0 6.57954 0 7.5C0 8.42046 0.746208 9.16667 1.66667 9.16667ZM1.66667 15C2.58712 15 3.33333 14.2538 3.33333 13.3333C3.33333 12.4129 2.58712 11.6667 1.66667 11.6667C0.746208 11.6667 0 12.4129 0 13.3333C0 14.2538 0.746208 15 1.66667 15Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.66732 1.66667C9.66732 2.58712 8.92111 3.33333 8.00065 3.33333C7.08019 3.33333 6.33398 2.58712 6.33398 1.66667C6.33398 0.746192 7.08019 0 8.00065 0C8.92111 0 9.66732 0.746192 9.66732 1.66667ZM8.00065 9.16667C8.92111 9.16667 9.66732 8.42046 9.66732 7.5C9.66732 6.57954 8.92111 5.83333 8.00065 5.83333C7.08019 5.83333 6.33398 6.57954 6.33398 7.5C6.33398 8.42046 7.08019 9.16667 8.00065 9.16667ZM8.00065 15C8.92111 15 9.66732 14.2538 9.66732 13.3333C9.66732 12.4129 8.92111 11.6667 8.00065 11.6667C7.08019 11.6667 6.33398 12.4129 6.33398 13.3333C6.33398 14.2538 7.08019 15 8.00065 15Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          )}
          <p className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M19.1158 1.68185C19.2224 1.50422 19.3953 1.37622 19.5963 1.32602C19.7973 1.27582 20.01 1.30751 20.1877 1.41414L20.1908 1.41623L20.1939 1.41831L20.2043 1.42456C20.245 1.44923 20.2846 1.47564 20.3231 1.50373C20.396 1.55477 20.4949 1.62873 20.6116 1.72456C20.8429 1.91727 21.1449 2.20581 21.447 2.59748C22.0533 3.38602 22.6502 4.59123 22.6502 6.2506C22.6502 7.90998 22.0533 9.11518 21.447 9.90373C21.1449 10.2954 20.8429 10.5839 20.6116 10.7766C20.4821 10.8845 20.3465 10.9846 20.2054 11.0766L20.1949 11.0829L20.1908 11.085L20.1887 11.086C20.1887 11.086 20.1877 11.0871 19.7856 10.4173L20.1877 11.0871C20.0107 11.1875 19.8016 11.2149 19.6047 11.1635C19.4079 11.1121 19.2389 10.986 19.1336 10.8119C19.0283 10.6378 18.995 10.4296 19.0409 10.2314C19.0868 10.0332 19.2082 9.86068 19.3793 9.7506L19.3845 9.74643L19.4262 9.71831C19.4679 9.68914 19.5314 9.64227 19.6106 9.57664C19.7699 9.44331 19.9887 9.23706 20.2074 8.95164C20.6429 8.38602 21.0877 7.50789 21.0877 6.2506C21.0877 4.99331 20.6429 4.11518 20.2085 3.54956C20.0326 3.32003 19.8321 3.11045 19.6106 2.92456C19.5382 2.86417 19.4627 2.8075 19.3845 2.75477L19.3793 2.7506C19.2032 2.64329 19.0767 2.47073 19.0274 2.27052C18.978 2.07032 19.0098 1.85872 19.1158 1.68185Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4106 1.30273C9.09829 1.30273 7.83977 1.82403 6.91186 2.75195C5.98394 3.67986 5.46265 4.93838 5.46265 6.25065C5.46265 7.56292 5.98394 8.82144 6.91186 9.74936C7.83977 10.6773 9.09829 11.1986 10.4106 11.1986C11.7228 11.1986 12.9814 10.6773 13.9093 9.74936C14.8372 8.82144 15.3585 7.56292 15.3585 6.25065C15.3585 4.93838 14.8372 3.67986 13.9093 2.75195C12.9814 1.82403 11.7228 1.30273 10.4106 1.30273ZM7.02515 6.25065C7.02515 5.35278 7.38182 4.49169 8.01671 3.8568C8.6516 3.22191 9.5127 2.86523 10.4106 2.86523C11.3084 2.86523 12.1695 3.22191 12.8044 3.8568C13.4393 4.49169 13.796 5.35278 13.796 6.25065C13.796 7.14852 13.4393 8.00961 12.8044 8.6445C12.1695 9.27939 11.3084 9.63607 10.4106 9.63607C9.5127 9.63607 8.6516 9.27939 8.01671 8.6445C7.38182 8.00961 7.02515 7.14852 7.02515 6.25065ZM10.4106 12.7611C8.28556 12.7611 6.32515 13.2507 4.86681 14.084C3.4314 14.9048 2.33765 16.1569 2.33765 17.709C2.33765 19.2611 3.4314 20.5131 4.86681 21.334C6.32515 22.1673 8.28661 22.6569 10.4106 22.6569C12.5356 22.6569 14.496 22.1673 15.9543 21.334C17.3897 20.5131 18.4835 19.2611 18.4835 17.709C18.4835 16.1569 17.3897 14.9048 15.9543 14.084C14.496 13.2507 12.5345 12.7611 10.4106 12.7611ZM3.90015 17.709C3.90015 16.959 4.43869 16.1288 5.64181 15.4413C6.82306 14.7663 8.50744 14.3236 10.4106 14.3236C12.3137 14.3236 13.9981 14.7663 15.1793 15.4413C16.3824 16.1288 16.921 16.96 16.921 17.709C16.921 18.459 16.3824 19.2892 15.1793 19.9767C13.9981 20.6517 12.3137 21.0944 10.4106 21.0944C8.50744 21.0944 6.82306 20.6517 5.64181 19.9767C4.43869 19.2892 3.90015 18.459 3.90015 17.709Z"
                fill="currentColor"
              />
              <path
                d="M18.1043 3.49663C18.0163 3.44367 17.9187 3.40858 17.8171 3.39338C17.7156 3.37817 17.612 3.38314 17.5123 3.40801C17.4127 3.43287 17.3189 3.47714 17.2364 3.53829C17.1539 3.59943 17.0842 3.67625 17.0314 3.76434L17.2918 4.83101L17.2991 4.83622C17.414 4.92263 17.5167 5.02425 17.6043 5.1383C17.846 5.45761 17.9723 5.84941 17.9626 6.24976C17.9626 6.77788 17.7783 7.13517 17.6043 7.36226C17.5167 7.4763 17.414 7.57792 17.2991 7.66434L17.2908 7.66851C17.1176 7.77802 16.9943 7.95119 16.9476 8.15071C16.9009 8.35024 16.9344 8.56013 17.041 8.73516C17.1475 8.91018 17.3186 9.03633 17.5173 9.08641C17.716 9.13649 17.9265 9.10649 18.1033 9.00288H18.1053L18.1074 9.0008L18.1106 8.99976L18.1178 8.99455L18.1387 8.98205C18.218 8.93132 18.2935 8.87492 18.3647 8.8133C18.4991 8.70184 18.671 8.53726 18.8428 8.31434C19.2947 7.72176 19.5349 6.99487 19.5251 6.24976C19.5349 5.50464 19.2947 4.77776 18.8428 4.18517C18.6581 3.94549 18.4399 3.7336 18.1949 3.55601C18.1764 3.54284 18.1576 3.52999 18.1387 3.51747L18.1178 3.50497L18.1106 3.49976L18.1074 3.49872L18.1053 3.49767C18.1053 3.49767 18.1043 3.49663 17.7022 4.16642L18.1043 3.49663Z"
                fill="currentColor"
              />
            </svg>
          </p>
          {props.type === 'update' || props.type === 'add' ? (
            <div>
              <input
                placeholder="16자 이내로 입력해주세요"
                value={cardTitle}
                onChange={onChangeCardTitle}
                maxLength={16}
              />
            </div>
          ) : (
            <h3>{cardTitle ? cardTitle : '안내멘트'}</h3>
          )}

          {/* 재생버튼 */}
          {props.type === 'view' && (
            <button
              className={`${audioPlayHooks.activePlayRecordIdS === props.data?.id ? 'voicePlayBtn active' : 'voicePlayBtn'}`}
              onClick={() => audioPlayHooks.handleAudioPlay(props.data?.id)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                <circle cx="9.21643" cy="9.21643" r="9.21643" fill="currentColor" />
                <path
                  d="M8.41834 5.66236C7.84234 5.32353 7.11621 5.73883 7.11621 6.4071V12.0261C7.11621 12.6944 7.84234 13.1097 8.41834 12.7708L13.1945 9.96134C13.7624 9.62726 13.7624 8.80594 13.1945 8.47185L8.41834 5.66236Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          )}
        </div>
        {props.type !== 'add' && (
          <button className="del" onClick={deleteArs}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <path
                d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                stroke="currentColor"
                strokeWidth="1.62805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.375 5.625L5.625 9.375"
                stroke="currentColor"
                strokeWidth="1.62805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.625 5.625L9.375 9.375"
                stroke="currentColor"
                strokeWidth="1.62805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>

      {/* 타입 선택 */}
      {(props.type === 'update' || props.type === 'add') && (
        <SS.UpdateBtnGroup className="updateBtnGroup">
          <button
            className={selectType === '텍스트' ? 'active' : ''}
            type="button"
            onClick={() => onClickEditType('텍스트')}
          >
            텍스트
          </button>
          <button
            className={selectType === '음성' ? 'active' : ''}
            type="button"
            onClick={() => onClickEditType('음성')}
          >
            음성
          </button>
        </SS.UpdateBtnGroup>
      )}
      {/* 간편보기시 추가할 삭제버튼 */}
      {/* {props.type === 'update' && (
        <button className="del" onClick={deleteArs}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path
              d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
              stroke="currentColor"
              strokeWidth="1.62805"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.375 5.625L5.625 9.375"
              stroke="currentColor"
              strokeWidth="1.62805"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.625 5.625L9.375 9.375"
              stroke="currentColor"
              strokeWidth="1.62805"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )} */}
      {props.type === 'add' || props.type === 'update' ? (
        <>
          {selectType === '음성' && (
            <ArsCardSpeak3 {...props} handleCancel={props.handleCancel} cardTitle={cardTitle} />
          )}
          {selectType === '텍스트' && (
            <ArsCardSpeak2 {...props} handleCancel={props.handleCancel} cardTitle={cardTitle} />
          )}
        </>
      ) : (
        <>
          {props.data.s015Data.ttsText === '' && (
            <ArsCardSpeak3 {...props} handleCancel={props.handleCancel} cardTitle={cardTitle} />
          )}
          {props.data.s015Data.ttsText !== '' && (
            <ArsCardSpeak2 {...props} handleCancel={props.handleCancel} cardTitle={cardTitle} />
          )}
        </>
      )}

      {/* 음성 */}
      {/* 텍스트 */}
    </div>
  );
};
export default ArsCardSpeakSet;
