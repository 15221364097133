/* eslint-disable */
import NextLoader from '@/components/common/NextLoader';
import NoData from '@/components/common/NoData';
import SkeletonBox from '@/components/common/SkeletonBox';
import { useEachChatRoomHook } from '@/components/hooks/customs/useEachChatRoomHook';
import { IEachChatRoom } from '@/components/softPhone/DisplayGroups/MessageManagement/ChatRoom/EachChatRoom/EachChatRoom.types';
import { useLoaderTimer } from '@/shared/hooks/useLoaderTimer';
import { removeWebCharacter } from '@/shared/util/format/contentFormatUtil';
import { formatDateAll } from '@/shared/util/format/dateFormatUtil';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import React from 'react';
import * as MLS from '../../../styles/CommonListStyles/CommonList.styles';
import IconCassetteTape from '@/styles/icons/IconCassetteTape';

/**
 * @title activeRoomKind 값이 each 일 경우 표출되는 컴포넌트
 *
 * @author 정휘학
 * @since 2024.05.08
 * */
const EachChatRoom = (props: IEachChatRoom) => {
  // 015 개별 채팅방 Hooks
  const eachHooks = useEachChatRoomHook();
  const timerHooks = useLoaderTimer({ isLoading: eachHooks?.inFiniteResult.isLoading });

  return (
    <>
      {timerHooks?.timer || eachHooks?.inFiniteResult.isLoading ? (
        // 로딩시 스켈레톤 UI
        <MLS.MessageList id={'eachChatRoom'}>
          {[...Array(5)].map((_, index) => (
            <SkeletonBox
              key={index}
              type="1"
              title={{ width: '30%' }}
              paragraph={{ rows: 2, width: ['20%', '80%'] }}
              active
            />
          ))}
        </MLS.MessageList>
      ) : eachHooks.amlDataS && eachHooks.amlDataS.length > 0 ? (
        <MLS.ChatList
          onScroll={(event) => eachHooks.handleInfiniteScroll(event, eachHooks.inFiniteResult)}
          id={'eachChatRoom'}
        >
          {eachHooks.amlDataS.map((list, index) => {
            return (
              <div
                key={`${list.phoneNumber}-${index}`}
                className={list.phoneNumber === eachHooks.activeChatRoomId ? 'active' : ''}
                onClick={() =>
                  eachHooks.clickEachListH({
                    phoneNumber: list.phoneNumber,
                    buddyName: list.buddyName,
                  })
                }
                onContextMenu={(e) => props.onContextMenuItem(e, list)}
              >
                {/* 상단 */}
                <div className="titleGroup">
                  <div className="title">
                    {list.buddyName ? (
                      <h3 className="peopleName">{list.buddyName}</h3>
                    ) : (
                      <h3 className="peopleName">{TelHyphen(list.phoneNumber)}</h3>
                    )}
                    {/* 마지막 메세지 날짜 표출 ( 오늘, 어제, 올해, 이전 년도에 따른 표출 방식 다름 ) */}
                    <p className="dateGroup">{formatDateAll(list.msgDttm)}</p>
                  </div>
                  {list.buddyName && (
                    <div className="peopleNumber">
                      <p>{TelHyphen(list.phoneNumber)}</p>
                    </div>
                  )}
                </div>
                {/* 대화 내용 */}
                <div className="textContent">
                  {list.msgType === 'MMS' && (
                    <div className="iconGroup">
                      <p className="imageIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <g clipPath="url(#clip0_19_40)">
                            <path
                              d="M6.49335 6.41516C6.49335 6.81299 6.33531 7.19452 6.05401 7.47582C5.7727 7.75713 5.39117 7.91516 4.99335 7.91516C4.59552 7.91516 4.21399 7.75713 3.93269 7.47582C3.65138 7.19452 3.49335 6.81299 3.49335 6.41516C3.49335 6.01734 3.65138 5.63581 3.93269 5.3545C4.21399 5.0732 4.59552 4.91516 4.99335 4.91516C5.39117 4.91516 5.7727 5.0732 6.05401 5.3545C6.33531 5.63581 6.49335 6.01734 6.49335 6.41516Z"
                              fill="currentColor"
                            />
                            <path
                              d="M2.49335 1.91516C1.96291 1.91516 1.45421 2.12587 1.07913 2.50095C0.704061 2.87602 0.493347 3.38473 0.493347 3.91516V13.9152C0.493347 14.4456 0.704061 14.9543 1.07913 15.3294C1.45421 15.7044 1.96291 15.9152 2.49335 15.9152H14.4933C15.0238 15.9152 15.5325 15.7044 15.9076 15.3294C16.2826 14.9543 16.4933 14.4456 16.4933 13.9152V3.91516C16.4933 3.38473 16.2826 2.87602 15.9076 2.50095C15.5325 2.12587 15.0238 1.91516 14.4933 1.91516H2.49335ZM14.4933 2.91516C14.7586 2.91516 15.0129 3.02052 15.2005 3.20805C15.388 3.39559 15.4933 3.64994 15.4933 3.91516V10.4152L11.7163 8.46816C11.6226 8.42118 11.5164 8.40489 11.4128 8.42158C11.3093 8.43827 11.2136 8.4871 11.1393 8.56116L7.42935 12.2712L4.76935 10.4992C4.67331 10.4352 4.55811 10.4065 4.44329 10.4178C4.32846 10.4291 4.22108 10.4797 4.13935 10.5612L1.49335 12.9152V3.91516C1.49335 3.64994 1.5987 3.39559 1.78624 3.20805C1.97378 3.02052 2.22813 2.91516 2.49335 2.91516H14.4933Z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>
                      </p>
                    </div>
                  )}
                  {list.msgType === 'VMS' && (
                    <div className="iconGroup">
                      <p className="voiceIcon">
                        <IconCassetteTape fontSize={'1rem'} />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
                          <path
                            fill="currentColor"
                            d="M3.3655 7.02506H4.14198V6.14669H3.3655V7.02506ZM5.56144 8.78181H6.33792V4.38994H5.56144V8.78181ZM7.97697 10.5386H8.75345V2.63319H7.97697V10.5386ZM10.3925 8.78181H11.169V4.38994H10.3925V8.78181ZM12.5884 7.02506H13.3649V6.14669H12.5884V7.02506ZM0.459839 15.4373V1.85583C0.459839 1.45178 0.595401 1.11448 0.866526 0.843943C1.13765 0.573404 1.47495 0.437841 1.87841 0.437256H14.852C15.2561 0.437256 15.5934 0.572818 15.8639 0.843943C16.1344 1.11507 16.27 1.45236 16.2706 1.85583V11.3159C16.2706 11.72 16.1353 12.0576 15.8648 12.3287C15.5942 12.5998 15.2566 12.7351 14.852 12.7345H3.1626L0.459839 15.4373Z"
                          />
                        </svg> */}
                      </p>
                    </div>
                  )}
                  {list.msgType === 'FILE' && (
                    <div className="iconGroup">
                      <p className="fileIcon">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M21.9403 11.5243L12.7503 20.7143C11.6244 21.8401 10.0975 22.4726 8.50529 22.4726C6.91311 22.4726 5.38613 21.8401 4.26029 20.7143C3.13445 19.5884 2.50195 18.0615 2.50195 16.4693C2.50195 14.8771 3.13445 13.3501 4.26029 12.2243L12.8303 3.65428C13.5809 2.90239 14.5994 2.47945 15.6618 2.47852C16.7241 2.47758 17.7434 2.89871 18.4953 3.64928C19.2472 4.39984 19.6701 5.41835 19.671 6.48074C19.672 7.54314 19.2509 8.56239 18.5003 9.31428L9.91029 17.8843C9.53501 18.2596 9.02602 18.4704 8.49529 18.4704C7.96456 18.4704 7.45557 18.2596 7.08029 17.8843C6.70501 17.509 6.49418 17 6.49418 16.4693C6.49418 15.9385 6.70501 15.4296 7.08029 15.0543L15.5703 6.57428"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </p>
                    </div>
                  )}
                  {list.msgType === 'MMS' && (
                    <p className="text">{removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '이미지'}</p>
                  )}
                  {list.msgType === 'VMS' && (
                    <p className="text">{removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '음성메시지'}</p>
                  )}
                  {list.msgType === 'FILE' && (
                    <p className="text">{removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '파일'}</p>
                  )}
                  {removeWebCharacter(list.sndMsg, '[Web발신]').length > 0 && (
                    <p className="text">{removeWebCharacter(list.sndMsg, '[Web발신]')}</p>
                  )}
                </div>
                {/* 알림 */}
                {list.rcvMsgCount > 0 && (
                  <div className="receive">
                    <p>{list.rcvMsgCount}</p>
                  </div>
                )}
              </div>
            );
          })}
          {/* 인피니티스크롤시 로딩 컴포넌트 */}
          {eachHooks.inFiniteResult.isFetchingNextPage && <NextLoader />}
        </MLS.ChatList>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default EachChatRoom;
