import { instance } from '@/shared/lib/clientAxios';

interface QueryKeyData {
  prepayPayNo: string;
  pageNumber: number;
  pageSize: number;
  keyword: string;
  keywordValue: string;
  callback: string;
}

type QueryKey = [string, QueryKeyData];

export const getReserveSendDetail = async (searchAllValue: any) => {
  try {
    const response = await instance.get(
      localStorage.getItem('callBack') === '01067873947' ? '/mcmp/result/reserve' : '/reserveSendDetail',
      {
        params: {
          prepayPayNo: searchAllValue.prepayPayNo,
          pageNumber: searchAllValue.currentPage,
          pageSize: searchAllValue.pageSize,
          keyword: searchAllValue.keyword,
          keywordValue: searchAllValue.keywordValue,
          callback: searchAllValue.callback,
        },
      },
    );
    return response.data;
  } catch (error) {
    return '';
  }
};

interface IUpdateStcMsgApi {
  sndMsg: string;
  subject: string;
  sndDttm: string;
  prepayPayNo: string;
  callback: string;
}
export const updateStcMsgApi = async (props: IUpdateStcMsgApi) => {
  // const response = await instance.post(`/updateReserveXms?prepayPayNo=${prepayPayNo}`, {
  const response = await instance.post(
    localStorage.getItem('callBack') === '01067873947' ? '/mcmp/result/reserve/update' : '/updateReserveXms',
    {
      sndMsg: props.sndMsg,
      subject: props.subject,
      sndDttm: props.sndDttm,
      prepayPayNo: props.prepayPayNo,
      callback: props.callback,
    },
  );
  return response.data;
};
