import React, { useRef, useState } from 'react';
import * as S from '../MobileHeader.style';
import { useNavigate } from 'react-router-dom'; // useNavigate import 추가
import { TbAddressBook, TbFolderPlus, TbSearch, TbSettings } from 'react-icons/tb';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import styled from 'styled-components';
import AddGroup from '@/pages/main/components/addressLeftZone/groupList/addGroup/AddGroup.index';
import { useAddGroup } from '@/components/hooks/customs/addressBook/useAddGroup';
import { useMutation } from '@tanstack/react-query';
import { postAddGroup } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import IconAddressSync from '@/styles/icons/IconAddressSync';
import IconAddAddressGroup from '@/styles/icons/IconAddAddressGroup';
import { useSetRecoilState } from 'recoil';
import { syncLoadingRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

const AddressGroupListHeader = () => {
  const navigate = useNavigate();
  const { confirmModal, warningModal } = useModalHook();
  const setSyncLoadingS = useSetRecoilState(syncLoadingRecoil);

  // 주소록 동기화 클릭 했을 때 동작하는 함수
  const onClickMobileAdrressAdd = () => {
    confirmModal(
      () => {
        setSyncLoadingS(true);
        window.fromWebToAppReqAddressList(localStorage.getItem('callBack') ?? '');
      },
      '주소록 동기화',
      '핸드폰의 주소록을 동기화하시겠습니까?',
      true,
      undefined,
      () => {
        warningModal('동기화 실패', '주소록에서 동기화를 다시 시도할 수 있습니다.', true);
      },
    );
  };

  return (
    <>
      <S.HeaderTitle>주소록 그룹</S.HeaderTitle>
      <S.IconContainer>
        {/* 검색 아이콘 */}
        {/* <S.HeaderButtonIcon
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <TbSearch style={{ strokeWidth: 1.5 }} />
        </S.HeaderButtonIcon> */}

        {/* 폴더 추가 아이콘 */}
        <S.HeaderButtonIcon
          onClick={() => {
            navigate('/mobile/address/groupAdd');
          }}
        >
          <IconAddAddressGroup />
        </S.HeaderButtonIcon>

        {/* 주소록 동기화 아이콘 */}
        <S.HeaderButtonIcon
          onClick={() => {
            onClickMobileAdrressAdd();
          }}
        >
          <IconAddressSync />
        </S.HeaderButtonIcon>

        {/* 설정 아이콘 */}
        {/* <S.HeaderButtonIcon>
          <TbSettings style={{ strokeWidth: 1.5 }} />
        </S.HeaderButtonIcon> */}
      </S.IconContainer>
    </>
  );
};

export default AddressGroupListHeader;
