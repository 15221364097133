import CashCharge from '@/pages/CashCharge/CashCharge';
import React from 'react';

const MobileEtcCharge = () => {
  return (
    <>
      <CashCharge />
    </>
  );
};
export default MobileEtcCharge;
