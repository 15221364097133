import React, { Fragment } from 'react';
import { removeWebCharacter, underLine } from '@/shared/util/format/contentFormatUtil';
import * as S from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/ChatRoom015Body.styled';
import ChatRoom015VMS from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015VMS/ChatRoom015VMS';
import { formatTime } from '@/shared/util/format/dateFormatUtil';
import ChatRoom015File from '../chatRoom015File/ChatRoom015File';

interface IChatRoom015MO {
  /* 채팅방 리스트 개별 데이터 */
  list: any;
  /* 채팅방 리스트 데이터 */
  content: any;
  /* 채팅방 리스트 인덱스 */
  index: number;

  /* 전송 실패한 메세지 재전송 Hook */
  reSend015Msg(messageId: string, msgType: string): void;

  /* 전송 실패한 메세지 삭제 Hook */
  cancelSend015Msg(messageId: string): void;

  /* 이미지 확대 Hook */
  closeUpImgHook(imgData: string): void;

  /* 이미지 표출 오류 Hook */
  handleImgError(e: any): void;
}

/**
 * @title 015 채팅방 MO 리스트 표출 컴포넌트
 *
 * @author 정휘학
 * @since 2024.04.18
 * */

const ChatRoom015MO = (props: IChatRoom015MO) => {
  return (
    <Fragment key={props.list.messageId}>
      {/* 이미지 */}
      {
        // props.list.imgData && (
        props.list.msgType === 'MMS' && (
          <>
            <S.EtcChattingYourZone>
              <S.EtcChattingContentWrap>
                <S.EtcChattingYourImageBox onClick={() => props.closeUpImgHook(props.list.imgData)}>
                  <img
                    src={`data:image/jpeg;base64,${props.list.imgData}`}
                    alt="이미지"
                    onError={props.handleImgError}
                  />
                </S.EtcChattingYourImageBox>
                {/* 전송 상태 표출 분기 처리 */}
                {removeWebCharacter(props.list.sndMsg, '[Web발신]') === '' &&
                  formatTime(props.list?.messageDttm) !==
                    (props.content[1][props.index + 1]?.direction === 'mo'
                      ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                      : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>}
              </S.EtcChattingContentWrap>
            </S.EtcChattingYourZone>
            {removeWebCharacter(props.list.sndMsg, '[Web발신]') !== '' && (
              <S.EtcChattingYourZone>
                <S.EtcChattingContentWrap>
                  <S.EtcChattingYourTextBox>
                    <p>{underLine(props.list.sndMsg)}</p>
                  </S.EtcChattingYourTextBox>
                  {formatTime(props.list?.messageDttm) !==
                    (props.content[1][props.index + 1]?.direction === 'mo'
                      ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                      : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>}
                </S.EtcChattingContentWrap>
              </S.EtcChattingYourZone>
            )}
          </>
        )
      }
      {/* TODO 파일 수신 - 아직 서버에서 컬럼 미완성. 해당 타입 추가되면 여기서 작업 */}
      {props.list.msgType === 'FILE' && (
        <ChatRoom015File
          list={props.list}
          content={props.content}
          index={props.index}
          direction={props.list.direction}
          cancelSend015Msg={props.cancelSend015Msg}
        />
      )}
      {/* 음성 녹음 */}
      {
        // props.list.vmsData && (
        props.list.msgType === 'VMS' && (
          <ChatRoom015VMS filename={props.list.vmsData} list={props.list} content={props.content} index={props.index} />
        )
      }
      {/* 내용 */}
      {(props.list.msgType === 'SMS' || props.list.msgType === 'LMS') &&
        removeWebCharacter(props.list.sndMsg, '[Web발신]') !== '' &&
        props.list.sndMsg !== 'Fail' && (
          <S.EtcChattingYourZone>
            <S.EtcChattingContentWrap>
              <S.EtcChattingYourTextBox>
                <p>{underLine(props.list.sndMsg)}</p>
              </S.EtcChattingYourTextBox>
              {formatTime(props.list?.messageDttm) !==
                (props.content[1][props.index + 1]?.direction === 'mo'
                  ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                  : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>}
            </S.EtcChattingContentWrap>
          </S.EtcChattingYourZone>
        )}
    </Fragment>
  );
};

export default ChatRoom015MO;
