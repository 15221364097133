import { useModalHook } from '@/components/commons/modals/useModalHook';
import { selectNumberState } from '@/recoil/atoms/addressList';
import { callbackListRecoil } from '@/recoil/atoms/useCallbackList';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import MobileHeader from '../mobileHeader/MobileHeader';
import MobilePageNavigation from '../MobilePageNavigation';
import * as S from './MobileLayout.style';

const MobileLayout = () => {
  const location = useLocation();
  const callbackListS = useRecoilValue(callbackListRecoil);
  const [selectNumberStateS, setSelectNumberStateS] = useRecoilState(selectNumberState);

  // 콜백 리스트 추가해주기(010, 015 탭이 없어서 모바일에서는 강제로 넣어줘야함)
  useEffect(() => {
    if (!(callbackListS && selectNumberStateS['015'] === '')) return;
    const test = callbackListS.find((item) => item.regMethod === '015');

    if (test) {
      setSelectNumberStateS((prev) => ({
        ...prev,
        '015': test.callback,
      }));
    }
  }, [callbackListS, localStorage]);

  return (
    <S.MobileMainLayout className="mobileMainLayout">
      {location.pathname !== '/mobile/mobileSignin' &&
        location.pathname !== '/mobile/015Signup' &&
        location.pathname !== '/mobile/015SubSignup' && <MobileHeader />}

      <Outlet />
      {location.pathname !== '/mobile/015SubCharge' &&
        location.pathname !== '/mobile/015Singup' &&
        location.pathname !== '/mobile/015SubSignup' &&
        location.pathname !== '/mobile/015Signup' && <MobilePageNavigation />}
    </S.MobileMainLayout>
  );
};

export default MobileLayout;
