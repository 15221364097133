import { Checkbox, Dropdown, Menu } from 'antd';
import styled, { keyframes } from 'styled-components';

// 애니메이션
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div<{ isActive: boolean }>`
  position: relative;
  width: 29rem;
  padding-top: 2rem;
  background-color: ${(props) => props.theme.colors.gray200};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.colors.gray300};

  > h1 {
    text-align: center;
    margin-bottom: 1rem;
    height: 5rem;

    a {
      display: inline-block;
      img {
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      height: 3.4rem;
    }
  }
  &.active {
    .openBtn {
      border-color: ${(props) => props.theme.colors.main};
      svg {
        color: ${(props) => props.theme.colors.main};
      }
    }
  }
  .openBtn {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    bottom: 8rem;
    left: -4rem;
    width: 4rem;
    height: 7rem;
    border-radius: 1.2rem 0 0 1.2rem;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.buttonBorder};
    color: ${(props) => props.theme.colors.txt500};
    border-right: 0;
    svg {
      padding: 0.2rem;
      width: 100%;
      height: 50%;
    }
  }

  @media screen and (max-width: 1920px) {
    width: 28rem;
  }
  @media screen and (max-width: 768px) {
    // 반응형 태블릿
    z-index: ${(props) => (props.isActive ? 100 : -1)};
    position: absolute;
    overflow-y: auto;
    width: 100%;
    max-width: unset;
    height: 100%;
    padding-right: 0;
    transition: 0.3s linear right;
    background-color: ${(props) => props.theme.colors.white};

    &.active {
      right: 0;
      .openBtn {
        left: 0;
        border-radius: 0 1.2rem 1.2rem 0;
        border: 2px solid ${(props) => props.theme.colors.buttonBorder};
        border-left: 0;
      }
    }
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 7rem);
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

// top, middle 감싸는 공통 스타일
export const GroupListGroup = styled.div`
  height: calc(100% - 5rem);
  padding: 0 0.5rem;

  &.active {
    .openAddGroup {
      max-height: calc(100% - 117px);
    }
  }

  .openAddGroup {
    @media screen and (max-width: 768px) {
      height: 100% !important;
      padding-bottom: 6rem;
      /* overflow-y: scroll; */
    }
  }
  /* 리스트 공통 스타일 */
  .list {
    cursor: pointer;
    height: 3.8rem;
    @media screen and (max-width: 768px) {
      height: auto;
    }
    .dots {
      display: none;
      margin-right: 1rem;
    }
    /* 전체그룹 */
    > div:not(.middleListAfterDiv) {
      height: 100%;
      padding: 0 1.5rem;
      .groupNameBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 15px - 1rem);
        height: 3.8rem;
      }
      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
    input[type='checkBox'] {
      margin: 0 1rem 0 0;
    }
  }
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

// 전체그룹 스타일
export const Top = styled.div`
  :hover {
    background-color: ${(props) => props.theme.colors.gray300};
  }
  margin: 0 0.1rem;
  border-radius: 0.5rem;

  @media screen and (max-width: 768px) {
    padding: 0.8rem 1rem;
    border-bottom: 1px solid rgba(120, 120, 120, 0.1);
  }
`;

// 전체그룹 스타일
export const TopWrap = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width: 768px) {
    border-bottom: none;
  }
`;

export const GroupAll = styled.div`
  // 전체그룹 / 수정 삭제
  cursor: pointer;
  min-width: max-content;
  height: 100%;
  font-weight: 600;
  font-size: 1.7rem;
  font-style: normal;
  color: ${(props) => props.theme.colors.txt400};

  .menuTab {
    display: flex;
    gap: 1.2rem;
    svg {
      width: 1.7rem;
      height: 1.7rem;
    }
    button {
      cursor: pointer;
      color: ${(props) => props.theme.colors.txt400};
      padding: 0;
    }
  }
`;

export const GroupAllName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-left: 1rem;
  }
`;

// 그룹 리스트 표출 스타일
export const Middle = styled.div`
  max-height: calc(100% - 6rem);
  overflow-y: auto;
  // overflow 스크롤 디자인
  ::-webkit-scrollbar {
    width: 4px;
    background-color: #d6d6dc;
    border-radius: 40px;
  }
  /* scroll thumb */
  ::-webkit-scrollbar-thumb {
    background: #98999a;
    border-radius: 40px;
  }

  .delBtn {
    cursor: pointer;
    display: none;
    color: ${(props) => props.theme.colors.gray600};
    padding: 0;
    :hover {
      color: ${(props) => props.theme.colors.gray700};
    }
  }

  @media screen and (max-width: 768px) {
    overflow-y: inherit;
  }
`;

export const Sort = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  padding: 0 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  p {
    color: ${(props) => props.theme.colors.txt400};
    font-size: 1.4rem;
    padding: 0.6rem 0;
  }
  &.custom {
    .custom {
      display: block;
    }
    .asc {
      display: none;
    }
    .desc {
      display: none;
    }
  }
  &.asc {
    .asc {
      display: block;
    }
    .custom {
      display: none;
    }
    .desc {
      display: none;
    }
  }
  &.desc {
    .desc {
      display: block;
    }
    .custom {
      display: none;
    }
    .asc {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const MiddleList = styled.div`
  position: relative;

  &.active {
    background-color: ${(props) => props.theme.colors.gray300};
  }
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-sizing: border-box;
    color: black;
    transition: none;
    border-radius: 0.5rem;

    > input {
      padding: 0;
      color: ${(props) => props.theme.colors.txt500};
      &::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }
    }
  }
  /* TODO - 호버삭제하면 원클 가능 원클릭시 보이는 */
  .middleListBasicDiv {
    :hover {
      background: ${(props) => props.theme.colors.gray300};
      .groupNm {
        color: ${(props) => props.theme.colors.txt500};
      }
    }
    &.active {
      background: ${(props) => props.theme.colors.gray300};
    }
  }

  /* 더블클릭시 보이는 */
  .middleListAfterDiv {
    border: 2px solid ${(props) => props.theme.colors.main};
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    svg {
      width: 1.7rem;
      height: 1.7rem;
    }
    input {
      width: 100%;
      font-size: 1.7rem;
      font-weight: 600;
      padding: 0 1rem;
      border: 0;
      outline: 0;
      background-color: transparent;
      ::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }
    }
  }
`;

export const MiddleListAfterDiv = styled.div`
  position: relative;

  .storage {
    color: ${(props) => props.theme.colors.main};
    font-weight: 700;
    white-space: nowrap;
  }
  &:has(input:placeholder-shown) .storage {
    color: ${(props) => props.theme.colors.txt200};
  }
  svg {
    overflow: visible;
    path {
      fill: ${(props) => props.theme.colors.txt200};
      stroke: ${(props) => props.theme.colors.txt200};
    }
  }
  input {
    ::placeholder {
      outline: unset;
    }
  }
  @media screen and (max-width: 768px) {
    height: 100% !important;
    padding: 10px 1rem !important;
  }
`;

export const MiddleListBasicDiv = styled.div`
  :hover {
    .delBtn {
      display: block;
    }
    .buddyCount {
      display: none;
    }
    .dots {
      display: grid;
    }
  }
`;

export const BottomWrap = styled.div`
  margin: 0 auto;
  /* margin-bottom: 16px; */
  width: 96%;
`;
export const Bottom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem 0 2.2rem;
  height: 4.6rem;
  font-weight: 500;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.gray300};
  svg {
    width: 1.7rem;
    height: 1.6rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0 1.2rem 0 2.2rem;
  }
`;

export const BinBtnWrap = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.txt400};
  span {
    font-size: 1.4rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.txt400};
    &:first-of-type {
      margin-left: 20px;
    }
  }
`;

/* 휴지통안 갯수 */
export const TrashNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 2.2rem;
  font-size: 1.3rem;
  font-weight: 600;
  background: ${(props) => props.theme.colors.gray600};
  border-radius: 100px;
  color: ${(props) => props.theme.colors.txt100};
`;

// X
export const GroupListDrag = styled.div`
  width: 100%;
  height: 100%;

  /* WebKit (Chrome, Safari) 스크롤바 스타일 */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #98999a; /* 스크롤바 썸의 배경색 */
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #333333; /* 스크롤바 썸의 호버 배경색 */
  }
  /* Firefox 스크롤바 스타일 */
  scrollbar-width: 6px;
  scrollbar-color: #6d6c6d;
  /* Firefox 스크롤바 썸 스타일 */
  &::-webkit-scrollbar-thumb {
    background-color: #98999a;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #333333;
  }
  .groupListLoading {
    display: flex;
    gap: 24px;
    /* justify-content: space-around; */
    margin: 12px 0 14px 16px;
    > li {
      margin: 0px !important;
    }
  }
`;

export const GroupNameBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors.txt400};
  cursor: pointer;
  pointer-events: auto;
  display: flex;

  .btnWrap {
    display: flex;
    justify-content: space-between;
  }

  .groupNm {
    display: block;
    /* max-width: calc(100% - 8rem); */
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
  }
  .buddyCount {
    color: ${(props) => props.theme.colors.txt300};
    font-weight: 500;
    font-size: 1.7rem;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 6rem;
  }
  span {
    color: ${(props) => props.theme.colors.txt400};
  }
`;

export const MobileGroupList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  > section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: rgb(120, 120, 120);
    padding: 0 1rem;
    border-bottom: 1px solid rgba(120, 120, 120, 0.1);

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 1rem 0;
      border-radius: 0.8rem;

      .groupNm {
        white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 */
        overflow: hidden; /* 넘치는 텍스트를 숨김 */
        text-overflow: ellipsis; /* 넘치는 텍스트를 '...'로 표시 */
        flex-grow: 1; /* 그룹 이름이 여유 공간을 채우도록 함 */
        user-select: none;
        font-size: 1.6rem;
        font-weight: 600;
        color: black;
      }

      .buddyCount {
        margin-left: 8px; /* 버디 카운트와 그룹 이름 사이에 간격 추가 */
        flex-shrink: 0; /* 버디 카운트가 줄어들지 않도록 설정 */
        font-size: 1.4rem;
      }
    }

    /* 액티브 되면 효과 */
    :hover {
      background: ${(props) => props.theme.colors.gray300};
      .groupNm {
        color: ${(props) => props.theme.colors.txt500};
      }
    }

    &.active {
      background: ${(props) => props.theme.colors.gray300};
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    > section > div {
      padding: 2rem 0;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-radio-inner {
    width: 18px;
    height: 18px;

    &::after {
      width: 10px;
      height: 10px;
    }
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    background-color: transparent; /* 기본 배경을 투명으로 설정 */
    border: 1px solid rgb(120, 120, 120); /* 기본 테두리 색상 설정 */
    transition:
      background-color 0.3s,
      border-color 0.3s; /* 부드러운 효과 */

    &::after {
      width: 6px;
      height: 10px;
    }
  }

  /* 체크되었을 때 */
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff; /* 파란색 배경 */
    border-color: #1890ff; /* 파란색 테두리 */
  }

  /* hover 시 */
  .ant-checkbox-inner:hover {
    border-color: #1890ff; /* hover시 파란색 테두리 */
  }

  /* 체크박스의 체크 표시 색상 */
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff; /* 체크된 상태에서 표시될 체크 표시 색상 */
  }
  .ant-radio,
  .ant-checkbox {
    font-size: 20px;
  }
`;

export const MenuDropDown = styled(Dropdown)`
  padding: 1rem 0rem 1rem 0.6rem;
  /* padding: 0.4rem; */
  background-color: transparent;
`;

export const MenuBox = styled(Menu)`
  background-color: #ebebeb !important;
  .ant-dropdown-menu-item {
    font-size: 16px !important;
    :nth-child(1) {
      margin-bottom: 2px !important;
    }
  }
`;

export const MenuItem = styled(Menu.Item)`
  display: flex;
  flex-direction: column;

  /* border: 1px solid #333; */

  .ant-dropdown-menu-title-content {
    color: #333;
    font-weight: 500;
    display: flex !important;
    align-items: center !important;
    gap: 6px;
  }
`;
