// 클라이언트 환경 확인하는 util 페이지
export const detectEnvUtils = () => {
  const ua = navigator.userAgent;

  if (ua.includes('WebView')) {
    return 'app'; // 앱 (WebView)
  }
  if (/Android|iPhone|iPad|iPod/i.test(ua)) {
    return 'mobile'; // 모바일 웹 브라우저
  }
  return 'pc'; // PC 웹 브라우저
};
