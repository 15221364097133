/* eslint-disable */
import { useSignIn } from '@/components/hooks/customs/signIn/useSignIn';
import { removeCookie, setCookie } from '@/shared/util/cookie';
import React, { useEffect } from 'react';
import { PiEye, PiEyeClosed } from 'react-icons/pi';
import { Link, useNavigate } from 'react-router-dom';
import BaseButton from '../../components/Atom/BaseButton';
import BaseInput from '../../components/Atom/BaseInput';
import NewsGroupWrap from './NewsGroup';
import * as S from './SignIn.styles';
import SigninHeader from './SigninHeader';
import { useRecoilState } from 'recoil';
import { signInRecoil } from '@/recoil/atoms/signInAtom';
import { useQueryClient } from '@tanstack/react-query';

/**
 * @title : 로그인 페이지
 */

const SignIn = () => {
  const [signInState, setSignInState] = useRecoilState(signInRecoil);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const token = localStorage.getItem('authorizationToken');

  useEffect(() => {
    if (signInState && token) {
      navigate('/');
    } else {
      const excludedQueryKeys = ['/customer/list'];
      // 모든 쿼리 캐시 중 제외할 쿼리 키를 제외하고 제거
      queryClient
        .getQueryCache()
        .getAll()
        .forEach((query: any) => {
          if (!excludedQueryKeys.includes(query.queryKey[0])) {
            queryClient.removeQueries(query.queryKey);
          }
        });

      setSignInState(false);
      removeCookie('Refresh_Token');
      setCookie('init', `init`, { path: '/' });
      /* 제거하지 않을 localStorage 키  */
      const keepKeys = [
        'dontShowAgain',
        'deviceId',
        // 'bizAgreement',
        'contacts',
        'loginDeviceIds',
        'addressColumViewLabel',
        'addressColumViewValue',
        'groupIndexes',
        'theme',
        'hotfix',
        'event1',
      ];

      const tempData: any = {};
      /* 제거하지 않을 localStorage 키값 저장시켜두기 */
      keepKeys.forEach((key) => {
        const value = localStorage.getItem(key);
        if (value) {
          tempData[key] = value;
        }
      });

      localStorage.clear();

      Object.keys(tempData).forEach((key) => {
        localStorage.setItem(key, tempData[key]);
      });
    }
  }, [signInState, token]);

  const { handleSubmit, onClickLogin, isLoading, errors, register, viewable, onClickPwViewableToggle, setValue } =
    useSignIn();

  return (
    <S.SigninWrap className="signin">
      <SigninHeader />
      <S.Wrapper>
        <S.Container>
          <S.ContentTitle>
            <h2>
              <img src="/img/signin/my015.png" alt="my015Image" />
            </h2>
          </S.ContentTitle>

          <S.LoginWrap>
            <form onSubmit={handleSubmit(onClickLogin)}>
              <S.LoginContentWrap>
                <S.ArreoLoginInfo>
                  <S.ArreoLoginInput className={'' + (errors?.signInId ? 'active' : '')}>
                    <BaseInput
                      type="text"
                      paddingLeft="0"
                      paddingRight="0"
                      width="100%"
                      height="3rem"
                      backgroundColor="transparent"
                      placeholder="전화번호"
                      {...register('signInId', {
                        onChange: (e) => {
                          const trimmedValue = e.target.value.trim();
                          setValue('signInId', trimmedValue);
                        },
                      })}
                    />
                  </S.ArreoLoginInput>
                  <S.ArreoLoginInput className={'' + (errors?.signInPassword ? 'active' : '')}>
                    <BaseInput
                      type={viewable ? 'text' : 'password'}
                      paddingLeft="0"
                      paddingRight="0"
                      width="100%"
                      height="3rem"
                      backgroundColor="transparent"
                      placeholder="비밀번호"
                      // placeholder="Enter your password"
                      {...register('signInPassword')}
                    />
                    <S.Viewable>
                      {viewable ? (
                        <PiEye className="on" onClick={() => onClickPwViewableToggle()} />
                      ) : (
                        <PiEyeClosed className="off" onClick={() => onClickPwViewableToggle()} />
                      )}
                    </S.Viewable>
                  </S.ArreoLoginInput>
                  <S.DataFunZone>
                    <Link to="/findaccount">아이디 / 비밀번호 찾기</Link>
                    <Link to="/signup">회원가입</Link>
                  </S.DataFunZone>
                </S.ArreoLoginInfo>
                <S.AgreementButtonWrap>
                  <BaseButton
                    type="submit"
                    height="7rem"
                    fontWeight="800"
                    padding="0"
                    backgroundColor="#191919"
                    borderRadius="5rem"
                    boxShadow="0px 0px 1rem 0px rgba(54, 93, 178, 0.2)"
                    color="#fff"
                    disabled={isLoading && true}
                  >
                    로그인
                  </BaseButton>
                </S.AgreementButtonWrap>
              </S.LoginContentWrap>
            </form>
          </S.LoginWrap>
          {/* End NoneSoftPhoneWrap Zone */}
        </S.Container>
        <NewsGroupWrap />
      </S.Wrapper>
    </S.SigninWrap>
  );
};

export default SignIn;
