import styled from 'styled-components';

export const SignUpContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 4rem;
  }
`;

export const SignUpFormWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 4.3vw 0;
  position: relative;
  flex-shrink: 0;
  padding: 0 5%;
  box-sizing: border-box;

  &::before {
    content: '서울이동통신 회원가입';
    display: block;
    color: ${(props) => props.theme.colors.txt500};
    text-align: center;
    font-family: 'Inter';
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 582;
    line-height: normal;
  }
`;

export const SignUpSubTitle = styled.p`
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2vw;
`;

export const TermLine = styled.hr`
  width: 100%;
  margin: 3rem 0;
`;
export const SignUpInputForm = styled.div`
  width: 100%;
  padding: 0 2vw;
  box-sizing: border-box;
  align-items: center;
  margin: 1.57vw 0 1.7vw 0;
`;

export const SignUpPhoneInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 0.5rem;
  .num015 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input {
      max-width: 10rem;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;

    .num015 {
      width: 100%;
      align-items: flex-start;
      input {
        max-width: unset;
      }
    }
  }
`;

export const SignUpInputSpan = styled.span`
  font-size: 2.5rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  white-space: nowrap;
  input {
    width: 100%;
    height: 3rem;
    max-width: 22rem;
    padding: 0.6rem;
    border: 1px solid ${(props) => props.theme.colors.gray400};
  }
  &.rowPasswoerd {
    input {
      /* width: calc(100% - 38rem); */
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    > * {
      height: auto;
      min-height: 3rem;
      margin-right: 0;
      margin-bottom: 2rem;
    }

    input {
      background: ${(props) => props.theme.colors.white};
      padding: 0.5rem 1rem;
      max-width: unset;
      font-size: 1.4rem;
      min-height: 3rem;
    }
  }
`;

export const Label = styled.label`
  font-size: 1.5rem;
  min-width: 120px;
  width: 10%;
  @media screen and (max-width: 768px) {
    width: 100%;
    background: ${(props) => props.theme.colors.gray300};
    margin-bottom: 0;
    margin-right: 0;
    text-align: center;
    min-height: auto;
    padding: 1rem 0;
    margin-bottom: 10px;
    span {
      display: none;
    }
  }
`;

export const SignUpEmailInputWrap = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  .emailBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    margin-right: 0;
  }
  > * {
    margin-right: 1rem;
    :last-of-type {
      margin-right: 0;
    }
  }
  .select {
    width: 100%;
    select {
      height: 3rem;
      width: 100%;
      border-color: ${(props) => props.theme.colors.gray400};
    }
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    > * {
      margin-right: 0;
    }
  }
`;

export const SignUpEmailSpan = styled.span`
  padding: 0px 0.43vw;
  box-sizing: border-box;
  font-size: 1.5rem;
`;

export const SignUpSelect = styled.select``;
export const SignUpCheckEmail = styled.div`
  box-sizing: border-box;
  /* padding: 0 0 0 9.4vw; */
  margin-left: 130px;
  label,
  input {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const SignUpAdAgreeWrap = styled.div`
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 4rem;
`;
export const AdAgreeCheckBox = styled.input``;

export const AdAgreeLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 1.2rem;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const AdLabelWrap = styled.div`
  display: flex;
  width: 54px;
  input,
  label {
    cursor: pointer;
  }
`;
export const AgreeLabel = styled.label``;
export const DisagreeLabel = styled.label``;

export const SignCustomButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 3vw;
  button {
    padding: 1.2rem 1rem;
    max-width: 138px;
    width: 45%;
  }
`;

export const ValidationError = styled.p`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.red};
  @media screen and (max-width: 768px) {
    min-width: auto;
  }
`;

export const ValidationMessage = styled.p`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt400};
`;

export const ValidationNumber = styled.p`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt400};
  &.green {
    color: ${(props) => props.theme.colors.green};
  }
  &.red {
    color: ${(props) => props.theme.colors.red};
  }
`;

export const PhoneGroup = styled.div`
  position: relative;
  .btn {
    height: 3rem;
    padding: 0.6rem;
    color: ${(props) => props.theme.colors.txt100};
    background: ${(props) => props.theme.colors.gray600};
    border-radius: 0.4rem;
  }
  .open {
    background: ${(props) => props.theme.colors.main};
  }
  .numberGroup {
    position: absolute;
    bottom: -0.6rem;
    left: 50%;
    transform: translateX(-50%);
  }
  ul {
    max-height: 21rem;
  }
`;
