import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 0.6em;
  height: 1.1em;
  &.left {
    transform: rotate(180deg);
  }
`;

const IconArrowRight: React.FC<React.SVGProps<SVGSVGElement>> = ({
  className,
  width,
  height,
  fill,
  stroke,
  fontSize,
}: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      width={width}
      height={height}
      fontSize={fontSize ?? '1rem'}
      viewBox="0 0 6 11"
      fill="none"
    >
      <path
        d="M4.78626 5.0227L1.19416 0.453069C1.13422 0.376784 1.05459 0.334228 0.971788 0.334228C0.888981 0.334228 0.809353 0.376784 0.749417 0.453069L0.745549 0.458232C0.716393 0.495214 0.693176 0.539728 0.677311 0.589067C0.661446 0.638406 0.653265 0.691539 0.653265 0.745233C0.653265 0.798927 0.661446 0.852059 0.677311 0.901398C0.693176 0.950737 0.716393 0.995252 0.745549 1.03223L4.12817 5.33509L0.745549 9.63622C0.716392 9.6732 0.693176 9.71772 0.677311 9.76706C0.661446 9.8164 0.653265 9.86953 0.653265 9.92322C0.653265 9.97692 0.661446 10.03 0.677311 10.0794C0.693176 10.1287 0.716392 10.1732 0.745549 10.2102L0.749417 10.2154C0.809352 10.2917 0.888981 10.3342 0.971787 10.3342C1.05459 10.3342 1.13422 10.2917 1.19416 10.2154L4.78626 5.64575C4.81785 5.60557 4.843 5.55724 4.86019 5.50369C4.87737 5.45014 4.88623 5.39249 4.88623 5.33423C4.88623 5.27597 4.87737 5.21832 4.86019 5.16477C4.843 5.11122 4.81785 5.06289 4.78626 5.0227Z"
        fill={fill ?? 'currentColor'}
        stroke={stroke ?? 'currentColor'}
        strokeWidth="0.352747"
      />
    </Svg>
  );
};

export default IconArrowRight;
