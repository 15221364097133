import CallingNumberAdmin from '@/components/Organism/MyPage/CallingNumberAdmin';
import React from 'react';

const MobileEtcCallbackNumber = () => {
  return (
    <>
      <CallingNumberAdmin />
    </>
  );
};
export default MobileEtcCallbackNumber;
