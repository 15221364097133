import React, { useEffect, useRef, useState } from 'react';
import * as S from './MobileAddressGroupNewAdd.style';
import { useNavigate } from 'react-router-dom';
import IconSend from '@/styles/icons/IconSend';
import { useRecoilState } from 'recoil';
import { mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { addStringValueRecoil, mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';

const MobileAddressGroupNewAdd = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null); // inputRef 추가
  const [addStringValueS, setAddStringValueS] = useRecoilState(addStringValueRecoil);

  useEffect(() => {
    setAddStringValueS('');
    inputRef.current?.focus(); // 컴포넌트가 마운트되면 포커스
  }, []);

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddStringValueS(value);
  };

  return (
    <S.Container className="mobileMessageNewAdd">
      <S.ListModal ref={modalRef}>
        <S.ListModalInput
          ref={inputRef} // ref 속성으로 inputRef 연결
          value={addStringValueS}
          onChange={onChangeInputValue}
          type="text"
          placeholder="폴더명"
          maxLength={50}
        />
      </S.ListModal>
    </S.Container>
  );
};

export default MobileAddressGroupNewAdd;
