/* eslint-disable */
import { replace } from 'lodash';
// 일반적인 전화번호 포맷
export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return '';

  const cleaned = phoneNumber.replace(/\D/g, '');
  let match;

  // 015로 시작하고 길이가 10자리인 경우
  if (cleaned.length === 10 && cleaned.startsWith('015')) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  }
  // 015로 시작하고 길이가 11자리인 경우
  else if (cleaned.length === 11 && cleaned.startsWith('015')) {
    match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  }
  // 길이가 8자리인 경우
  else if (cleaned.length === 8) {
    match = cleaned.match(/^(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}`;
    }
  }
  // 길이가 9자리 또는 10자리이고 2 또는 02로 시작하는 경우
  else if ((cleaned.length === 9 || cleaned.length === 10) && (cleaned.startsWith('2') || cleaned.startsWith('02'))) {
    match = cleaned.match(/^(\d{2})(\d{3,4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  }
  // 그 외의 경우
  else {
    match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  }

  // 마지막으로 2-4-남은 번호 전부 형식으로 포맷
  match = cleaned.match(/^(\d{2})(\d{4})(\d+)$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return '';
};

// 모든 하이픈을 제거하고 숫자만 남김
export const removeHyphens = (inputString: string) => {
  return inputString.replace(/-/g, '');
};

// 숫자만 추출하는 fomat
export const fomatOnlyNum = (inputString: string) => {
  return inputString.replace(/[^0-9]/g, '');
};

// 휴대폰번호 + 문자열이 있을 경우 휴대폰 번호에만 하이픈 붙이고 문자열은 영향 없음
export const formatPhoneString = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return '';

  const cleaned = phoneNumber.replace(/\D/g, '');
  let match;

  // 015로 시작하고 길이가 10자리인 경우
  if (cleaned.length === 10 && cleaned.startsWith('015')) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const formattedNumber = `${match[1]}-${match[2]}-${match[3]}`;
      const remainingPart = phoneNumber.replace(/\d+/g, '');
      return remainingPart ? `${formattedNumber} ${remainingPart}` : formattedNumber;
    }
  }
  // 015로 시작하고 길이가 11자리인 경우
  else if (cleaned.length === 11 && cleaned.startsWith('015')) {
    match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      const formattedNumber = `${match[1]}-${match[2]}-${match[3]}`;
      const remainingPart = phoneNumber.replace(/\d+/g, '');
      return remainingPart ? `${formattedNumber} ${remainingPart}` : formattedNumber;
    }
  }
  // 길이가 8자리인 경우
  else if (cleaned.length === 8) {
    match = cleaned.match(/^(\d{4})(\d{4})$/);
    if (match) {
      const formattedNumber = `${match[1]}-${match[2]}`;
      const remainingPart = phoneNumber.replace(/\d+/g, '');
      return remainingPart ? `${formattedNumber} ${remainingPart}` : formattedNumber;
    }
  }
  // 길이가 9자리 또는 10자리이고 2 또는 02로 시작하는 경우
  else if ((cleaned.length === 9 || cleaned.length === 10) && (cleaned.startsWith('2') || cleaned.startsWith('02'))) {
    match = cleaned.match(/^(\d{2})(\d{3,4})(\d{4})$/);
    if (match) {
      const formattedNumber = `${match[1]}-${match[2]}-${match[3]}`;
      const remainingPart = phoneNumber.replace(/\d+/g, '');
      return remainingPart ? `${formattedNumber} ${remainingPart}` : formattedNumber;
    }
  }
  // 그 외의 경우
  else {
    match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      const formattedNumber = `${match[1]}-${match[2]}-${match[3]}`;
      const remainingPart = phoneNumber.replace(/\d+/g, '');
      return remainingPart ? `${formattedNumber} ${remainingPart}` : formattedNumber;
    }
  }

  return phoneNumber;
};

function char_remove(s1: string, s2: string) {
  for (let i = 0; i < s1.length; i++) {
    if (s1.substring(i, i + 1) == s2) {
      s1 = s1.substring(0, i) + s1.substring(i + 1);
    }
  }
  if (s1.length > 13) s1 = s1.substring(0, 13);
  return s1;
}

// 숫자로만 이루어 졌는지 체크
function check_Num01(obj: any) {
  for (let i = 0; i < obj.length; i++) {
    if (obj.substring(i, i + 1) < '0' || obj.substring(i, i + 1) > '9') {
      // alert('이 항목은 숫자만 입력 가능합니다.');
      return false;
    }
  }
  return true;
}

function check_ddd_016789(obj: any) {
  if (obj.length > 2) {
    const ch = obj.substring(0, 3);
    if (
      ch == '031' ||
      ch == '032' ||
      ch == '033' ||
      ch == '041' ||
      ch == '042' ||
      ch == '043' ||
      ch == '051' ||
      ch == '052' ||
      ch == '053' ||
      ch == '054' ||
      ch == '055' ||
      ch == '061' ||
      ch == '062' ||
      ch == '063' ||
      ch == '064' ||
      ch == '010' ||
      ch == '011' ||
      ch == '016' ||
      ch == '017' ||
      ch == '018' ||
      ch == '019' ||
      ch == '013'
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export const TelHyphen = (phoneNumber: string) => {
  // phoneNumber = replace(phoneNumber, ' ', '');
  if (!phoneNumber) return '';
  phoneNumber = phoneNumber.trim().replace(/[\p{C}]/gu, '');
  const _tel = char_remove(phoneNumber, '-');
  if (check_Num01(_tel)) {
    if (_tel.substring(0, 2) == '02') {
      if (_tel.length > 9) {
        //02-1234-5678
        phoneNumber = _tel.substring(0, 2) + '-' + _tel.substring(2, 6) + '-' + _tel.substring(6, 10);
      } else if (_tel.length > 5) {
        phoneNumber = _tel.substring(0, 2) + '-' + _tel.substring(2, 5) + '-' + _tel.substring(5, 9);
      }
      // 수신인에 02로 시작시 삭제되지 않음 주석처리
      // else {
      //   phoneNumber = _tel.substring(0, 2) + '-' + _tel.substring(2);
      // }
    } else if (_tel.substring(0, 3) === '015') {
      // '015'로 시작하는 번호 처리
      if (_tel.length > 10) {
        phoneNumber = _tel.substring(0, 3) + '-' + _tel.substring(3, 7) + '-' + _tel.substring(7, 11);
      } else if (_tel.length > 7) {
        phoneNumber = _tel.substring(0, 3) + '-' + _tel.substring(3, 7) + '-' + _tel.substring(7);
      }
    } else {
      if (_tel.substring(0, 4) == '0130') {
        if (_tel.length > 11) {
          phoneNumber = _tel.substring(0, 4) + '-' + _tel.substring(4, 8) + '-' + _tel.substring(8, 12);
        } else if (_tel.length > 8) {
          phoneNumber = _tel.substring(0, 4) + '-' + _tel.substring(4, 7) + '-' + _tel.substring(7, 11);
        } else {
          phoneNumber = _tel.substring(0, 4) + '-' + _tel.substring(4);
        }
      } else {
        if (_tel.length > 10) {
          phoneNumber = _tel.substring(0, 3) + '-' + _tel.substring(3, 7) + '-' + _tel.substring(7, 11);
        } else if (_tel.length > 7) {
          phoneNumber = _tel.substring(0, 3) + '-' + _tel.substring(3, 6) + '-' + _tel.substring(6, 10);
        } else if (_tel.length > 3) {
          phoneNumber = _tel.substring(0, 3) + '-' + _tel.substring(3);
        } else {
          phoneNumber = _tel;
        }

        /*        else if (_tel.length == 3) {
          if (!check_ddd_016789(_tel)) {
            phoneNumber = '';
          }
        } else if (_tel.length > 3) {
          if (!check_ddd_016789(_tel.substring(0, 3))) {
            phoneNumber = '';
          } else {
            if (_tel.substring(0, 3) == '013') {
              if (_tel.substring(0, 4) == '0130') {
              } else {
                alert('휴대전화번호를 확인하세요 !');
                phoneNumber = '';
              }
            } else {
              phoneNumber = _tel.substring(0, 3) + '-' + _tel.substring(3);
            }
          }
        }*/
      }
    }
    return phoneNumber;
  } else {
    // phoneNumber = "';
    return '';
  }
};
