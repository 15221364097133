import { detectEnvUtils } from '@/shared/util/detectEnvUtils';
import { useNiceCertificationUrlQuery } from '../mutations/niceCertificationMutation';
import { useRecoilValue } from 'recoil';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

/**
 * @title nice 인증 팝업창 띄우는 Hook
 *
 * @author 정휘학
 * @since 2024.03.18
 * */
export const useNiceCertification = () => {
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);
  const returnUrl = environmentS === 'app' ? `${window.location.origin}/mobile` : window.location.origin;

  const { mutateAsync } = useNiceCertificationUrlQuery();

  // 1. 모바일용 인증 처리 (페이지 리다이렉션)
  const niceCertificationMobile = async () => {
    try {
      const response = await mutateAsync({ callbackUrl: `${returnUrl}/AuthComponent`, errorUrl: `${returnUrl}/` });
      window.location.href = `${process.env.REACT_APP_SERVER_URL}${response}`; // 리다이렉션 처리
    } catch (error) {
      console.error('모바일 인증 요청 오류', error);
    }
  };

  // 2. 데스크탑용 인증 처리 (팝업창)
  const niceCertificationDesktop = async () => {
    try {
      const response = await mutateAsync({ callbackUrl: `${returnUrl}/AuthComponent`, errorUrl: `${returnUrl}/` });
      const left = window.screen.width / 2 - 500 / 2;
      const top = window.screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;
      const popup = window.open(`${process.env.REACT_APP_SERVER_URL}${response}`, 'nicePopup', option);

      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        console.error('팝업이 차단되었거나 열리지 않았습니다.');
      }
    } catch (error) {
      console.error('데스크탑 인증 요청 오류', error);
    }
  };

  // 디바이스에 따라 다른 인증 함수 호출
  const niceCertification = () => {
    if (environmentS === 'app') {
      // 앱에서는 리다이렉션 방식
      localStorage.setItem('prevPage', window.location.pathname);
      niceCertificationMobile();
    } else {
      // 데스크탑에서는 팝업 방식
      niceCertificationDesktop();
    }
  };

  return {
    niceCertification,
  };
};
