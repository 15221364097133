/**
 * @title 캐시 충전안내 컴포넌트
 *
 * @author 정휘학
 * @since 2024.05.18
 * */
import { ARREO_LMS, ARREO_MMS, ARREO_SMS, WORKS015_LMS, WORKS015_MMS, WORKS015_SMS } from '@/shared/constants';
import React from 'react';

const CashChargeGuide = () => {
  return (
    <div className=" priceGuide">
      <div className=" texthideTable group right">
        <table>
          <thead>
            <tr>
              <th>
                <h3>문자 발송요금 안내</h3>
              </th>
              <th>010 / 015</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>일반문자(SMS)</th>
              <td>
                <p>일반문자(SMS)</p>
                <div>
                  {ARREO_SMS}원(건) / {WORKS015_SMS}원(건)
                </div>
              </td>
            </tr>
            <tr>
              <th>장문문자(LMS)</th>
              <td>
                <p>장문문자(LMS)</p>
                <div>
                  {ARREO_LMS}원(건) / {WORKS015_LMS}원(건)
                </div>
              </td>
            </tr>
            <tr>
              <th>포토문자(MMS)</th>
              <td>
                <p>포토문자(MMS)</p>
                <div>
                  {ARREO_MMS}원(건) / {WORKS015_MMS}원(건)
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="etcPriceGuide">
        <span>
          - STT : 음성녹음을 텍스트로 변환합니다. <br /> (60원/분(신규 가입 시 5분 제공)
        </span>
        <span>
          - TTS : 안내멘트의 텍스트를 음성으로 변환합니다. <br /> (매일 5건의 작성회수를 제공합니다. 이후 건당 100원의
          요금이 부과됩니다.)
        </span>
      </div>
    </div>
  );
};

export default CashChargeGuide;
