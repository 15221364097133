import React, { useEffect, useRef, useState } from 'react';
import * as S from './MobileMessageNewAdd.style';
import { useNavigate } from 'react-router-dom';
import IconSend from '@/styles/icons/IconSend';
import { useRecoilState } from 'recoil';
import { mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { addNumberValueRecoil, mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';

const MobileMessageNewAdd = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null); // inputRef 추가
  const [addNumberValueS, setAddNumberValueS] = useRecoilState(addNumberValueRecoil);

  useEffect(() => {
    setAddNumberValueS('');
    inputRef.current?.focus(); // 컴포넌트가 마운트되면 포커스
  }, []);

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = value.replace(/-/g, ''); // 하이픈을 제거한 값
    if (/^\d*$/.test(numericValue)) {
      setAddNumberValueS(numericValue);
    }
  };

  return (
    <S.MobileMessageNewAddContainer className="mobileMessageNewAdd">
      <S.ListModal ref={modalRef}>
        <S.ListModalInput
          ref={inputRef} // inputRef 연결
          value={TelHyphen(addNumberValueS)} // 화면에만 하이픈을 포함한 값 표시
          onChange={onChangeInputValue}
          type="text"
          placeholder="전화번호"
        />
      </S.ListModal>
      <S.AddExplanation>-없이 숫자만 입력해 주세요.</S.AddExplanation>
    </S.MobileMessageNewAddContainer>
  );
};

export default MobileMessageNewAdd;
