import styled from 'styled-components';

// 015 사서함 가입절차 스타일
export const Btn100 = styled.div`
  height: auto !important;
  overflow: unset !important;
  margin: 2rem 0 0 !important;
  padding: 0 !important;

  .startBtn {
    cursor: pointer;
    width: 100%;
    padding: 1rem;
    height: 7rem;
    border-radius: 8rem;
    background: ${(props) => props.theme.colors.main};
    border: 0;
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
  }

  .unactive {
    background: #3c445e;
  }

  @media screen and (max-width: 768px) {
    .startBtn {
      font-size: 1.6rem;
      height: 6rem;
      margin: 0 auto 2rem;
    }
  }
`;

export const BlackRound = styled.button`
  width: 42px;
  height: 42px;
  background: #000;
  border-radius: 50%;

  .flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// button 형식으로 감싸지만
// 자식에 span으로 한번더 감싸야한다
export const SpeechBubble = styled.button`
  p {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #191919;
    border-radius: 50%;
    width: 42px;
    height: 42px;

    ::before {
      content: '';
      position: absolute;
      bottom: 2px;
      left: -2px;
      width: 0;
      height: 0;
      transform: rotate(-5deg);
      border-top: 10px solid transparent;
      border-bottom: 24px solid #191919;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
`;

// 작은 엑스 버튼
export const DelBtn = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border: 2px solid ${(props) => props.theme.colors.gray600};
  border-radius: 50rem;
  color: red;

  ::after,
  ::before {
    content: '';
    display: block;
    position: absolute;
    background: ${(props) => props.theme.colors.gray600};
    transform: rotate(45deg);
    border-radius: 50rem;
  }
  ::before {
    width: 0.8rem;
    height: 0.2rem;
  }
  ::after {
    height: 0.8rem;
    width: 0.2rem;
  }
`;
