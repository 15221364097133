import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'] {
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.txt200};
  }
  //input[type='text']:focus,
  //input[type='number']:focus {
  //  border: none;
  //  border-bottom: 5px solid #e5e5e5;
  //}
`;

export const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    /* width: calc(100% - 2rem); */
    width: 100%;
  }
`;
export const Title = styled.div<{ isActive: boolean }>`
  margin-bottom: 3rem;
  @media screen and (max-width: 768px) {
    display: ${(props) => (props.isActive ? null : 'none')};
  }
`;
export const SubWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .contentsWrap {
    position: relative;
    width: 100%;
  }
  .closeBtn {
    position: absolute;
    top: -0.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: ${(props) => props.theme.colors.txt400};
    gap: 0.6rem;
    svg {
      width: 1rem;
      height: 1.3rem;
      color: ${(props) => props.theme.colors.txt400};
      transform: rotate(180deg);
    }
    h2 {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 768px) {
    .contentsWrap {
      section {
        h2 {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;
export const SideNav = styled.div<{ isActive: boolean }>`
  width: 20%;

  > div {
    margin-bottom: 30px;
  }

  h5 {
    color: ${(props) => props.theme.colors.txt500};
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1.4rem;
    padding: 1rem 0;
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray500};
    padding-bottom: 2rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 768px) {
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    padding: 0 1.6rem;

    h5 {
      padding: 0 0 0.5rem;
      color: ${(props) => props.theme.colors.txt400};
      font-weight: 600;
      font-size: 1.6rem;
    }
  }
`;
// 마이페이지 전체 컴포넌트
export const Content = styled.section`
  width: 80%;

  .currentGroup {
    margin: 0 0 4rem;
  }

  .basicTable {
    .title + span {
      font-size: 1.2rem;
    }
  }

  .texthideTable {
    td {
      p {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
  }

  /* 테이블 반응형 공통 작업 */
  @media screen and (max-width: 768px) {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: ${(props) => props.theme.colors.gray100}; */
    transform: translateX(100%);
    transition: transform 0.4s linear;

    &.active {
      position: relative;
      transform: translateX(0);
    }

    .currentGroup {
      padding: 0 1.6rem;
    }

    .buttonList {
      width: 100%;
    }

    .headerWrap {
      z-index: 2;
      position: sticky;
      top: 0;
      left: 0%;
      padding: 2rem 1.6rem;
      margin: 0 0 2rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      background: ${(props) => props.theme.colors.gray100};

      > button {
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        text-align: left;
        svg {
          color: ${(props) => props.theme.colors.txt400};
          /* width: 1.6rem;
          height: 100%; */
        }
      }
      p {
        margin: 0 auto;
        padding: 0 2rem;
        color: ${(props) => props.theme.colors.txt500};
        font-size: 2.4rem;
        font-weight: 700;
      }
    }

    /* 베이직 테이블 스타일 타이틀등 묶고있는곳에 class */
    .basicTable {
      .title + span {
        font-size: 1.4rem;
        line-height: normal;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
      }
      td,
      th {
        width: 100%;
        padding: 1.5rem 1rem;
        text-align: left;
      }
      td {
        font-size: 1.7rem;
        div,
        input {
          font-size: 1.7rem;
        }
        button {
          font-size: 1.5rem;
          padding: 0.8rem 1.6rem;
        }
        label:has(input + span) {
          span {
            font-size: 1.6rem;
          }
        }
        input[type='text'] {
          font-size: 1.7rem;
          padding: 1rem;
        }
      }
      th {
        font-size: 1.4rem;
        background: ${(props) => props.theme.colors.gray100};
      }
    }

    /* 이메일주소 스타일 주의* tr에 class명을 직접 입력해줘야함 */
    .emailRow {
      td {
        div {
          display: grid;
          grid-template-columns: 1fr 0.1fr 1fr;
          gap: 1rem;
          > * {
            font-size: 1.7rem;
          }
        }
        input {
          width: 100%;
          max-width: unset;
          padding: 1rem;
          font-size: 1.6rem;
        }
        p {
          text-align: center;
        }
        select {
          position: relative;
          grid-column: 1/ -1;
          padding: 1rem;
          border-radius: 0.5rem;
          font-size: 1.5rem;
        }
      }
    }

    /* 번호입력 스타일 주의* tr에 class명을 직접 입력해줘야함 묶을부분은 div로감싸야함 */
    .numberRow {
      div {
        display: grid;
        grid-template-columns: 1fr 0.1fr 1fr 0.1fr 1fr;
      }
      input {
        width: 100%;
        max-width: unset;
        padding: 1rem;
        font-size: 1.6rem;
      }
      p {
        /* text-align: center; */
      }
      select {
        position: relative;
        padding: 1rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
      }
      .text {
        grid-column: 1/ -1;
        text-align: left;
        font-size: 1.5rem;
        margin: 1rem 0;
      }
    }

    /* 스크롤이 필요한 테이블의 경우 table을 div로 감싼후 scrollTable class명을 붙인다*/
    .scrollTable {
      white-space: nowrap;
      overflow: auto;
      table {
        margin: 0;
      }
      th,
      td {
        font-size: 1.5rem;
        padding: 1rem 2rem;
        :has(> input) {
          padding: 0.5rem;
          width: 2rem;
        }
        div,
        p,
        span,
        input {
          font-size: 1.5rem;
        }
      }
    }

    /* 버튼만있는 테이블 스타일 주의* tr에 class명을 직접 입력해줘야함 */
    .buttonTable {
      td,
      th {
        width: 100%;
        padding: 1.5rem 1rem;
        text-align: left;
      }
      td {
        font-size: 1.7rem;
        button {
          font-size: 1.5rem;
          padding: 1rem 1.6rem;
          width: 100%;
          height: auto;
        }
      }
      th {
        font-size: 1.4rem;
        background: ${(props) => props.theme.colors.gray100};
      }
    }

    /* 테이블 반응형이 필요한 경우 2개씩있는 테이블 
		thead는 th 2개 
		tbody에는 th, td 순서대로 각각 1개씩  */
    .texthideTable {
      th,
      td {
        font-size: 1.6rem;
        text-align: left;
      }
      th:nth-of-type(1) {
        display: none;
      }
      td {
        div {
          padding: 1rem;
        }
        p {
          display: block;
          padding: 1rem;
          margin: 0 0 1rem;
          background: ${(props) => props.theme.colors.gray300};
        }
      }
    }

    .select {
      select {
        position: relative;
        width: 100%;
        grid-column: 1/ -1;
        padding: 1rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .headerWrap {
      > button {
        svg {
          height: 1.8rem;
        }
      }
      p {
        font-size: 1.8rem;
      }
    }
  }
`;
