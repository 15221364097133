import { usePushNotification } from '@/apis/hooks/usePushNotification';
import { signInRecoil } from '@/recoil/atoms/signInAtom';
import IconBell from '@/styles/icons/IconBell';
import IconLogout from '@/styles/icons/IconLogout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as S from '../MobileHeader.style';

const EtcListHeader = () => {
  const navigate = useNavigate();
  const [, setSignInState] = useRecoilState(signInRecoil);
  /* 웹푸시 hooks */
  const pushHooks = usePushNotification();

  return (
    <>
      <S.HeaderLeft>
        <S.HeaderTitle>더보기</S.HeaderTitle>
      </S.HeaderLeft>
      <S.IconContainer>
        {/* 알림 아이콘 */}
        {/* <S.HeaderButtonIcon>
          <IconBell />
        </S.HeaderButtonIcon> */}

        {/* 로그아웃 버튼 */}
        <S.HeaderButtonIcon
          onClick={() => {
            setSignInState(false);
            navigate('/mobile/mobileSignin');
            pushHooks.unsubscribe();
          }}
        >
          <IconLogout />
        </S.HeaderButtonIcon>
      </S.IconContainer>
    </>
  );
};
export default EtcListHeader;
