import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useAddressBookFunBtn } from '@/components/hooks/customs/addressBook/useAddressBookFunBtn';
import { useTopGroup } from '@/components/hooks/customs/addressBook/useTopGroup';
import { checkGroupListState } from '@/recoil/atoms/addressList';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import {
  editModeRecoil,
  isLongPressRecoil,
  mobileBuddyListRecoil,
  windowWidthRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import { detectEnvUtils } from '@/shared/util/detectEnvUtils';
import * as CBS from '@/styles/checkBox.styles';
import IconUpadateContact from '@/styles/icons/IconUpadateContact';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as S from '../GroupList.styles';
import { IGroupListTop } from './GroupListTop.types';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

/**
 * @title GroupList.tsx 컴포넌트의 TOP 컴포넌트
 *
 * @author 정휘학
 * @since 2024.03.15
 * */
const GroupListTopIndex = (props: IGroupListTop) => {
  const groupTopHooks = useTopGroup();
  const setMobileBuddyListState = useSetRecoilState(mobileBuddyListRecoil);
  const windowWidth = useRecoilValue(windowWidthRecoil);
  const { confirmModal, warningModal } = useModalHook();

  /* 주소록 체크박스 상태 관리 recoil */
  const [mainTableCheckDataFormatR, setMainTableCheckDataFormatR] = useRecoilState(mainTableCheckDataFormatRecoil);
  const [checkedGroupListS, setCheckedGroupListS] = useRecoilState(checkGroupListState);
  const [editMode, setEditMode] = useRecoilState(editModeRecoil);
  const hooks = useAddressBookFunBtn();
  const isLongPress = useRecoilValue(isLongPressRecoil);

  return (
    <>
      {windowWidth <= 768 && (
        <section
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1.5rem',
            padding: '0 1rem',
            // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          {!editMode && (
            <h2
              style={{
                fontSize: '28px',
                fontWeight: '700',
                color: '#333',
                gap: '6px',
              }}
            >
              {/* 주소록 - <LuFolderOpen /> 그룹 */}
              주소록
            </h2>
          )}

          {!editMode && (
            <button
              onClick={() => {
                setEditMode(true);
              }}
              style={{
                fontSize: '16px',
                display: 'block',
                color: `black`,
              }}
            >
              편집
            </button>
          )}

          {editMode && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem',
              }}
            >
              <button
                style={{ fontSize: '16px' }}
                onClick={() => {
                  setEditMode(false);
                  setCheckedGroupListS([]);
                  setMainTableCheckDataFormatR(null);
                }}
              >
                취소
              </button>
              <h2 style={{ fontSize: '24px', fontWeight: '700', color: '#333', gap: '6px' }}>
                {checkedGroupListS.length}개 선택됨
              </h2>
              <button
                style={{ color: 'red', fontSize: '16px' }}
                onClick={() => {
                  hooks.deleteGroupHook.deleteGroupOnClickHandler();
                }}
              >
                삭제
              </button>
            </div>
          )}
        </section>
      )}

      <S.Top className="list">
        <S.TopWrap>
          {!groupTopHooks.groupSearchTextS &&
            (windowWidth > 768 ? (
              <CBS.CheckBoxInput
                type="checkBox"
                onChange={(e) => props.toggleSelectAll(e)}
                checked={groupTopHooks.groupSearchTextS ? false : props.selectAll}
                disabled={groupTopHooks.groupSearchTextS ? true : false}
              />
            ) : (
              <S.StyledCheckbox
                type="checkBox"
                onChange={(e: any) => props.toggleSelectAll(e)}
                checked={groupTopHooks.groupSearchTextS ? false : props.selectAll}
                disabled={groupTopHooks.groupSearchTextS ? true : false}
              />
            ))}

          <S.GroupAll
            onClick={(e) => {
              // props.onClickGroupNm(null);
            }}
          >
            <S.GroupAllName
              onClick={(e) => {
                windowWidth > 768 ? props.onClickGroupNm(null) : e.stopPropagation();
                if (!isLongPress) {
                  // isLongPress가 true이면 클릭을 막음
                  if (!editMode) {
                    props.onClickGroupNm(null);
                    setMobileBuddyListState(true);
                  }
                }
              }}
            >
              전체그룹
            </S.GroupAllName>
            <div className="menuTab">
              {/* 추가 */}
              <button onClick={() => groupTopHooks.onClickAddGroupBtn()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path
                    d="M16 8.5C16 8.66576 15.9342 8.82473 15.8169 8.94194C15.6997 9.05915 15.5408 9.125 15.375 9.125H9.125V15.375C9.125 15.5408 9.05915 15.6997 8.94194 15.8169C8.82473 15.9342 8.66576 16 8.5 16C8.33424 16 8.17527 15.9342 8.05806 15.8169C7.94085 15.6997 7.875 15.5408 7.875 15.375V9.125H1.625C1.45924 9.125 1.30027 9.05915 1.18306 8.94194C1.06585 8.82473 1 8.66576 1 8.5C1 8.33424 1.06585 8.17527 1.18306 8.05806C1.30027 7.94085 1.45924 7.875 1.625 7.875H7.875V1.625C7.875 1.45924 7.94085 1.30027 8.05806 1.18306C8.17527 1.06585 8.33424 1 8.5 1C8.66576 1 8.82473 1.06585 8.94194 1.18306C9.05915 1.30027 9.125 1.45924 9.125 1.625V7.875H15.375C15.5408 7.875 15.6997 7.94085 15.8169 8.05806C15.9342 8.17527 16 8.33424 16 8.5Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  />
                </svg>
              </button>
              {/* 검색 */}
              {/* TODO - 검색후 체크박스, 체크목록 관리 */}
              <button onClick={() => groupTopHooks.onClickSearchGroupBtn()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path
                    d="M12.6667 12.6667L16 16M1 7.66667C1 9.43478 1.70238 11.1305 2.95262 12.3807C4.20286 13.631 5.89856 14.3333 7.66667 14.3333C9.43478 14.3333 11.1305 13.631 12.3807 12.3807C13.631 11.1305 14.3333 9.43478 14.3333 7.66667C14.3333 5.89856 13.631 4.20286 12.3807 2.95262C11.1305 1.70238 9.43478 1 7.66667 1C5.89856 1 4.20286 1.70238 2.95262 2.95262C1.70238 4.20286 1 5.89856 1 7.66667Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </S.GroupAll>
        </S.TopWrap>
      </S.Top>
    </>
  );
};

export default GroupListTopIndex;
