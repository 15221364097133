import { useSignUpForm } from '@/components/hooks/customs/signUp/useSignUpForm';
import * as SS from '@/styles/selectBox.styles';
import React from 'react';

import BaseButton from '@/components/Atom/BaseButton';
import BaseInput from '@/components/Atom/BaseInput';
import BaseSelect from '@/components/Atom/BaseSelect';
import SignUpProcess from '@/components/common/SignUpLayout/SignUpProcess';
import {
  Label,
  PhoneGroup,
  Row,
  SignCustomButtonWrap,
  SignUpContainer,
  SignUpEmailInputWrap,
  SignUpEmailSpan,
  SignUpFormWrapper,
  SignUpInputForm,
  SignUpInputSpan,
  SignUpPhoneInputWrap,
  SignUpSubTitle,
  TermLine,
  ValidationError,
  ValidationMessage,
  ValidationNumber,
} from './MobileSignUpForm.style';

const MobileSignUpForm = () => {
  const hooks = useSignUpForm();

  let validationMessage;
  if (hooks.errors?.selectEmailOne) {
    validationMessage = <ValidationError>{hooks.errors.selectEmailOne.message}</ValidationError>;
  } else if (hooks.errors?.selectEmailTwo) {
    validationMessage = <ValidationError>{hooks.errors.selectEmailTwo.message}</ValidationError>;
  } else {
    null;
    // validationMessage = <ValidationError>&apos;</ValidationError>;
  }

  return (
    <SignUpContainer>
      <SignUpFormWrapper>
        <SignUpProcess process={hooks.process} />
        <SignUpSubTitle>
          서울이동통신은 회원님의 개인정보를 안전하게 보호하고 있으며, 회원님의 동의없이 공개되거나 제3자에게 제공되지
          않습니다. <br />
          <br />
          *로 표기된 값은 필수 값 입니다.
        </SignUpSubTitle>
        <TermLine />
        <SignUpInputForm>
          <Row className="rowId">
            <Label>
              아이디 <span>:</span>
            </Label>
            <SignUpPhoneInputWrap>
              <BaseInput type="number" value={hooks.watch('numOne')} disabled {...hooks.register('numOne')} />
              <SignUpInputSpan>-</SignUpInputSpan>
              <BaseInput type="number" value={hooks.watch('numTwo')} disabled {...hooks.register('numTwo')} />
              <SignUpInputSpan>-</SignUpInputSpan>
              <BaseInput type="number" value={hooks.watch('numThree')} disabled {...hooks.register('numThree')} />
            </SignUpPhoneInputWrap>
          </Row>
          <Row className="rowName">
            <Label>
              이름 <span>:</span>{' '}
            </Label>
            <BaseInput type="text" disabled value={hooks.watch('name')} {...hooks.register('name')} />
          </Row>
          <Row className="rowPasswoerd">
            <Label>
              *비밀번호 <span>:</span>{' '}
            </Label>
            <BaseInput
              type="password"
              placeholder="비밀번호"
              // onChange={(e) => handleSignUpFormChange({ value: e.target.value, onChange: password.onChange })}
              value={hooks.watch('password')}
              {...hooks.register('password')}
            />
            {hooks.errors?.password ? (
              <ValidationError>{hooks.errors.password?.message}</ValidationError>
            ) : (
              <ValidationMessage>최소 8자, 최대 16자 이내로 입력해주세요 (문자, 숫자 조합)</ValidationMessage>
            )}
          </Row>
          <Row className="rowPasswordCheck">
            <Label>
              *비밀번호 확인 <span>:</span>{' '}
            </Label>
            <BaseInput
              type="password"
              placeholder="비밀번호 확인"
              value={hooks.watch('isPassword')}
              {...hooks.register('isPassword')}
            />
            <ValidationError>{hooks.errors?.isPassword ? hooks.errors.isPassword?.message : ''}</ValidationError>
          </Row>

          <Row className="rowEmail">
            <Label>
              *이메일 주소 <span>:</span>{' '}
            </Label>
            <SignUpEmailInputWrap>
              <div className="emailBox">
                <BaseInput type="text" value={hooks.watch('selectEmailOne')} {...hooks.register('selectEmailOne')} />
                <SignUpEmailSpan>@</SignUpEmailSpan>
                <BaseInput type="text" value={hooks.watch('selectEmailTwo')} {...hooks.register('selectEmailTwo')} />
                <div className="select">
                  <BaseSelect
                    options={hooks.emailData}
                    value={hooks.watch('selectEmailTwo')}
                    onChange={hooks.handleSelectEmail}
                  />
                </div>
              </div>
            </SignUpEmailInputWrap>
            {validationMessage}
          </Row>
          {/* 015 번호발급 로직 */}
          <Row className="rowNumber">
            <Label>
              *015 번호 <span>:</span>
            </Label>
            <SignUpPhoneInputWrap>
              <BaseInput type="number" value={hooks.watch('num015One')} disabled {...hooks.register('num015One')} />
              <SignUpInputSpan>-</SignUpInputSpan>
              <input
                type="text"
                maxLength={hooks.corporation015S ? 3 : 4}
                placeholder={hooks.corporation015S ? 'XXX' : '8504'}
                value={hooks.inputValue.firstPart}
                name="firstPart"
                disabled
                onChange={hooks.handleInputChange}
              />
              <SignUpInputSpan>-</SignUpInputSpan>
              <input
                type="text"
                maxLength={4}
                placeholder="XXXX"
                value={hooks.inputValue.secondPart}
                name="secondPart"
                onChange={hooks.handleInputChange}
              />

              {/* 추천번호 보기 클릭후 numberGroup에 active가 붙으면 펼쳐짐 */}
              <PhoneGroup className="phoneGroup">
                <button
                  className={`btn ${hooks.signUpSelectOpen ? 'open' : 'close'}`}
                  type="button"
                  onClick={() => hooks.sug015NumViewHandle('signUp')}
                >
                  <p>추천번호 {hooks.signUpSelectOpen ? '닫기' : '보기'}</p>
                </button>

                <SS.SelectBox className={hooks.signUpSelectOpen ? 'numberGroup active' : 'numberGroup'}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      hooks.generateFullNumber;
                    }}
                  >
                    <div className="recommendationNumberBox">
                      <ul className="recommenationNumber">
                        {hooks.signUpSelectOpen &&
                          hooks.sug015List &&
                          hooks.sug015List.map((el: any) => (
                            <li key={el}>
                              <button
                                type="button"
                                id={el}
                                name="suggest"
                                value={el}
                                onClick={(event: any) => hooks.onClickRecommendNumber(event)}
                              >
                                {hooks.addHyphen(el)}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </form>
                </SS.SelectBox>
              </PhoneGroup>

              {hooks.errorMessage ? (
                // <p style={hooks.messageStyle ? { color: 'green' } : { color: 'red' }}>{hooks.errorMessage}</p>
                <ValidationNumber className={`validationNumber ${hooks.messageStyle ? 'green' : 'red'}`}>
                  {hooks.errorMessage}
                </ValidationNumber>
              ) : (
                <ValidationNumber>희망하는 번호를 입력해주세요</ValidationNumber>
              )}
            </SignUpPhoneInputWrap>
          </Row>
        </SignUpInputForm>
        <TermLine />
        <SignCustomButtonWrap>
          <BaseButton
            className="dark"
            fontWeight="bold"
            onClick={() => {
              return hooks.navigate('/mobile/mobileSignin');
            }}
          >
            취소
          </BaseButton>
          {/* <Link to="/signin"> */}
          <BaseButton
            type="submit"
            fontWeight="bold"
            disabled={hooks.errorMessage !== hooks.checkedMessage.success || hooks.isLoading ? true : false}
            onClick={hooks.handleSubmit(hooks.onSubmit)}
          >
            회원가입
          </BaseButton>
          {/* </Link> */}
        </SignCustomButtonWrap>
        {/* </form> */}
      </SignUpFormWrapper>
    </SignUpContainer>
  );
};
export default MobileSignUpForm;
