import styled from 'styled-components';

export const SignUpContainer = styled.div`
  /* background: #f5f5f5; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 4rem;
  }
`;

export const SignUpFormWrapper = styled.div`
  position: relative;
  max-width: 1080px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  padding: 0 5%;
  box-sizing: border-box;
  overflow-y: auto;

  &::before {
    content: '서울이동통신 회원가입';
    display: block;
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 582;
    line-height: normal;
  }

  label {
    margin: 1rem 0 2rem;
    font-size: 1.2rem;
  }
  input {
    cursor: pointer;
  }
`;

export const TermsHeading = styled.p`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${(props) => props.theme.colors.txt500};
  margin: 0 auto 0.9vw auto;
`;
export const TermsSubHeading = styled.p`
  width: 100%;
  color: ${(props) => props.theme.colors.txt400};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.83vw;
`;

export const TermLine = styled.hr`
  width: 100%;
  margin-bottom: 1.36vw;
`;

export const AgreementWrap = styled.ul`
  margin-bottom: 4.5vw;
  > li {
    margin-bottom: 3rem;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const AgreementButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
  box-sizing: border-box;
  /* width: 500px; */
  margin: 0 auto;
  margin-bottom: 4vw;
`;
