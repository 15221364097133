import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import React from 'react';
import * as S from '../MobileHeader.style';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { addStringValueRecoil, mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { chatUserInfoRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { useMutation } from '@tanstack/react-query';
import { postAddGroup } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';

const AddressGroupAddHeader = () => {
  const navigate = useNavigate();
  const [addStringValueS, setAddStringValueS] = useRecoilState(addStringValueRecoil);

  const { mutate: addGroupMutate } = useMutation(postAddGroup);
  const cacheHooks = groupListCacheUpdate();

  const { warningModal } = useModalHook();

  //주소록 새로 만들기
  const onClickCreateGroup = () => {
    const newGroup = {
      groupNm: addStringValueS,
    };

    addGroupMutate(newGroup, {
      onSuccess: (data: any) => {
        // 그룹 추가 성공시 cache update
        cacheHooks.handleGroupAddCacheUpdate(data);
        setAddStringValueS('');
        navigate(-1);
      },
      onError: (error: any) => {
        setAddStringValueS('');
        warningModal('그룹 등록', `에러 발생: ${error.message}`, true);
        navigate(-1);
      },
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <S.HeaderLeft className="messageNewAddHeader">
        <S.HeaderButtonIcon
          onClick={() => {
            navigate(-1);
          }}
        >
          <IconArrowLongLeft />
        </S.HeaderButtonIcon>
        <S.HeaderTitle>폴더 추가</S.HeaderTitle>
      </S.HeaderLeft>
      <S.IconContainer>
        <S.HeaderButtonFont onClick={onClickCreateGroup} className={`${addStringValueS && 'active'}`}>
          확인
        </S.HeaderButtonFont>
      </S.IconContainer>
    </>
  );
};
export default AddressGroupAddHeader;
