import React from 'react';
import * as S from './SubscriptionGuide.styles';
import { SERVICE_NAME } from '@/shared/constants';

interface ISubscriptionGuide {
  setOpen015SubPage: any;
  initial015SubPage: any;
  say015SubHandle: any;
}

// const SubscriptionGuide = ({ setOpen015SubPage, initial015SubPage, say015SubHandle }: ISubscriptionGuide) => {
const SubscriptionGuide = () => {
  return (
    <S.SubscriptionGuideWrap>
      <div className="subGuide">
        <h2>
          AI Contact Center
          <br />
          나만의 AI 삐삐
          <br />
          {SERVICE_NAME} 서비스 시작하기
        </h2>
        {/* <p>
          신규 가입 혜택으로,
          <br />
          <br />
          015번호를 회신번호로 지정하여
          <br />
          <br />
          SMS 100건을 무료로 발송해보세요
        </p> */}
      </div>
      <span className="rowSpan" />
      {/* <div>
        <button onClick={() => say015SubHandle(setOpen015SubPage, initial015SubPage.third)}>
          Say 015 서비스 가입하기
        </button>
      </div> */}
    </S.SubscriptionGuideWrap>
  );
};

export default SubscriptionGuide;
