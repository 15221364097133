import styled from 'styled-components';

export const AddressBuddyListContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 5.8rem;
`;

export const SearchContainer = styled.div`
  z-index: 2;
  position: fixed;
  top: 4.8rem;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 1.4rem;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 1rem 1.4rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  background-color: ${(props) => props.theme.colors.gray100};
  ::placeholder {
    color: ${(props) => props.theme.colors.disable};
  }
`;

export const MessageContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const BuddyListContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1.4rem;
  gap: 1rem;

  transition: background-color 0.4s ease-in-out; // 듀레이션 0.4초 추가
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  cursor: pointer;
`;

export const BuddyImg = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: ${(props) => props.theme.colors.chatIconBack};
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.txt100}99;
  font-size: 4rem;
  font-weight: 900;
  overflow: hidden;
  svg {
    font-size: 3.4rem;
    margin: 1.2rem 0 0;
  }
`;

export const BuddyContents = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 700;

  > h3 {
    display: inline-block;
    max-width: 12ch; /* 최대 7글자로 나오게 ... 포함 */
    white-space: nowrap;
    overflow: hidden; /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.txt500};
    font-weight: 600;
  }
  p {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt300};
  }
`;
