import { deleteAddressBinAllList, postAdressBinAllList } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { mainTableCacheUpdate } from '@/components/cache/mainTableCacheUpdate';
import { recyclebinCacheUpdate } from '@/components/cache/recyclebinCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { binSearchTextState, binSelectValueState, binSuccessSearchRecoil } from '@/recoil/atoms/addressList';
import { fullScreenSpinLoadingRecoil } from '@/recoil/atoms/atoms';
import { BuddyData } from '@/types/index.types';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const useRecycleBin = () => {
  const [recycleBinOpen, setRecycleBinOpen] = useState<boolean>(false);
  const [binAllBtnState, setBinAllBtnState] = useState<boolean>(false); // 버튼 중복 클릭 방지
  const [, setBinSelectValue] = useRecoilState(binSelectValueState);
  const [, setBinSearchText] = useRecoilState(binSearchTextState);
  // 검색 성공 후 검색어 담긴 RECOIL
  const binSuccessSearchS = useRecoilValue(binSuccessSearchRecoil);

  // api 호출중 로딩 상태 표시할 recoil
  const setFullScreenSpinLoadingR = useSetRecoilState(fullScreenSpinLoadingRecoil);

  const cacheRHooks = recyclebinCacheUpdate();
  const cacheGHooks = groupListCacheUpdate();
  const cacheMHooks = mainTableCacheUpdate();

  const { confirmModal, successModal, warningModal } = useModalHook();

  // 휴지통 전체 복원
  const { mutate: postAdressBinAllListM, isLoading: postAdressBinAllListIsLoading } = useMutation(
    postAdressBinAllList,
    {
      onSuccess: (data: BuddyData[]) => {
        setBinAllBtnState(false);
        cacheRHooks.recyclebinAllCleanCacheUpdate();
        cacheGHooks.handleRestoreCacheUpdate(data);
        cacheMHooks.mainTableRestoreCacheUpdate(data);
        successModal('연락처 복원', '휴지통의 전체 연락처를 복원했습니다.', true);
      },
      onError: (error: any) => {
        setBinAllBtnState(false);
        warningModal('연락처 복원', error.response.message, true);
      },
    },
  );

  // 휴지통 비우기
  const { mutate: deleteAddressBinAllListM, isLoading: deleteAddressBinAllListIsLoading } = useMutation(
    deleteAddressBinAllList,
    {
      onSuccess: (data: number) => {
        setBinAllBtnState(false);
        cacheRHooks.recyclebinAllCleanCacheUpdate();
        cacheGHooks.handleBinDeleteCacheUpdate(data);
        successModal('연락처 삭제', '휴지통의 전체 연락처를 삭제했습니다.', true);
      },
      onError: (error: any) => {
        setBinAllBtnState(false);
        warningModal('연락처 삭제', error.response.message, true);
      },
    },
  );

  // ==============================
  // 휴지통 전체 삭제, 복원 중 일 때 로딩 표시할 useEffect hook
  // ==============================
  useEffect(() => {
    if (postAdressBinAllListIsLoading || deleteAddressBinAllListIsLoading) {
      setFullScreenSpinLoadingR(true);
    } else {
      setFullScreenSpinLoadingR(false);
    }
  }, [postAdressBinAllListIsLoading, deleteAddressBinAllListIsLoading]);

  // 휴지통 토글
  const recycleBinClickHandle = () => {
    setBinSearchText('');
    setBinSelectValue('name');
    setRecycleBinOpen((prev) => !prev);
  };
  ``;

  // 휴지통 전체복원 클릭
  const restoreBinAllListOnClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setBinAllBtnState(true);
    confirmModal(
      async () => postAdressBinAllListM(binSuccessSearchS),
      '연락처 복원',
      '휴지통의 전체 연락처를 복원하시겠습니까?',
      true,
      undefined,
      async () => setBinAllBtnState(false),
    );
  };

  // 휴지통 비우기 클릭
  const deleteBinAllListOnClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setBinAllBtnState(true);
    confirmModal(
      async () => deleteAddressBinAllListM(binSuccessSearchS),
      '연락처 삭제',
      '휴지통의 전체 연락처를 영구 삭제하시겠습니까?',
      true,
      undefined,
      async () => setBinAllBtnState(false),
    );
  };

  return {
    deleteBinAllListOnClick,
    recycleBinClickHandle,
    recycleBinOpen,
    restoreBinAllListOnClick,
    binAllBtnState,
    postAdressBinAllListIsLoading,
  };
};
