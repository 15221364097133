import styled from 'styled-components';

interface IMenuCard {
  activeState: boolean;
}

export const TopWrapper = styled.div``;

export const CashCharge = styled.div``;

export const Wrapper = styled.div`
  max-width: 1200px;
  > h2 {
    margin-bottom: 4rem;
  }
  .par {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const MenuWrapper = styled.div`
  width: 100%;
  max-width: 675px;
  display: flex;
  justify-content: center;
  margin: 0 auto 3.3rem;
  padding: 1.2rem;
  gap: 1.2rem;
  background: ${(props) => props.theme.colors.gray100};
  border-radius: 1rem;
  @media screen and (max-width: 1440px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const MenuCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 25%;
  padding: 1.2rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  text-align: center;
  background-color: transparent;
  color: ${(props) => props.theme.colors.txt400};

  cursor: pointer;
  .title {
    margin: 0 0 1rem;
  }
  .subTitle {
    color: ${(props) => props.theme.colors.txt200};
    font-size: 1.3rem;
  }
  &.active {
    background-color: ${(props) => props.theme.colors.grayButton};
    color: ${(props) => props.theme.colors.txt100};
  }
  .title:has(+ .subTitle:empty) {
    margin: 0;
  }

  @media screen and (max-width: 1440px) {
    width: calc(50% - 3rem);
  }
  @media screen and (max-width: 768px) {
    min-height: 6.6rem;
    .title {
      font-size: 1.7rem;
    }
    .subTitle {
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const MenuSubTitle = styled.span`
  font-size: 1.3rem;
  color: ${(props: IMenuCard) => (props.activeState ? '#fff' : '#333')};
`;

// 금액 박스
export const AmountWrapper = styled.div`
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  span.line {
    display: block;
    margin: 6rem auto 2rem;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.gray600};
  }
  /* 금액 왼쪽 */
  .leftGroup {
    width: 70%;
    background: ${(props) => props.theme.colors.white};
    padding: 3rem 8rem;
    border-radius: 1rem;
    box-shadow: 0px 1rem 1rem 0px rgba(0, 0, 0, 0.1);

    /* 금액 직접 입력 */
    .amountEnter {
      position: relative;
      font-weight: 500;
      color: ${(props) => props.theme.colors.txt500};
      margin-bottom: 1.6rem;
      > p {
        display: none;
        margin-bottom: 1rem;
        line-height: 1.4em;
        font-size: 1.2rem;
        color: ${(props) => props.theme.colors.txt200};
      }
      > div {
        position: relative;
      }
      span {
        position: absolute;
        top: 50%;
        right: 2rem;
        font-size: 2rem;
        transform: translateY(-50%);
        display: block;
      }
      .amount {
        width: 100%;
        height: 60px;
        padding: 2rem 4rem 2rem 2rem;
        font-size: 2rem;
        outline: transparent;
        border: 2px solid ${(props) => props.theme.colors.main};
        border-radius: 1rem;
        background: ${(props) => props.theme.colors.white};
        :focus {
          border-color: ${(props) => props.theme.colors.main};
        }
        /* input 입력받지않았을때 스타일*/
        :placeholder-shown {
          border-color: transparent;
          background-color: ${(props) => props.theme.colors.gray200};
        }
        :placeholder-shown + span {
          display: none;
        }
      }
    }

    /* 금액 선택 */
    .sumGroup {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1.6rem 2.4rem;
      li {
        width: 100%;
        background: ${(props) => props.theme.colors.gray200};
        border-radius: 1rem;
        input {
          display: none;
        }
        input:checked + div {
          background: ${(props) => props.theme.colors.white};
          border-color: ${(props) => props.theme.colors.main};
          color: ${(props) => props.theme.colors.main};
          svg {
            path {
              /* fill: ${(props) => props.theme.colors.main};; */
              stroke: ${(props) => props.theme.colors.main};
            }
          }
        }
        div {
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 6rem;
          padding: 1.6rem;
          font-size: 2.2rem;
          border-radius: 1rem;
          border: 2px solid transparent;
          p {
            text-align: right;
            /* width: calc(100% - 30px); */
            width: 100%;
            small {
              font-size: 1.3rem;
              color: ${(props) => props.theme.colors.txt400};
            }
          }
        }
      }
    }

    /* 금액 안내 */
    .priceGuide {
      h3 {
        font-size: 1.4rem;
        font-weight: 600;
      }
      .flexBox {
        display: flex;
      }
      .left {
        font-size: 1.2rem;
        color: ${(props) => props.theme.colors.txt400};
      }
      .right {
        font-size: 1.4rem;
        table {
          width: 100%;
          thead {
            color: ${(props) => props.theme.colors.txt500};
            font-weight: 700;
            background: ${(props) => props.theme.colors.gray100};
          }
          th,
          td {
            border: 1px solid ${(props) => props.theme.colors.gray200};
            padding: 1rem;
            text-align: center;
            color: ${(props) => props.theme.colors.txt500};
          }
        }
      }
      .etcPriceGuide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 1.2rem;
        margin-top: 1rem;
        gap: 1rem;
        br {
          display: none;
        }
      }
    }
  }

  /* 금액 오른쪽 */
  .rightGroup {
    position: relative;
    width: 28%;

    .wrapper {
      padding: 3rem;
      border-radius: 10px;
      background: ${(props) => props.theme.colors.white};
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);

      .paymentGroup {
        h4 {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1rem;
          color: ${(props) => props.theme.colors.gray800};
        }
        > p {
          font-size: 1.4rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors.gray600};
          margin-bottom: 3.5rem;
        }
      }
      .money {
        /* margin-bottom: 16rem; */
        h3 {
          font-size: 3rem;
          font-weight: 700;
          margin-bottom: 1rem;
          color: ${(props) => props.theme.colors.main};
          span {
            font-size: 0.5em;
            margin-left: 5px;
            color: ${(props) => props.theme.colors.gray800};
          }
        }
        p {
          color: ${(props) => props.theme.colors.gray600};
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
      /* SMS 발송 금액 안내 */
      .smsBox {
        margin: 1rem 0 0;
        p {
          color: ${(props) => props.theme.colors.gray600};
          font-size: 1.4rem;
          margin-bottom: 1rem;
          :last-of-type {
            margin-bottom: 0;
          }
          span {
            font-size: 1.6rem;
            color: ${(props) => props.theme.colors.main};
            font-weight: 700;
          }
        }
      }
      /* point */
      .point {
        margin-bottom: 4.7rem;
        h3 {
          color: ${(props) => props.theme.colors.txt500};
        }
      }
      .bottom {
        /* 무통장 - 입금자명 */
        .depositWrap {
          margin-bottom: 2rem;
          label {
            display: block;
            margin-bottom: 1rem;
          }
          p {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
          }
          input {
            font-size: 1.4rem;
            width: 100%;
            height: 3.6rem;
            border: 0;
            background: ${(props) => props.theme.colors.gray200};
            padding: 1rem;
            border-radius: 5px;
          }
        }
        /* 입금자명 Error */
        .errorRed {
          margin-top: 0.5rem;
          text-align: right;
          color: red;
        }
        .depositName {
          width: 100%;
          /* position: absolute;
        top: 50%; */
          margin-bottom: 2rem;
          > div {
            display: flex;
            align-items: center;
            width: 100%;
            p {
              font-size: 1.4rem;
              font-weight: 600;
              margin-right: 1rem;
            }
            input {
              width: calc(100% - 6rem);
              border: 0;
              background-color: ${(props) => props.theme.colors.gray200};
              height: 32px;
              border-radius: 6px;
              padding: 10px;
              :focus {
                outline: none;
              }
            }
          }
        }
        /* 무통장 - 문자 수신체크 */
        .messageCheck {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          color: ${(props) => props.theme.colors.txt400};
          margin-bottom: 1rem;
          input {
            cursor: pointer;
            margin: 0;
            margin-right: 5px;
            width: 15px;
            height: 15px;
          }
          span {
            color: ${(props) => props.theme.colors.txt200};
            margin-left: 5px;
          }
        }
        button {
          cursor: pointer;
          width: 100%;
          /* height: 48px; */
          padding: 1.4rem 1rem;
          font-size: 1.6rem;
          border-radius: 10px;
          background: ${(props) => props.theme.colors.main};
          color: ${(props) => props.theme.colors.txt100};
        }
      }
      .messageReceive {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: ${(props) => props.theme.colors.txt400};
        margin-bottom: 0.6rem;

        .txt {
          span {
            color: ${(props) => props.theme.colors.txt200};
          }
        }
      }
    }

    /* 포인트 적립 퍼센트 */
    .earnTxt {
      font-size: 1.2rem;
      color: ${(props) => props.theme.colors.txt400};
      padding: 2.4rem 1.7rem;
      line-height: 1.6em;
      .top {
        font-weight: 700;
        margin-bottom: 3rem;
      }
    }
  }

  .row {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.gray500};
    margin: 4.7rem auto;
  }

  @media screen and (max-width: 1200px) {
    .leftGroup {
      padding: 3rem;
      .sumGroup {
        grid-template-columns: 1fr 1fr;
      }
      span.line {
        margin: 2rem auto;
      }
      .priceGuide {
        .flexBox {
          flex-direction: column;
        }
        .group {
          width: 100%;
        }
        .left {
          margin-bottom: 1rem;
        }
        .right {
          table {
            th,
            td {
              border: 1px solid ${(props) => props.theme.colors.gray200};
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    .leftGroup {
      width: 100%;
      margin-bottom: 3rem;
    }
    .rightGroup {
      width: 100%;
      .money {
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .leftGroup {
      padding: 1rem;
      .amountEnter {
        margin-bottom: 1rem;
        > p {
          display: block;
        }
        .amount {
          font-size: 1.6rem;
          height: 5rem;
          ::placeholder {
            content: '충전금액 입력';
          }
        }
        span {
          font-size: 1.6rem;
        }
      }
      .sumGroup {
        gap: 1rem;
        li {
          div {
            font-size: 1.7rem;
            flex-direction: column;
            height: auto;
            padding: 1.8rem 1rem 1.8rem 0.5rem;
            p {
              width: 100%;
              font-weight: 700;
            }
          }
        }
      }
      .priceGuide {
        .etcPriceGuide {
          line-height: 1.4em;
          font-size: 1.3rem;
          br {
            display: block;
          }
        }
      }
    }

    .rightGroup {
      .bottom {
        button {
          height: 5rem;
        }
      }
      .wrapper {
        .bottom {
          .depositWrap {
            p {
              font-size: 1.6rem;
              margin: 0 0 1rem;
            }
            input {
              font-size: 1.7rem;
              height: auto;
              padding: 1rem;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 540px) {
    .leftGroup {
      /* .sumGroup {
        li {
          div {
          }
        }
      } */
    }
  }
`;
