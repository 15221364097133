import React from 'react';
import TaxInvoiceList from '@/components/Organism/Charge/TaxInvoiceList';

const MobileEtcTaxCashReceiptsDetail = () => {
  return (
    <>
      <TaxInvoiceList />
    </>
  );
};
export default MobileEtcTaxCashReceiptsDetail;
