// eslint-disable-next-line import/no-cycle
import { instance } from '@/shared/lib/clientAxios';

/**
 * 전송결과 삭제 API: POST
 */
interface IDeleteTransferMsg {
  prepayPayNo: string;
  callback: string;
}

export const deleteTransferMsg = async ({ prepayPayNo, callback }: IDeleteTransferMsg) => {
  const params = [{ prepayPayNo, callback }];
  try {
    // const response = await instance.post(`/deleteResult?prepayPayNo=${prepayPayNo}`);
    const response = await instance.post(
      localStorage.getItem('callBack') === '01067873947' ? '/mcmp/result/direct/delete' : '/deleteResult',
      params,
    );
    return response.data;
  } catch (error) {
    return '';
  }
};

export const reSendMsgApi = async (prepayNo: string) => {
  try {
    await instance.post(`/reSendMsg`, prepayNo);
  } catch (error) {
    console.error(error);
  }
};
