//아이콘
import { IoCloseOutline } from 'react-icons/io5'; // 닫기 버튼
import { IoCloseSharp } from 'react-icons/io5'; // 닫기 버튼
import { FaImage } from 'react-icons/fa'; // 이미지 버튼
import { IoSearch } from 'react-icons/io5'; // 검색 버튼
import { FaEdit } from 'react-icons/fa'; // 수정 버튼
import { TbMessageDown } from 'react-icons/tb'; // 메세지 헤더

//스타일
import styled from 'styled-components';

export const ModalContainer = styled.div`
  height: 100%;
  padding: 3rem 0 2rem;
  background-color: ${(props) => props.theme.colors.gray100};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
`;

export const Inner = styled.div`
  padding: 0 1.6rem;
`;

export const HeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    cursor: pointer;
    color: ${(props) => props.theme.colors.gray600};
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`;

export const HeaderTitle = styled.div`
  font-size: ${(props) => props.theme.fontSize.title20};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.txt500};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  svg {
    width: 2.2rem;
    height: 2.2rem;
    color: ${(props) => props.theme.colors.main};
  }
`;

export const CloseBtn = styled(IoCloseOutline)`
  font-size: 32px;
  color: ${(props) => props.theme.colors.gray600};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const MessageInputSection = styled.section<{ isEditing?: boolean }>`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: ${(props) => props.isEditing && `1px solid ${props.theme.colors.main}`};
`;

export const MessageTitleInput = styled.input`
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: ${(props) => props.theme.fontSize.title16};
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  font-size: 1.4rem;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.placeholder};
  }
`;

export const MessageBodyInput = styled.textarea`
  width: 100%;
  padding: 0.6rem 1rem;
  font-size: ${(props) => props.theme.fontSize.title14};
  border-radius: 0.4rem;
  resize: vertical;
  border: none;
  resize: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  font-size: 1.4rem;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.placeholder};
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1rem;
  font-size: 2.4rem;
  color: ${(props) => props.theme.colors.txt400};
  button {
    cursor: pointer;
    color: ${(props) => props.theme.colors.gray500};

    svg {
      width: 2.3rem;
      height: 2.1rem;
    }

    :hover {
      color: ${(props) => props.theme.colors.main};
    }
  }
`;

export const MessageStateInfo = styled.div`
  text-align: end;
  font-size: 1.2rem;
`;

export const SendBtn = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.gray800};
  color: white;
  cursor: pointer;
  font-size: 1.3rem;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.main};
  }
`;

export const SendCancelBtn = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.gray600};
  color: white;
  cursor: pointer;
  font-size: 1.3rem;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray700};
  }
`;

export const SendEditBtn = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.main};
  color: white;
  cursor: pointer;
  font-size: 1.3rem;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.main};
  }
`;

export const ImageContainer = styled.div`
  padding: 0.6rem 1rem;
`;

export const ImageItemContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DeleteBtn = styled(IoCloseOutline)`
  position: absolute;
  top: -0rem;
  right: -1.2rem;
  font-size: 2rem;
  padding: 2px;
  background-color: ${(props) => props.theme.colors.gray700};
  transition: 0.3s ease;
  color: ${(props) => props.theme.colors.white};
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray800};
  }
`;

export const ListDeleteBtn = styled(IoCloseSharp)`
  font-size: 1.4rem;
  border: ${(props) => `.2rem solid ${props.theme.colors.gray600}`};
  border-radius: 100%;
  background-color: white;
  color: ${(props) => props.theme.colors.gray600};
  transition: 0.3s ease;
  path {
    stroke: ${(props) => props.theme.colors.gray600};
    stroke-width: 3rem;
    border-radius: 50px;
  }
`;

export const ListDeleteBtnConctainer = styled.span`
  transition: 0.3s ease;
  cursor: pointer;
  &:hover ${ListDeleteBtn} {
    border-color: ${(props) => ` ${props.theme.colors.gray800}`}; // hover 시 border 색상 변경
    color: ${(props) => props.theme.colors.gray100}; // hover 시 아이콘 색상 변경
    background-color: ${(props) => props.theme.colors.gray800};
    path {
      stroke: ${(props) => props.theme.colors.gray100};
    }
  }
`;

export const EditBtnContainer = styled.span<{ isEditing: boolean }>`
  padding: 0.3rem;
  color: ${(props) => (props.isEditing ? props.theme.colors.main : props.theme.colors.gray600)};
  transition: 0.3s ease;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.main};
  }
`;

// 수정버튼
export const EditBtn = styled(FaEdit)`
  font-size: 1.4rem;
`;

export const TabSaveMessageSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  .tabWrap {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  }
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  button svg {
    width: 1.7rem;
    height: 1.7rem;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const TabList = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
`;

interface TabItemProps {
  messageTabState: string;
}

export const TabItemSMS = styled.button<TabItemProps>`
  padding: 0.5rem 0.8rem;
  font-size: ${(props) => props.theme.fontSize.title16};
  font-weight: 600;
  border-bottom: ${(props) =>
    props.messageTabState === 'SMS' ? `2px solid ${props.theme.colors.main}` : '2px solid transparent'};
  color: ${(props) => (props.messageTabState === 'SMS' ? props.theme.colors.main : props.theme.colors.txt200)};
  cursor: pointer;
`;

export const TabItemMMS = styled.button<TabItemProps>`
  padding: 0.5rem 0.8rem;
  font-size: ${(props) => props.theme.fontSize.title16};
  font-weight: 600;
  border-bottom: ${(props) =>
    props.messageTabState === 'MMS' ? `2px solid ${props.theme.colors.main}` : '2px solid transparent'};
  color: ${(props) => (props.messageTabState === 'MMS' ? props.theme.colors.main : props.theme.colors.txt200)};
  cursor: pointer;
`;

export const SearchBtnContainer = styled.div<{ absolute: boolean }>`
  color: ${(props) => props.theme.colors.gray600};
  cursor: pointer;

  ${(props) =>
    props.absolute &&
    `
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    left: .4rem;
    font-size: 2.4rem;
    color: ${props.theme.colors.gray500};
  `}

  &:hover {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const SearchBtn = styled(IoSearch)`
  padding: 0.2rem;
  font-size: ${(props) => props.theme.fontSize.title32};
`;

export const SearchSection = styled.section`
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.gray200};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 0.6rem 1.2rem;
  button {
    cursor: pointer;
    color: ${(props) => props.theme.colors.gray600};
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`;

export const SearchContainer = styled.div`
  flex: 1;
  position: relative;
`;

export const SearchInput = styled.input`
  margin-top: 0.2rem;
  padding: 0.8rem 0.8rem 0.8rem 3rem;
  width: 100%;
  border-radius: 0.6rem;
  border: none;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.title14};
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.gray300}`};
  font-weight: 500;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;

export const NoData = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.title32};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray500};
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 1rem auto 0;
  padding: 1rem 1.6rem;
  overflow-y: auto;
`;

export const MessageListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MessageListItem = styled.div<{ isEditing: boolean }>`
  position: relative;
  width: 100%;
  min-height: 10rem;
  box-shadow: 0px 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border: ${(props) => (props.isEditing ? `1px solid ${props.theme.colors.main}` : '')};
  pointer-events: ${(props) => (props.isEditing ? 'none' : '')};
  // 블러 효과 추가
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(1px);
    visibility: ${(props) => (props.isEditing ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.isEditing ? 1 : 0)};
    transition:
      opacity 0.3s ease,
      visibility 0.3s ease;
    z-index: 1; // 블러 레이어가 내용 위에 위치하도록 설정
  }

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors.gray600};
  }
`;

export const MessageListItemHeader = styled.div<{ isEditing: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  padding: 0.8rem;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.gray400}`};
  font-size: ${(props) => props.theme.fontSize.title16};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isEditing ? props.theme.colors.main : props.theme.colors.txt500)};
  font-weight: 600;

  & > div {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.4rem;
    font-size: 1.4rem;
  }

  & > input {
    flex: 1;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.gray400};
    border-radius: 0.4rem; /* 인풋 테두리 둥글게 하기 */
    padding: 0.6rem; /* 인풋 내 여백 추가 */
  }
`;

export const ImagePreview = styled.img`
  height: 10rem;
  border-radius: 6px;
  margin: 1rem 0 0;
`;

export const MessageListItemBody = styled.div`
  font-size: 1.4rem;
  padding: 0.6rem;
  flex: 1;
  width: 100%;
  max-height: 22rem;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: ${(props) => props.theme.colors.txt400};
  line-height: 1.4em;

  & > textarea {
    width: 100%;
    min-height: 14rem;
    border: 1px solid ${(props) => props.theme.colors.gray400};
    border-radius: 0.4rem; /* 인풋 테두리 둥글게 하기 */
    padding: 0.6rem; /* 인풋 내 여백 추가 */
    resize: none;
  }
`;

// export const MessageEditBtn = styled.button`
//   padding: 0.6rem;
//   color: ${(props) => props.theme.colors.txt100};
//   background-color: ${(props) => props.theme.colors.gray800};
//   text-align: center;
//   transition: 0.3s ease;

//   cursor: pointer;

//   &:hover {
//     background-color: ${(props) => props.theme.colors.main};
//   }
// `;
