import PhoneNumberChange from '@/components/Organism/MyPage/PhoneNumberChange';
import React from 'react';

const MobileEtcPhoneNumberChange = () => {
  return (
    <>
      <PhoneNumberChange />
    </>
  );
};
export default MobileEtcPhoneNumberChange;
