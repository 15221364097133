import { postNiceValidate } from '@/apis/api/signUpApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useNice } from '@/shared/components/niceCertification/hooks/useNice';
import { useNiceCertification } from '@/shared/components/niceCertification/hooks/useNiceCertification';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useSignUp = () => {
  const [checkItems, setCheckItems] = useState(new Set());
  const checkSize = checkItems.size;
  const [isAble, setIsAble] = useState<boolean>(true);
  const { confirmModal, successModal, warningModal } = useModalHook();
  const navigate = useNavigate();
  const { niceCertification } = useNiceCertification();

  // 회원가입 mutate
  const { mutate, isLoading: signUpLoading } = useMutation(postNiceValidate, {
    onSuccess: (response) => {
      if (response.code === '200') {
        successModal('성공', '인증이 완료되었습니다.', true);
        navigate('/signUpForm', {
          state: { data: response },
        });
      } else if (response.code === '201') {
        confirmModal(
          async () => {
            navigate('/SignUpIdCheck', {
              state: { data: response },
            });
          },
          '성공',
          '이미 계정이 존재합니다. </br>계정 정보를 확인하시겠습니까?',
          true,
        );
      } else if (response.code === '202') {
        warningModal('회원가입 실패', '14세 미만의 회원은 가입하실 수 없습니다.', true);
      } else if (response.code === '203') {
        warningModal('회원가입 실패', '동일한 가입정보로 1일 1회 가입이 가능합니다.', true);
      } else if (response.code === '204') {
        warningModal('회원가입 실패', '동일한 주민등록번호로 4개까지 가입할 수 있습니다.', true);
        navigate('/SignUp');
      } else if (response.code === '205') {
        warningModal('회원가입 실패', '가입 후 한달안에 재가입이 불가능합니다.', true);
      } else if (response.code === '206') {
        warningModal('회원가입 실패', '탈퇴 후 한달안에 재가입이 불가능합니다.', true);
      }
    },
    onError: () => {
      warningModal('실패', '고객센터에 문의바랍니다.', true);
    },
  });

  /* nice 인증 return 값 받아오는 Hook */
  const { niceData } = useNice();

  const updateSet = (set: Set<number>, id: number) => {
    const updatedSet = new Set(set);

    if (updatedSet.has(id)) {
      updatedSet.delete(id);
    } else {
      updatedSet.add(id);
    }

    return updatedSet;
  };

  const signUpData: any = [
    {
      id: 1,
      checkLabel: 'agree1',
      children: '서울이동통신 이용약관',
      contents: '/mbr_usecnt_msg.html',
    },
    { id: 2, checkLabel: 'agree2', children: '개인정보 취급방침', contents: '/com_protect_m.html' },
    {
      id: 3,
      checkLabel: 'agree3',
      children: '개인정보 수집/이용 동의서',
      contents: '/com_protect_per.html',
    },
    {
      id: 4,
      checkLabel: 'agree4',
      children: '개인정보 제3자 제공 및 활용 동의서',
      contents: '/com_protect_info3rd.html',
    },
  ];

  const checkItemHandler = (id: number) => {
    setCheckItems((prevSet: any) => updateSet(prevSet, id));
  };

  const toggleAllCheckedById = ({ target: { checked } }: any) => {
    if (checked) {
      const allChecked = new Set(signUpData.map(({ id }: any) => id));
      setCheckItems(allChecked);
    } else {
      setCheckItems(new Set());
    }
  };

  useEffect(() => {
    if (checkItems.size === 4) {
      setIsAble(false);
    } else {
      setIsAble(true);
    }
  }, [checkItems, isAble]);

  /**
   * @title 나이스 인증 팝업 표출 Hook
   *
   * @author 정휘학
   * @since 2024.03.18
   * */
  const onClickCertify = async () => {
    await niceCertification();
  };

  /**
   * @title 나이스 인증 완료시 실행 Hook ( 나이스 인증 return 값인 sEncodeData 를 서버로 던져서 그에 맞는 code 값 받기 )
   *
   * @author 정휘학
   * @since 2024.03.18
   * */
  const signUpClick = async () => {
    try {
      if (niceData !== null) {
        const encData = {
          encodeData: niceData,
        };
        mutate(encData);

        return null;
      }
    } catch (error) {
      return error;
    }
    return null;
  };

  /**
   * @title 나이스 인증 완료시 실행되는 useEffect
   *
   * @author 정휘학
   * @since 2024.03.18
   * */
  useEffect(() => {
    if (niceData) {
      signUpClick();
    }
  }, [niceData]);

  return {
    onClickCertify,
    checkItemHandler,
    isAble,
    setCheckItems,
    toggleAllCheckedById,
    checkSize,
    checkItems,
    signUpData,
    signUpLoading,
  };
};
