import MemberLeave from '@/components/Organism/MyPage/MemberLeave';
import PhoneNumberChange from '@/components/Organism/MyPage/PhoneNumberChange';
import UserInfoChange from '@/components/Organism/MyPage/UserInfoChange';
import UserNameChange from '@/components/Organism/MyPage/UserNameChange';
import My015SubInfo from '@/pages/My015Charge/My015SubInfo';
import React from 'react';

const MobileEtcUserInfo = () => {
  return (
    <div className="mobileEtcUserInfo">
      {/* 회원정보 */}
      <UserInfoChange />
      {/* 휴대폰번호 변경 */}
      <PhoneNumberChange />
      {/* 발급정보 */}
      <My015SubInfo />
      {/* 명의 변경 */}
      <UserNameChange />
      {/* 회원탈퇴 */}
      <MemberLeave />
    </div>
  );
};
export default MobileEtcUserInfo;
