import { instance } from '@/shared/lib/clientAxios';
import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { selectedMobileGroupListRecoil, syncLoadingRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import * as S from './MobileAddressGroupList.style';
import { getGroupList } from '@/apis/api/addressApis';
import IconFolder from '@/styles/icons/IconFolder';
import NoData from '@/components/common/NoData';

interface GroupItem {
  groupSeqNo: number;
  usrKey: string;
  buddyCount: number;
  groupNm: string;
}

interface GroupListResponse {
  groupList: GroupItem[];
  recycleCount: number;
  nextPage?: number | null;
}

const MobileAddressGroupList: React.FC = () => {
  const navigate = useNavigate();
  const setSelectedMobileGroupListS = useSetRecoilState(selectedMobileGroupListRecoil);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>(searchValue);

  const setSyncLoading = useSetRecoilState(syncLoadingRecoil);

  // 디바운스를 위해 searchValue가 변경될 때마다 0.4초 후 debouncedSearchValue를 업데이트
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 400);

    // cleanup: 타이머 초기화
    return () => clearTimeout(handler);
  }, [searchValue]);

  const { data, isLoading, error, refetch } = useQuery<GroupListResponse>({
    queryKey: ['groupList2', debouncedSearchValue], // queryKey에 debouncedSearchValue 추가
    queryFn: () => getGroupList(debouncedSearchValue), // 검색어를 파라미터로 전달
    enabled: !!debouncedSearchValue || searchValue === '', // 검색어가 있거나 초기 로드일 때만 요청
  });

  const onClickMoveBuddyList = (group: GroupItem) => {
    navigate(`/mobile/address/buddyList/${group.groupSeqNo}`);
    setSelectedMobileGroupListS(group);
  };

  // 페이지 로드시 데이터를 리패치
  useEffect(() => {
    refetch();
  }, [refetch]);

  // 데이터 가져올때 로딩창
  useEffect(() => {
    if (isLoading) {
      setSyncLoading(true);
    } else {
      setSyncLoading(false);
    }
  }, [isLoading]);

  return (
    <>
      <S.SearchContainer className="messageAddSearch">
        <S.SearchInput
          type="text"
          placeholder="이름, 전화번호 검색"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </S.SearchContainer>
      <S.AddressContainer>
        {data?.groupList.length === 0 && !isLoading && <NoData />}
        {data?.groupList?.map((group) => (
          <S.GroupListContainer key={group.groupSeqNo} onClick={() => onClickMoveBuddyList(group)}>
            <S.GroupListLeft>
              <S.GroupImageContainer>
                <IconFolder />
              </S.GroupImageContainer>
              <h3>{group.groupNm}</h3>
            </S.GroupListLeft>
            <S.BuddyCount>{group.buddyCount} 명</S.BuddyCount>
          </S.GroupListContainer>
        ))}
      </S.AddressContainer>
    </>
  );
};

export default MobileAddressGroupList;
