import styled from 'styled-components';

export const SigninWrap = styled.div`
  position: relative;
  padding-top: 0 !important;
  background: url('/img/signin/bigbg.png') center / cover no-repeat;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  padding: 0 0 4rem;
  @media screen and (max-width: 768px) {
    padding: 20vh 0 4rem;
  }
`;

export const SigninHeader = styled.header`
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
    padding: 2rem 4% 0 4%;
  }
`;
export const SigninLogo = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0 4rem;

  a {
    display: inline-block;
    height: 100%;
  }
`;
export const Container = styled.div`
  margin: 0 auto;
  width: fit-content;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 3rem;
  }
`;
/* Start Content Zone */
export const Content = styled.div``;
export const ContentInfoWrap = styled.div``;
export const ContentTitle = styled.div`
  /* margin-bottom: 4rem; */
  margin: 0 0 4rem;
  width: 100%;
  /* @media screen and (max-height: 968px) {
    margin-bottom: 2rem;
  } */
  h2 {
    display: flex;
    justify-content: center;
    img {
      max-width: 340px;
      @media screen and (max-width: 1024px) {
        width: 70%;
      }
      @media screen and (max-width: 480px) {
        width: 60%;
      }
    }
  }
`;
/* End Content Zone */
/* Start NoneSoftPhone Zone */
export const LoginWrap = styled.div`
  margin: 0 auto;
`;
export const LoginContentWrap = styled.div``;
export const ArreoLoginInfo = styled.div`
  > div {
    &:first-of-type {
      margin: 0 0 4rem;
    }
  }
  @media screen and (max-width: 768px) {
    > div {
      &:first-of-type {
        margin: 0 0 2rem;
      }
    }
  }
`;
export const ArreoLoginInput = styled.div`
  position: relative;
  width: 100%;
  &.active {
    input:placeholder-shown {
      border-color: ${(props) => props.theme.colors.red};
    }
  }
  /* 자동완성 시 input 배경 색 변경 방지 */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: rgba(255, 255, 255, 1);
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
  input {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 4.8rem;
    margin-top: 1.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
    background: transparent;
    border-color: ${(props) => props.theme.colors.gray200};
    color: ${(props) => props.theme.colors.txt100};
    outline: none;

    &:placeholder-shown {
      border-color: ${(props) => props.theme.colors.gray500};
    }
    &:focus-visible {
      border-radius: 1rem;
      border-color: ${(props) => props.theme.colors.txt100};
    }
    &::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
    }
  }

  .inputLabel {
    opacity: 0;
    position: absolute;
    top: -1.2rem;
    left: 2rem;
    padding: 0.5rem 1rem;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.main};
    font-size: 1.4rem;
    transition: all 0.2s ease-in-out;
  }
  input + .inputLabel {
    opacity: 1;
  }
  input:placeholder-shown + .inputLabel {
    opacity: 0;
  }
`;

export const Viewable = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.2rem;
  .on {
    color: ${(props) => props.theme.colors.txt100};
  }
  .off {
    color: ${(props) => props.theme.colors.deactivation};
  }
`;

export const ArreoIdInputWrap = styled.div`
  position: relative;
  width: 100%;
`;
export const ArreoPasswordInputWrap = styled.div`
  position: relative;
  width: 100%;
  > div {
    &:first-child {
      margin-top: 46px;
    }
  }
`;
export const DataFunZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  a {
    position: relative;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(props) => props.theme.colors.txt200};
  }
  button {
    font-size: 1.2rem;
    font-weight: 600;
  }
  /* @media screen and (max-width: 768px) {
    a {
      color: ${(props) => props.theme.colors.txt200};
    }
  } */
`;
export const AgreementButtonWrap = styled.div`
  margin: 6.8rem 0 1.8rem 0;

  button {
    width: 100%;
    height: 5rem;
    border-radius: 100px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.42);
    backdrop-filter: blur(1.5px);
    font-size: 1.6rem;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.colors.txt100};
    transition: none;
  }
`;
export const Label = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  color: rgba(255, 255, 255, 0.53);
`;
export const ArreoLoginError = styled.div``;
export const ValidationError = styled.span`
  font-size: 1rem;
  color: red;
`;

/* Start 공지사항 & 고객센터 & 보유기술 */
export const NewsGroupWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  margin: 9rem auto 0 auto;
  width: fit-content;
  height: 16rem;
  > div {
    height: 100%;
    border-radius: 1rem;
  }
  @media screen and (max-width: 1280px) {
    flex-direction: column;
    gap: 1rem;
    margin-top: 4rem;
    width: 90%;
    height: fit-content;
  }
  @media screen and (max-width: 480px) {
    width: 90%;
  }
  button {
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export const ResGroupWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  width: 80rem;
  @media screen and (max-width: 1280px) {
    gap: 1rem;
    width: 100%;
    height: 16rem;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  > div {
    border-radius: 1rem;
  }
`;

export const Notice = styled.div`
  padding: 2rem;
  width: 40rem;
  background: rgba(72, 72, 72, 0.6);
  border: 2px solid #fff;
  @media screen and (max-width: 1280px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const NoticeTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.txt100};
  }
  button {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.txt200};
  }
`;

export const NoticeList = styled.ul`
  margin-top: 1.8rem;
  overflow: hidden;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    &.active {
      span {
        display: block;
        margin-left: 0.6rem;
        width: 1rem;
        height: 1rem;
        border-radius: 1px;
        background: ${(props) => props.theme.colors.notify};
      }
    }
    a {
      max-width: calc(100% - 1.6rem);
      position: relative;
      font-size: 1.4rem;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #fff;
      &:has(span) {
        max-width: calc(100% - 2.6rem);
      }
      span {
        display: none;
        width: 1rem;
        height: 1rem;
        border-radius: 1px;
        background: ${(props) => props.theme.colors.notify};
      }
    }
    /* &:last-of-type {
      margin-bottom: 0;
      a {
        font-weight: 400;
        color: ${(props) => props.theme.colors.gray400};
      }
    } */
  }
`;

export const ServiceCS = styled.div`
  padding: 2rem;
  background: rgba(72, 72, 72, 0.6);
  border: 2px solid #fff;
  width: 40rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &:has(+ .appDownloadWrap) {
    height: 70%;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #fff;
  }
  p {
    margin: 1rem 0;
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
    @media screen and (max-width: 1280px) {
      margin: 18px 0 24px 0;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
    @media screen and (max-width: 480px) {
      margin: 1.4rem 0 1.8rem 0;
    }
  }
  span {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.txt200};

    @media screen and (max-width: 768px) {
      &:nth-of-type(1) {
        display: none;
      }
    }
    &.csTimeText {
      display: none;
      line-height: 1.4;
      @media screen and (max-width: 768px) {
        &:nth-of-type(1) {
          display: none;
        }
        display: block;
      }
    }
  }
`;

export const FunWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 400px;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  @media screen and (max-width: 968px) {
  }
  > div {
    background: rgba(72, 72, 72, 0.6);
    border: 2px solid #fff;
    border-radius: 10px;
  }
`;

export const FunFirst = styled.div`
  padding: 2rem;
  height: 70%;
  @media screen and (max-width: 1280px) {
    height: initial;
  }
  p {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.gray400};
  }
`;

export const FunFirstTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.2rem;
  @media screen and (max-width: 1280px) {
    margin-bottom: 1.6rem;
  }
  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #fff;
  }
`;

export const FunLast = styled.div`
  height: 30%;
  @media screen and (max-width: 1280px) {
    height: initial;
  }
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 2rem;
    @media screen and (max-width: 1280px) {
      padding: 2rem;
    }
    p {
      font-size: 1.6rem;
      font-weight: 600;
      color: #fff;
    }
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 2rem;
    @media screen and (max-width: 1280px) {
      padding: 2rem;
    }
    p {
      font-size: 1.6rem;
      font-weight: 600;
      color: #fff;
    }
  }
`;
