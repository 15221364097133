import styled from 'styled-components';

export const MobilePopupContainer = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

export const PopupWrap = styled.div`
  background: ${(props) => props.theme.colors.white};
  .addChatHeader {
    padding: 0 1.6rem;
    height: 5rem;
  }
`;

export const Group = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 1.6rem;
  max-width: 60rem;
  margin: 0 auto;
  li {
    margin: 0 auto;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1.6rem;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
`;
