import React, { useEffect, useRef } from 'react';
import * as S from './MobilePopup.style';
import ChatPopupListHeader from './mobilePopupList/ChatPopupListHeader';
import IconSpeechBubble from '@/styles/icons/IconSpeechBubble';
import IconSpeechBubblePlus from '@/styles/icons/IconSpeechBubblePlus';
import { useNavigate } from 'react-router-dom';
import IconChatHuman from '@/styles/icons/IconChatHuman';
import IconChatTel from '@/styles/icons/IconChatTel';

interface MobilePopupProps {
  onClose: () => void;
}

const MobilePopup: React.FC<MobilePopupProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const popupRef = useRef<HTMLDivElement>(null);

  // 외부 클릭 감지 함수
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const onClickNewChatBtn = () => {
    navigate('/mobile/message/messageNewAdd');
  };

  return (
    <S.MobilePopupContainer>
      <S.PopupWrap className="mobilePopup" ref={popupRef}>
        {/* 쳇 리스트 */}
        <ChatPopupListHeader onClose={onClose} />
        <S.Group>
          <li>
            <button type="button" onClick={() => navigate('/mobile/message/messageAdd')}>
              {/* <IconSpeechBubble /> */}
              <IconChatHuman />
              <p>주소록</p>
            </button>
          </li>
          <li>
            <button type="button" onClick={() => onClickNewChatBtn()}>
              {/* <IconSpeechBubblePlus /> */}
              <IconChatTel />
              <p>번호입력</p>
            </button>
          </li>
        </S.Group>
      </S.PopupWrap>
    </S.MobilePopupContainer>
  );
};
export default MobilePopup;
