import React from 'react';
import RegisterTaxInvoice from '@/components/Organism/Charge/RegisterTaxInvoice';

const MobileEtcRegisterTaxInvoice = () => {
  return (
    <>
      <RegisterTaxInvoice />
    </>
  );
};
export default MobileEtcRegisterTaxInvoice;
