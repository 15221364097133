import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useRecoilValue } from 'recoil';
import { fullScreenSpinLoadingRecoil } from '@/recoil/atoms/atoms';

const FullScreenSpinLoader = () => {
  const fullScreenSpinLoadingR = useRecoilValue(fullScreenSpinLoadingRecoil);
  const [timer, setTimer] = useState<boolean>(false);

  const handleTimer = () => {
    setTimeout(() => {
      setTimer(false);
    }, 1500);
  };

  useEffect(() => {
    if (fullScreenSpinLoadingR) setTimer(true);
    if (!fullScreenSpinLoadingR) handleTimer();
  }, [fullScreenSpinLoadingR]);

  return <Spin spinning={fullScreenSpinLoadingR || timer} size={'large'} fullscreen />;
};

export default FullScreenSpinLoader;
