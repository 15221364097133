import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { mainTableCacheUpdate } from '@/components/cache/mainTableCacheUpdate';
import { softPhone015CacheUpdate } from '@/components/cache/softPhone015CacheUpdate';
import { useMainTableReset } from '@/components/hooks/customs/resetRecoil/useMainTableReset';
import { IMainTableUpdateApiParams } from '@/components/hooks/mutations/apiTypes';
import { fetchMutation } from '@/components/hooks/mutations/fetchMutation';
import { checkGroupListState, detailBuddyDataRecoil } from '@/recoil/atoms/addressList';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { useAddTableCache } from '../../customs/addressBook/useAddTableCache';

import FileSaver from 'file-saver';
import { instance } from '@/shared/lib/clientAxios';

/* 주소록 복사 Mutation params type */
export type MainTableCopyParamsType = { groupSeqNo: number; buddySeqNos: number[] };
/* 주소록 간편 추가 Mutation params type */
export type MainTableSimpleAddParamsType = {
  buddyNm: string;
  emailId: string;
  etcInfo: string;
  groupSeqNo: number;
  keyCommNo: string;
};
/* 주소록 간편 추가 Mutation response type */
export type MainTableSimpleAddResponseType = {
  buddyNm: string;
  buddySeqNo: number;
  keyCommNo: string;
};
/**
 * @title 주소록 메인 테이블 api hook
 *
 * @author 정휘학
 * @since 2024.06.05
 * */
export const useAddressBookMutations = () => {
  const queryClient = useQueryClient();
  /* 주소록 그룹 체크박스 상태 관리 recoil */
  const [, setCheckGroupListR] = useRecoilState(checkGroupListState);
  /* 주소록 상세 페이지를 위해 해당 BuddyData 값만 따로 담아놓는 recoil */
  const [, setDetailBuddyDataS] = useRecoilState(detailBuddyDataRecoil);
  /* 주소록 메인 테이블 캐싱 작업 hook */
  const {
    mainTableUpdateToCacheUpdate,
    mainTableDeleteToCacheUpdate,
    mainTableCopyToCacheUpdate,
    mainTableSimpleAddToCacheUpdate,
  } = mainTableCacheUpdate();

  /* 간편추가 성공시 softPhone 015 list에 해당 번호 존재하면 이름으로 변경 */
  const cacheSHooks = useAddTableCache();
  /* 간편추가 성공시 groupList cache update */
  const cacheGHooks = groupListCacheUpdate();

  /* 소프트폰 015 채팅방 리스트 캐시 업데이트 */
  const { cacheUpdateToTableUpdated } = softPhone015CacheUpdate();

  // ======================================
  // 주소록 및 좌측 체크박스에 관한 recoil 리셋 함수
  // ======================================
  const { mainTableResetRecoil } = useMainTableReset();

  // ==================
  // 주소록 수정 Mutation
  // ==================
  const onSuccessToUpdatedM = (data: any, variables: any) => {
    // 015 번호 리스트 수정
    cacheUpdateToTableUpdated(variables);
    // 주소록 수정
    mainTableUpdateToCacheUpdate(variables);
    // 그룹 리스트 카운트 수정
    cacheGHooks.handleTableEditCacheUpdate(data, variables);
    // 메인테이블 리코일 리셋
    mainTableResetRecoil();
    // 주소록 상세페이지 관련 Recoil
    setDetailBuddyDataS(variables);
  };
  const { mutate: updatedM } = fetchMutation<IMainTableUpdateApiParams, any>({
    mutationKey: ['/contacts/updateDetail'],
    method: 'POST',
    onSuccess: (data, variables, context) => onSuccessToUpdatedM(data, variables),
  });

  // ==================
  // 주소록 삭제 Mutation
  // ==================
  const onSuccessToDeletedM = (data: any, variables: number[]) => {
    mainTableDeleteToCacheUpdate(variables);
    cacheGHooks.handleTableDeleteCacheUpdate(data);
    mainTableResetRecoil();
  };
  const { mutate: deletedM } = fetchMutation<number[], any>({
    mutationKey: ['/contacts/delete'],
    method: 'POST',
    onSuccess: (data, variables, context) => onSuccessToDeletedM(data, variables),
  });

  // ==================
  // 주소록 복사 Mutation
  // ==================
  const onSuccessToCopyM = (data: any, variables: MainTableCopyParamsType) => {
    mainTableCopyToCacheUpdate(data, variables);
    cacheGHooks.handleCopyTableCacheUpdate(variables);
    mainTableResetRecoil();
    setCheckGroupListR([]);
  };
  const { mutate: copyM, isLoading: copyL } = fetchMutation<MainTableCopyParamsType, any>({
    mutationKey: ['/contacts/copyContacts'],
    method: 'POST',
    onSuccess: (data, variables, context) => onSuccessToCopyM(data, variables),
  });

  // ==============================
  // 주소록 간편 추가 Mutation
  // ==============================
  const onSuccessToSimpleAdd = (data: MainTableSimpleAddResponseType, variables: MainTableSimpleAddParamsType) => {
    mainTableSimpleAddToCacheUpdate(data, variables);
    // 015list cache update hook
    cacheSHooks.onSuccessAddTable(data);
    // groupList cache update hook
    cacheGHooks.handleTableAddCacheUpdate(variables);
    mainTableResetRecoil();
  };

  const { mutate: simpleAddM } = fetchMutation<MainTableSimpleAddParamsType, MainTableSimpleAddResponseType>({
    mutationKey: ['/contacts/create'],
    method: 'POST',
    onSuccess: (data, variables, context) => onSuccessToSimpleAdd(data, variables),
  });

  // ==============================
  // 주소록 엑셀다운
  // ==============================
  const downloadContactsExcelFile = async (buddySeqNos: any) => {
    try {
      const response = await instance.post(
        '/contacts/excel-download',
        { buddySeqNos },
        {
          responseType: 'blob',
        },
      );

      const contentDisposition = response.headers['content-disposition'];
      const filenameMatch = /filename="(.*)"/.exec(contentDisposition);
      const filename = filenameMatch ? filenameMatch[1] : '주소록.xlsx';

      FileSaver.saveAs(response.data, filename);
      return response;
    } catch (error) {
      return error;
    }
  };

  return {
    copyL,
    copyM,
    deletedM,
    downloadContactsExcelFile,
    updatedM,
    simpleAddM,
    mainTableResetRecoil,
  };
};
