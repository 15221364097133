import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { Reset } from 'styled-reset';
import Footer from './components/common/Footer';
import * as S from './styles/Global.styles';

import Body from '@/components/common/Body';
import FullScreenSpinLoader from '@/components/common/FullScreenSpinLoading';
import SkeletonPopup from '@/components/common/SkeletonPopup';
import InitSetting from '@/pages/InitSettingPage/InitSettingPage';
import { Spin } from 'antd';
import { Cookies, CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga4';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { ThemeProvider } from 'styled-components';
import { postAddMobileGroup } from './apis/api/addressApis';
import { useModalHook } from './components/commons/modals/useModalHook';
import GlobalModal from './pages/main/components/modal/globalModal/GlobalModal';
import { globalModalStateRecoil, windowWidthRecoil } from './recoil/atoms/router/mainRouterRecoil';
import AppRoutes from './routes/AppRoutes';
import ScrollToTop from './routes/SrollToTop';
import { detectEnvUtils } from './shared/util/detectEnvUtils';
import { darkTheme, theme } from './styles/theme';
import { setCookie } from './shared/util/cookie';
import { environmentRecoil, syncLoadingRecoil } from './recoil/atoms/MobileRecoil/MobileRecoil';
import axios from 'axios';
import MobileLoader from './components/mobileApp/MobileLoader';

// 높이값 가져오기위함
function setScreenSize(): void {
  const vh: number = window.innerHeight;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// 높이 resize
const setVh = () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
};
window.addEventListener('resize', setVh);
setVh();

const AppContent = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // 현재 경로 가져오기
  const location = useLocation();
  const hidePageNavPaths = ['/signin']; // PageNav를 숨길 경로 배열
  const { isOpen } = useRecoilValue(globalModalStateRecoil);
  const [windowWidth, setWindowWidth] = useRecoilState(windowWidthRecoil);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { customSuccessModal, customWarningModal } = useModalHook();

  //모바일 환경을 체크 하는 유즈 이펙트
  //기준: 처음 들어올때 webView라는게 안들어와서 navigator.userAgent 를 게속
  // 주시 후 webView 업데이트
  // ************************************************************************
  const [environmentS, setEnvironmentS] = useRecoilState(environmentRecoil);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    // alert(`${userAgent}`);
    if (userAgent.includes('version') && userAgent.includes('android')) {
      setEnvironmentS('app');
      localStorage.setItem('userAgent', 'app');
    } else if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)) {
      setEnvironmentS('mobile');
    } else {
      setEnvironmentS('pc');
    }
  }, [navigator.userAgent]);

  // ************************************************************************

  //모바일 주소록 동기화 시킬때 로딩창
  const [syncLoading, setSyncLoading] = useRecoilState(syncLoadingRecoil);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { mutate: addGroupMutate } = useMutation(postAddMobileGroup, {
    onMutate: () => {
      setSyncLoading(true);
    },
    onSuccess: () => {
      customSuccessModal(
        () => {
          setSyncLoading(false);
          queryClient.refetchQueries(['groupList2']);
        },
        '성공',
        '주소록 정보가 동기화 되었습니다.',
        true,
      );
    },
    onError: (error) => {
      customWarningModal(
        () => {
          setSyncLoading(false);
        },
        '실패',
        `주소록 정보 동기화에 실패 했습니다.(주소록에서 모바일 주소록 동기화를 다시 한번 진행해 주세요): ${error}`,
        true,
      );
    },
    onSettled: () => {
      navigate('/mobile/address/groupList');
    },
  });

  useEffect(() => {
    if (environmentS === 'app') {
      window.getAppToken = (fcmToken: any) => {
        try {
          const payload = {
            token: fcmToken,
            clientEnv: 'app',
          };

          const token = localStorage.getItem('authorizationToken');

          // API 요청으로 서버에 전송
          axios.post(`${process.env.REACT_APP_SERVER_URL}/api/push/chrome/subscribe`, payload, {
            headers: {
              Authorization: `Bearer ${token}`, // 토큰을 헤더에 추가
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          alert(`토큰 오류 발생: ${error}`);
        }
      };
      window.autoAddressSync = (addressList) => {
        type TAddress = { name: string; phone: string };

        const parseAddressList: TAddress[] = JSON.parse(addressList).filter(
          (item: any): item is TAddress => typeof item.phone === 'string' && typeof item.name === 'string',
        );

        type TPayload = {
          contacts: { buddyNm: string; keyCommNo: string }[];
        };

        const payload: TPayload = {
          contacts: parseAddressList.map((item: TAddress) => ({
            buddyNm: item.name,
            keyCommNo: item.phone.replace(/\D/g, ''), // 전화번호에서 숫자만 추출
          })),
        };
        setSyncLoading(false);
        addGroupMutate(payload);
      };
    }
  }, [environmentS]);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (syncLoading) {
      timer = setTimeout(() => {
        setSyncLoading(false); // syncLoading을 false로 설정
      }, 10000); // 30초 후에 실행
    }

    return () => clearTimeout(timer); // 클린업으로 타이머를 정리
  }, [syncLoading]);

  return (
    <>
      <Spin spinning={isLoading} size="large">
        <FullScreenSpinLoader />
        {syncLoading && <MobileLoader />}
        <InitSetting />
        {/* <Header /> */}
        <SkeletonPopup
          style={{ height: '25px' }}
          title={false}
          paragraph={{ rows: 3, width: ['50%', '35%', '80%'] }}
          active
        />
        <Body>
          <>
            {/* 페이지 라우터 */}
            <AppRoutes />
            {isOpen && <GlobalModal />}
          </>
        </Body>
      </Spin>
      {(windowWidth > 768 || location.pathname === '/signin') && !location.pathname.startsWith('/mobile') && <Footer />}
    </>
  );
};
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error: any) => {
          const status = error.response ? error.response.status : null;
          return status === 401;
        },
      },
      mutations: {
        retry: (failureCount, error: any) => {
          const status = error.response ? error.response.status : null;
          return status === 401;
        },
      },
    },
  });

  // 다크모드 타입
  type TThemeMode = 'lightTheme' | 'darkTheme';
  const initialTheme = (localStorage.getItem('theme') as TThemeMode) ?? 'lightTheme';
  // 다크모드 일반모드 STATE
  const [isDarkMode, setIsDarkMode] = useState<TThemeMode>(initialTheme);

  // 다크모드 토글 버튼
  const onClickThemeMode = (mode: TThemeMode) => {
    localStorage.setItem('theme', mode);
    setIsDarkMode(mode);
  };

  // 구글 애널리틱스 연결
  useEffect(() => {
    setScreenSize();
    ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);
  }, []);

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <ThemeProvider theme={isDarkMode === 'darkTheme' ? darkTheme : theme}>
          {/* <ThemeWrap className="themeToggle">
            {isDarkMode === 'lightTheme' ? (
              <button onClick={() => onClickThemeMode('darkTheme')}>🐥</button>
            ) : (
              <button
                onClick={() => {
                  onClickThemeMode('lightTheme');
                }}
              >
                🦉
              </button>
            )}
          </ThemeWrap> */}
          <S.Container>
            <Reset />
            <S.GlobalStyle />
            <BrowserRouter>
              <ScrollToTop /> {/* 페이지 이동시 스크롤 상단으로 이동 */}
              <AppContent />
            </BrowserRouter>
          </S.Container>
        </ThemeProvider>
      </QueryClientProvider>
    </CookiesProvider>
  );
};

const ThemeWrap = styled.div`
  z-index: 3;
  padding: 10px;
  position: fixed;
  button {
    font-size: 28px;
  }
`;

export default App;
