import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	
  *, ::after, ::before {
    box-sizing: border-box;
  }
	::selection{
		background: #444;
		color: #fff;
	}
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  html {
    position: relative;
    width: 100%;
    font-size: 10px;
    overflow-x: hidden;
    font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
    color: ${(props) => props.theme.colors.txt500};
    font-weight: 500;
    box-sizing: border-box;

    :root {
      --vh: 100%;
    }
    ::-webkit-scrollbar {
			width: 1rem;
			height: 1rem;
			/* background: transparent; */
      background:  ${(props) => props.theme.colors.gray300};
		
			background-clip: padding-box;
    }
    ::-webkit-scrollbar-thumb {
			border: .2rem solid transparent;
			border-radius: 50px;
      background:  ${(props) => props.theme.colors.gray600};
			background-clip: padding-box;
    }

    div, p, ul, textarea {
			&:has(::-webkit-scrollbar){
				padding-right: 0;
			}
      ::-webkit-scrollbar {
        width: 1.4rem;
				height: 1.4rem;
				background: transparent;
      }

      ::-webkit-scrollbar-thumb {
				border: .5rem solid transparent;
        background:  ${(props) => props.theme.colors.gray600};
				background-clip: padding-box;
				border-radius: 40px;

      }
    }

		input, textarea {
			/* background: transparent; */
			outline-color: ${(props) => props.theme.colors.gray600};
			background: transparent;
		}

	}
	
  body {
    width: 100% !important;
    min-height: calc(100vh - 230px); //signin 해더 깨짐 현상 해결방법
		background-color: ${(props) => props.theme.colors.background};
    display: flex;
    flex-direction: column;
    /* @media screen and (max-width: 1440px) {
      overflow-y: visible;
      overflow-x: hidden;
    } */
    /* 드래그 방지 */
    /* -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none; */
  }

  div, ul, li{

    ::-webkit-scrollbar, ::-webkit-scrollbar-thumb {
      border-radius: 4rem;
      width: 4px;
      height: 4px;
    }
  }

  a {
    text-decoration: none;
    font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
    color: ${(props) => props.theme.colors.txt500};
  }

  input {
    font-weight: 500;
    font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
    accent-color: ${(props) => props.theme.colors.main};
		border: 0;
		background: transparent;
		outline: transparent;
		color: ${(props) => props.theme.colors.txt500};
  }

  button {
		cursor: pointer;
		padding: 0;
		margin: 0;
    border: 0;
    font-weight: 500;
    background: transparent;
    font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
  }

  textarea {
    font-weight: 500;
    background: transparent;
    font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
  }

	svg{
		display: inherit;
		&.curFill{
			path{
				fill: currentColor;
			}
		}
		&.curStroke{
			path{
				stroke: currentColor;
			}
		}
	}
	
	.inputErrorMessage{
		color: ${(props) => props.theme.colors.red};
		margin: .4rem 0;
		font-size: 1.3rem;
	}


	.mwrap{
		width: 100%;
		padding: 0 1.4rem;
		margin: 0 auto;
	}
	/*  */
	.w30{
		width: 30%;
	}
	.w40{
		width: 40%;
	}
	.w50{
		width: 50%;
	}
	/* ------------------- 테이블에 클래스 추가시 적용되는 css -------------------  */
  /* 테이블 margin-bottom */
  .marB10 {
    margin-bottom: 1rem;
  }
  .marB20 {
    margin-bottom: 2rem;
  }
  .marB30 {
    margin-bottom: 3rem;
  }
  .marB40 {
    margin-bottom: 4rem;
  }
  /* 테이블 margin-top */
  .marT10 {
    margin-top: 1rem;
  }
  .marT20 {
    margin-top: 2rem;
  }
  .marT30 {
    margin-top: 3rem;
  }
  .marT40 {
    margin-top: 4rem;
  }
	/* ------------------- text css -------------------  */
  .txtCenter {
    text-align: center;
  }
  .txtLeft {
    text-align: left;
  }
  .txtRight {
    text-align: right;
  }

	/* flexBox styles - start / class명 추가시 해당 css 입력됨*/
	.flex {
    display: flex;
  }
	.flexAc{
		display: flex;
		align-items: center;
	}
	.flexJc{
		display: flex;
		justify-content: center;
	}
	.flexAcJc{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.flexAs{
		display: flex;
		align-items: start;
	}
	.flexJs{
		display: flex;
		justify-content: flex-start;
	}
	.flexAsJs{
		display: flex;
		align-items: start;
		justify-content: flex-start;
	}
	.flexJsb{
		display: flex;
		justify-content: space-between;
	}
	/* wrap추가 */
	.flexBox{
		display: flex;
		flex-wrap: wrap;
	}
	.flexBoxAc{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.flexBoxJc{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.flexBoxAcJc{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}
	.flexBoxAs{
		display: flex;
		align-items: start;
		flex-wrap: wrap;
	}
	.flexBoxJs{
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.flexBoxAsJs{
		display: flex;
		align-items: start;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.flexBoxJsb{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	/* flexBox styles - end */

	/* gap styles - start */
	.gap5{
		gap: .5rem;
	}
	.gap10{
		gap: 1rem;
	}
	.gap15{
		gap: 1.5rem;
	}
	.gap20{
		gap: 2rem;
	}
	.gap30{
		gap: 3rem;
	}
	/* gap styles - end */

  /* invert styles - start */
  .invert{
    filter: ${(props) => props.theme.colors.invert};
  }
  .skeletonInvert{
    h3, li, span{
      filter: ${(props) => props.theme.colors.invert};
    }
  }
  /* invert styles - end */

	/* 메인컬러 색상 */
	.mainColor{
		color: ${(props) => props.theme.colors.main};
	}
	.red{
		color: ${(props) => props.theme.colors.red};
	}



	/* @media screen and (min-width: 2560px){
		html{
			font-size: 1vmin;
		}
	} */
	@media screen and (max-width: 640px) {
		html{
			overflow-x: hidden !important;
		}
	}
	/* 340와이드 반응형 */
	@media screen and (max-width: 340px){
		html{
			font-size: min(3.2vw, 10px);
		}
	}

	
  //  전송내역관리 내에 캘린더 라이브러리 스타일 변경
  .ant-picker-dropdown {
    /* z-index: 9999 !important; */
    @media screen and (max-width: 640px) {
      position: fixed;
      width: 95%;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  //.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout{
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout {
    @media screen and (max-width: 640px) {
      width: 100%;
      .ant-picker-month-panel,
      .ant-picker-panel {
        width: 100%;
      }
    }
  }

  //.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-datetime-panel{
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-datetime-panel {
    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
      flex-direction: column !important;
      > div {
        width: 100%;

        table {
          width: 100%;
        }
      }
    }
  }

	.scheduleDataContent.scheduleSelectWrap > div:last-of-type{
		padding: 0.4rem 1.1rem;
		background: ${(props) => props.theme.colors.gray200};
		color: ${(props) => props.theme.colors.txt500};
	}
	.ant-picker-outlined{
		background: transparent;
	}
	.ant-picker .ant-picker-input > input{
		font-size: 1.4rem;
		color: ${(props) => props.theme.colors.txt500};
    font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
	}
  .ant-picker-range .ant-picker-active-bar {
    background: ${(props) => props.theme.colors.main};
  }
  
  //.ant-picker-dropdown .ant-picker-header-view button:hover{
  .ant-picker-dropdown .ant-picker-header-view button:hover {
    color: ${(props) => props.theme.colors.main};
  }

  //.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid ${(props) => props.theme.colors.main};
  }

  //.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  //.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before{
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${(props) => props.theme.colors.gray200};
  }

  .ant-picker-cell-range, .ant-picker-cell-range-hover,
  .ant-picker-cell-range-hover-end,
  .ant-picker-cell-range-hover-start {
    &::before {
      background: #b4c7ff !important;
    }
  }

  .ant-picker-cell-inner {
    &::after {
      background-color: #b4c7ff !important;
    }
  }

  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${(props) => props.theme.colors.main};
  }

  /* 캘린더 라이브러리 스타일 시간 */
  .ant-btn-primary {
    background: #4863b3;
  }

  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: ${(props) => props.theme.colors.main};
  }

  .ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: ${(props) => props.theme.colors.gray200};
  }

  .ant-picker:hover,
  .ant-picker-focused.ant-picker,
  .ant-picker:hover {
    border-color: ${(props) => props.theme.colors.main};
  }

  .ant-picker-focused.ant-picker {
    box-shadow: 0 0 0 2px rgb(76 54 178 / 10%);
  }

  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels{
    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
      flex-direction: column !important;
      > div {
        width: 100%;

        table {
          width: 100%;
        }
      }
    }
  }

`;

// 전체 컨테이너
export const Container = styled.div`
  width: 100%;
  height: 100%;

  /* 고객센터, 로그인, 도움말 팝업 footer margin 삭제 */
  &:has(.CustomerPageContainer),
  &:has(.signin),
  &:has(.popupContent) {
    footer {
      margin-top: 0;
    }
  }
  &:has(.adPopupContent) {
    .bodyWrap {
      min-height: unset;
    }
    footer {
      margin-top: 0;
    }
  }
  /* @media screen and (max-width: 768px) {
    &:has(.pageNav) {
      margin-bottom: 6rem;
    }
  } */
`;
