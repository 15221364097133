import React from 'react';
import * as S from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/ChatRoom015Head.styled';
import { IChatRoom015HeadReg } from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/ChatRoom015Head.types';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';

const ChatRoom015HeadReg = (props: IChatRoom015HeadReg) => {
  return (
    // 연락처가 등록되었을 경우 UI 컴포넌트
    <S.EtcChattingHeadReg>
      <S.EtcChattingHeadRegLeftWrap>
        <S.RegContactInfoWrap>
          <S.EtcChattingHideBtn
            type="button"
            onClick={() => {
              if (props.chatUserInfo.phoneNumber !== null)
                props.clickEachListH({
                  buddyName: props.chatUserInfo.buddyName,
                  phoneNumber: props.chatUserInfo.phoneNumber,
                });
            }}
          >
            <IconArrowLongLeft />
          </S.EtcChattingHideBtn>
          <S.RegContactName>{props.chatUserInfo.buddyName}</S.RegContactName>
          <S.RegContacPhoneNumber>
            {props.chatUserInfo.phoneNumber && TelHyphen(props.chatUserInfo.phoneNumber)}
          </S.RegContacPhoneNumber>
        </S.RegContactInfoWrap>
      </S.EtcChattingHeadRegLeftWrap>
      {/*<S.EtcChattingHeadRegRightWrap>*/}
      {/*    <S.SearchBtn>*/}
      {/*        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">*/}
      {/*            <path d="M22.6591 23.1203L16.3963 16.8575M16.3963 16.8575C17.2135 16.0403 17.8618 15.0701 18.3041 14.0024C18.7463 12.9347 18.974 11.7903 18.974 10.6346C18.974 9.47885 18.7463 8.33446 18.3041 7.26673C17.8618 6.19899 17.2135 5.22882 16.3963 4.41161C15.5791 3.5944 14.609 2.94616 13.5412 2.50389C12.4735 2.06162 11.3291 1.83398 10.1734 1.83398C9.01767 1.83398 7.87328 2.06162 6.80554 2.50389C5.73781 2.94616 4.76764 3.5944 3.95043 4.41161C2.3 6.06204 1.3728 8.3005 1.3728 10.6346C1.3728 12.9686 2.3 15.2071 3.95043 16.8575C5.60086 18.5079 7.83932 19.4351 10.1734 19.4351C12.5074 19.4351 14.7459 18.5079 16.3963 16.8575Z" stroke="#001F49" />*/}
      {/*        </svg>*/}
      {/*    </S.SearchBtn>*/}
      {/*</S.EtcChattingHeadRegRightWrap>*/}
    </S.EtcChattingHeadReg>
  );
};

export default ChatRoom015HeadReg;
