import { atom } from 'recoil';
import { Message015Type } from '@/components/hooks/customs/useEachChatRoomHook';
import { IArsScenarioInfoListS } from '@/features/ars/arsScenario/ui/ArsScenario.types';

// 015사서함 검색시 저장할 select 벨류
export const say015SelectValueState = atom<string>({
  key: 'say015SelectValueState',
  default: 'number',
});

// 015사서함 검색시 저장할 input 벨류
export const say015SearchTextState = atom<string>({
  key: 'say015SearchTextState',
  default: '',
});
// 015 사서함 detail message list 클릭 id
export const detailMessageListIdRecoil = atom<string | null>({
  key: 'detailMessageListIdRecoil',
  default: null,
});
// 015사서함 message클릭시 detail messageId 상태
export const detailMassageIdState = atom<string | null>({
  key: 'detailMassageIdState',
  default: null,
});
// 015사서함 message클릭시 detailId sndPhnId
export const detailMassageSndPhnIdState = atom<string>({
  key: 'detailMassageSndPhnIdState',
  default: '',
});

// 015사서함 message 우클릭시 user data recoil
export const pickItemRecoil = atom<Message015Type | null>({
  key: 'pickItemRecoil',
  default: null,
});

// 015사서함 message detail 페이징네이션 state 묶음
// export const detailMessagePageBtns = atom<any>({
//   key: 'detailMessagePageBtns',
//   default: { curr },
// });

// 015 채팅방 표출 toggle
export const msg015ChatRoomToggleRecoil = atom<boolean>({
  key: 'msg015ChatRoomToggleRecoil',
  default: false,
});

// 문자 저장함 상태
// export const saveMessageToggleRecoil = atom<boolean>({
//   key: 'saveMessageToggleRecoil',
//   default: false,
// });

// 채팅룸 형식 010 전송결과(예약전송) 토글 상태
export const msgResultToggleRecoil = atom<boolean>({
  key: 'msgResultToggleRecoil',
  default: false,
});

// 채팅룸 형식 010 전송결과 or 예약전송인지 확인 상태
type TMsgResultPageRecoil = 'result' | 'schedule' | null;
export const msgResultPageRecoil = atom<TMsgResultPageRecoil>({
  key: 'msgResultPageRecoil',
  default: null,
});

// 015관련 권한 State
export const say015AuthState = atom<any>({
  key: 'say015AuthState',
  default: {
    say015User: false,
    availabilityStatus: false,
  },
});

// 015관련 권한에 따른 page
export const say015PageState = atom<string | null>({
  key: 'say015PageState',
  default: null,
});

// ARS 페이지 분기처리 State
export const needsGuidePageRecoil = atom<boolean | null>({
  key: 'needsGuidePageRecoil',
  default: null,
});

// 015 번호
export const say015Number = atom<string | null>({
  key: 'say015Number',
  default: null,
});

// Ars state
export const selectButtonState = atom<any>({
  key: 'selectButtonState',
  default: '',
});

// Ars 수정 삭제 ul state
export const udBoxState = atom<any>({
  key: 'udBoxState',
  default: '',
});

/* 타입이 BLANK_ADD 인 트리의 설정 팝업이 열려져 있는 아이디 담는 RECOIL */
export const focusBlankAddTreeRecoil = atom<any>({
  key: 'focusBlankAddTreeRecoil',
  default: null,
});

// Ars 목업에서 재생중인 wav파일 버튼 상태와 카드 상태를 위한 Recoil
type TIsPlayRecoil = {
  [key: string]: boolean;
};
export const isPlayRecoil = atom<TIsPlayRecoil>({
  key: 'isPlayRecoil',
  default: {},
});

type TarsSoftPhoneRecoil = 'mock_s1' | 'mock_s2' | 'mock_s3' | 'real_s1' | 'real_s2' | null;
// ars용 소프트폰에서 목업, 시나리오, 실사용 상태로 나누기위한 Recoil
export const arsSoftPhoneRecoil = atom<TarsSoftPhoneRecoil>({
  key: 'arsSoftPhoneRecoil',
  default: null,
});

// ars 목업 페이지에서 서비스 시작하기 버튼 클릭시 소프트폰 화면 전환을 위한 Recoil
export const join015ToggleRecoil = atom<boolean>({
  key: 'join015ToggleRecoil',
  default: false,
});

// ars 반응형 softphone 토글 STATE
export const arsIsActiveRecoil = atom<boolean>({
  key: 'arsIsActiveRecoil',
  default: false,
});

// arsPhone 문자발송 컴포넌트 표출 상태 Recoil
export const arsMsgToggleRecoil = atom<boolean>({
  key: 'arsMsgToggleRecoil',
  default: false,
});

// 015가입자가 목업을 볼 때 true로 변경해야하는 Recoil
export const userMockupViewRecoil = atom<boolean>({
  key: 'userMockupViewRecoil',
  default: false,
});

// 휴대폰 active 숫자
export const activeNumberRecoil = atom<string | null>({
  key: 'activeNumberRecoil',
  default: null,
});

// 휴대폰 active 된 번호에 따라 ARS 카드 애니메이션 되는 ID
export const animationNumberRecoil = atom<string | null>({
  key: 'animationNumberRecoil',
  default: null,
});

// ARS 인사말, 음성 재생 카드에서 음성파일 재생중인 id 를 담는 recoil
export const activePlayRecordIdRecoil = atom<string | null>({
  key: 'activePlayRecordIdRecoil',
  default: null,
});

// ARS 트리 간편보기 여부
export const simpleViewArsTreeRecoil = atom<boolean>({
  key: 'simpleViewArsTreeRecoil',
  default: false,
});

// ARS 선택된 시나리오
export const selectedArsScenarioInfoRecoil = atom<IArsScenarioInfoListS | null>({
  key: 'selectedArsScenarioInfoRecoil',
  default: null,
});

// 회원가입시 015 번호 발급 체크를 위한 atom 리팩토링후 삭제해야함
// TODO - 해당 코드 사용하는곳 리팩토링후 삭제해야함. 긴급용
export const checkConfirm015Recoil = atom<boolean>({
  key: 'checkConfirm015Recoil',
  default: false,
});
