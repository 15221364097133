import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconChatUser: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M10 0.799988C11.2731 0.799988 12.494 1.3057 13.3941 2.20588C14.2943 3.10605 14.8 4.32695 14.8 5.59999C14.8 6.87303 14.2943 8.09393 13.3941 8.9941C12.494 9.89428 11.2731 10.4 10 10.4C8.72699 10.4 7.50609 9.89428 6.60591 8.9941C5.70574 8.09393 5.20002 6.87303 5.20002 5.59999C5.20002 4.32695 5.70574 3.10605 6.60591 2.20588C7.50609 1.3057 8.72699 0.799988 10 0.799988ZM10 12.8C15.304 12.8 19.6 14.948 19.6 17.6V20H0.400024V17.6C0.400024 14.948 4.69602 12.8 10 12.8Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconChatUser;
