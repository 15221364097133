/* eslint-disable */
import React from 'react';
import { usePageCharge } from '@/components/hooks/customs/charge/chargeStation/useCharge';
import { IMenus015 } from '@/components/hooks/customs/charge/chargeStation/useChargeMenuItems';
import { amountFormat } from '@/shared/util/format/amountFormatUtil';
// import ChargePopup from '@/components/Molecules/Charge/ChargePopup';
import ChargeOnlinePopup from '@/components/Molecules/Charge/charge/chargeOnlienPopup/ChargeOnlinePopup';
import { AmountCardHoverStateType } from '@/components/hooks/customs/charge/chargeStation/useChargeStateHandler';
import { WORKS015_ANNUAL, WORKS015_MONTHLY } from '@/shared/constants';
import { windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useRecoilValue } from 'recoil';

/**
 * @title 015 아용요금 결제 컴포넌트의 월간 이용권 카드 컴포넌트
 *
 * @author 정휘학
 * @since 2024.05.17
 * */

interface IMy015ChargeMidCard {
  // 결제 수단 코드 0 : 캐시, 11 : 카드, 21 : 무통장, 31 : 휴대폰
  payMethodTypeCodeState: IMenus015['payMethodTypeCode'];
  // 이용권 타입 : monthly - 월간 , annual - 연간
  productType: 'monthly_pass' | 'annual_pass';
  // 결제 금액
  amount: number;
  // 마우스가 현재 올라가있는 결제 카드에 따라 state 변경 해주는 hook
  handleChangeInfoCard(hoverCardType: AmountCardHoverStateType): void;
}

const My015ChargeMidCard = (props: IMy015ChargeMidCard) => {
  // 월간가격
  const monthlyPrice = parseInt(WORKS015_MONTHLY as string, 10);
  // 연간가격
  const annualPrice = parseInt(WORKS015_ANNUAL as string, 10);
  // 결제 API HOOK
  const chargeHooks = usePageCharge({ returnUrl: 'EasyComponent' });

  // 반응형 상태값
  const windowWidth = useRecoilValue(windowWidthRecoil);

  return (
    <>
      {/* 이지페이 팝업창 */}
      {/* {chargeHooks.easyPayPopupOpen && (
        <ChargePopup
          easyPayPopupDataS={chargeHooks.easyPayPopupDataS}
          isChargeKiccLoading={chargeHooks.isChargeKiccLoading}
          closePopup={chargeHooks.closePopup}
        />
      )} */}
      {/* 입금요청서 팝업창 */}
      {chargeHooks.bankTransferOpenS && (
        <ChargeOnlinePopup
          setBankTransferOpenS={chargeHooks.setBankTransferOpenS}
          yupGetValues={chargeHooks.getValues}
          amount={props.productType === 'monthly_pass' ? monthlyPrice : annualPrice}
        />
      )}
      <li className="w30 hov month" onMouseOver={() => props.handleChangeInfoCard(props.productType)}>
        <div className="top">
          <h4>{props.productType === 'monthly_pass' ? '월간' : '연간'}</h4>
          <p>{props.productType === 'monthly_pass' ? '1개월의 월간 통신료 결제' : '12개월의 월간 통신료 결제'}</p>
        </div>
        <div className="middle">
          <h5>
            {props.productType === 'monthly_pass' ? amountFormat(monthlyPrice) : amountFormat(annualPrice)}
            <span>원</span>
          </h5>
          <p>VAT 포함</p>
          {props.payMethodTypeCodeState === '0' ? (
            <>
              <div className="depositWrap">
                {/* 입금자명 htmlFor depositName 임시 명칭 */}
                <label htmlFor="depositName" className="depositName">
                  <p>입금자명</p>
                  <input type="text" placeholder={'입금자명을 입력해주세요.'} {...chargeHooks.register('name')} />
                  <p className="errorRed">{chargeHooks.errors.name?.message}</p>
                </label>
                <label htmlFor="depositName" className="depositName">
                  <p>알림받을 전화번호 ( 선택 )</p>
                  <input
                    type="string"
                    placeholder={"'-' 제거"}
                    value={chargeHooks.bankTransferPhoneNumberS}
                    {...chargeHooks.register('phoneNumber')}
                  />
                </label>
              </div>
              <button
                onClick={chargeHooks.handleSubmit(() =>
                  chargeHooks.onClickDepositRequest(props.productType === 'monthly_pass' ? monthlyPrice : annualPrice),
                )}
              >
                입금요청서 작성하기
              </button>
            </>
          ) : (
            <button
              onClick={() =>
                chargeHooks.onChargeApi({
                  amount: props.productType === 'monthly_pass' ? monthlyPrice : annualPrice,
                  payMethodTypeCode: props.payMethodTypeCodeState,
                  productType: props.productType,
                  returnUrl: 'EasyComponent',
                })
              }
            >
              결제하기
            </button>
          )}
        </div>
        {/* 월간, 연간 통신료에 관한 서비스 안내 */}
        {props.productType === 'monthly_pass' ? (
          /* 월간 통신료 카드 하단 서비스 내용 */
          <div className="bottom">
            <div className="box">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>음성사서함 무제한 이용</p>
            </div>
            <div className="box">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>SMS 발송 100건 제공</p>
            </div>
            <div className="box">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>STT 변환 월 5분 제공</p>
            </div>
            <div className="box">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>TTS 변환 일 5건 제공</p>
            </div>
          </div>
        ) : (
          /* 연간 통신료 카드 하단 서비스 내용 */
          <div className="bottom">
            <div className="box blue">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>17% 할인</p>
            </div>
            <div className="box">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>음성사서함 무제한 이용</p>
            </div>
            <div className="box">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>SMS 발송 100건 제공</p>
            </div>
            <div className="box">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>STT 변환 월 5분 제공</p>
            </div>
            <div className="box">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M9.35652 16.7068C11.1134 16.7068 12.8159 16.1051 14.1739 15.0043C15.532 13.9035 16.4616 12.3717 16.8043 10.6699C17.147 8.96808 16.8817 7.20153 16.0535 5.67126C15.2253 4.14099 13.8855 2.94168 12.2624 2.27767C10.6393 1.61366 8.83326 1.52605 7.15206 2.02975C5.47086 2.53345 4.0185 3.59731 3.04246 5.04005C2.06641 6.4828 1.62706 8.21516 1.79928 9.94196C1.97149 11.6688 2.74461 13.2832 3.9869 14.5101"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12.7314 7.54004L9.69563 11.1384C9.14298 11.7934 8.86623 12.1217 8.49413 12.1384C8.12288 12.155 7.8166 11.8525 7.20573 11.2492L5.98145 10.04"
                  stroke="#676767"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <p>TTS 변환 일 5건 제공</p>
            </div>
          </div>
        )}
      </li>
    </>
  );
};

export default My015ChargeMidCard;
