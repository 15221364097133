import styled from 'styled-components';

export const AmountHelp = styled.div`
  .amountGroup {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
`;

export const AmountHelpWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 -1rem;
  padding: 0 1rem;
  height: 100%;
`;

export const AnnouncementGroup = styled.div`
  margin-bottom: 9rem;
  .announcement {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    :last-child {
      margin-bottom: 0;
    }
    > p {
      margin-right: 1.2rem;
    }
    .guideText {
      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
`;

export const AmountGroup = styled.div`
  border: 1px solid #adc1f4;
  padding: 3rem 1.2rem;
  background: #f6f7fe;
  border-radius: 1rem;
  text-align: center;

  .top {
    p {
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
      color: #6e6e71;
    }
    h3 {
      font-size: 2rem;
      font-weight: 700;
    }
  }
  .arrowRow {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background: #d6d6dc;
    margin: 2rem 0;
    .arrowRowSpan {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      border-top: 11px solid #d6d6dc;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      ::before {
        content: '';
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        border-top: 11px solid #f6f7fe;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
      }
    }
  }

  .bottom {
    font-size: 1.3rem;
    line-height: normal;
    color: #191919;
    b {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
`;
