import React from 'react';

import ATitle from '@/components/Atom/ATitle';
import BaseTable from '../Table';
import BaseButton from '../../Atom/BaseButton';
import { usePayHistory } from '../../hooks/customs/charge/usePayHistory';

import Paginations01Index from '../../common/paginations/Pagination02/Pagination02.index';
import ChargeDateSearch from '../../Molecules/Charge/ChargeDateSearch';
import styled from 'styled-components';
import Loader from '../../common/Loader';
import * as TS from '@/styles/table.styles';
import UsageHistoryTable from './UsageHistoryTable';

const PaymentHistory = styled.div`
  > h2 {
    margin-bottom: 2rem;
  }
  .top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 0 1rem;
  }
  .payText {
    line-height: 1.4em;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    .top {
      margin: 0 0 3rem;
      > button {
        font-size: 1.5rem;
        width: 100%;
        padding: 1rem;
      }
    }
    .payText {
      line-height: normal;
      font-size: 1.3rem;
    }
  }
`;
const TableFoot = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2.5rem 0;
`;

const BaseTableCover = styled.div`
  /* min-height: 400px; */

  & tr td:last-child {
    padding: 0;
  }
`;

const PaymentHistoryTable = () => {
  const {
    totalPage,
    handlePageChange,
    activePage,
    setActivePage,
    startPage,
    setStartPage,
    thead,
    payResultData,
    selectedValue,
    disabledDate,
    setSearchSelectValue,
    setSearchDates,
    setCurrentPage,
    handlePrint,
    isLoading,
  } = usePayHistory();

  return (
    <PaymentHistory className="paymentHistory">
      <TS.Wrapper>
        <ATitle type="sub" text="결제내역" />
        <div className="top">
          <ChargeDateSearch
            chargeOptionsProp={selectedValue}
            name1="결제내역"
            disabledDate={disabledDate}
            setSearchSelectValue={setSearchSelectValue}
            setSearchDates={setSearchDates}
            setStartPage={setStartPage}
            setActivePage={setActivePage}
            setCurrentPage={setCurrentPage}
          />
          <BaseButton padding=".5rem 1rem" fontSize="1.4rem" fontWeight="bold" onClick={handlePrint}>
            조회 기간 전체 영수증 인쇄
          </BaseButton>
        </div>
        <BaseTableCover>
          <BaseTable type="line" thead={thead} tbody={payResultData} />
          {isLoading && <Loader backgroundToggle />}
        </BaseTableCover>
        <TableFoot>
          <Paginations01Index
            dataCount={totalPage}
            startPage={startPage}
            setStartPage={setStartPage}
            activePage={activePage}
            setActivePage={setActivePage}
            eventHook={handlePageChange}
            pageSize={10}
          />
        </TableFoot>
        <p className="payText">
          결제 내역은 최근 3년간의 내역을 검색할 수 있으며, 이전 자료는 자동 삭제 됩니다.
          <br />
          결제한 내역에 대해 영수증을 인쇄할 수 있습니다.
        </p>
      </TS.Wrapper>
      {/* 사용내역 */}
      <UsageHistoryTable />
    </PaymentHistory>
  );
};

export default PaymentHistoryTable;
