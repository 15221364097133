import React, { useEffect, useState } from 'react';
import { FadeLoader } from 'react-spinners';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); // 검정 배경 투명도
  z-index: 1000;
`;

const LoaderContent = styled.div`
  font-size: 1.8rem;
  color: white;
`;

const MobileLoader = () => {
  // const [dots, setDots] = useState('');

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setDots((prev) => (prev.length < 3 ? `${prev}.` : '')); // 템플릿 리터럴로 변경
  //   }, 500);

  //   return () => clearInterval(interval); // 컴포넌트가 언마운트되면 인터벌을 클리어
  // }, []);

  return (
    <LoaderWrapper>
      <FadeLoader color="#FFF" />
      {/* <LoaderContent>로딩중 입니다{dots}</LoaderContent> */}
    </LoaderWrapper>
  );
};

export default MobileLoader;
