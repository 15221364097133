import { useAddressBookTable } from '@/components/hooks/customs/useAddressBookTable';
import {
  mobileBuddyListAddRecoil,
  selectedMobileGroupListRecoil,
  syncLoadingRecoil,
} from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { instance } from '@/shared/lib/clientAxios';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { TbUser } from 'react-icons/tb';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as S from './MobileAddressBuddyList.style';
import { useNavigate } from 'react-router-dom';
import { chatUserInfoRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import IconChatUser from '@/styles/icons/IconChatUser';
import NoData from '@/components/common/NoData';

const fetchGroupBuddyList = async ({ pageSize, groupSeqNo, keyword, pageNumber }: any) => {
  try {
    const response = await instance.get('/groupBuddyList', {
      params: {
        pageSize,
        groupSeqNo,
        keyword,
        pageNumber,
      },
    });
    return response.data; // 응답 데이터 반환
  } catch (error) {
    console.error('Error fetching buddy list:', error);
    throw new Error('Failed to fetch buddy list'); // 에러 처리
  }
};

const MobileAddressBuddyList = () => {
  const [mobileBuddyListAddS, setMobileBuddyListAddS] = useRecoilState(mobileBuddyListAddRecoil);
  const selectedMobileGroupListS = useRecoilValue(selectedMobileGroupListRecoil);
  const { groupSeqNo } = selectedMobileGroupListS;

  const [searchValue, setSearchValue] = useState<string>(''); // 검색어
  const [debouncedValue, setDebouncedValue] = useState<string>('');

  const setMobileBuddyListAddRecoilS = useSetRecoilState(mobileBuddyListAddRecoil);
  const setSyncLoading = useSetRecoilState(syncLoadingRecoil);

  /* messageHistory api 요청 params */
  const [, setMhQueryParams] = useRecoilState(mhQueryParamsRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const [chatUserInfo, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);

  // Clear added buddy list on mount
  useEffect(() => {
    setMobileBuddyListAddS({});
  }, [setMobileBuddyListAddS]);

  // Debounce effect
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 400);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  // Infinite query to fetch buddy list
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } = useInfiniteQuery(
    ['groupBuddyList', debouncedValue, groupSeqNo],
    ({ pageParam = 0 }) =>
      fetchGroupBuddyList({
        pageSize: 50,
        groupSeqNo: groupSeqNo!,
        keyword: debouncedValue,
        pageNumber: pageParam,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        return pages.length < lastPage.totalPages ? pages.length + 1 : undefined; // 페이지 인덱스 증가
      },
    },
  );

  // 페이지 로드시 데이터를 리패치
  useEffect(() => {
    refetch();
  }, [refetch]);

  // 데이터 가져올때 로딩창
  useEffect(() => {
    if (isLoading) {
      setSyncLoading(true);
    } else {
      setSyncLoading(false);
    }
  }, [isLoading]);

  // Scroll handler to fetch more data
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  const options = data ? data.pages.flatMap((page) => page.content) : [];

  const navigate = useNavigate();

  return (
    <>
      <S.SearchContainer className="messageAddSearch">
        <S.SearchInput
          type="text"
          placeholder="이름, 전화번호 검색"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </S.SearchContainer>
      <S.AddressBuddyListContainer className="mobileAddressBuddyList" onScroll={handleScroll}>
        {options.length === 0 && !isLoading && <NoData />}
        {options?.map((buddy) => {
          return (
            <S.BuddyListContainer
              key={buddy.buddySeqNo}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => {
                if (buddy.keyCommNo) {
                  navigate(`/mobile/message/messageChatRoom/${buddy.keyCommNo}`);

                  setChatUserInfo({ phoneNumber: buddy.keyCommNo, buddyName: buddy.buddyNm });
                  setMobileBuddyListAddRecoilS({
                    buddyNm: buddy.buddyNm,
                    keyCommNo: buddy.keyCommNo,
                  });

                  setMhQueryParams((prevState: any) => {
                    return {
                      ...prevState,
                      contactNumber: buddy.keyCommNo,
                    };
                  });
                }
              }}
            >
              <S.BuddyImg>
                <IconChatUser />
              </S.BuddyImg>
              <S.BuddyContents>
                <h3>{buddy.buddyNm}</h3>
                <p>{TelHyphen(buddy.keyCommNo)}</p>
              </S.BuddyContents>
            </S.BuddyListContainer>
          );
        })}
      </S.AddressBuddyListContainer>
    </>
  );
};

export default MobileAddressBuddyList;
