import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import React from 'react';
import * as S from '../MobileHeader.style';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { addNumberValueRecoil, mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { chatUserInfoRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';

const MessageNewAddHeader = () => {
  const navigate = useNavigate();
  const [addNumberValueS, setAddNumberValueS] = useRecoilState(addNumberValueRecoil);

  const [, setMhQueryParams] = useRecoilState(mhQueryParamsRecoil);
  const [mobileBuddyListAddS, setMobileBuddyListAddS] = useRecoilState(mobileBuddyListAddRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const [chatUserInfo, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);

  const onClickCreateChatroom = () => {
    if (addNumberValueS) {
      navigate(`/mobile/message/messageChatRoom/${addNumberValueS}`);
      setChatUserInfo({ phoneNumber: addNumberValueS, buddyName: '' });
      setMobileBuddyListAddS({
        buddySeqNo: '',
        buddyNm: '',
        keyCommNo: addNumberValueS,
      });
      setMhQueryParams((prevState: any) => {
        return {
          ...prevState,
          contactNumber: addNumberValueS,
        };
      });
    }
  };

  return (
    <>
      <S.HeaderLeft className="messageNewAddHeader">
        <S.HeaderButtonIcon
          onClick={() => {
            navigate(-1);
          }}
        >
          <IconArrowLongLeft />
        </S.HeaderButtonIcon>
        <S.HeaderTitle>신규 대화</S.HeaderTitle>
      </S.HeaderLeft>
      <S.IconContainer>
        <S.HeaderButtonFont onClick={onClickCreateChatroom} className={`${addNumberValueS && 'active'}`}>
          확인
        </S.HeaderButtonFont>
      </S.IconContainer>
    </>
  );
};
export default MessageNewAddHeader;
