import { atom } from 'recoil';

import {
  IActiveCharRoomId,
  IUseQueryAllMessageList,
} from '@/components/softPhone/DisplayGroups/MessageManagement/ChatRoom/ChatRoom.types';
import {
  IChatListData,
  IChatUserInfoRecoil,
  IUseQueryMessageHistory,
} from '@/pages/main/components/etcLeftZone/chatRoom015/ChatRoom015.types';

/**
 * @title allMessageList api 요청 params recoil
 * */
export const amlQueryParamsRecoil = atom<IUseQueryAllMessageList>({
  key: 'amlQueryParamsRecoil',
  default: { pageSize: 20, pageNumber: 0, keyword: null },
});

/**
 * @title 클릭한 채팅방 리스트 아이디 recoil
 * */
export const activeChatRoomIdRecoil = atom<IActiveCharRoomId>({
  key: 'activeChatRoomIdRecoil',
  default: null,
});

/**
 * @title messageHistory api 요청 params recoil
 * */
export const mhQueryParamsRecoil = atom<IUseQueryMessageHistory>({
  key: 'mhQueryParamsRecoil',
  default: { pageSize: 30, pageNumber: 0, contactNumber: null },
});

/**
 * @title 활성화 채팅 상대방 INFO ( 번호, 이름 )
 * */
export const chatUserInfoRecoil = atom<IChatUserInfoRecoil>({
  key: 'chatUserInfoRecoil',
  default: { phoneNumber: null, buddyName: null },
});

/**
 * @title 마지막 활성화 채팅 상대방 번호
 */
export const chatSaveNumberRecoil = atom<string | null>({
  key: 'chatSaveNumberRecoil',
  default: null,
});

/**
 * @title 좌측 채팅방 리스트 데이터
 * */
export const chatListDataRecoil = atom<null | IChatListData>({
  key: 'chatListDataRecoil',
  default: null,
});

/**
 * @title 015 채팅방 메세지 전송 캐쉬 작업 toggle
 * */
export const sendMsgCacheToggleRecoil = atom<boolean>({
  key: 'sendMsgCacheToggleRecoil',
  default: false,
});

/**
 * @title 015 채팅방 메세지 수신 캐쉬 작업 toggle
 * */
export const rcvMsgCacheToggleRecoil = atom<boolean>({
  key: 'rcvMsgCacheToggleRecoil',
  default: false,
});

/**
 * @title 015 채팅방 새로운 수신 메세지 팝업 toggle
 * */
export const rcvMsgPopupToggleRecoil = atom<boolean>({
  key: 'rcvMsgPopupToggleRecoil',
  default: false,
});

/**
 * @title 음성녹음 파일 get api isLoading
 * */
export const vmsGetApiIsLoadingRecoil = atom<boolean>({
  key: 'vmsGetApiIsLoadingRecoil',
  default: true,
});

/**
 * @title 채팅방 메세지 작성 컴포넌트 height
 * */
export const footerHeightRecoil = atom<number>({
  key: 'footerHeightRecoil',
  default: 0,
});
