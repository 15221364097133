import { atom } from 'recoil';

/**
 * @title 메인 주소록 테이블 표출될 아이템 label
 * */
export const addressColumViewValueRecoil = atom<Set<string>>({
  key: 'addressColumViewValueRecoil',
  default: new Set(['groupNm', 'buddyNm', 'keyCommNo', 'emailId', 'etcInfo']),
});

/**
 * @title 메인 주소록 테이블 표출될 아이템 value
 * */
export const addressColumViewLabelRecoil = atom<Set<string>>({
  key: 'addressColumViewLabelRecoil',
  default: new Set(['그룹', '이름', '휴대폰번호', '이메일', '메모']),
});

/**
 * @title 대량추가 주소록 테이블 표출될 아이템 label
 * */
export const addressLargeColumViewValueRecoil = atom<Set<string>>({
  key: 'addressLargeColumViewValueRecoil',
  default: new Set(['buddyNm', 'keyCommNo', 'emailId', 'etcInfo']),
});

/**
 * @title 대량추가 주소록 테이블 표출될 아이템 value
 * */
export const addressLargeColumViewLabelRecoil = atom<Set<string>>({
  key: 'addressLargeColumViewLabelRecoil',
  default: new Set(['이름', '휴대폰번호', '이메일', '메모']),
});

// 항목추가 모달 토글할 Recoil
export const isModalOpenRecoil = atom<boolean>({
  key: 'isModalOpenRecoil',
  default: false,
});
