import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem 1.6rem;
`;

export const ListModal = styled.div`
  position: relative;
  width: 100%;
`;

export const ListModalInput = styled.input`
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray500};
  color: ${(props) => props.theme.colors.txt500};

  ::placeholder {
    color: ${(props) => props.theme.colors.deactivation};
  }
  :focus {
    outline: 0;
  }
  font-size: 1.6rem;
`;
