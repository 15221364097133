import styled from 'styled-components';

/* Start EtcChattingBody */
type EtcChattingBodyType = {
  viewScroll: boolean;
  footHeight: number;
};
export const EtcChattingBody = styled.div<EtcChattingBodyType>`
  padding: 0 0.6rem;
  margin: 0.6rem;
  height: 100%;

  overflow: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: transparent;
    z-index: 10;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => (props.viewScroll ? '#999' : 'transparent')};
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }
`;

export const EtcChattingDateText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0 3rem;
  text-align: center;

  &:nth-of-type(n + 2) {
    margin: 3rem 0;
  }

  span {
    padding: 0.5rem 2rem;
    border-radius: 10rem;
    font-size: 1.2rem;
    font-weight: 500;
    background: ${(props) => props.theme.colors.gray700}80;
    color: ${(props) => props.theme.colors.txt100};
  }
`;

/* Start My */
export const EtcChattingMyZoneWrap = styled.div``;

export const EtcChattingMyZone = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
`;

export const EtcChattingMyTextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  min-height: 5rem;
  border-radius: 1.5rem 0px 1.5rem 1.5rem;
  background-color: ${(props) => props.theme.colors.main};

  > p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6em;
    word-break: break-word;
    white-space: pre-wrap;
    color: ${(props) => props.theme.colors.txt100};
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;

export const EtcChattingMyImageBox = styled.div`
  padding: 0px 0.2rem 0px;
  overflow: hidden;
  border: 2px solid transparent;
  border-bottom: none;
  border-radius: 1.5rem 0px 1.5rem 1.5rem;
  cursor: pointer;

  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
`;
/* End My */

/* Start Your */

export const EtcChattingYourZone = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0 1rem;
  align-items: flex-end;
  margin-bottom: 2.5rem;
  flex-direction: row;

  &.mt {
    justify-content: flex-end;
    .etcChattingFileWrap {
      order: 2;
      .filePreviewContainer {
        background: transparent;
      }
    }
  }

  &:has(audio) {
    flex-wrap: wrap;
  }
`;

export const EtcChattingContentWrap = styled.div`
  // My, Your 공용
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  max-width: 80%;

  &:last-of-type {
    @media screen and (max-width: 768px) {
      /* margin-bottom: 20px; */
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 90%;
  }
`;

export const EtcChattingMusicWrap = styled.div`
  position: relative;
  max-width: 24rem;
  border-radius: 1.5rem;
  padding: 1.5rem;
  background: ${(props) => props.theme.colors.white};

  .chatControlWrap {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    .dot {
      content: '';
      display: block;
      width: 0.3rem;
      height: 0.3rem;
      background: ${(props) => props.theme.colors.txt200};
      border-radius: 50%;
    }
    button {
      p {
        font-size: 1.2rem;
        color: ${(props) => props.theme.colors.txt200};
      }
    }
  }
  @media screen and (max-width: 480px) {
    max-width: calc(100% - 6rem);
  }
`;

export const EtcChattingYourMuiscBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  margin: 0 0 1.5rem;
  padding: 1rem;
  width: 100%;
  min-height: 4.8rem;
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};
  overflow: hidden;
  /* @media screen and (max-width: 480px) {
    gap: 0.8rem;
  } */
  button {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 3.2rem;
    height: 3.2rem;
    font-size: 1rem;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.gray800};
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
  }
  span {
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    min-width: 4rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.txt300};
  }

  @keyframes Equalizer {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: block;
    width: 0;
    height: 100%;
    background: ${(props) => props.theme.colors.main};
    transition: 0.5s all;
    pointer-events: none;
  }
  .triangle {
    width: 1.3rem;
    height: 1.4rem;
  }
  .square {
    position: absolute;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.2rem;
    background: ${(props) => props.theme.colors.txt100};
  }
  &.active {
    &::before {
      width: 100%;
    }
    span {
      color: ${(props) => props.theme.colors.txt100};
    }

    ul {
      li {
        background: rgba(134, 161, 242, 0.8);
        &::after {
          animation: none;
        }
        &.active::after {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          display: block;
          width: 100%;
          height: 0;
          background: ${(props) => props.theme.colors.txt100};
          animation: Equalizer 0.5s 1s ease-out alternate infinite;
        }
      }
    }
  }
`;

export const EtcChattingYourMuiscEqualizer = styled.ul`
  position: relative;
  transform: translateY(50%);
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 86px;

  li {
    position: relative;
    transform: translateY(-50%);
    width: 2px;
    height: 1px;
    border-radius: 1px;
    background: ${(props) => props.theme.colors.txt200};
    &:nth-of-type(1) {
      height: 2px;
    }

    &:nth-of-type(2) {
      height: 8px;
    }

    &:nth-of-type(3) {
      height: 14px;
    }

    &:nth-of-type(4) {
      height: 4px;
    }

    &:nth-of-type(5) {
      height: 16px;
    }

    &:nth-of-type(6) {
      height: 14px;
    }

    &:nth-of-type(7) {
      height: 10px;
    }

    &:nth-of-type(8) {
      height: 10px;
    }

    &:nth-of-type(9) {
      height: 10px;
    }

    &:nth-of-type(10) {
      height: 14px;
    }

    &:nth-of-type(11) {
      height: 10px;
    }

    &:nth-of-type(12) {
      height: 16px;
    }

    &:nth-of-type(13) {
      height: 10px;
    }

    &:nth-of-type(14) {
      height: 4px;
    }

    &:nth-of-type(15) {
      height: 2px;
    }
  }

  @media screen and (max-width: 376px) {
    display: none;
  }
`;

export const EtcChattingControlWrap = styled.div`
  /* width: 3.8rem;
  height: 4.8rem;
  gap: 0; */
`;

// TMS 음성변환하기 아이콘
export const EtcChattingMusicTextChange = styled.button`
  border: none;
  border-radius: 0 1.5rem 1.5rem;
  background: ${(props) => props.theme.colors.white};
  transition: width 0.3s;
  outline: none;
  color: ${(props) => props.theme.colors.txt200};
  cursor: pointer;
`;
// TMS 음성다운로드 아이콘
export const EtcChattingMusicDownload = styled.button`
  border: none;
  border-radius: 0 1.5rem 1.5rem;
  background: ${(props) => props.theme.colors.white};
  transition: width 0.3s;
  outline: none;
  color: ${(props) => props.theme.colors.txt200};
  cursor: pointer;
`;

export const EtcChattingMusicHoverText = styled.p`
  /* 텍스트 변환하기 */
  z-index: -1;
  position: absolute;
  right: 5rem;
  opacity: 0;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.txt100};
`;

export const MusicChangeAfterContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 3rem;
`;

export const MusicChangeAfterContentWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  max-width: 80%;
`;

export const MusicChangeAfterTextWrap = styled.div`
  /* 텍스트 변환 후 텍스트 박스 */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  min-height: 5rem;
  border-radius: 0px 1.5rem 1.5rem 1.5rem;
  background: ${(props) => props.theme.colors.white};
  @media screen and (max-width: 768px) {
    padding: 1.2rem;
    font-size: 1.4rem;
  }
  > p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 25px;
    word-break: break-word;
    color: ${(props) => props.theme.colors.txt500};
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;

export const EtcChattingYourTextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  min-height: 5rem;
  border-radius: 0px 1.5rem 1.5rem 1.5rem;
  background: ${(props) => props.theme.colors.white};
  @media screen and (max-width: 768px) {
    padding: 1.2rem;
    font-size: 1.4rem;
  }
  > p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6em;
    word-break: break-word;
    white-space: pre-wrap;
    color: ${(props) => props.theme.colors.txt500};
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;
export const EtcChattingYourImageBox = styled.div`
  padding: 0px 0.2rem 0px;
  overflow: hidden;
  border: 2px solid transparent;
  border-bottom: none;
  border-radius: 0px 15px 15px 15px;
  cursor: pointer;
  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
`;

// 채팅룸 파일
export const EtcChattingFileWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 22rem;
  padding: 1.5rem;
  background: ${(props) => props.theme.colors.white};
  border-radius: 1.5rem;

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
`;

export const FilePreviewContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  background: ${(props) => props.theme.colors.white};

  /* 추가적인 스타일 */
  img,
  video,
  audio {
    display: block;
    width: 100%;
  }
  img {
    max-width: 100%; /* 이미지가 화면에 맞게 조정되도록 */
    height: auto;
    border-radius: 1rem;
  }

  audio {
    display: block;
    height: 3.8rem;
  }
  .documentMessage {
    border-radius: 0.5rem;
    color: ${(props) => props.theme.colors.txt500};
    font-size: 1.4rem;
    line-height: normal;
    word-break: break-all;
    /* 기타 스타일 */
  }
`;

export const FileControlBtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
`;

export const FileBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.2rem;
  min-height: 3.2rem;
  background: ${(props) => props.theme.colors.gray100};
  color: ${(props) => props.theme.colors.txt500};
  border-radius: 55rem;
  font-size: 1.2rem;
  &:hover {
    background: ${(props) => props.theme.colors.gray300};
  }
  &.round {
    border-radius: 50rem;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

/* End Your */

export const EtcChattingHourText = styled.span`
  // My, Your 공용
  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.txt300};
  @media screen and (max-width: 540px) {
    font-size: 1.1rem;
  }
`;

export const EtcChattingReSendBtn = styled.span`
  // 재전송 버튼
  margin-right: 0.4rem;
  font-size: 1.4rem;
  cursor: pointer;
`;

export const EtcChattingSnedCancelBtn = styled.span`
  // 전송취소 버튼
  margin-right: 0.4rem;
  font-size: 1.4rem;
  cursor: pointer;
`;

/* Start Modal */
export const EtcChattingBigImageModal = styled.div`
  // Modal
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  &.mobileBigImg {
    background: #000000;
  }
`;

export const EtcChattingBigImageWrap = styled.div`
  position: relative;
  max-width: calc(90% - 30px);
  max-height: 90%;
  @media screen and (min-width: 1441px) {
    max-width: calc(70% - 30px);
  }
`;

export const EtcChattingBigImage = styled.img`
  width: 100%;
  max-height: calc(-30px + 90vh);
  object-fit: contain;
`;

export const EtcChattingBigImageModalcloseBtn = styled.button`
  // closeBtn
  cursor: pointer;
  position: absolute;
  top: -4rem;
  right: 0;
  padding: 0;
  appearance: none;
  border: none;
  outline: none;
  svg {
    width: 3.1rem;
    height: 3.1rem;
  }
  &:focus {
    outline: none;
  }
`;

/* End Modal */
/* End EtcChattingBody */

// 모바일 채팅방 - 이미지 클릭
export const EtcChattingBigImageModalBtnWrap = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  background-color: ${(props) => props.theme.colors.gray100};
  button {
    flex: 1;
  }
`;

export const MobileEtcChattingBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  height: 5.2rem;
  font-size: 1.8rem;

  &.imgDownload {
    background: ${(props) => props.theme.colors.gray800};
    color: ${(props) => props.theme.colors.txt100};
  }
`;

export const MobileChattingBigImgModal = styled.div`
  // Modal
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  &.mobileBigImg {
    background: #000000;
  }
`;

export const MobileChattingBigImgWrap = styled.div`
  position: relative;
  max-width: unset;
  min-width: 100%;
  max-height: calc(100% - 10.2rem);
  padding: 2rem;
`;

export const MobileChattingBigImg = styled.img`
  width: 100%;
  max-height: calc(-10.2rem + 90vh);

  object-fit: contain;
`;
