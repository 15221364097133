import MessageResultTable from '@/components/Organism/Charge/MessageResultTable';
import React from 'react';

const MobileEtcMessageResult = () => {
  return (
    <>
      <MessageResultTable />
    </>
  );
};
export default MobileEtcMessageResult;
