import { DefaultRowData, GroupListType } from '@/apis/api/addressApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { selectGroupRecoil, unRegisteredPhoneNum } from '@/recoil/atoms/addressList';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useVirtualizer } from '@tanstack/react-virtual';
/* 대량추가 리스트 post할 필드 */
export const defaultAddData = {
  // groupNm: '', // 그룹이름
  groupSeqNo: null, // 그룹고유번호
  buddyNm: '', // 이름
  keyCommNo: '', // 휴대폰번호
  siteId: '', // 서비스이용 사이트 이름(아레오, 네티앙)
  usrId: '', // ?한성노트북
  usrKey: '', // ?
  emailId: '', // 이메일
  birthday: '', // 생일
  coNm: '', // 회사이름
  coHandle: '', // 직함
  coDept: '', // 부서
  genderFlag: '', // 성별
  homeZipCd: '', // 집우편번호
  homeAddr: '', // 집주소
  homeNumber: '', // 집전화
  coZipCd: '', // 회사우편번호
  coAddr: '', // 회사주소
  coNumber: '', // 회사전화
  faxNumber: '', // 팩스
  homepageUrl: '', // 홈페이지
  accountNo: '', // 계좌번호
  marriageDay: '', // 결혼기념일
  messenger: '', // 메신저
  number015: '', // 015번호
  etcInfo: '', // 메모
  residentNo: '', // 주민번호
  natCd: '', // 국가번호
  // deleteBtn: '',
};

export const useAddLargeTable = () => {
  const queryClient = useQueryClient();
  /* 대량추가 테이블 바디 ref */
  const tableRef = useRef<any>(null);
  // const lastInputRef = useRef<HTMLInputElement | null>(null);
  const groupListD: any = queryClient.getQueryData(['groupList2']);
  /* Select STATE */
  const [selectToggleS, setSelectToggleS] = useState<boolean>(false);
  /* Select group 표시할 STATE */
  const [selectGroupS, setSelectGroupS] = useRecoilState(selectGroupRecoil);
  /* rows 초기값 지정 (행 갯수) */
  const initialRows = useMemo(() => Array.from({ length: 10 }, () => ({ ...defaultAddData })), []);
  /* 테이블 rows */
  const [rows, setRows] = useState<DefaultRowData[]>(initialRows);
  /* 미등록 핸드폰 번호 */
  const [unRegisteredPhoneNumS, setUnRegisteredPhoneNumS] = useRecoilState(unRegisteredPhoneNum);
  /* gender Select STATE */
  const [genderToggle, setGenderToggle] = useState<{ [key: number]: boolean }>({});
  /* excel로 받아오는 성별을 문자열로 변환 */
  const genderLabels = ['선택 안 함', '여', '남'];

  const { warningModal } = useModalHook();

  const handleClickOutside = (event: any) => {
    const focusElement = document.querySelectorAll('td.focus');
    if (focusElement[0] && !focusElement[0].contains(event.target as Node)) {
      setGenderToggle((prev) => {
        // `prev` 객체의 모든 key 값을 순회하며, 각 key에 대해 false 설정
        const allFalse = Object.keys(prev).reduce(
          (acc, key) => {
            acc[Number(key)] = false;
            return acc;
          },
          {} as { [key: number]: boolean },
        );

        return allFalse;
      });
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /* row 추가하는 Hook */
  const handleAddRow = useCallback(() => {
    setRows((prevRows) => [...prevRows, { ...defaultAddData }]);
  }, []);

  /* onClick row 삭제하는 btn Hook */
  const onClickDeleteRow = (indexToDelete: number) => {
    if (rows.length > 1) {
      const updatedRows = rows.filter((_, index) => index !== indexToDelete);
      setRows(updatedRows);
    } else {
      warningModal('주소록 추가', '마지막 항목입니다.', true);
    }
  };

  /* input onChange Hook */
  const handleInputChange = useCallback((index: number, field: string, value: string) => {
    setRows((prevRows) => {
      // 필드가 성별이고 벨류가 성별일때 분류하고 필드가 성별이 아니면 value 그대로 리턴
      let newValue;
      if (field === 'genderFlag') {
        if (value === '여') {
          newValue = '1';
        } else if (value === '남') {
          newValue = '2';
        } else {
          newValue = '0';
        }
        setGenderToggle((prev) => ({ ...prev, [index]: false }));
      } else {
        newValue = value;
      }

      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index], [field]: newValue };
      return newRows;
    });
  }, []);

  // 마지막 row에 포커싱이면 새로운 row 생성
  const handleFocus = (index: number) => {
    if (index === rows.length - 1) {
      handleAddRow();
    }
  };

  /**
   * @title 미등록 번호 length 에 맞게 row 추가
   * */
  useEffect(() => {
    if (!unRegisteredPhoneNumS || unRegisteredPhoneNumS.length === 0) return;
    if (rows.length < unRegisteredPhoneNumS.length) {
      const rowsToAdd = unRegisteredPhoneNumS.length - rows.length;
      for (let i = 0; i < rowsToAdd; i++) {
        handleAddRow();
      }
      return;
    }

    const updatedRows = rows.map((row, index) => {
      if (index < unRegisteredPhoneNumS.length) {
        const unRegisteredItem = unRegisteredPhoneNumS[index];
        return {
          ...row,
          keyCommNo: unRegisteredItem || row.keyCommNo,
        };
      }
      return row;
    });

    setRows(updatedRows);
    setUnRegisteredPhoneNumS(null);
  }, [unRegisteredPhoneNumS, rows.length]);

  /* 그룹 select onClick Hook */
  const onClickSelect = () => {
    setSelectToggleS((prev) => !prev);
  };

  /* 그룹 Select option onClick Hook */
  const onClickSelectGroup = (group: GroupListType) => {
    setSelectToggleS(false);
    setSelectGroupS(group.groupNm);
    setRows((prevRows) => {
      const newRows = prevRows.map((item: DefaultRowData) => {
        return { ...item, groupSeqNo: group.groupSeqNo };
      });
      return newRows;
    });
  };
  const rowVirtualizer = useVirtualizer({
    count: rows.length, // 총 항목 수를 지정
    getScrollElement: () => tableRef.current, // 스크롤 이벤트를 감지할 요소를 반환
    estimateSize: () => 42, // 각 항목의 예상 높이를 40픽셀로 지정
    overscan: 200, // 화면 밖에서 미리 렌더링할 추가 항목 수를 200으로 지정
  });
  return {
    handleAddRow,
    onClickSelect,
    selectToggleS,
    groupListD,
    onClickSelectGroup,
    rows,
    setRows,
    selectGroupS,
    handleInputChange,
    handleFocus,
    onClickDeleteRow,
    rowVirtualizer,
    tableRef,
    genderLabels,
    genderToggle,
    setGenderToggle,
  };
};
