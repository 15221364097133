import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconAddChatting: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M15.3299 1.92129C14.0939 1.52235 12.8009 1.32023 11.5 1.32258C5.70137 1.32258 1 5.2271 1 10.0439C1 11.9239 1.71662 13.6658 2.93594 15.089L1.65625 21L6.796 17.8426C8.28556 18.4558 9.88511 18.7691 11.5 18.7639C17.2986 18.7639 22 14.8594 22 10.0439C22 9.16 21.8425 8.3071 21.5472 7.50323M19.375 1V6.16129M22 3.58065H16.75"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconAddChatting;
