import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import React from 'react';
import * as S from '../MobileHeader.style';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  addNumberValueRecoil,
  addStringValueRecoil,
  mobileBuddyListAddRecoil,
  selectedMobileGroupListRecoil,
} from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { chatUserInfoRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { useMutation } from '@tanstack/react-query';
import { postAddBuddy, postAddGroup } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';

const AddressBuddyAddHeader = () => {
  const navigate = useNavigate();
  const [addStringValueS, setAddStringValueS] = useRecoilState(addStringValueRecoil);
  const [addNumberValueS, setAddNumberValueS] = useRecoilState(addNumberValueRecoil);
  const selectedMobileGroupListS = useRecoilValue(selectedMobileGroupListRecoil);

  const { mutate: addBuddyMutate } = useMutation(postAddBuddy);
  const { warningModal } = useModalHook();

  const newBuddy = {
    groupSeqNo: selectedMobileGroupListS.groupSeqNo,
    buddyNm: addStringValueS,
    keyCommNo: addNumberValueS,
  };

  //연락처 새로 만들기
  const onClickCreateBuddy = () => {
    addBuddyMutate(newBuddy, {
      onSuccess: (data: any) => {
        // 연락처 추가 성공시 cache update
        setAddStringValueS('');
        navigate(-1);
      },
      onError: (error: any) => {
        setAddStringValueS('');
        warningModal('연락처 등록', `에러 발생: ${error.message}`, true);
        navigate(-1);
      },
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <S.HeaderLeft className="messageNewAddHeader">
        <S.HeaderButtonIcon
          onClick={() => {
            navigate(-1);
          }}
        >
          <IconArrowLongLeft />
        </S.HeaderButtonIcon>
        <S.HeaderTitle>연락처 추가</S.HeaderTitle>
      </S.HeaderLeft>
      <S.IconContainer>
        <S.HeaderButtonFont onClick={onClickCreateBuddy} className={`${addStringValueS && 'active'}`}>
          확인
        </S.HeaderButtonFont>
      </S.IconContainer>
    </>
  );
};
export default AddressBuddyAddHeader;
