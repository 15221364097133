import styled from 'styled-components';

type AddLargeTableBodyType = {
  tableTdWidth: number;
};

export const AddLarge = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const AddLargeTableLeftZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem 1rem;
  width: 29rem;
  height: calc(var(--vh, 100vh));
  max-width: 29rem;
  background: ${(props) => props.theme.colors.gray200};
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const LeftLogo = styled.div`
  width: 100%;

  svg {
    color: ${(props) => props.theme.colors.txt500};
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    overflow: hidden;
    img {
      height: auto;
      object-fit: contain;
    }
  }
`;

export const LeftFileDropWrap = styled.div`
  width: 100%;
  height: calc(100% - 11.7rem);
  border: 1px solid ${(props) => props.theme.colors.gray400};
  border-radius: 1rem;
  /* transition: 1s; */
  &:hover {
    background: ${(props) => props.theme.colors.gray300};
  }
  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4.4rem;
    height: 100%;
    cursor: pointer;
    img {
      width: fit-content;
      margin: 0 auto;
      object-fit: scale-down;
    }
    span {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      color: ${(props) => props.theme.colors.gray600};
      &.alert {
        color: ${(props) => props.theme.colors.main};
      }
    }
  }
  input {
    display: none;
  }
`;

export const DownloadBtn = styled.button`
  padding: 1.4rem 0;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.gray600};
  color: ${(props) => props.theme.colors.gray200};
  cursor: pointer;
`;

export const AddLargeTableRightZone = styled.div`
  padding: 1.8rem 1.2rem 0;
  width: calc(100% - 290px);
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const AddLargeBtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  margin-bottom: 2rem;
  button {
    padding: 1rem 2rem;
    outline: none;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    button {
      padding: 1rem 1.2rem;
    }
  }
`;
export const AddLargeResetBtn = styled.button`
  border: 1px solid ${(props) => props.theme.colors.gray800};
  color: ${(props) => props.theme.colors.txt400};
`;
/* Start Save & Cancel */
export const AddLargeSCBtnGroup = styled.div`
  display: flex;
  gap: 0.8rem;
`;
export const AddLargeSaveBtn = styled.button`
  border: 1px solid transparent;
  background: ${(props) => props.theme.colors.gray800};
  color: ${(props) => props.theme.colors.gray100};
`;
export const AddLargeCanceltBtn = styled.button`
  border: 1px solid transparent;
  background: ${(props) => props.theme.colors.gray600};
  color: ${(props) => props.theme.colors.gray100};
`;

// Head FileDropWrap
export const HeadFileDropWrap = styled.div``;

/* End Save & Cancel */

export const AddLargeTableWrap = styled.div`
  position: relative;
  /* min-width: calc(100% - 38rem); */
  height: calc(var(--vh, 100vh) - 82px);
  max-height: calc(var(--vh, 100vh) - 82px);
  overflow: auto;

  /* 스크롤바-배경 */
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    border-radius: 0;
    background: transparent;
  }
  /* 스크롤바-바 */
  ::-webkit-scrollbar-thumb {
    min-height: 50px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
  /* 스크롤바-좌우만나는공간  */
  ::-webkit-scrollbar-corner {
    background: transparent;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-button:start {
    background: transparent;
    width: 0;
    height: 31px;
    background-clip: padding-box;
  }
  .fixedBox {
    button {
      color: ${(props) => props.theme.colors.gray600};
    }
  }

  table {
    thead {
      p {
        font-size: 1.3rem;
      }
    }
  }
`;

export const AddLargeTable = styled.table`
  position: relative;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  white-space: nowrap;
  border-collapse: separate;
  table-layout: fixed;
  thead,
  tbody {
    tr {
      display: table;
      table-layout: auto;
      width: 100%;
      border-radius: 5px;
      border-left-color: transparent;
      td,
      th {
        min-width: 200px;
        vertical-align: middle;
        &:first-of-type {
          border-radius: 5px 0px 0px 5px;
        }

        &:last-of-type {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
  tr {
    /* height: 28px; */
  }
  th,
  td {
    position: relative;
    text-align: center;
    border-collapse: collapse;
    vertical-align: middle;
  }
  /* 공통 체크박스 */
  .fixedBox {
    text-align: center;
    min-width: 40px;
    width: 40px;
    max-width: 40px;
    padding: 5px 0;
  }
`;

export const AddLargeTableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 3;
  display: block;
  font-weight: 600;
  tr {
    height: 28px;
    max-height: 28px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    background: ${(props) => props.theme.colors.gray300};
  }
  th {
    padding: 0rem 2rem;
    height: 30px;
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
    :last-of-type {
      border-right: 0;
    }
    .arrangeGroup {
      p {
        font-size: 1.3rem;
      }
    }
    &:first-of-type {
      position: sticky;
      left: 0;
      z-index: 11;
      min-width: 40px;
      width: 40px;
      border-right: 1px solid transparent;
      background: ${(props) => props.theme.colors.gray300};
    }
  }
`;

export const ArrangeGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Arrange = styled.div`
  display: flex;
  flex-direction: column;
  button {
    border: 0;
    background: transparent;
    outline: none;
  }
`;

export const AscBtn = styled.button``;
export const DescBtn = styled.button``;

export const AddLargeTableBody = styled.tbody<AddLargeTableBodyType>`
  background: ${(props) => props.theme.colors.gray100};
  position: relative;
  width: 100%;

  td {
    width: ${(props) => `${props.tableTdWidth}px`};
    position: relative;
    padding: 0rem 2rem;
    max-height: 40px;
    border-right: 1px solid ${(props) => props.theme.colors.gray300};
    border-top: 1px solid ${(props) => props.theme.colors.gray300};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};

    input[type='text'],
    textarea {
      display: block;
      width: 100%;
      max-height: 30px;

      overflow: hidden auto;
      text-overflow: ellipsis;
      white-space: normal;

      appearance: none;
      border: none;
      outline: none;
      resize: none;
      &:focus {
        border: none;
        outline: none;
      }
      &.focus {
        height: 200px;
      }
    }
    input {
      &::placeholder {
        font-weight: 500;
        color: ${(props) => props.theme.colors.placeholder};
      }
    }
    textarea {
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: #d6d6dc;
      }

      ::-webkit-scrollbar-thumb {
        background: #999;
      }
    }
    &.focus {
      p {
        opacity: 0;
      }
      > div {
        opacity: 1;
        z-index: 1;
      }
    }
    &:first-of-type {
      position: sticky;
      left: 0;
      z-index: 4;
      padding: 0;
      min-width: 40px;
      width: 40px;
      background: #fff;
      border-left: 1px solid ${(props) => props.theme.colors.gray300};
      border-right-color: transparent;
    }
    &:last-of-type {
      /* padding-right: 0; */
    }
  }
`;

export const TBodyFirstLineDivWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LineEliminateBtn = styled.button`
  padding: 10px 0;
  display: flex;
  justify-content: center;
  width: 40px;
  cursor: pointer;
`;

export const LargeTableMemoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/* Start copy Select */
export const SelectBox = styled.div`
  z-index: 10;
  position: relative;
  height: fit-content;
  max-height: 150px;
  /* width: calc(100% - 40px); */
  border: 2px solid transparent;
  button {
    border: 0;
    cursor: pointer;
    gap: 1rem;
  }
  > button {
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem 14px;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 600;
    color: #5b5b5c;
  }
  &.active {
    height: fit-content;
    max-height: 150px;
    background: white;
    background-clip: padding-box;
    border: solid 2px transparent;
    border-radius: 10px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(white, white), linear-gradient(100deg, #ffffff, #76767620),
      linear-gradient(180deg, #76767620, #ffffff);
    background-origin: border-box;
    background-clip: content-box, border-box;
    > button {
      background: #e8e8e8;
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const SelectUl = styled.ul`
  position: absolute;
  padding-bottom: 4px;
  width: 100%;
  height: fit-content;
  max-height: 120px;
  overflow: auto;
  background: #e8e8e8;
  // overflow 스크롤
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: transparent;
    border-radius: 40px;
    margin: 20px 0;
    scrollbar-gutter: both;
  }
  &::-webkit-scrollbar-thumb {
    background: #98999a;
    border-radius: 40px;
    padding: 1rem;
    background-clip: padding-box;
  }
  li {
    position: relative;
    padding: 0rem 1rem;
    height: 30px;
    cursor: pointer;
    :last-child {
      margin-bottom: 0;
    }
    button {
      padding: 0rem 1rem;
      width: 100%;
      height: 100%;
      color: #5b5b5c;
      background: transparent;
      font-size: 1.2rem;
      font-weight: 500;
      text-align: center;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    :hover {
      button {
        border-radius: 5px;
        background: #fdfdfd;
        font-weight: 700;
      }
    }
  }
`;
/* End copy Select */
