import { instance, instanceFile } from '@/shared/lib/clientAxios';
import { getAllCallbackList } from '@/apis/api/myPageApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { callbackListRecoil } from '@/recoil/atoms/useCallbackList';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useCallingValidation from './useCallingValidation';

interface callingNumberType {
  callbackNo: string;
  alarmPhone: String;
  remark: String;
  callbackNumberCount: number;
  file: FileList;
}

type TRadioToggle = 'nice' | 'paper' | null;

export const useCallingNumberAdmin = () => {
  const queryClient = useQueryClient();
  /* 발신번호 등록하는 방법 toggle STATE */
  const [radioToggle, setRadioToggle] = useState<TRadioToggle>('nice');
  /* 승인된 발신번호 List Recoil */
  const callbackListS = useRecoilValue(callbackListRecoil);
  /* useQuery enabled에 대한 STATE */
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [, setSelectOpen] = useState(false);

  const location = useLocation();
  const { confirmModal, warningModal, successModal } = useModalHook();

  // 컴포넌트가 마운트될 때 isEnabled를 true로 설정
  useEffect(() => {
    const searchUrl = location.pathname.includes('callingnumber');
    if (searchUrl) {
      setIsEnabled(true);
    }
  }, []);

  const { data: allCallingNumberList, refetch: allCallingNumberListRefetch } = useQuery(
    ['/allCallbackList'],
    getAllCallbackList,
    {
      enabled: isEnabled,
    },
  );

  // ==============================
  // onChange input radio 변경 hook
  // ==============================
  const onChangeRadioToggle = (e: any) => {
    setRadioToggle(e.target.value);
  };

  const { schema } = useCallingValidation();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<callingNumberType>({
    resolver: yupResolver(schema) as any,
    mode: 'onSubmit',
    defaultValues: {
      callbackNo: ``,
      alarmPhone: ``,
      remark: ``,
      callbackNumberCount: callbackListS?.length,
    },
  });

  // 등록하기 버튼
  const handleCertifyClick = (data: callingNumberType) => {
    if (data.file.length === 0) {
      warningModal('발신번호 등록', '파일을 첨부 해 주세요.', true);
      return;
    }

    if (callbackListS?.length === 10) {
      warningModal('발신번호 등록', '발신번호 등록은 최대 10개까지 가능합니다.', true);
      return;
    }

    confirmModal(
      async () => {
        try {
          // 주소 관련 필드를 합쳐서 corpAddress로 설정
          const callingNumberData: any = {
            callbackNo: data.callbackNo,
            alarmPhone: data.alarmPhone,
            remark: data.remark,
            callbackNumberCount: callbackListS?.length,
          };

          // 폼 데이터 생성
          const formData = new FormData();
          const json = JSON.stringify(callingNumberData);
          const blob = new Blob([json], { type: 'application/json' });
          formData.append('requestDto', blob);
          formData.append('file', data.file[0]); // 파일은 배열로 전송됩니다.

          // Axios를 사용하여 서버로 데이터 전송
          await instanceFile.post('registerCallback', formData);
          successModal('발신번호 등록', '발신번호 등록을 완료했습니다.', true);
          queryClient.invalidateQueries(['/allCallbackList']);
        } catch (error: any) {
          warningModal('발신번호 등록', error.response.data, true);
        }
        allCallingNumberListRefetch();
      },
      '발신번호 등록',
      '발신번호 등록 하시겠습니까?',
      true,
    );
  };

  // 발신번호 삭제
  const handleDelete = async (e: any, callbackNo: string) => {
    e.preventDefault();
    confirmModal(
      async () => {
        try {
          await instance.post(`/deleteCallback?callbackNo=${callbackNo}`);
          successModal('발신번호 삭제', '선택한 발신번호가 삭제되었습니다.', true);
          localStorage.removeItem('contacts');
          queryClient.refetchQueries(['/message/getCallbackList']);
          queryClient.invalidateQueries(['/allCallbackList']);
        } catch (error) {
          console.error(error);
          warningModal('발신번호 삭제', '선택한 발신번호 삭제를 실패했습니다.', true);
        }
      },
      '발신번호 삭제',
      '선택한 발신번호를 삭제하시겠습니까?',
      true,
    );
  };

  const selectOpenHandle = () => {
    setSelectOpen((prev) => !prev);
  };

  return {
    callbackListS,
    handleSubmit,
    handleDelete,
    register,
    errors,
    handleCertifyClick,
    selectOpenHandle,
    allCallingNumberList,
    allCallingNumberListRefetch,
    radioToggle,
    onChangeRadioToggle,
  };
};
