import TaxInvoiceHistoryTable from '@/components/Organism/Charge/TaxInvoiceHistoryTable';
import React from 'react';

const MobileEtcTaxInvoiceDetail = () => {
  return (
    <>
      <TaxInvoiceHistoryTable />
    </>
  );
};
export default MobileEtcTaxInvoiceDetail;
