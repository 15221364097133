import { useState } from 'react';
import { IPostCopyBuddyList } from '@/apis/api/addressApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useAddressSelect } from '@/components/hooks/customs/addressBook/useAddressSelect';
import { useAddressBookMutations } from '@/components/hooks/mutations/addressBook/useAddressBookMutations';
import { useGetBuddySeqNo } from '@/components/hooks/customs/commons/useGetBuddySeqNo';

/**
 * @title useCopyTable.ts => AddressBookFunBtn.tsx 관련 Hooks
 *
 * @author 김남규
 * @since 2024.03.18
 **/
export const useCopyTable = () => {
  /* 리스트 복사 중복 요청 방지 버튼 STATE */
  const [copyBtnBlock, setCopyBtnBlock] = useState(false);
  /* mutate 보낼 STATE */
  const [postCopyList, setPostCopyList] = useState<IPostCopyBuddyList>();
  const selectHooks = useAddressSelect();

  /**
   * @title 주소록 리스트 COPY Hook (POST)
   *
   * @author 김남규
   * @since 2024.03.18
   **/
  const { copyM, copyL } = useAddressBookMutations();
  const { getBuddySeqNosToMainTableCheckDataFormatRecoil } = useGetBuddySeqNo();
  const { successModal, warningModal } = useModalHook();

  /**
   * @title 주소록 복사 - 리스트 onClick ( list 선택 )
   * 이동할 곳의 groupSeqNo를 받아서 저장
   * @author 김남규
   * @since 2024.03.20
   **/
  const onClickTargetGroup = (group: number) => {
    setPostCopyList({ groupSeqNo: group });
  };

  /**
   * @title 주소록 복사 - 확인 onClick ( 확인 Btn )
   *
   * @author 김남규
   * @since 2024.03.18
   **/
  const onClickConfirmCopy = async (group: number) => {
    setCopyBtnBlock(true);
    if (copyBtnBlock) return;
    const buddyCopyList = await getBuddySeqNosToMainTableCheckDataFormatRecoil();
    if (buddyCopyList.length === 0) {
      warningModal('주소록 복사', '선택된 연락처가 없습니다.', true);
      setCopyBtnBlock((prev) => prev && false);
      selectHooks.setToggleCopySelect((prev) => !prev && false);
      return;
    }
    copyM(
      {
        groupSeqNo: group,
        buddySeqNos: buddyCopyList,
      },
      {
        onSuccess: () => {
          successModal('주소록 복사', '주소록 복사가 완료했습니다.', true);
          setCopyBtnBlock((prev) => prev && false);
        },
        onError: () => {
          setCopyBtnBlock((prev) => prev && false);
        },
      },
    );
  };
  /**
   * @title 주소록 복사 버튼 onClick ( Btn )
   *
   * @author 김남규
   * @since 2024.03.18
   **/
  const onClickCopySelect: React.MouseEventHandler<HTMLButtonElement> = () => {
    selectHooks.setToggleCopySelect((prev) => !prev);
  };

  /**
   * @title 주소록 복사 - 취소 onClick ( 취소 Btn )
   *
   * @author 김남규
   * @since 2024.03.18
   **/
  const onClickCancelCopy: React.MouseEventHandler<HTMLButtonElement> = () => {
    selectHooks.setToggleCopySelect(false);
  };

  return {
    copyL,
    postCopyList,
    copyBtnBlock,
    onClickConfirmCopy,
    onClickCopySelect,
    onClickCancelCopy,
    onClickTargetGroup,
  };
};
