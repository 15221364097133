import React from 'react';
import styled from 'styled-components';

interface IBody {
  children: React.JSX.Element;
}

const BodyContainer = styled.div`
  /* 기본 테이블 높이 조정 */

  > .bodyWrap {
    min-height: calc(100vh - 218px);
    background: ${(props) => props.theme.colors.background};
  }
`;

const Body = (props: IBody) => {
  return (
    <BodyContainer>
      <div className="bodyWrap">{props.children}</div>
    </BodyContainer>
  );
};
export default Body;
