import PaymentHistoryTable from '@/components/Organism/Charge/PaymentHistoryTable';
import React from 'react';

const MobileEtcPayment = () => {
  return (
    <>
      <PaymentHistoryTable />
    </>
  );
};
export default MobileEtcPayment;
