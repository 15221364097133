import React, { useEffect } from 'react';
import * as S from './MobileMessageList.style';
import { useEachChatRoomHook } from '@/components/hooks/customs/useEachChatRoomHook';
import { formatDateAll } from '@/shared/util/format/dateFormatUtil';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { removeWebCharacter } from '@/shared/util/format/contentFormatUtil';
import NextLoader from '@/components/common/NextLoader';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { mobileBuddyListAddRecoil, syncLoadingRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { activeChatRoomIdRecoil, chatUserInfoRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import IconImage from '@/styles/icons/IconImage';
import IconFile from '@/styles/icons/IconFile';
import IconCassetteTape from '@/styles/icons/IconCassetteTape';
import NoData from '@/components/common/NoData';

const MobileMessageList = () => {
  // 015 개별 채팅방 Hooks
  const eachHooks = useEachChatRoomHook();
  const navigate = useNavigate();
  const setMobileBuddyListAddRecoilS = useSetRecoilState(mobileBuddyListAddRecoil);

  const setSyncLoading = useSetRecoilState(syncLoadingRecoil);

  /* messageHistory api 요청 params */
  const [, setMhQueryParams] = useRecoilState(mhQueryParamsRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const [chatUserInfo, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);
  const { data, isLoading } = eachHooks.inFiniteResult;

  useEffect(() => {
    if (isLoading) {
      setSyncLoading(true);
    } else {
      setSyncLoading(false);
    }
  }, [isLoading]);

  return (
    <>
      <S.MessageContainer
        onScroll={(event) => eachHooks.handleInfiniteScroll(event, eachHooks.inFiniteResult)}
        id={'eachChatRoom'}
      >
        {!isLoading && eachHooks.amlDataS.length === 0 ? (
          <NoData />
        ) : (
          eachHooks.amlDataS.map((list, index) => {
            return (
              <S.MessageListContainer
                key={`${list.phoneNumber}`}
                onClick={() => {
                  if (list.phoneNumber) {
                    navigate(`/mobile/message/messageChatRoom/${list.phoneNumber}`);

                    setChatUserInfo({ phoneNumber: list.phoneNumber, buddyName: list.buddyName });
                    setMobileBuddyListAddRecoilS({
                      buddyNm: list.buddyName,
                      keyCommNo: list.phoneNumber,
                    });

                    setMhQueryParams((prevState: any) => {
                      return {
                        ...prevState,
                        contactNumber: list.phoneNumber,
                      };
                    });
                  }
                }}
              >
                <S.MessageImage>015</S.MessageImage>
                <S.MessageContents>
                  <S.MessageContentsTop>
                    {list.buddyName && <h3>{list.buddyName}</h3>}
                    {list.buddyName ? <div>{TelHyphen(list.phoneNumber)}</div> : <h3>{TelHyphen(list.phoneNumber)}</h3>}
                  </S.MessageContentsTop>
                  <S.MessageContentsBottom>
                    <div className="textContent">
                      {list.msgType === 'MMS' && (
                        <div className="iconGroup">
                          <p className="imageIcon">
                            <IconImage />
                          </p>
                        </div>
                      )}
                      {list.msgType === 'VMS' && (
                        <div className="iconGroup">
                          <p className="voiceIcon">
                            <IconCassetteTape fontSize={'1rem'} />
                          </p>
                        </div>
                      )}
                      {list.msgType === 'FILE' && (
                        <div className="iconGroup">
                          <p className="fileIcon">
                            <IconFile />
                          </p>
                        </div>
                      )}
                      {list.msgType === 'MMS' && (
                        <p className="text">{removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '이미지'}</p>
                      )}
                      {list.msgType === 'VMS' && (
                        <p className="text">
                          {removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '음성메시지'}
                        </p>
                      )}
                      {list.msgType === 'FILE' && (
                        <p className="text">{removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '파일'}</p>
                      )}
                      {removeWebCharacter(list.sndMsg, '[Web발신]').length > 0 && (
                        <p className="text">{removeWebCharacter(list.sndMsg, '[Web발신]')}</p>
                      )}
                    </div>
                  </S.MessageContentsBottom>
                </S.MessageContents>
                <S.MessageInfo>
                  <div>{formatDateAll(list.msgDttm)}</div>
                  {list.rcvMsgCount > 0 && <span>{list.rcvMsgCount}</span>}
                </S.MessageInfo>
              </S.MessageListContainer>
            );
          })
        )}
        {eachHooks.inFiniteResult.isFetchingNextPage && <NextLoader />}
      </S.MessageContainer>
    </>
  );
};

export default MobileMessageList;
