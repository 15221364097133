import styled from 'styled-components';

export const SoftPhoneContainer = styled.div<{ isActive: boolean }>`
  z-index: 5;
  position: relative;
  width: 52rem;
  max-width: 52rem;
  border-left: 1px solid ${(props) => props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.background};
  font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;

  .openArrow {
    display: none;
  }

  input,
  textarea {
    font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
  }
  @media screen and (max-width: 1920px) {
    width: 45rem;
  }
  @media screen and (max-width: 1440px) {
    margin-top: 0;
    position: fixed;
    top: 0;
    right: -45rem;
    height: 100%;
    transition:
      left 0.2s linear,
      right 0.2s linear;

    &.active {
      right: 0;

      /* 전송하기 */
      .ManagementBtnWrap {
        .messageSend {
          right: 2rem;
          transition: right 0.5s linear;
        }
      }
    }
    /* 전송하기 */
    .ManagementBtnWrap {
      .messageSend {
        transition: right 0s linear;
        right: -20rem;
      }
    }

    .openArrow {
      display: block;
      position: absolute;
      top: 50%;
      left: -60px;
      transform: translateY(-50%);
      button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.6rem;
        width: 50px;
        min-height: 80px;
        padding: 4rem 1.2rem;
        border-radius: 123px;
        /* 75% */
        background-color: ${(props) => props.theme.colors.gray800}bf;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.2);
        p {
          color: ${(props) => props.theme.colors.txt100};
        }
        svg {
          width: 100%;
          height: 50%;
          color: ${(props) => props.theme.colors.txt100};
        }
      }
    }
    &.active {
      .openArrow {
        button {
          /* 94% */
          background-color: ${(props) => props.theme.colors.main}f0;
        }
        svg,
        p {
          color: ${(props) => props.theme.colors.txt100};
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .openArrow {
      display: none;
    }
    max-width: unset;
    width: 100%;
    right: 0;
  }
`;
export const SoftPhoneInner = styled.div`
  padding: 1.2rem;

  @media screen and (max-width: 1440px) {
    position: relative;
    top: unset;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    border-radius: 0;
    box-shadow: 0;
    padding: 0;
  }
`;
export const SoftPhoneBlock = styled.div`
  position: sticky;
  top: 1.2rem;

  @media screen and (max-width: 640px) {
    height: auto;
    border-radius: 0;
  }
`;
