import React from 'react';
import styled from 'styled-components';

const CustomCheckbox = styled.input`
  cursor: pointer;
  /* 체크되지 않은 상태의 스타일 */
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid rgb(145, 144, 145); /* 테두리 스타일 */
  background-color: transparent;
  border-radius: 0; /* border-radius를 0으로 설정하여 직사각형 모양으로 만듭니다. */
  outline: none; /* 포커스 효과 제거 */
  margin: 0 0.5rem 0 0;
  position: relative;

  /* 체크된 상태의 스타일 */
  &:checked {
    background-color: #fff; /* 체크되었을 때 배경색 변경 */
    border: ${(props) => `1px solid ${props.theme.colors.main}`}; /* 테두리 스타일 */
  }

  /* 체크 표시 제거 */
  &:after {
    content: ''; /* 가상 요소 생성 */
    display: block;
    width: 5px;
    height: 11px;
    border: solid transparent;
    border-width: 0 2px 2px 0; /* 체크 모양 생성 */
    transform: rotate(45deg);
    position: absolute;
    top: 0px;
    left: 4px;
    opacity: 0; /* 체크 표시 숨김 */
  }

  /* 체크된 상태일 때 체크 표시 보이기 */
  &:checked:after {
    opacity: 1;
    border-color: ${(props) => props.theme.colors.main};
  }
`;
const AgreementLabel = styled.label`
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
`;

const AgreementCheckBoxInner = ({ id, checkLabel, checkItemHandler, checked }: any) => {
  const handleAgreement = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkItemHandler(e.target.id, e.target.checked);
  };
  return (
    <AgreementLabel htmlFor={checkLabel}>
      <CustomCheckbox id={id} name={checkLabel} checked={checked.has(id)} onChange={handleAgreement} type="checkbox" />
      본 약관에 동의합니다.
    </AgreementLabel>
  );
};

export default AgreementCheckBoxInner;
