import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import React from 'react';
import * as S from '../MobileHeader.style';
import { useNavigate } from 'react-router-dom';
import { TbSettings } from 'react-icons/tb';

const ArsTreeHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <S.HeaderLeft>
        <S.HeaderTitle>인사말 설정</S.HeaderTitle>
      </S.HeaderLeft>
      {/* <S.IconContainer>
        <S.HeaderButtonIcon
          onClick={() => {
            navigate(-1);
          }}
        >
          <TbSettings />
        </S.HeaderButtonIcon>
      </S.IconContainer> */}
    </>
  );
};
export default ArsTreeHeader;
