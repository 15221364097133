import styled from 'styled-components';

export const AddGroupComponent = styled.form`
  z-index: 1;
  gap: 1rem;
  border-top: 1px solid ${(props) => props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};
  padding: 1rem 1.3rem;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0px 0.7rem 1.3rem 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
  > svg {
    margin: 1rem 0 0;
    width: 1.7rem;
    height: 1.7rem;
  }
  .addGroup {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 3.8rem;
    background-color: ${(props) => props.theme.colors.white};
    outline: 1px solid ${(props) => props.theme.colors.gray300};
    padding: 0 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.gray600};

    :has(input:focus) {
      outline-color: ${(props) => props.theme.colors.gray400};
    }
  }
  input {
    width: 100%;
    border: 0;
    font-size: 1.6rem;
    font-weight: 600;
    background: transparent;
    color: ${(props) => props.theme.colors.txt500};

    :focus {
      border: 0;
      outline: 0;
    }
    ::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
      border: 0;
      font-size: 1.4rem;
    }
  }

  /* 저장, 취소 스타일 */
  .btnGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    button {
      cursor: pointer;
      width: 48%;
      border-radius: 0.5rem;
      padding: 0.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      background: ${(props) => props.theme.colors.grayButton2};
      color: ${(props) => props.theme.colors.grayButtonText};
      &.addBtn {
        background: ${(props) => props.theme.colors.grayButton};
      }
    }
  }
`;
