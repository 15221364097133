import FileSaver from 'file-saver';
import { instance } from '@/shared/lib/clientAxios';
// eslint-disable-next-line import/no-cycle
import { pointExcelDownload } from '../../components/hooks/customs/charge/usePointExcel';
import { messageExcelDownload } from '@/components/hooks/customs/charge/useMessageResultExcel';

export type GetPointApiType = {
  rsrvd_id: string;
  phn_id: string;
  use_cc: number;
  use_pg: number;
  used_cc: number;
  used_pg: number;
  use_amt: number;
};
// 유저 포인트
export const getPointApi = async (): Promise<GetPointApiType> => {
  const response = await instance.get('/userPoint');
  return response.data;
};

// 사용내역 조회 API
export const getPointResult = async (
  currentPage: number,
  pageSize: number,
  dates?: string[] | string,
  selectedValue?: string | null,
) => {
  try {
    if (dates === undefined) return null;
    const response = await instance.get('/pointHistory', {
      params: {
        pageSize,
        pageNumber: currentPage,
        searchStartDate: dates[0] !== '' ? dates[0] : null,
        searchEndDate: dates[1] !== '' ? dates[1] : null,
        selectedValue: selectedValue === 'all' || selectedValue === 'null' ? null : selectedValue,
      },
    });
    return response.data;
  } catch (error) {
    return '';
  }
};

// 전송결과 조회 API
export const getMessageResult = async (
  currentPage: number,
  pageSize: number,
  dates?: string[] | string,
  selectedValue?: string | null,
  selectMode?: string,
) => {
  try {
    if (dates === undefined) return null;
    const response = await instance.get(
      localStorage.getItem('callBack') === '01067873947' ? '/mcmp/result/search' : '/result010',
      {
        params: {
          pageSize,
          pageNumber: currentPage,
          searchStartDate: dates[0] !== '' ? dates[0] : null,
          searchEndDate: dates[1] !== '' ? dates[1] : null,
          selectedValue: selectedValue === 'all' || selectedValue === 'null' ? null : selectedValue,
        },
      },
    );
    return response.data;
  } catch (error) {
    return '';
  }
};

// 결제내역
export const getPayResult = async (
  currentPage: number,
  pageSize: number,
  selectedValue?: string | null,
  dates?: string[] | string,
) => {
  try {
    if (dates === undefined) return null;
    const response = await instance.get('/payResult', {
      params: {
        pageSize,
        pageNumber: currentPage,
        selectedValue: selectedValue === 'all' || selectedValue === 'null' ? null : selectedValue,
        searchStartDate: dates !== '' ? dates[0] : null,
        searchEndDate: dates !== '' ? dates[1] : null,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return '';
  }
};

// 세금계산서/현금영수증 신청내역
export const getTaxInvoiceList = async (currentPage: any): Promise<any> => {
  try {
    const response = await instance.get(`/getTaxInvoiceList?pageNumber=${currentPage ?? 0}&pageSize=${10}`);
    if (response.status === 200) {
      return response.data;
    }
    return undefined;
  } catch (error) {
    return error;
  }
};

// 세금계산서 발행내역
export const getTaxBillList = async (currentPage: any): Promise<any> => {
  try {
    const response = await instance.get(`/getTaxBillList?pageNumber=${currentPage ?? 0}&pageSize=${10}`);
    if (response.status === 200) {
      return response.data;
    }
    return undefined;
  } catch (error) {
    return error;
  }
};

export const payResultPrintAll = async (dates?: string[] | string) => {
  try {
    if (dates === undefined) return null;
    const response = await instance.get('/payResultPrint', {
      params: {
        searchStartDate: dates !== '' ? dates[0] : null,
        searchEndDate: dates !== '' ? dates[1] : null,
      },
    });
    return response.data;
  } catch (error) {
    return '';
  }
};

export const cshPubDetail = async (serialnum: any) => {
  try {
    if (serialnum === undefined) return null;
    const response = await instance.get('/cshPubDetail', {
      params: {
        serialnum: serialnum !== '' ? serialnum : null,
      },
    });
    return response.data;
  } catch (error) {
    return '';
  }
};

export const pointHistoryExcelDownload = async ({ searchDates, searchSelectValue }: pointExcelDownload) => {
  try {
    const response = await instance.get('/pointHistoryExcelDownload', {
      responseType: 'blob',
      params: {
        searchStartDate: searchDates !== '' ? searchDates[0] : null,
        searchEndDate: searchDates !== '' ? searchDates[1] : null,
        selectedValue: searchSelectValue,
      },
    });

    const contentDisposition = response.headers['content-disposition'];
    const filenameMatch = /filename="(.*)"/.exec(contentDisposition);
    const filename = filenameMatch ? filenameMatch[1] : '사용내역.xlsx';

    // 파일 다운로드
    FileSaver.saveAs(response.data, filename);
    return response;
  } catch (error) {
    return error;
  }
};

export const messageResultExcelDownload = async ({ searchDates, searchSelectValue }: messageExcelDownload) => {
  try {
    const reqselectValue = searchSelectValue === 'all' ? null : searchSelectValue;
    const response = await instance.get(
      localStorage.getItem('callBack') === '01067873947'
        ? '/mcmp/result/search/excel-download'
        : '/result010/excel-download',
      {
        responseType: 'blob',
        params: {
          searchStartDate: searchDates !== '' ? searchDates[0] : null,
          searchEndDate: searchDates !== '' ? searchDates[1] : null,
          selectedValue: reqselectValue,
        },
      },
    );

    const contentDisposition = response.headers['content-disposition'];
    const filenameMatch = /filename="(.*)"/.exec(contentDisposition);
    const filename = filenameMatch ? filenameMatch[1] : '전송결과.xlsx';

    // 파일 다운로드
    FileSaver.saveAs(response.data, filename);
    return response;
  } catch (error) {
    return error;
  }
};

export const ArsJsonData = async ({ fileName }: { fileName: string }) => {
  const response = await instance.get(`/vms/json/${fileName}`); // '/json/filename'에 실제 백엔드 엔드포인트를 넣어주세요.
  return response.data;
};
