import React from 'react';
import * as S from './CustomerService.styles';
import { useRecoilState } from 'recoil';
import { customerRouterRecoil } from '@/recoil/atoms/customer/customer';
import { useNavigate } from 'react-router-dom';

/**
 * @title 공지사항 우측 컴포넌트 ( 소프트폰 영역 )
 * */
const CustomerService = () => {
  const navigate = useNavigate();
  const [customerRouterState, setCustomerRouterState] = useRecoilState(customerRouterRecoil);

  const handleRouter = (number: number) => {
    setCustomerRouterState(number);
  };

  return (
    <S.CustomerServiceWrap className="customerService">
      <div className="serviceGroup">
        <S.CustomerService>
          <h2>고객센터</h2>
          <div className="helpGroup">
            <button className={customerRouterState === 0 ? 'active' : ''} onClick={() => handleRouter(0)}>
              <span className="text">공지사항</span>{' '}
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="23" viewBox="0 0 12 23" fill="none">
                <path
                  d="M0.313849 1.78345L9.53653 10.7626C9.7388 10.9585 9.85225 11.223 9.85225 11.4986C9.85225 11.7742 9.7388 12.0386 9.53653 12.2345L0.31585 21.2156C0.114632 21.4132 0.0020009 21.6784 0.00200091 21.9545C0.00200092 22.2305 0.114632 22.4957 0.31585 22.6933C0.414611 22.7904 0.532651 22.8675 0.663015 22.9202C0.793378 22.9729 0.933426 23 1.07491 23C1.21638 23 1.35643 22.9729 1.4868 22.9202C1.61716 22.8675 1.7352 22.7904 1.83396 22.6933L11.0546 13.7161C11.6605 13.1247 12 12.3292 12 11.5005C12 10.6718 11.6605 9.87624 11.0546 9.28493L1.83396 0.30769C1.73517 0.210323 1.617 0.132929 1.48646 0.0800781C1.35591 0.0272292 1.21563 -5.31367e-08 1.0739 -4.69419e-08C0.932184 -4.07471e-08 0.791901 0.0272293 0.661353 0.0800781C0.530805 0.132929 0.412641 0.210323 0.313849 0.30769C0.112631 0.505295 -9.71685e-07 0.770443 -9.59617e-07 1.04653C-9.47549e-07 1.32262 0.112631 1.58776 0.313849 1.78537"
                  fill="#366EFF"
                />
              </svg>
            </button>
            <button className={customerRouterState === 1 ? 'active' : ''} onClick={() => handleRouter(1)}>
              <span className="text">자주 묻는 질문</span>{' '}
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="23" viewBox="0 0 12 23" fill="none">
                <path
                  d="M0.313849 1.78345L9.53653 10.7626C9.7388 10.9585 9.85225 11.223 9.85225 11.4986C9.85225 11.7742 9.7388 12.0386 9.53653 12.2345L0.31585 21.2156C0.114632 21.4132 0.0020009 21.6784 0.00200091 21.9545C0.00200092 22.2305 0.114632 22.4957 0.31585 22.6933C0.414611 22.7904 0.532651 22.8675 0.663015 22.9202C0.793378 22.9729 0.933426 23 1.07491 23C1.21638 23 1.35643 22.9729 1.4868 22.9202C1.61716 22.8675 1.7352 22.7904 1.83396 22.6933L11.0546 13.7161C11.6605 13.1247 12 12.3292 12 11.5005C12 10.6718 11.6605 9.87624 11.0546 9.28493L1.83396 0.30769C1.73517 0.210323 1.617 0.132929 1.48646 0.0800781C1.35591 0.0272292 1.21563 -5.31367e-08 1.0739 -4.69419e-08C0.932184 -4.07471e-08 0.791901 0.0272293 0.661353 0.0800781C0.530805 0.132929 0.412641 0.210323 0.313849 0.30769C0.112631 0.505295 -9.71685e-07 0.770443 -9.59617e-07 1.04653C-9.47549e-07 1.32262 0.112631 1.58776 0.313849 1.78537"
                  fill="#366EFF"
                />
              </svg>
            </button>
            {/*<button className={customerRouterState === 2 ? 'active' : ''} onClick={() => handleRouter(2)}>*/}
            {/*  <span className="text">이벤트 게시판</span>{' '}*/}
            {/*  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="23" viewBox="0 0 12 23" fill="none">*/}
            {/*    <path*/}
            {/*      d="M0.313849 1.78345L9.53653 10.7626C9.7388 10.9585 9.85225 11.223 9.85225 11.4986C9.85225 11.7742 9.7388 12.0386 9.53653 12.2345L0.31585 21.2156C0.114632 21.4132 0.0020009 21.6784 0.00200091 21.9545C0.00200092 22.2305 0.114632 22.4957 0.31585 22.6933C0.414611 22.7904 0.532651 22.8675 0.663015 22.9202C0.793378 22.9729 0.933426 23 1.07491 23C1.21638 23 1.35643 22.9729 1.4868 22.9202C1.61716 22.8675 1.7352 22.7904 1.83396 22.6933L11.0546 13.7161C11.6605 13.1247 12 12.3292 12 11.5005C12 10.6718 11.6605 9.87624 11.0546 9.28493L1.83396 0.30769C1.73517 0.210323 1.617 0.132929 1.48646 0.0800781C1.35591 0.0272292 1.21563 -5.31367e-08 1.0739 -4.69419e-08C0.932184 -4.07471e-08 0.791901 0.0272293 0.661353 0.0800781C0.530805 0.132929 0.412641 0.210323 0.313849 0.30769C0.112631 0.505295 -9.71685e-07 0.770443 -9.59617e-07 1.04653C-9.47549e-07 1.32262 0.112631 1.58776 0.313849 1.78537"*/}
            {/*      fill="#366EFF"*/}
            {/*    />*/}
            {/*  </svg>*/}
            {/*</button>*/}
            <S.VocHelp
              className="vocHelp"
              onClick={() => window.open('http://www.voc.standardnetworks.co.kr/?origin=standart&code=00')}
            >
              <p className="text">도움이 필요하신가요?</p>
              <S.ArreoService>
                <h2>015 works. 고객센터</h2>
                <div className="addressBook">
                  <div className="smallGroup phoneGroup">
                    <h4>대표번호</h4>
                    <h3>015-8504-0006</h3>
                  </div>
                  <div className="smallGroup workGroup">
                    <p>
                      평일 9:00~18:00 (주말/공휴일 휴무)
                      <br />
                      (점심시간 12:00 ~ 13:00)
                    </p>
                  </div>
                  <div className="smallGroup emailGroup">
                    <h4>실시간 문자 상담</h4>
                    <h3>015-8504-0006</h3>
                  </div>
                  <div className="smallGroup emailGroup">
                    <h4>대표메일</h4>
                    <h3>help@arreo.com</h3>
                  </div>
                </div>
              </S.ArreoService>
              <div className="chat">
                <p>문의하실 내용이 있으면 클릭해주세요</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                  <path
                    d="M18.9263 10.7815C20.23 10.1672 20.2301 8.30273 18.9256 7.68932L2.99005 0.184368C1.68152 -0.431452 0.254349 0.768903 0.62612 2.17127L2.19894 8.09713L8.87067 8.09695C9.16777 8.10213 9.45095 8.22433 9.65922 8.43724C9.86749 8.65015 9.98419 8.93673 9.98418 9.23525C9.98417 9.53377 9.86746 9.82035 9.65917 10.0333C9.45089 10.2462 9.16771 10.3684 8.87061 10.3736L2.19887 10.3738L0.626539 16.3005C0.253888 17.7021 1.6818 18.9016 2.98956 18.2865L18.9263 10.7815Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </S.VocHelp>
          </div>
        </S.CustomerService>
      </div>
      <button
        className="backButton"
        onClick={() => {
          navigate(-1);
        }}
      >
        뒤로가기
      </button>
    </S.CustomerServiceWrap>
  );
};

export default CustomerService;
