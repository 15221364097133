import React from 'react';
import * as S from '../MobileHeader.style';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { selectedMobileGroupListRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import IconAddAddress from '@/styles/icons/IconAddAddress';

const AddressBuddyListHeader = () => {
  const navigate = useNavigate();
  const selectedMobileGroupListS = useRecoilValue(selectedMobileGroupListRecoil);

  return (
    <>
      <S.HeaderLeft className="chatRoomHeader">
        <S.HeaderButtonIcon
          onClick={() => {
            navigate(-1);
          }}
        >
          {/* <TbArrowBack /> */}
          <IconArrowLongLeft />
        </S.HeaderButtonIcon>
        {selectedMobileGroupListS.groupNm && <S.HeaderTitle>{selectedMobileGroupListS.groupNm}</S.HeaderTitle>}
      </S.HeaderLeft>

      {/* 주소록 추가 버튼 */}
      <S.IconContainer className="addressBuddyListHeader">
        <S.HeaderButtonIcon
          onClick={() => {
            navigate('/mobile/address/buddyAdd');
          }}
        >
          <IconAddAddress />
        </S.HeaderButtonIcon>
      </S.IconContainer>
    </>
  );
};

export default AddressBuddyListHeader;
